import { useEffect, useState } from "react";
import "./styles.css";

import { useNavigate } from "react-router-dom";

import { ReactComponent as ChevronRighticon } from "../../../../assets/svg/icons/chevronRight.svg";
import { ReactComponent as Circleicon } from "../../../../assets/svg/icons/circle.svg";
import { useFilter } from "../../../../hooks/useFilter";

type MenuOptionProps = {
  name: string;
  title?: string;
  withoutIcon?: boolean;
  to?: string;
  onClicked?: () => void;
  subItems?: Array<MenuOptionProps>;
};

export function MenuOption({ title, withoutIcon, to, name, onClicked, subItems }: MenuOptionProps) {
  const navigate = useNavigate();
  const { setSideBarOptionSelected } = useFilter();

  const [selected, setSelected] = useState<boolean>(false);
  const [nameMenu] = useState<string>(name);

  function isParentSelected(parentOption: string): boolean {
    return parentOption ? window.location.pathname.includes(parentOption) : false;
  }

  useEffect(() => {
    isParentSelected(nameMenu) ? setSelected(true) :
      setSelected(nameMenu === window.location.pathname);
  }, [window.location.pathname]);

  return (
    <>
      <button
        className={`sidebar_menuoption--button sidebar_menuoption${!subItems && selected && "--selected"}`}
        onClick={() => {
          onClicked ? onClicked() : null
          if (to) {
            if (!to.includes("dashboard")) {
              setSideBarOptionSelected('MAPA');
            }
            navigate(to);
          }
        }}
      >
        <div
          className={`sidebar_subMenuoptionIcon${subItems && selected ? "--selected" : ""}`}
        >
          {!withoutIcon && (selected ? <ChevronRighticon /> : <Circleicon />)}
        </div>
        <span id="sidebar_menuoption--text">{title}</span>
      </button>

      <div
        className={`sidebar_subMenuoption--collapsible${selected ? "--selected" : ""}`}
      >
        {subItems && subItems.map((item, index) => (
          <div
            key={index}
            className={`sidebar_menuoption--subitems${selected ? "--selected" : ""}`}
          >
            <MenuOption {...item} />
          </div>
        ))}
      </div>
    </>
  );
}
