import { Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home/Index";
import { Login } from "../pages/Session/Login/Index";
import { NewPassword } from "../pages/Session/NewPassword/Index";
import { SessionTemplate } from "../pages/Session/SessionTemplate/Index";

export function MainRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/" element={<SessionTemplate />}>
          <Route path="entrar" element={<Login />} />
          <Route path="novasenha" element={<NewPassword />} />
        </Route>
      </Routes>
    </>
  );
}
