import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ReactComponent as GrassIcon } from "../../../../assets/svg/icons/grass.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";
import { ButtonYesOrNo } from "../../../Buttons/ButtonYesOrNo";
import { format } from "date-fns";

interface InfoSprayingProps {
  task: ITaskSpraying | undefined;
}


export default function InfoSpraying(task: InfoSprayingProps) {
  const [taskSpraying, setTaskSpraying] = useState<ITaskSpraying>();
  useEffect(() => {
    setTaskSpraying(task.task)
  }, []);


  return (
    <>
      <div >
        <span>No pasto</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <GrassIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskSpraying?.initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <div className="containerQuestion">
        <MessageBalloon text={`A tarefa foi iniciada?`} pointed />
        <ButtonYesOrNo onlyResult optionSelected={!taskSpraying?.skip_reason ? "yes" : "no"} />
      </div>

      {taskSpraying?.skip_reason ?
        <MessageBalloon text={`${taskSpraying?.skip_reason}`} pointed />
        :
        <>
          <div className="containerQuestion">
            <MessageBalloon text={`Qual o tipo de operação?`} pointed />
            <ButtonPressed onlyResult optionSelected={1} value={1} text={`${taskSpraying?.operation_type}`} />
          </div>

          {taskSpraying?.operation_type == "MECANIZADA" &&
            <>
              <MessageBalloon text={`${format(new Date(taskSpraying?.start_time || 0), 'dd/MM/yyyy - HH:mm')}`} />

              <MessageBalloon text={`Informar horímetro inicial`} />
              <CounterInputEdit isDisabled defaultValue={taskSpraying?.initial_hour_meter || 0} control={undefined} isInline label="h" />


              <ButtonPressed onlyResult optionSelected={1} value={1} text={`Horímetro final`} />

              <MessageBalloon text={`${format(new Date(taskSpraying?.end_time || 0), 'dd/MM/yyyy - HH:mm')}`} />

              <MessageBalloon text={`Informar horímetro final`} />
              <CounterInputEdit isDisabled defaultValue={taskSpraying?.final_hour_meter || 0} control={undefined} isInline label="h" />
            </>
          }
        </>
      }
    </>
  )
}