import "./styles.css"
import { CSSProperties, useEffect, useState } from "react";
import { Modal } from "../../../../Modal/Default/Index";
import { ButtonOnlyTitle } from "../../../../Buttons/ButtonOnlyTitle/Index";
import { useTaskCreation } from "../../../../../hooks/useTaskCreation";
import { RoundedCloseButton } from "../../../../Buttons/RoundedCloseButton/Index";
import { ReactComponent as CheckComplete } from "../../../../../assets/svg/icons/CheckFull.svg";
import axios from "../../../../../services/axios"

const createEvaluatorModalStyle = {
  padding: "50px",
  width: "600px",
  height: "fit-content",
  borderRadius: 20,
  position: "relative"
} as CSSProperties;

export default function StartEvaluation() {
  const { modalStartEvaluator, setModalStartEvaluator } = useTaskCreation();
  const [modalEvaluationStarted, setModalEvaluationStarted] = useState<boolean>(false);

  function submitStartEvaluation() {
    axios.post("tasks/pasture-evaluation/assign")
      .then(({ data }) => {
        setModalStartEvaluator(false)
        setModalEvaluationStarted(true)
      })
      .catch(err => console.error(err))
  }

  return (
    <>
      <Modal
        visible={modalStartEvaluator}
        contentStyle={createEvaluatorModalStyle}
      >
        <div className="closeButton__startEvaluation">
          <RoundedCloseButton
            onClick={() => {
              setModalStartEvaluator(false)
            }}
          />
        </div>
        <section className="body__startEvaluation">
          <header>
            <h2 className="title__startEvaluation">Iniciar avaliação</h2>
            <span className="description__startEvaluation">Ao iniciar a avaliação de pastagem, você irá gerar uma tarefa referente a todos os pastos com avaliador cadastrado, que ainda não possuem tarefa de avaliação de pastagem iniciada.</span>
          </header>
          <footer className="footerCreateEvaluator">
            <ButtonOnlyTitle
              title="Cancelar"
              theme="light"
              type="button"
              onClick={() => setModalStartEvaluator(false)}
            />
            <ButtonOnlyTitle
              title="Iniciar"
              theme="info"
              type="button"
              onClick={submitStartEvaluation}
            />
          </footer>
        </section>
      </Modal>

      <Modal
        visible={modalEvaluationStarted}
        contentStyle={createEvaluatorModalStyle}
      >
        <div className="closeButton__startEvaluation">
          <RoundedCloseButton
            onClick={() => {
              setModalEvaluationStarted(false)
            }}
          />
        </div>
        <section className="body__startEvaluation">
          <CheckComplete />
          <header>
            <h2 className="title__startEvaluation">Avaliação iniciada!</h2>
            <span className="description__startEvaluation">Para avaliar novamente você pode reiniciar a avaliação.</span>
          </header>
          <footer className="footerCreateEvaluator">
            <ButtonOnlyTitle
              title="Concluir"
              theme="info"
              type="button"
              onClick={() => {
                setModalEvaluationStarted(false)
                setModalStartEvaluator(false)
              }}
            />
          </footer>
        </section>
      </Modal>
    </>
  )
}