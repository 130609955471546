import { useEffect, useRef, useState } from "react";
import { IoTriangle } from "react-icons/io5";
import { FaMinus } from "react-icons/fa";
import { GoPlus } from "react-icons/go";

type CustomInputCounterProps = JSX.IntrinsicElements["input"] & {
  min?: number;
  max?: number;
  getMessageError?: (error: string) => void;
  onChangevalue?: (value: number) => void;
  isInline?: boolean;
  label?: string;
  isDisabled?: boolean;
  onlyResponse?: boolean;
  control: any;
  defaultValue: number;
  errorText?: string;
};

export function CounterInputEdit({
  name,
  min,
  max,
  getMessageError,
  onChangevalue,
  isInline = false,
  label,
  isDisabled = false,
  onlyResponse = false,
  control,
  defaultValue,
  errorText,
  ...rest
}: CustomInputCounterProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState<number>(0);

  function handleIncrementCount(value: number) {
    if (max && value > max) return max;
    if (inputRef && inputRef.current) inputRef.current.value = value.toString();
    if (onChangevalue) onChangevalue(value);
    return value;
  }

  function handleDecrementCount(value: number) {
    if ((min && value < min) || value < 0) return min || 0;
    if (inputRef && inputRef.current) inputRef.current.value = value.toString();
    if (onChangevalue) onChangevalue(value);
    return value;
  }

  function handleOnchangeInput(numb: string) {
    if (!isNaN(Number(numb)) && inputRef && inputRef.current) {
      const value = Math.abs(Number(numb));
      inputRef.current.value = value.toString();
      setValue(() => value);
    }
    if (onChangevalue) onChangevalue(value);
  }

  useEffect(() => {
    const value = Number(inputRef.current?.value);
    setValue(() => Number(value));
    onChangevalue && onChangevalue(value);
    if (inputRef.current && max && value >= max) {
      inputRef.current.value = String(max)
      setValue(max)
    } else if (inputRef.current && value < 0) {
      inputRef.current.value = String(0)
    }
  }, [inputRef.current?.value]);

  useEffect(() => {
    if (inputRef.current)
      inputRef.current.value = String(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <div
        className={`custominput__number${isInline ? "--container-inline" : "--container-block"
          } custominput__number${isDisabled ? "--disabled" : ""} ${errorText ? "custominput__number--error" : ""}`}
      >
        <div
          className={`input__counter-container${isInline && label ? "--inline" : "--block"
            }`}
        >
          <input
            onWheel={(e) => (e.target as any).blur()}
            onChange={(numb) => {
              handleOnchangeInput(numb.target.value);
              if (onChangevalue) onChangevalue(value);
            }}
            defaultValue={defaultValue}
            ref={inputRef}
            type="number"
            disabled={isDisabled}
            max={max}
            {...rest}
          />

          {isInline && label && (
            <div
              className="label__only--inline"
              onClick={() => inputRef.current?.focus()}
            >
              <span>{label}</span>
            </div>
          )}
        </div>

        <div className={`btn__up--counter${isInline ? "-inline" : "-block"}`}>
          <button
            disabled={isDisabled || (max && value === max) || false}
            onClick={() => {
              setValue((prevState) => handleIncrementCount(prevState + 1));
            }}
            type="button"
            hidden={onlyResponse}
          >
            {isInline ? <GoPlus /> : <IoTriangle />}
          </button>
        </div>

        <div className={`btn__down--counter${isInline ? "-inline" : "-block"}`}>
          <button
            disabled={isDisabled || (min && value === min) || value <= 0}
            onClick={() => {
              setValue((prevState) => handleDecrementCount(prevState - 1));
            }}
            type="button"
            hidden={onlyResponse}
          >
            {isInline ? <FaMinus /> : <IoTriangle />}
          </button>
        </div>
      </div>
      {errorText && (
        <small className="textInput--small-error animation__fadeIn">
          {errorText}
        </small>
      )}
    </>
  );
}
