import { forwardRef, ReactNode, useEffect, useImperativeHandle, useState } from "react";
import { FitBoundsOptions } from "leaflet";
import { useMap } from "react-leaflet";
import "./styles.css";

import { ICoord, ICoords } from "../../../@types/GoogleMaps/ICoord";
import Constants from "../../../constants/Index";
import { getCenterCoords } from "../../../utils/leaflet/getCenterCoords";

export type FlyToBoundsRefProps = {
  flyToBounds: (coords: ICoords, options?: FitBoundsOptions, callBack?: () => void) => void;
  setZoommap: (zoom: number, center?: ICoord) => void;
  getBoundsZoom: (coords: ICoords) => number;
  fitBounds: (coords: ICoords, options?: FitBoundsOptions) => void;
  canZoomAnimate: (coords: ICoords, minDistance?: number) => boolean;
  flyEvent: () => void;
  onStateAnimation: (state: IAnimationState) => void;
  resizeMap: () => void;
};

type FlyToBoundsProps = {
  toCoords?: ICoords;
  fitCoords?: ICoords;
  onZoomEnd?: (state: boolean) => void;
  children?: ReactNode;
  onZoomChanged?: (zoom: number) => void;
  stateZoom?: (zoom: IZoomState) => void;
  getBoundsZoom?: (coords: ICoords) => number;
  fitBounds?: (coords: ICoords, options?: FitBoundsOptions) => void;
  canZoomAnimate?: (coords: ICoords, minDistance?: number) => boolean;
  flyEvent?: () => void;
  onStateAnimation?: (state: IAnimationState) => void;
  getCoordinatesOnClick?: (coords: ICoord) => void;
};

export type IZoomState = "1X" | "2X" | "3X" | "4X";

export type IAnimationState = "start" | "end";

export const FlyToBounds = forwardRef((props: FlyToBoundsProps, ref) => {
  const {
    toCoords,
    fitCoords,
    children,
    onZoomEnd: syncOnZoomEnd,
    onZoomChanged,
    stateZoom,
    onStateAnimation,
    getCoordinatesOnClick
  } = props;

  const [currentZoom, setCurrentZoom] = useState<number>(0);

  const map = useMap();

  useImperativeHandle(ref, () => ({
    flyToBounds(coords: ICoords, options?: FitBoundsOptions, callBack?: () => void) {
      callBack && callBack();
      map.once("zoomstart", () => handleStateAnimation("start"));
      map.once("zoomend", () => handleStateAnimation("end"));
      const { lat, lng } = map.getCenter();
      const distance = Math.round(map.distance([lat, lng], getCenterCoords(coords)));
      const timeDistance = Math.round(distance / Constants.LEAFLET_FACTOR_DISTANCE);
      const maxFactor = Constants.LEAFLET_MAX_TIME_DISTANCE_ANIMATION;
      map.flyToBounds(coords as any, {
        duration: timeDistance < maxFactor ? timeDistance : maxFactor,
      });
      onZoomEnd && onZoomEnd();
    },
    fitBounds(coords: ICoords, options?: FitBoundsOptions) {
      map.fitBounds(coords as any, options);
    },
    setZoommap(zoom: number, center?: ICoord) {
      if (center) map.setView(center, zoom);
      else map.setZoom(zoom);
    },
    getBoundsZoom(coords: ICoords) {
      return map.getBoundsZoom(coords as any);
    },
    canZoomAnimate(coords: ICoords, minDistance: number) {
      const minimalDistance = minDistance ? minDistance : Constants.LEAFLET_MINIMAL_DISTANCE_TO_ZOOM;
      const { lat, lng } = map.getCenter();
      const distance = Math.round(map.distance([lat, lng], getCenterCoords(coords)));
      return distance > minimalDistance;
    },
    resizeMap() {
      map.invalidateSize();
    },

  }));

  const handleStateAnimation = (state: IAnimationState) => {
    onStateAnimation && onStateAnimation(state);
  };

  const onZoomEnd = () => {
    syncOnZoomEnd && syncOnZoomEnd(true);
  };

  useEffect(() => {
    map.on("zoom", () => {
      const current = Math.floor(map.getZoom());
      setCurrentZoom((prevState) => (prevState !== current ? current : prevState));
    });

    map.on('click', event => {
      const { lat, lng } = event.latlng
      getCoordinatesOnClick && getCoordinatesOnClick({ lat, lng } as ICoord)
    })
  }, []);

  function getCurrentStateZoom() {
    switch (true) {
      case currentZoom <= Constants.LEAFLET_ZOOM_1X:
        return "1X";
      case currentZoom <= Constants.LEAFLET_ZOOM_2X:
        return "2X";
      case currentZoom <= Constants.LEAFLET_ZOOM_3X:
        return "3X";
      case currentZoom <= Constants.LEAFLET_ZOOM_4X:
        return "4X";
    }
    return "1X";
  }

  useEffect(() => {
    const currentState = getCurrentStateZoom();
    stateZoom && stateZoom(currentState);
    onZoomChanged && onZoomChanged(currentZoom);
  }, [currentZoom]);

  useEffect(() => {
    if (toCoords && toCoords.length > 0) {
      setTimeout(() => {
        map.invalidateSize();
        map.flyToBounds(toCoords as any, { duration: 10 });
        onZoomEnd();
      }, 250);
    }
  }, [toCoords]);

  useEffect(() => {
    if (fitCoords && fitCoords.length > 0) {
      setTimeout(() => {
        map.invalidateSize();
        map.fitBounds(fitCoords as any);
      }, 250);
    }
  }, [fitCoords]);

  return <> {children}</>;
});
