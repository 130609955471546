import { useEffect, useRef, useState } from "react";
import useSmoothScroll from "react-smooth-scroll-hook";
import { Scope } from "@unform/core";
import { Form } from "@unform/web";
import "./styles.css";
import * as yup from "yup";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { IFarm, IFarms } from "../../../../@types/app/IFarm";
import { ButtonOnlyTitle } from "../../../../components/Buttons/ButtonOnlyTitle/Index";
import { FooterControlls } from "../../../../components/Onboarding/Template/FooterControlls/Index";
import { CreateFarm } from "../../../../components/Onboarding/Register/CreateFarm/Index";
import { useOnboarding } from "../../../../hooks/useOnboarding";
import { IOptionsProps } from "../../../../@types/reactSelect/IOption";
import { IFarmOnboarding } from "../../../../@types/Onboarding/IFarm";
import Constants from "../../../../constants/Index";
import { generateuuid } from "../../../../utils/uuid/generate";
import axios from "../../../../services/axios";
import { EditValues } from "../../../../hooks/EditValues";
import { TextInput } from "../../../../components/Inputs/TextInputs/TextInput/Index";

yup.addMethod(
  yup.array,
  "unique",
  function (message: string, key: string, mapper = (a: any) => a) {
    return this.test("unique", function (list: any) {
      const errors = list.map((element: any, ind: number) => {
        if (element[key].length < 1) return;
        const counter = list.filter(
          (li: any) => li[key] === element[key]
        ).length;
        if (counter > 1) {
          const customMessage =
            key === "document" && element[key].length > 11
              ? "Este CNPJ já está sendo utilizado."
              : message;
          return new yup.ValidationError(
            customMessage,
            customMessage,
            `farms[${ind}].${key}`
          );
        }
      });
      const filtered = errors.filter((err: any) => !!err);
      if (filtered.length < 1) return true;
      return this.createError({
        message: () => errors.filter((err: any) => !!err),
      });
    });
  }
);

const farmBase = {
  uuid: "",
  name: "",
  document: "",
  initials: "",
  state_registration: "",
  manager_user_id: null,
  address: {
    city: null,
    uf: null,
  },
  properties: {
    season: {
      rain_initial: 3,
      dry_initial: 7,
    },
  },
  map_coords: [],
  filename: "",
} as IFarm;

const farmSchema = yup.object().shape({
  farms: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .matches(/^[^\s]+(\s+[^\s]+)*$/, "Este nome não é válido.")
          .required("NOME DA FAZENDA é necessário."),
        initials: yup
          .string()
          .matches(/^[^\s]+(\s+[^\s]+)*$/, "Esta sigla não é válida.")
          .required("SIGLA é necessário."),
        document: yup
          .string()
          .notRequired()
          .test("documentCPF", "CPF informado inválido", function (value) {
            return (value?.length == 0 || (value || "").length > 11 || cpf.isValid(value || ""));
          })
          .test("documentCNPJ", "CNPJ informado inválido", function (value) {
            return (value?.length == 0 || (value || "").length <= 11 || cnpj.isValid(value || ""));
          }),
        manager_user_id: yup
          .object({
            label: yup.string(),
            value: yup.string().required("Tempo máximo SEM gado é necessário."),
          })
          .nullable()
          .required("RESPONSÁVEL é necessário."),
        address: yup.object({
          city: yup
            .object({
              label: yup.string(),
              value: yup.string().required("CIDADE é necessário."),
            })
            .nullable()
            .required("CIDADE é necessário."),
          uf: yup
            .object({
              label: yup.string(),
              value: yup.string().required("UF é necessário."),
            })
            .nullable()
            .required("UF é necessário."),
        }),
        file: yup.object().nullable().required("Mapa da fazenda é necessário."),
      })
    )
    //@ts-ignore
    .unique("Fazenda já cadastrada com o nome.", "name")
    .unique("Esta sigla já está sendo utilizada.", "initials")
    .unique("Este CPF já está sendo utilizado.", "document")
    .unique("Esta Inscrição Estadual já está sendo utilizada.","state_registration"),
});

interface CreateFarmsProps {
  onlyRead?: boolean;
  onBoarding?: boolean;
}

export function CreateFarms({ onlyRead = false, onBoarding = false }: CreateFarmsProps) {
  const { payload, setPayload, setBlockNextStep } = useOnboarding();

  // referencia do formulário
  const formRef = useRef<HTMLFormElement>();
  // Isso diz ao smooth qual coponente é o principal
  const mainRef = useRef<HTMLElement>(null);
  // Isso lista os components de fazenda
  const [farmsData, setFarmsData] = useState<IFarms>([farmBase]);
  // Isso diz ao smooth qual tela mostrar
  const [farmNumber, setFarmNumber] = useState<number>(-1);

  const [managers, setManagers] = useState<IOptionsProps>([]);

  const { setFormRefValue, setBlockSave, refreshValues, permissionMaster } = EditValues();

  const [numFarms, setNumFarms] = useState<number>(0);

  // Isso dá foco na fazenda que foi escolhida ou adicionada
  const { scrollTo } = useSmoothScroll({
    ref: mainRef,
    speed: 30,
    direction: "y",
  });

  function removeFarm(uuid: number) {
    formRef.current?.setErrors({});
    const farms: IFarms = formRef.current?.getData().farms;
    if (farms) {
      let filteredForm = farms.filter((_, index) => index !== uuid);
      let filteredList = farmsData.filter((_, index) => index !== uuid);
      setFarmsData(filteredList);
      setFarmNumber(filteredList.length - 1);
      setTimeout(() => {
        formRef.current?.setData({ farms: filteredForm });
        handleChangesFarm();
      }, 1);
    }
  }

  async function addNewFarm() {
    const isValid = await validateForm();
    if (isValid && !onlyRead) {
      setBlockNextStep(true);
      setFarmsData((prevfarms) => {
        return [...prevfarms, farmBase];
      });
      setFarmNumber(farmsData.length);
    } else if (isValid && onlyRead) {
      setBlockSave(true);
      setFarmsData((prevfarms) => {
        return [...prevfarms, farmBase];
      });
    }
  }

  async function savePayload() {
    const formData = formRef.current?.getData();
    const farms: IFarms = formData.farms;
    const farmsFiltered = farms.filter((farm: IFarm) => farm?.name || farm?.name == "");
    if (formData) {
      const data = farmsFiltered.map((farm, index) => {
        let document = farm.document != "" ? farm.document : null;
        farm.document = document;
        const response = {
          ...farm,
          uuid: farm.uuid ? farm.uuid : generateuuid(),
          manager_user_id: farm.manager_user_id?.value,
          address: {
            city: {
              code: farm.address.city?.value,
              name: farm.address.city?.label,
            },
            state: farm.address.uf?.value,
            street: "",
          },
          properties: {
            season: farm.properties.season,
          },
          map_coords: farm.file?.coords,
          filename: farm.file?.name,
        } as IFarmOnboarding;
        // delete response.file;
        return response;
      });
      setPayload((payload) => {
        const response = { ...payload, farms: data };
        localStorage.setItem(Constants.LOCALSTORAGE_PAYLOAD, JSON.stringify(response));
        return response;
      });
      return true;
    }
    return false;
  }

  function handleFarmsdata() {
    return payload.farms.map((farm) => {
      const manager = payload.users.find((user) => user.uuid === farm.manager_user_id)?.name;
      return {
        ...farm,
        uuid: farm.uuid,
        manager_user_id: manager
          ? {
            label: manager,
            value: farm.manager_user_id,
          }
          : null,
        address: {
          city: farm.address.city?.code
            ? {
              label: farm.address.city.name,
              value: farm.address.city?.code,
            }
            : null,
          uf: farm.address.state
            ? {
              value: farm.address.state,
              label: farm.address.state,
            }
            : null,
        },
        properties: {
          season: farm.properties.season,
        },
        file: farm.map_coords
          ? {
            coords: farm.map_coords || null,
            name: farm.filename || "",
          }
          : undefined,
      } as IFarm;
    });
  }

  async function handleSubmit() {
    if (await validateForm()) {
      savePayload();
      return true;
    }
    return false;
  }

  async function handleChangesFarm() {
    const isValid = await validateForm();
    if (!onlyRead) {
      setBlockNextStep(!isValid);
    } else {
      setFormRefValue(formRef as any);
      setBlockSave(!isValid);
    }
  }

  async function validateForm() {
    const formData = formRef.current?.getData() || null;
    const farms: IFarms = formData.farms;
    const farmsFiltered = farms.filter((farm: IFarm) => farm?.name || farm?.name == "");
    formRef.current?.setErrors({});
    const errors = {} as any;
    let isValide = false;
    if (formData) {
      try {
        // Isso valida o formulário
        await farmSchema.validate({ farms: farmsFiltered }, { abortEarly: false });
        isValide = true;
      } catch (err) {
        if (!onlyRead) setBlockNextStep(true);
        if (err instanceof yup.ValidationError) {
          err.inner.forEach((e) => {
            errors[(e.path as string).replace(".value", "")] = e.message;
          });
        }
      } finally {
        if (formRef && formRef.current) {
          formRef.current.setErrors(errors);
        }
        return isValide;
      }
    }
    return false;
  }

  useEffect(() => {
    if (farmNumber !== -1) scrollTo(`#farm-${farmNumber}`);
    setFarmNumber(-1);
  }, [farmNumber]);

  useEffect(() => {
    if (!onlyRead) {
      if (payload) {
        if (payload.users) {
          setManagers(() => {
            return payload.users
              .filter((user) => Number(user.profile_id) === 5)
              .map((user) => ({ label: user.name, value: user.uuid }));
          });
        }
        if (payload.farms) {
          const farms = handleFarmsdata();
          setFarmsData(() => farms);
        }
      }
      setTimeout(()=>{
        handleChangesFarm();
      }, 1)
    } else {
      axios
        .get("/farms/user")
        .then(({ data }) => {
          const farms = data.map((value: any) => ({
            uuid: value.id,
            name: value.name,
            document: value.document,
            initials: value.initials,
            state_registration: value.state_registration,
            manager_user_id: {
              label: value.owner_user.name,
              value: value.owner_user.id,
            },
            address: {
              city: {
                label: value.address.city.name,
                value: value.address.city.code,
              },
              uf: { label: value.address.state, value: value.address.state },
            },
            properties: {
              season: {
                rain_initial: value.properties.season.rain_initial,
                dry_initial: value.properties.season.dry_initial,
              },
            },
            file: {
              coords: value.map_coords
            }
          })
          );
          setNumFarms(farms.length);
          setFarmsData(farms);
        }
        );

      axios.get("/users/user").then(({ data }) =>
        setManagers(
          data
            .filter((user: any) => user.profile_name == "Gerente")
            .map((profile: any) => {
              var profilesList = {
                label: profile.name,
                value: profile.id,
              };
              return profilesList;
            })
        )
      );
      setBlockSave(true)
    }
  }, [, refreshValues]);

  return (
    <>
      <main ref={mainRef as any}>
        <section className="createFarm__container">
          <h1 id="pageStart">Fazenda</h1>
          <Form ref={formRef as any} onSubmit={() => {}}>
            <TextInput
              hidden={true}
              value={"farms"}
              name="form"
            />
            {farmsData.map((farm, index) => (
              <Scope key={index} path={`farms[${index}]`}>
                <CreateFarm
                  onEventRemove={removeFarm}
                  isDeletable={
                    onlyRead
                      ? index >= numFarms
                      : farmsData.length > 1 || index > 0
                  }
                  formRef={formRef}
                  initData={farm}
                  path="farms"
                  uuid={index}
                  onAnywhereChanges={handleChangesFarm}
                  managers={managers}
                  onlyRead={onlyRead && !(index >= numFarms)}
                  onBoarding={!(onBoarding || permissionMaster)}
                />
              </Scope>
            ))}
            {(onBoarding || permissionMaster) &&
              <ButtonOnlyTitle
                theme="info"
                title="+ adicionar outra fazenda"
                onClick={addNewFarm}
                type={"button"}
              />
            }
          </Form>
        </section>
      </main>
      {!onlyRead && (
        <footer>
          <FooterControlls
            nextStepEvent={async () => {
              return await handleSubmit();
            }}
            backStepEvent={async () => {
              return await savePayload();
            }}
          />
        </footer>
      )}
    </>
  );
}
