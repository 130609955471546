import axios from 'axios'
import { getToken, logout } from './auth'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

instance.interceptors.request.use(async (config) => {
  const token = getToken()
  if (token) {
    config.headers!.Authorization = `Bearer ${token}`
  }
  return config
})

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401 && error.config.url != "/auth/login") {
      logout()
      window.location.replace('/entrar');
    }
    return Promise.reject(error);
  }
);

export default instance
