import { CSSProperties, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IOptionsProps } from "../../../../@types/reactSelect/IOption";
import axios from "../../../../services/axios";
import { fetchStateCities, fetchStatesUF } from "../../../../services/ibge";
import { sortBy } from "../../../../utils/arrays/sortBy";
import { Input } from "../../components/Input";
import { Dropdown } from "../../components/Select";
import { FooterEditOnboarding } from "../../Footer";
import "./../../styles.css";
import "./styles.css";
import { ReactComponent as UploadIcon } from "../../../../assets/svg/icons/upload.svg";
import { FileUploadKml } from "../../components/FileUploadKml";
import { useEditOnboarding } from "../../../../hooks/useEditOnboarding";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SliderComponent } from "../../components/Slider/Index";
import Constants from "../../../../constants/Index";
import { DrawOnMap } from "../../components/DrawOnMap/DrawOnMap";
import { Chips } from "../../../Chips";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { ButtonRedirect } from "../../../Modal/Error/ButtonRedirect/Index";
import { AlertModal } from "../../../Modal/Alert/Index";
import { TitleModal } from "../../../Modal/Contents/TitleModal/Index";
import { TextModal } from "../../../Modal/Contents/TextModal/Index";
import { SimpleButtonClose } from "../../../Modal/Contents/SimpleButtonClose/Index";

const farmSchema = yup.object({
  name: yup
    .string()
    .matches(/^[^\s]+(\s+[^\s]+)*$/, "Este nome não é válido.")
    .required("Nome da Fazenda é necessário."),
  initials: yup.string().required("Sigla é necessário."),
  document: yup
    .string()
    .notRequired()
    .test("documentCPF", "CPF informado inválido.", (value) => {
      const valueParsed = value?.toString().replaceAll('.', '').replaceAll('-', '')
      if (!!valueParsed && valueParsed.length < 12) return cpf.isValid(valueParsed);
      return true;
    })
    .test("documentCNPJ", "CNPJ informado inválido.", (value) => {
      const valueParsed = value?.toString().replaceAll('.', '').replaceAll('-', '')
      if (!!valueParsed && valueParsed.length > 11 && valueParsed.length < 15) return cnpj.isValid(value || "");
      return true;
    }),
  state_registration: yup.string(),
  owner_user_id: yup
    .object({
      label: yup.string(),
      value: yup.string().required("Responsável é necessário."),
    })
    .nullable()
    .required("Responsável é necessário."),
  address: yup.object({
    state: yup
      .object({
        label: yup.string(),
        value: yup.string().required("UF é necessário."),
      })
      .nullable()
      .required("UF é necessário."),
    city: yup
      .object({
        label: yup.string(),
        value: yup.string().required("Cidade é necessário."),
      })
      .nullable()
      .required("Cidade é necessário."),
  }),
  map_coords: yup.array().nullable().min(1, "Mapa da fazenda é necessário."),
});

export function CreateFarm() {
  const { setOptionForm, defaultValue } = useEditOnboarding();
  const isNew = !defaultValue?.initials;
  const [listUF, setListUF] = useState<IOptionsProps>([]);
  const [managers, setManagers] = useState<IOptionsProps>([]);
  const [listCities, setListCities] = useState<IOptionsProps>([]);
  const [blockCities, setBlockCities] = useState<boolean>(true);
  const [areasList, setAreasList] = useState<any[]>();
  const [initialsOnlyRead, setInitialsOnlyRead] = useState<boolean>(isNew);
  const [maskCFPCNPJ, setMaskCFPCNPJ] = useState<string>("999.999.999-999999");
  const [inicioChuva, setInicioChuva] = useState(isNew ? 3 : defaultValue?.season?.rain_initial);
  const [inicioSeca, setInicioSeca] = useState(isNew ? 8 : defaultValue?.season?.dry_initial);
  const [msgError, setMsgError] = useState<string>("");
  const contentStyleAlert = {
    width: "335px",
    height: "237px",
    borderRadius: "20px",
    padding: "20px",
  } as CSSProperties;

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(farmSchema),
  });

  const docInput = watch("document");
  const nameInput = watch("name");
  const stateDropdown = watch("address.state");

  useEffect(() => {
    if (!isNew)
      axios
        .get("/areas/", { params: { farm_id: defaultValue.id } })
        .then(({ data }) => setAreasList(data));
  }, [defaultValue]);

  const deleteFarm = () => {
    axios
      .delete(`/farms/${defaultValue.id}`)
      .then(() => {
        setOptionForm(undefined);
      })
      .catch((err) => setMsgError(err.response.data.msg));
  }

  const onSubmit = (data: any) => {
    if (isNew) {
      var formCreateFarm = [
        {
          name: data?.name,
          initials: data?.initials,
          document:
            data?.document != ""
              ? data?.document.replace(/[^0-9a-z]/gi, "")
              : undefined,
          address: {
            city: {
              code: data?.address?.city?.value,
              name: data?.address?.city?.label,
            },
            state: data?.address?.state?.label,
          },
          properties: {
            season: {
              dry_initial: inicioSeca,
              rain_initial: inicioChuva,
            },
          },
          state_registration: data?.state_registration,
          owner_user_id: data?.owner_user_id?.value,
          map_coords: data?.map_coords,
        },
      ];
      axios
        .post("/farms", formCreateFarm)
        .then(() => {
          setOptionForm(undefined);
        })
        .catch((err) => setMsgError(err.response.data.msg));
    } else {
      var formPathFarm = [
        {
          farm_id: defaultValue.id,
          state_registration: data?.state_registration,
          name: data?.name,
          document: data?.document,
          owner_user_id: data.owner_user_id.value,
          properties: {
            season: {
              dry_initial: inicioSeca,
              rain_initial: inicioChuva,
            },
          },
        },
      ];

      axios
        .patch("/farms", formPathFarm)
        .then(() => {
          setOptionForm(undefined);
        })
        .catch((err) => setMsgError(err.response.data.msg));
    }
  };

  useEffect(() => {
    fetchStatesUF().then((data) => {
      const ufs: Array<any> = data.map((uf: any) => ({
        label: uf,
        value: uf,
      }));
      setListUF(() => ufs.sort(sortBy("label")));
    });
    axios.get("/users/user").then(({ data }) =>
      setManagers(
        data
          .filter((user: any) => user.profile_name == "Gerente")
          .map((profile: any) => {
            var profilesList = {
              label: profile.name,
              value: profile.id,
            };
            return profilesList;
          })
      )
    );
  }, []);

  useEffect(() => {
    if (docInput) {
      docInput.length <= 14
        ? setMaskCFPCNPJ("999.999.999-999999")
        : setMaskCFPCNPJ("99.999.999/9999-99");
    } else setValue("document", "");
  }, [docInput]);

  useEffect(() => {
    if (initialsOnlyRead) {
      if (nameInput) {
        const nameFarm = nameInput.toLocaleUpperCase().split(" ");
        if (nameFarm[0] != "") {
          if (nameFarm.length == 1) {
            setValue("initials", `${nameFarm[0][0]}${nameFarm[0][1] || ""}`);
          } else if (nameFarm[1] !== "") {
            setValue("initials", `${nameFarm[0][0]}${nameFarm[1][0]}`);
          }
        }
      } else setValue("initials", "");
    }
  }, [nameInput, initialsOnlyRead]);

  useEffect(() => {
    if (stateDropdown) {
      fetchStateCities(stateDropdown.value).then((data) => {
        const response = data
          .map((city: any) => ({ label: city.nome, value: city.id }))
          .sort();
        setListCities(() => response);
      });
      setBlockCities(false);
    }
  }, [stateDropdown]);

  return (
    <>
      <AlertModal visible={!!msgError} contentStyle={contentStyleAlert}>
        <section className="EditAreas_modalalert-container">
          <SimpleButtonClose onClick={() => setMsgError("")} />
          <main className="EditAreas_modalalert-content">
            <TitleModal>Algo deu errado.</TitleModal>
            <TextModal>{msgError}</TextModal>
          </main>
          <ButtonRedirect onClick={() => setMsgError("")}>
            Voltar
          </ButtonRedirect>
        </section>
      </AlertModal>
      <main className={"body__formEditOnboarding"}>
        <div className="firstColumn">
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: 500 }}>
            <Input
              control={control}
              label={"Nome da Fazenda"}
              theme="normal"
              {...register("name")}
              errorText={(errors?.name as any)?.message}
            />

            <Input
              control={control}
              label={"Sigla"}
              theme={!isNew || initialsOnlyRead ? "read-only" : "normal"}
              disabled={!isNew || initialsOnlyRead}
              maxLength={3}
              {...register("initials")}
              errorText={
                !initialsOnlyRead ? (errors?.initials as any)?.message : ""
              }
            />

            {isNew && (
              <label
                onClick={() => setInitialsOnlyRead(!initialsOnlyRead)}
                className="initial--readOnly"
              >
                {initialsOnlyRead ? "Alterar sigla" : "Usar sigla automática"}
              </label>
            )}

            <Input
              control={control}
              label={"CPF / CNPJ"}
              theme="normal"
              mask={maskCFPCNPJ}
              {...register("document")}
              errorText={(errors?.document as any)?.message}
            />

            <Input
              control={control}
              label={"Inscrição Estadual"}
              theme="normal"
              {...register("state_registration")}
            />

            <div className="city__container">
              <Dropdown
                control={control}
                label={"UF"}
                closeMenuOnSelect={true}
                placeholderText={""}
                options={listUF}
                className={"city__container__uf"}
                isDisabled={!isNew}
                isSearchable={isNew}
                {...register("address.state")}
                errorText={
                  (errors?.address as any)?.state?.value?.message ? " " : ""
                }
              />

              <Dropdown
                control={control}
                label={"Cidade"}
                closeMenuOnSelect={true}
                placeholderText={"Selecione..."}
                options={listCities}
                className={"city__container__city"}
                isDisabled={!isNew || blockCities}
                isSearchable={isNew && !blockCities}
                {...register("address.city")}
                errorText={
                  (errors?.address as any)?.city?.value?.message ? " " : ""
                }
              />
            </div>

            {errors?.address ? (
              <small className="textInput--small-error animation__fadeIn">
                UF e CIDADE é necessário
              </small>
            ) : (
              ""
            )}

            <Dropdown
              label={"Responsável da Fazenda"}
              control={control}
              closeMenuOnSelect={true}
              placeholderText={"Selecione..."}
              options={managers}
              isSearchable={isNew}
              {...register("owner_user_id")}
              errorText={(errors?.owner_user_id as any)?.value?.message}
            />

            <div className="season_definition">
              <label className="rhf__header--label">Definição de Estação</label>
              <div className="barSeason">
                <SliderComponent
                  listOptions={Constants.MONTHS_SHORT_NAME}
                  rain_initial={inicioChuva}
                  dry_initial={inicioSeca}
                  setRainInitial={setInicioChuva}
                  setDryInitial={setInicioSeca}
                />
              </div>
            </div>
          </form>
        </div>
        <div className="secondColumn">
          {isNew ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FileUploadKml
                title="*Clique para adicionar sua fazenda."
                control={control}
                errorText={errors?.map_coords?.message as any}
                {...register("map_coords")}
              >
                <UploadIcon />
                <strong>Upload Mapa KML</strong>
              </FileUploadKml>
            </form>
          ) : (
            <>
              <DrawOnMap
                staticsElements={
                  (areasList && areasList.map((e: any) => e.map_coords)) || []
                }
                style={{ width: "400px", height: "300px" }}
                mainPolygon={defaultValue.map_coords}
                initialsLabel={defaultValue.initials}
                control={control}
                name={"map_coords"}
              />

              <ul>
                {areasList &&
                  areasList.map((area) => (
                    <li key={area.id}>
                      <Chips.Pressable
                        variationStyles={{ container: { width: "100%" } }}
                        theme={"link-light"}
                        label={`${area?.farm?.initials}-${area?.initials}`}
                      />
                    </li>
                  ))}
              </ul>
            </>
          )}
        </div>
      </main>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FooterEditOnboarding
          undoFunction={() => setOptionForm(undefined)}
          saveFunction={() => { }}
          deleteFunction={() => deleteFarm()}
          isNew={isNew}
        />
      </form>
    </>
  );
}
