import { Scope } from '@unform/core';
import { MutableRefObject } from 'react';
import { IGoalMonthQuantity } from '../../../../../../@types/Onboarding/IGoal';
import { TextInput } from '../../../../../Inputs/TextInputs/TextInput/Index';
import { InputQuantity } from '../../InputQuantity/Index';
import './styles.css';

type TableEditRowProps = {
  category: number;
  handleInputChanges?: (data?: HTMLInputElement) => void;
  inputsErrors?: (err: string) => void;
  inputError?: (err?: string) => void;
  formRef: MutableRefObject<HTMLFormElement | null | undefined>;
  initData?: Array<IGoalMonthQuantity>;
  goalIndex: number;
  onlyRead?: boolean;
};

export function TableEditRow({
  category,
  handleInputChanges,
  inputError,
  formRef,
  initData,
  goalIndex,
  onlyRead = false
}: TableEditRowProps) {
  const firstSemester = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho'];

  const secondSemester = ['Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  function handleInputs(data?: HTMLInputElement) {
    if (data && handleInputChanges) {
      handleInputChanges(data);
    }
  }

  const getQuantity = (month:number, category:number) => {
    let goalsFilter = initData?.filter(goal => goal.month == month && goal.category_id == category) || [];
    return goalsFilter?.length > 0 ? goalsFilter[0].quantity + '' : "0";
  }

  return (
    <div>
      <table className="tableGoalItemEdit__table">
        <tbody>
          {[...Array(6)].map((_, index) => {
            return (
              <>
                <tr className="tableGoalItemEdit__row">
                  <td>{firstSemester[index]}</td>
                  <td key={index}>
                    <Scope path={`quantities[${index}]`}>
                      <TextInput value={index + 1} hidden={true} name="month" />
                      <TextInput value={category} hidden={true} name="category_id" />
                      <InputQuantity
                        category={category}
                        quantityInit={getQuantity(index + 1, category)}
                        month={index + 1}
                        handleInputChanges={handleInputs}
                        onInputError={(err) => inputError && inputError(err)}
                        onlyRead={onlyRead}
                      />
                    </Scope>
                  </td>
                  <td>{secondSemester[index]}</td>
                  <td key={index + 6}>
                    <Scope path={`quantities[${index + 6}]`}>
                      <TextInput value={index + 7} hidden={true} name="month" />
                      <TextInput value={category} hidden={true} name="category_id" />
                      <InputQuantity
                        category={category}
                        quantityInit={getQuantity(index + 7, category)}
                        month={index + 7}
                        handleInputChanges={handleInputs}
                        onInputError={(err) => inputError && inputError(err)}
                        onlyRead={onlyRead}
                      />
                    </Scope>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
