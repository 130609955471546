import { HTMLAttributes } from "react";
import "./styles.css";

import { ReactComponent as GrassIcon } from "../../../assets/svg/icons/grass.svg";
import { ReactComponent as AreaIcon } from "../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as FarmIcon } from "../../../assets/svg/icons/farmIcon.svg";

type MicroAreaSpecsProps = HTMLAttributes<HTMLDivElement> & {
  microAreaLabel: string;
  type: "farm" | "area" | "batch";
};

export function SpecsMouseOver({ microAreaLabel, type, ...rest }: MicroAreaSpecsProps) {
  return (
    <section className={`microareaSpecs__container ${rest.className}`}>
      <div className="microareaSpecs__microarea">
        {type=="farm"?(<FarmIcon/>):(<></>)}
        {type=="area"?(<AreaIcon/>):(<></>)}
        {type=="batch"?(<GrassIcon/>):(<></>)}
        <h3>{microAreaLabel}</h3>
      </div>
    </section>
  );
}
