import { ReactNode, useState } from "react";
import "./styles.css";

import { ReactComponent as LeftIcon } from "../../../../../../assets/svg/icons/leftChevron.svg";
import { ReactComponent as RightIcon } from "../../../../../../assets/svg/icons/rightChevron.svg";

import { Swiper } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Constants from "../../../../../../constants/Index";

type TableVisualizationContainerProps = {
  children: ReactNode | ReactNode[];
};

interface SwiperProps extends Swiper {
  slidePrev: () => void;
  slideNext: () => void;
}

export function TableVisualizationContainer({ children }: TableVisualizationContainerProps) {
  const [currentSlider, setCurrentSlider] = useState<number>(0);

  const [month] = useState<any>(Constants.MONTHS_FULL_NAME);

  const [sliderRef, setSliderRef] = useState<SwiperProps | null>();

  function handlePrevMoth() {
    if (sliderRef) sliderRef.slidePrev();
  }

  function handleNextMoth() {
    if (sliderRef) sliderRef.slideNext();
  }

  function handleCurrentIndex(slide: any) {
    setCurrentSlider(slide.realIndex < 12 ? slide.realIndex : 0);
  }

  return (
    <>
      <section className="tableVisualizationContainer__controlls--slider">
        <button onClick={handlePrevMoth}>
          <LeftIcon />
        </button>
        <strong className="tableVisualizationContainer__title">{month[currentSlider]}</strong>
        <button onClick={handleNextMoth}>
          <RightIcon />
        </button>
      </section>

      <section className="tableVisualizationContainer">
        <div>categoria</div>
        <div>idade</div>
        <div>peso</div>
      </section>

      <section className="tableVisualizationContainer__categories">
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          initialSlide={currentSlider}
          loop
          onSlideChange={handleCurrentIndex}
          onInit={(swiper) => setSliderRef(swiper as any)}
        >
          {children}
        </Swiper>
      </section>
    </>
  );
}
