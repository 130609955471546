import { CSSProperties, useEffect, useState } from "react";
import { RoundedCloseButton } from "../../Buttons/RoundedCloseButton/Index";
import { StaticMapRender } from "../../EditOnboarding/components/StaticMapRender/Index";
import { Modal } from "../../Modal/Default/Index";
import axios from "../../../services/axios";
import { useEditOnboarding } from "../../../hooks/useEditOnboarding";
import InfoAnimalAdd from "./InfoAnimalAdd";
import InfoEvolution from "./InfoEvolution";
import InfoAnimalSell from "./InfoAnimalSell";
import InfoSplitBatch from "./InfoSplitBatch";
import InfoAnimalDeath from "./InfoAnimalDeath";
import InfoMoveBatch from "./InfoMoveBatch";
import InfoGrazingRoute from "./InfoGrazingRoute";
import { ReactComponent as GrazingRouteIcon } from "../../../assets/svg/icons/grazingRoute.svg";
import { ReactComponent as SplitIcon } from "../../../assets/svg/icons/fractionateBatches.svg";
import { ReactComponent as MoveIcon } from "../../../assets/svg/icons/moveBatch.svg";
import { ReactComponent as EvolutionIcon } from "../../../assets/svg/icons/evolution.svg";
import { ReactComponent as AnimalAddIcon } from "../../../assets/svg/icons/animalAdd.svg";
import { ReactComponent as AnimalSaleIcon } from "../../../assets/svg/icons/animalSale.svg";
import { ReactComponent as AnimalDeathIcon } from "../../../assets/svg/icons/animalDeath.svg";
import { ReactComponent as AnimalTransferIcon } from "../../../assets/svg/icons/animalTransfer.svg";
import { ReactComponent as FertilizationIcon } from "../../../assets/svg/icons/fertillizing.svg";
import { ReactComponent as WaterTroughIcon } from "../../../assets/svg/icons/drinking_fountain.svg";
import { ReactComponent as FencesIcon } from "../../../assets/svg/icons/fences.svg";
import { ReactComponent as DetachedTaskIcon } from "../../../assets/svg/icons/detached_task.svg";
import { ReactComponent as ContourFarmIcon } from "../../../assets/svg/icons/contour_line.svg";
import { ReactComponent as PlantingIcon } from "../../../assets/svg/icons/planting.svg";
import { ReactComponent as SoilPreparationIcon } from "../../../assets/svg/icons/soil_preparation.svg";
import { ReactComponent as SprayingIcon } from "../../../assets/svg/icons/pulverization.svg";
import { ReactComponent as ProductReceiveIcon } from "../../../assets/svg/icons/bagFoodReceive.svg";
import { ReactComponent as ProductDispatchIcon } from "../../../assets/svg/icons/SupplementWithdrawIcon.svg";
import { ReactComponent as BrushCuttingIcon } from "../../../assets/svg/icons/mowing.svg";
import InfoAnimalTransfer from "./InfoAnimalTransfer";
import InfoFertilization from "./InfoFertilization";
import InfoWaterTrough from "./InfoWaterTrough";
import InfoFence from "./InfoFence";
import InfoDetachedTask from "./InfoDetachedTask";
import InfoContourFarming from "./InfoContourFarming";
import InfoPlanting from "./InfoPlanting";
import InfoSoilPreparation from "./InfoSoilPreparation";
import InfoSpraying from "./InfoSpraying";
import InfoProductIncoming from "./InfoProductIncoming";
import InfoBrushCutting from "./InfoBrushCutting";
import InfoAnimalReceive from "./InfoAnimalReceive";
import InfoProductDispatch from "./InfoProductDispatch";
import { Loading } from "../../Loading/Index";

interface InfoTasksProps {
    selected: number;
    setSelectedId: (e: number) => void;
}

export default function ViewTasks({
    selected,
    setSelectedId
}: InfoTasksProps) {
    const {
        idSelected
    } = useEditOnboarding();
    const [task, setTask] = useState<any>();
    const [microArea, setMicroArea] = useState<IGetMicroArea>();
    const [area, setArea] = useState<IArea>();
    const [initialsType, setInitialsType] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const createEvaluatorModalStyle = {
        padding: "50px",
        width: "910px",
        height: "fit-content",
        borderRadius: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
        overflow: "hidden",
    } as CSSProperties;

    useEffect(() => {
        switch (task?.task_type) {
            case "Adubação":
            case "Bebedouro":
            case "Cerca":
            case "Curva de nível":
            case "Plantio":
            case "Preparo de solo":
            case "Pulverização":
            case "Roçagem":
                setInitialsType("Pasto")
                break;

            case "Dividir lote":
            case "Evoluir categoria":
            case "Movimentar lote":
            case "Atualizar rota de pastejo":
            case "Transferência de Lote":
            case "Junção de lotes":
            case "Atualizar lote":
                setInitialsType("Lote")
                break;

            default:
                setInitialsType(undefined)
                break;
        }
    }, [task]);

    useEffect(() => {
        if (selected != 0) {
            setIsLoading(true)
            axios.get(`tasks/${selected}`)
                .then(({ data }) => {
                    setTask(data)
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => setIsLoading(false))
        }
        const contentInfoBuy = document.getElementById('contentInfo');

        if (contentInfoBuy) {
            contentInfoBuy.scrollTop = 0
        }
    }, [selected]);

    useEffect(() => {
        if (microArea) {
            axios.get(`areas/${microArea.area_id}`)
                .then(({ data }) => {
                    setArea(data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [microArea]);

    const TaskIcon: React.FC<{ task: any | null }> = ({ task }) => {
        const taskTypeToIconMapping: { [key: string]: () => JSX.Element | null } = {
            "Atualizar lote": () => {
                switch (task?.task_update_type) {
                    case "Nascimento":
                    case "Compra":
                        return <AnimalAddIcon />;
                    case "Venda":
                        return <AnimalSaleIcon />;
                    case "Abate":
                        return <AnimalSaleIcon />;
                    case "Morte":
                        return <AnimalDeathIcon />;
                    default:
                        return null;
                }
            },
            "Dividir lote": () => <SplitIcon />,
            "Evoluir categoria": () => <EvolutionIcon />,
            "Movimentar lote": () => <MoveIcon />,
            "Junção de lotes": () => <MoveIcon />,
            "Atualizar rota de pastejo": () => <GrazingRouteIcon />,
            "Transferência de Lote": () => <AnimalTransferIcon />,
            "Adubação": () => <FertilizationIcon />,
            "Bebedouro": () => <WaterTroughIcon />,
            "Cerca": () => <FencesIcon />,
            "Tarefa avulsa": () => <DetachedTaskIcon />,
            "Curva de nível": () => <ContourFarmIcon />,
            "Plantio": () => <PlantingIcon />,
            "Preparo de solo": () => <SoilPreparationIcon />,
            "Pulverização": () => <SprayingIcon />,
            "Recebimento do produto": () => <ProductReceiveIcon />,
            "Saída do produto": () => <ProductDispatchIcon />,
            "Roçagem": () => <BrushCuttingIcon />,
        };

        return taskTypeToIconMapping[task?.task_type]?.() || null;
    };

    const TaskInfo: React.FC<{ task: any | null }> = ({ task }) => {
        const taskTypeToIconMapping: { [key: string]: () => JSX.Element | null } = {
            "Atualizar lote": () => {
                switch (task?.task_update_type) {
                    case "Nascimento":
                    case "Compra":
                        return <InfoAnimalAdd task={task as ITaskAnimalAdd} />;
                    case "Venda":
                        return <InfoAnimalSell task={task as ITaskAnimalAdd} />;
                    case "Abate":
                        return <InfoAnimalSell task={task as ITaskAnimalAdd} />;
                    case "Morte":
                        return <InfoAnimalDeath task={task as ITaskAnimalDeath} />;
                    default:
                        return null;
                }
            },
            "Dividir lote": () => <InfoSplitBatch task={task as ITaskSplitBatch} />,
            "Evoluir categoria": () => <InfoEvolution task={task as ITaskEvolution} />,
            "Movimentar lote": () => <InfoMoveBatch task={task as ITaskMoveBatch} />,
            "Junção de lotes": () => <InfoMoveBatch task={task as ITaskMoveBatch} />,
            "Atualizar rota de pastejo": () => <InfoGrazingRoute task={task as ITaskGrazingRoute} />,
            "Transferência de Lote": () => {
                switch (task?.task_inventoryTransfer_batch_type) {
                    case "Conclusão de Transferência":
                        return <InfoAnimalReceive task={task as ITaskAnimalTransfer} />;
                    default:
                        return <InfoAnimalTransfer task={task as ITaskAnimalTransfer} />;
                }
            },
            "Adubação": () => <InfoFertilization task={task as ITaskFertilization} />,
            "Bebedouro": () => <InfoWaterTrough task={task as ITaskWaterTrough} />,
            "Cerca": () => <InfoFence task={task as ITaskFence} />,
            "Tarefa avulsa": () => <InfoDetachedTask task={task as ITaskDetachedTask} />,
            "Curva de nível": () => <InfoContourFarming task={task as ITaskContourFarming} />,
            "Plantio": () => <InfoPlanting task={task as ITaskPlanting} />,
            "Preparo de solo": () => <InfoSoilPreparation task={task as ITaskSoilPreparation} />,
            "Pulverização": () => <InfoSpraying task={task as ITaskSpraying} />,
            "Recebimento do produto": () => <InfoProductIncoming task={task as ITaskProductIncoming} />,
            "Saída do produto": () => <InfoProductDispatch task={task as ITaskProductDispatch} />,
            "Roçagem": () => <InfoBrushCutting task={task as ITaskBrushCutting} />,
        };

        return taskTypeToIconMapping[task?.task_type]?.() || null;
    };

    return (
        <Modal
            visible={selected != 0}
            contentStyle={createEvaluatorModalStyle}
        >
            <section>
                <Loading visible={isLoading} contentStyle={{
                    position: "absolute"
                }} />

                <header className="headerInfoEvaluation">
                    <div>
                        <div className="iconViewTaskInfo">
                            <TaskIcon task={task} />
                        </div>
                        <h1 className="titleInfoEvaluation">{task?.task_update_type || task?.task_type}</h1>
                    </div>
                    <div>
                        <RoundedCloseButton
                            onClick={() => {
                                setInitialsType("")
                                setTask(undefined)
                                setSelectedId(0)
                            }}
                        />
                    </div>
                </header>

                <div className="dividerInfoEvoluation" />

                <main className="bodyInfoEvaluation">
                    <div className="historyInfoEvaluation" id="contentInfo">
                        <TaskInfo task={task} />
                    </div>

                    <div className="contentInfoEvaluation">
                        <div className="statusInfoEvaluation">
                            <span>{task?.status}</span>
                            <div className="stepsInfoEvaluation">
                                <div className="stepCompleteInfoEvaluation" />
                                <div className="stepCompleteInfoEvaluation" />
                            </div>
                        </div>

                        <div className="contentInputContainerInfoEvaluation">
                            <div className="containerCompleteInfoEvaluation">
                                <div className="inputContainerCompleteInfoEvaluation">
                                    <h1>Responsável</h1>
                                    <span>{task?.task_owner}</span>
                                </div>
                            </div>
                            {initialsType &&
                                <div className="containerCompleteInfoEvaluation">
                                    <div className="inputContainerCompleteInfoEvaluation">
                                        <h1>{initialsType}</h1>
                                        <span>{task?.initials}</span>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="mapContainerInfoEvaluation">
                            <StaticMapRender
                                farm={area?.map_coords}
                                mainPolygon={task?.map_coords || task?.micro_area_route?.map((el: any) => el?.map_coords) || task?.destination_micro_area_route?.map((el: any) => el?.map_coords) || []}
                                microAreas={[]}
                                fitCoords={task?.map_coords || task?.area_map_coords || []}
                            />
                        </div>
                    </div>
                </main>

            </section>
        </Modal>
    )
}