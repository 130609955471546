import useEventListener from "@use-it/event-listener";
import { useEffect, useState } from "react";
import { ImSearch } from "react-icons/im";
import "./styles.css";

interface SearchProps {
  type?: string;
  onClick?: (value: string | undefined) => void;
  onChange?: (value: string | undefined) => void;
}

export default function ComponentInputSearch({ type, onClick, onChange }: SearchProps) {
  const [textToSearch, setTextToSearch] = useState<string | undefined>();

  useEventListener("keyup", (event: KeyboardEvent) => {
    const { key } = event;
    if (key === "Enter") onClick && onClick(textToSearch);
  });

  useEffect(() => {
    setTextToSearch("");
  }, [type]);

  return (
    <div className="search__container">
      <input
        placeholder={`PESQUISAR${type?` ${type?.toLocaleUpperCase()}`:""}...`}
        className="search__input"
        max={150}
        value={textToSearch}
        onChange={(value: any) => {
          if(onChange) onChange(value.target.value)
          setTextToSearch(value.target.value)
        }}
      />
      <ImSearch
        className="search__icon"
        onClick={() => onClick && onClick(textToSearch)}
      />
    </div>
  );
}
