import { useEffect, useState } from "react";
import "./styles.css";
import { FiMenu, FiX } from "react-icons/fi";
import Logo from "../../../assets/png/logo.png";

type NavBarProps = {
  isOnTop: boolean;
  setProps?: any;
};

export function NavBar({ isOnTop, setProps }: NavBarProps) {
  const [onTop, setOnTop] = useState(true);
  const [translateX, setTranslateX] = useState(-200);

  useEffect(() => {
    setOnTop(isOnTop);
  }, [isOnTop]);

  return (
    <div className={`navbar ${onTop && "navbarOnTop"}`}>
      <div className="logo">
        <img src={Logo} alt="logo pastejo" />
      </div>

      <FiMenu id="icon" onClick={() => setTranslateX(0)} />

      {/* navbar padrao do site */}
      <nav>
        <main>
          <ul>
            <li>
              <a href="/../#aPlataforma">A Plataforma</a>
            </li>
            <li>
              <a href="/../#section2" onClick={()=>setProps(0)}>Mapa de Gado</a>
            </li>
            <li>
              <a href="/../#section2" onClick={()=>setProps(1)}>Manejo de Pastagens</a>
            </li>
            <li>
              <a href="/../#section2" onClick={()=>setProps(2)}>Suplementação</a>
            </li>
            {/* <li>
              <a href="/">Plano e Preços</a>
            </li> */}
            <li>
              <a href="https://wa.me/553598550684">Fale conosco</a>
            </li>
            {/* <li>
              <a href="/">Entre</a>
            </li> */}
          </ul>
        </main>
      </nav>

      {/* navbar exibida para dispositivos mobile */}
      <div
        className="responsiveMenu"
        style={{
          transform: `translateX(${translateX}vw)`,
        }}
      >
        <nav>
          <FiX id="icon" onClick={() => setTranslateX(-200)} />
          <main>
            <ul>
              <li>
                <a href="/../#aPlataforma">A Plataforma</a>
              </li>
              <li>
                <a href="/../#section2" onClick={()=>setProps(0)}>Mapa de Gado</a>
              </li>
              <li>
                <a href="/../#section2" onClick={()=>setProps(1)}>Manejo de Pastagens</a>
              </li>
              <li>
                <a href="/../#section2" onClick={()=>setProps(2)}>Suplementação</a>
              </li>
              {/* <li>
              <a href="/">Plano e Preços</a>
              </li> */}
                <li>
                  <a href="https://wa.me/553598550684">Fale conosco</a>
                </li>
                {/* <li>
                <a href="/">Entre</a>
              </li> */}
            </ul>
            {/* <div className="navbar-socials">
              <CgFacebook />
              <BsInstagram />
              <BsYoutube />
            </div> */}
          </main>
        </nav>
      </div>
    </div>
  );
}
