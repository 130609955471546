import { useEffect, useRef, useState } from "react";
import { Polygon, Tooltip } from "react-leaflet";
import "./styles.css";
import { useFilter } from "../../../../hooks/useFilter";
import { AnimatedZoom } from "../../../../components/Leaflet/AnimatedZoom/Index";
import ContainerZoom1X from "../../../../components/Leaflet/ContainerZoom1X/Index";
import ContainerZoom2X from "../../../../components/Leaflet/ContainerZoom2X/Index";
import ContainerZoom3X from "../../../../components/Leaflet/ContainerZoom3X/Index";
import ContainerZoom4X from "../../../../components/Leaflet/ContainerZoom4X/Index";
import { CustomControls } from "../../../../components/Leaflet/CustomControls/Index";
import {
  FlyToBounds,
  FlyToBoundsRefProps,
  IAnimationState
} from "../../../../components/Leaflet/FlyToBounds/Index";
import { BatchesIcons } from "../../../../components/PaddocksMap/BatchesIcons/Index";
import { BatchesSpecsDetails } from "../../../../components/PaddocksMap/BatchesSpecsDetails";
import { LabelFarm1X } from "../../../../components/PaddocksMap/LabelFarm1X/Index";
import { PolylineFarm } from "../../../../components/PaddocksMap/PolylineFarm/Index";
import { SpecsMouseOver } from "../../../../components/PaddocksMap/SpecsMouseOver/Index";
import { IBatch } from "../../../../@types/API/IBatches";
import { IFarm, IFarms } from "../../../../@types/API/IFarm";
import { ICoord, ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { IArea } from "../../../../@types/API/IArea";
import Constants from "../../../../constants/Index";
import axios from "../../../../services/axios";
import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";

type ICustomFarm = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  bgColor: string;
};

type ICustomArea = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  farmId?: string;
};

type ICustomBatch = {
  initials: string;
  coords: ICoord;
  id: string;
  farmId: string;
  areaId: string;
  visible: boolean;
  capacityRate: number;
};

type ICustomMicroArea = {
  batch_initials: any;
  id: string;
  initials: string;
  coords: ICoords;
  capacityRate: number;
  areaId: string;
  farmId: string;
  areaInitials: string;
  amountAnimals: number;
  currentRoute: boolean;
};

interface ICustomFarms extends Array<ICustomFarm> {}
interface ICustomAreas extends Array<ICustomArea> {}
interface ICustomBatches extends Array<ICustomBatch> {}
interface ICustomMicroAreas extends Array<ICustomMicroArea> {}

export function PastureOccupation() {
  const flytoBoundsRef = useRef<FlyToBoundsRefProps>();
  const { farmFilter, setFarmFilter, areaFilter, defaultBGFarm, setHideMicroAreaFilter, setHideBatchFilter } =
    useFilter();
  const [currentFarm, setCurrentFarm] = useState<ICustomFarm | undefined>(undefined);
  const [currentArea, setCurrentArea] = useState<ICustomArea | undefined>(undefined);

  const [farmsPolygons, setFarmsPolygons] = useState<ICustomFarms>([]);
  const [areaPolygons, setAreasPolygons] = useState<ICustomAreas>([]);
  const [microAreasPolygons, setMicroAreasPolygons] = useState<ICustomMicroAreas>([]);
  const [batchesPolygons, setBatchesPolygons] = useState<ICustomBatches>([]);

  const [stateOfAnimation, setStateOfAnimation] = useState<IAnimationState>("end");

  function setColor(micro: ICustomMicroArea) {
    if (currentArea && currentArea.id !== micro.areaId && micro.farmId === farmFilter?.id)
      return Constants.PASTUREOCCUPATION_COLORS.WHITE;
    if (micro.capacityRate < 0) return Constants.PASTUREOCCUPATION_COLORS.WHITE;
    if (micro.currentRoute) return Constants.PASTUREOCCUPATION_COLORS.ORANGE;
    else return Constants.PASTUREOCCUPATION_COLORS.BLUE;
  }

  function handleColorArea(area: ICustomArea) {
    const response = batchesPolygons.some((f) => f.areaId === area.id);
    if (currentArea && currentArea.id !== area.id && area.farmId === farmFilter.id)
      return Constants.LEAFLET_UNFOCUS.color;
    if (response) return Constants.PASTUREOCCUPATION_COLORS.ORANGE;
    else return Constants.PASTUREOCCUPATION_COLORS.BLUE;
  }

  async function handleBatchesData(farms: IFarms, currentArea?: IArea) {
    const batchesParsed = await Promise.all(
      farms.map(async (farm) => {
        const { data, status } = await axios.get("/batches", { params: { farm_id: farm.id } });
        return data.map(
          ({
            id,
            area,
            current_micro_area,
            micro_area_route,
            batch_capacity_rate,
            animals_quantity: amountAnimals,
            initials: batch_initials
          }: IBatch) => {
            const responseMicro = micro_area_route.map(
              ({ id, initials, map_coords: coords }) =>
                ({
                  capacityRate: batch_capacity_rate,
                  coords,
                  initials,
                  id,
                  batch_initials,
                  farmId: farm.id,
                  areaId: area.id,
                  areaInitials: area.initials,
                  amountAnimals,
                  currentRoute: current_micro_area.id === id,
                } as ICustomMicroArea)
            );
            setMicroAreasPolygons((microAreas) => [...microAreas, ...responseMicro]);
            return {
              id,
              coords: getCenterCoords(current_micro_area.map_coords),
              initials: current_micro_area.initials,
              farmId: farm.id,
              areaId: area.id,
              visible: currentArea && farm.id === farmFilter.id ? area.id === currentArea.id : true,
            } as ICustomBatch;
          }
        );
      })
    );
    const batchesFiltereds = batchesParsed.flat();
    setBatchesPolygons(() => batchesFiltereds);
  }

  async function handleAreasData(farms: IFarms) {
    const areasData = await Promise.all(
      farms.map(async (farm) => {
        const { data, status } = await axios.get("/areas", { params: { farm_id: farm.id } });
        const response = data.map((area: ICustomArea) => ({ ...area, farmId: farm.id }));
        return status === Constants.STATUS_CODE_OK ? response : [];
      })
    );
    setAreasPolygons(() =>
      areasData.flat().map((micro) => ({
        id: micro.id,
        initials: micro.initials,
        coords: micro.map_coords,
        name: micro.name,
        farmId: micro.farmId,
      }))
    );
  }

  async function getBatchesAvailables(area: ICustomArea, farmId: string) {
    const { data, status } = await axios.get("/micro-areas/available", { params: { area_id: area.id } });
    if (data && data.length > 0) {
      return data.map(({ acreage, id, initials, map_coords: coords }: any) => ({
        acreage,
        areaId: area.id,
        id,
        initials,
        coords,
        capacityRate: -1,
        farmId,
        areaInitials: area.initials,
      }));
    } else return [];
  }

  function handleFocusCenter() {
    const coords: ICoords = areaFilter ? areaFilter.coords : farmFilter.coords;
    if (coords && flytoBoundsRef.current?.canZoomAnimate(coords, 50)) {
      flytoBoundsRef.current.flyToBounds(coords);
    } else setStateOfAnimation("end");
  }

  useEffect(() => {
    if (farmFilter && flytoBoundsRef) handleFocusCenter();
    setCurrentFarm(farmFilter);
  }, [farmFilter, flytoBoundsRef]);

  useEffect(() => {
    if (farmFilter) {
      let current: ICustomArea | undefined = areaFilter;
      setCurrentArea(() => current);
      setBatchesPolygons((batches) =>
        batches.map((batch) => ({
          ...batch,
          visible: current && batch.farmId === farmFilter.id ? batch.areaId === current.id : true,
        }))
      );
      handleFocusCenter();
    }
  }, [areaFilter, areaPolygons, farmFilter]);

  useEffect(() => {
    areaPolygons.forEach(async (area) => {
      const availables = await getBatchesAvailables(area, area.farmId as string);
      setMicroAreasPolygons((microAreas) => [...microAreas, ...availables]);
    });
  }, [areaPolygons]);

  useEffect(() => {
    axios
      .get("/farms/user")
      .then(({ data }) => {
        const [currentFarm] = data;
        if (currentFarm) {
          const { id, initials, name, map_coords: coords } = currentFarm;
          const farm = {
            id,
            initials,
            name,
            coords,
          } as ICustomFarm;
          if (!farmFilter) setFarmFilter(farm);
          setCurrentFarm(farm);
        }
        setFarmsPolygons(() =>
          data.map(
            ({ id, initials, name, map_coords: coords }: IFarm) =>
              ({ id, initials, name, coords } as ICustomFarm)
          )
        );

        setAreasPolygons(() => []);
        setMicroAreasPolygons(() => []);
        handleAreasData(data);
        handleBatchesData(data, areaFilter);
      })
      .catch((error) => console.log(error));
    document.title = Constants.TITLE_PADDOCKS_MAP_OCCUPATION;
    setHideMicroAreaFilter(true);
    setHideBatchFilter(true);
  }, []);

  function handleBGOppacity(farmId: string, areaId: string) {
    if (farmId !== farmFilter.id) return 0.55;
    if (areaFilter && areaId !== areaFilter.id) return 0.1;
    return 0.55;
  }

  function handleBorderColor(farmId: string, areaId: string) {
    if (farmId !== farmFilter.id) return Constants.PADDOCKTIME_COLORS.WHITE;
    if (areaFilter && areaId !== areaFilter.id) return Constants.LEAFLET_UNFOCUS.color;
    return Constants.PADDOCKTIME_COLORS.WHITE;
  }

  function overrideCentermap() {
    setStateOfAnimation("start");
    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter)
      flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }

  return (
    <div className="defaultmap__container">
      <FlyToBounds ref={flytoBoundsRef} onStateAnimation={(state) => setStateOfAnimation(state)}>
        {farmsPolygons.map((farm, key) => (
          <AnimatedZoom key={key} coordsToBoundZoom={farm.coords} updateZoomVisualization={stateOfAnimation}>
            <ContainerZoom1X>
              <LabelFarm1X
                initials={`${farm.initials}${
                  currentArea && currentArea.farmId === farm.id ? `-${currentArea.initials}` : ""
                }`}
                position={getCenterCoords(farm.coords)}
                key={farm.initials}
              />
            </ContainerZoom1X>

            <ContainerZoom2X>
              <Polygon
                positions={currentArea && currentArea.farmId === farm.id ? currentArea.coords : farm.coords}
                fillColor={defaultBGFarm}
                fillOpacity={0.55}
                color={"#FFF"}
                fill={true}
                weight={2}
                noClip
              >
                <Tooltip className="customToolTip__container" sticky>
                  <SpecsMouseOver microAreaLabel={farm.initials} type={"farm"} />
                </Tooltip>
              </Polygon>
            </ContainerZoom2X>

            <ContainerZoom3X>
              <PolylineFarm path={farm.coords} />
              <BatchesIcons batches={batchesPolygons.filter((f) => f.farmId === farm.id)} />
              {areaPolygons
                .filter((f) => f.farmId === farm.id)
                .map((area, key) => (
                  <Polygon
                    key={key}
                    pathOptions={{
                      fillOpacity: handleBGOppacity(area.farmId as string, area.id),
                      color: handleBorderColor(area.farmId as string, area.id),
                      fillColor: handleColorArea(area),
                    }}
                    positions={area.coords as ICoords}
                    fill={true}
                    weight={2}
                  >
                    <Tooltip className="customToolTip__container" sticky>
                      <SpecsMouseOver
                        microAreaLabel={`${currentFarm?.initials}-${area.initials}`}
                        type={"area"}
                      />
                    </Tooltip>
                  </Polygon>
                ))}
            </ContainerZoom3X>

            <ContainerZoom4X>
              <PolylineFarm path={farm.coords} />
              <BatchesIcons batches={batchesPolygons.filter((f) => f.farmId === farm.id)} />
              {microAreasPolygons
                .filter((f) => f.farmId === farm.id)
                .map((micro, key) => (
                  <Polygon
                    key={key}
                    pathOptions={{
                      fillOpacity: handleBGOppacity(micro.farmId, micro.areaId),
                      color: handleBorderColor(micro.farmId, micro.areaId),
                      fillColor: setColor(micro),
                    }}
                    positions={micro.coords}
                    fill={true}
                    weight={2}
                  >
                    <Tooltip className="customToolTip__container" sticky>
                      <SpecsMouseOver
                        className={micro.amountAnimals ? "havebatch" : ""}
                        microAreaLabel={`${currentFarm?.initials}-${micro.areaInitials}-${micro.initials}`}
                        type={"batch"}
                      />
                      {micro.amountAnimals && (
                        <BatchesSpecsDetails
                          batchLabel={`${currentFarm?.initials}-${micro.areaInitials}-${micro.batch_initials}`}
                          qtdLabel={`${micro.amountAnimals} animais`}
                          capacity={`${micro.capacityRate}`}
                        />
                      )}
                    </Tooltip>
                  </Polygon>
                ))}
            </ContainerZoom4X>
          </AnimatedZoom>
        ))}
      </FlyToBounds>
      <CustomControls overrideCentermap={overrideCentermap} />
      <section className="pastureoccupation__legend--container">
        <strong className="occupation__legend--title">OCUPAÇÃO DOS PASTOS</strong>
        <div className="occupation__legend--container">
          <div>
            <div style={{ background: Constants.PASTUREOCCUPATION_COLORS.ORANGE }} className="elipse-icon" />
            <strong className="legendDescription">PASTO COM LOTE</strong>
          </div>
          <div>
            <div style={{ background: Constants.PASTUREOCCUPATION_COLORS.BLUE }} className="elipse-icon" />
            <strong className="legendDescription">PASTO SEM LOTE</strong>
          </div>
          <div>
            <div style={{ background: Constants.PASTUREOCCUPATION_COLORS.WHITE }} className="elipse-icon" />
            <strong className="legendDescription last">PASTO SEM LOTE VINCULADO</strong>
          </div>
        </div>
      </section>
    </div>
  );
}
