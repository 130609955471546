import { ChangeEvent, useEffect, useRef, useState } from "react";
import "./styles.css";
import AnimateHeight from "react-animate-height";
import { Scope } from "@unform/core";
import { Form } from "@unform/web";
import * as yup from "yup";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { FooterControlls } from "../../../../components/Onboarding/Template/FooterControlls/Index";
import { TextInput } from "../../../../components/Inputs/TextInputs/TextInput/Index";
import { Select } from "../../../../components/Inputs/Select/Index";
import { IOptionProps, IOptionsProps } from "../../../../@types/reactSelect/IOption";
import { IAssortment } from "../../../../@types/app/IAssortment";
import { IAssortmentOnboarding } from "../../../../@types/Onboarding/IAssortment";
import { fetchStateCities, fetchStatesUF } from "../../../../services/ibge";
import { useOnboarding } from "../../../../hooks/useOnboarding";
import { useAuth } from "../../../../hooks/useAuth";
import Constants from "../../../../constants/Index";
import axios from "../../../../services/axios";
import { EditValues } from "../../../../hooks/EditValues";
import { HandleUUID } from "../../../../components/Inputs/HandleUUID/Index";

const FORM_MESSAGE_REQUIRED = "Campo obrigatório não informado.";

const selectOptions = [
  { label: "Agropecuária", value: "agropecuaria" },
  { label: "Fazenda", value: "fazenda" },
] as IOptionsProps;

const assortmentForm = yup.object().shape({
  whoiam: yup
    .object({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required("Selecione Agropecuária/Fazenda"),
  name: yup
    .string()
    .matches(/^[^\s]+(\s+[^\s]+)*$/, "NOME FANTASIA é necessário")
    .required(FORM_MESSAGE_REQUIRED),
  document: yup
    .string()
    .notRequired()
    .test("documentCPF", "CPF informado inválido", function (value) {
      return value?.length == 0 || (value || "").length > 11 || cpf.isValid(value || "");
    })
    .test("documentCNPJ", "CNPJ informado inválido", function (value) {
      return value?.length == 0 || (value || "").length <= 11 || cnpj.isValid(value || "");
    }),
  state_registration: yup.string().notRequired(),
  address: yup.object({
    city: yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable()
      .required(FORM_MESSAGE_REQUIRED),
    state: yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .nullable()
      .required(FORM_MESSAGE_REQUIRED),
    street: yup.string(),
  }),
});

const baseAssortment = {
  whoiam: null,
  name: "",
  document: "",
  state_registration: "",
  phone: "",
  address: {
    city: null,
    state: null,
    street: "",
  },
} as IAssortment;

interface CreateAssortmentProps {
  onlyRead?: boolean;
  onBoarding?: boolean;
}

export function CreateAssortment({ onlyRead = false, onBoarding = false }: CreateAssortmentProps) {
  const { setBlockNextStep, setPayload, payload } = useOnboarding();

  const { advanceStep, userProgress, screenNumber, subScreenNumber } = useOnboarding();

  const [showAssortment, setShowAssortment] = useState<boolean>(false);

  const [ufHasSelected, setUfHasSelected] = useState<boolean>(false);

  const [listUfs, setListUfs] = useState<IOptionsProps>([]);

  const [listCities, setListCities] = useState<IOptionsProps>([]);

  const [maskCFPCNPJ, setMaskCFPCNPJ] = useState<string>("999.999.999-999999");

  const [assortmentData, setAssortmentData] = useState<IAssortment>(baseAssortment);

  const formRef = useRef<HTMLFormElement>();

  const { setFormRefValue, setBlockSave, refreshValues, permissionMaster } = EditValues()

  // Isso lida com a opção que o usuário escolher
  function handleWhoIam(data: IOptionProps, action?: string) {
    if (action && data.value === "fazenda") {
      setPayload((payload) => ({
        ...payload,
        assortment: { ...baseAssortment, whoiam: data } as IAssortmentOnboarding,
      }));
      advanceStep(userProgress, screenNumber, subScreenNumber);
    } else setShowAssortment(true);
  }

  // Isso lista as cidades do uf selecionado
  function handleUFSelected(data: IOptionProps) {
    setUfHasSelected(true);
    fetchStateCities(data.value as string).then((data) => {
      setListCities(() => data.map((city: any) => ({ label: city.nome, value: city.id })));
    });
  }

  // Isso lida com qual máscara ele deve exibir no componente
  function handleDocument(input: ChangeEvent<HTMLInputElement>) {
    setMaskCFPCNPJ(input.target.value.length <= 14 ? "999.999.999-999999" : "99.999.999/9999-99");
  }

  // Isso verfifca os dados inseridos e avança nos passos
  async function handleFormSubmit() {
    const data: IAssortment = formRef.current?.getData();
    const isValid = await validateForm();
    if (isValid) {
      let document = data.document != "" ? data.document : null;
      data.document = document;
      const payloadData = {
        ...data,
        address: {
          city: {
            code: data.address.city?.value,
            name: data.address.city?.label,
          },
          state: data.address.state?.value,
          street: data.address.street,
        },
      } as IAssortmentOnboarding;
      setPayload((payload) => {
        const response = { ...payload, assortment: payloadData };
        localStorage.setItem(Constants.LOCALSTORAGE_PAYLOAD, JSON.stringify(response));
        return response;
      });
      return true;
    }
    return false;
  }

  // Isso lida com as mudanças que vão acontecendo nos campos
  async function handleChangesForm() {
    const isValid = await validateForm();
    if (!onlyRead) {
      setBlockNextStep(!isValid);
    } else {
      setBlockSave(!isValid)
      setFormRefValue(formRef as any)
    }
  }

  async function validateForm() {
    const formData = formRef.current?.getData() || null;
    formRef.current?.setErrors({});
    const errors = {} as any;
    let isValide = false;
    if (formData) {
      try {
        // Isso valida o formulário
        await assortmentForm.validate(formData, { abortEarly: false });
        isValide = true;
      } catch (err) {
        if (!onlyRead) setBlockNextStep(true);
        if (err instanceof yup.ValidationError) {
          err.inner.forEach((e) => {
            errors[e.path as string] = e.message;
          });
        }
      } finally {
        if (formRef && formRef.current) {
          formRef.current.setErrors(errors);
        }
        return isValide;
      }
    }
  }

  // Isso inicializa o componente
  function initComponent() {
    if (payload && payload.assortment) {
      const data = {
        ...payload.assortment,
        address: {
          city: payload.assortment.address.city?.code ? {
            label: payload.assortment.address.city?.name,
            value: payload.assortment.address.city?.code,
          } : null,
          state: payload.assortment.address.state ? {
            label: payload.assortment.address.state,
            value: payload.assortment.address.state,
          } : null,
          street: payload.assortment.address.street,
        },
      } as IAssortment;
      if (payload && payload.assortment && payload.assortment.document)
        setMaskCFPCNPJ(
          payload.assortment.document?.length <= 11 ? "999.999.999-999999" : "99.999.999/9999-99"
        );
      data.document = data.document == null ? "" : data?.document;
      setAssortmentData(() => data);
      formRef.current?.setData(data);
      handleChangesForm();
    } else {
      setAssortmentData(() => baseAssortment);
      formRef.current?.setData(baseAssortment);
      setTimeout(() => handleChangesForm(), 100)
    }
  }

  useEffect(() => {
    fetchStatesUF().then((ufs) => {
      setListUfs(() => ufs.map((uf: string) => ({ label: uf, value: uf })));
    });
    if (!onlyRead) {
      setBlockNextStep(true);
      initComponent();
    } else {
      axios
        .get("/assortments")
        .then(({ data }) => {
          const loadValues = {
            id: data.id,
            name: data.name,
            document: data.document || "",
            state_registration: data.state_registration || "",
            address: {
              city: { label: data.address.city.name, value: data.address.city.code },
              state: { label: data.address.state, value: data.address.state },
              street: data.address.street || "",
            },
          } as IAssortment;
          setAssortmentData(loadValues)
          formRef.current?.setData(loadValues);
          setMaskCFPCNPJ(data?.document?.length <= 11 ? "999.999.999-999999" : "99.999.999/9999-99");
        })
      setShowAssortment(true);
      setBlockSave(true)
    }
  }, [, refreshValues]);

  useEffect(() => {
    if (assortmentData.whoiam) setShowAssortment(assortmentData.whoiam.value === "agropecuaria");
    if (onBoarding) handleChangesForm();
  }, [assortmentData, assortmentData.whoiam]);

  return (
    <>
      <main>
        <section className="createassortment__container">
          <h1>Agropecuária / Fazenda</h1>
          <Form ref={formRef as any} onSubmit={() => { }}>
            <TextInput
              hidden={true}
              value={"assortment"}
              name="form"
            />
            {!onlyRead && (
              <div className="createassortment__container--input">
                <label htmlFor="createassortment__title">*Você é uma agropecuária ou uma fazenda?</label>
                <Select
                  defaultMenuIsOpen={!!assortmentData.whoiam}
                  handleAnywhereChanges={() => { }}
                  getSelectedData={handleWhoIam}
                  id="createassortment__title"
                  closeMenuOnSelect={true}
                  placeholder="Selecione"
                  options={selectOptions}
                  isSearchable={false}
                  name="whoiam"
                />
              </div>
            )}

            <section className="createassortment__form--container">
              <AnimateHeight height={showAssortment || onlyRead ? "auto" : 0}>
                <HandleUUID name="id" />

                <div className="createassortment__container--input">
                  <label htmlFor="createassortment__name">*Nome Fantasia</label>
                  <TextInput
                    handleAnywhereChanges={handleChangesForm}
                    id="createassortment__name"
                    name="name"
                    disabled={!(onBoarding || permissionMaster)}
                  />
                </div>
                <div className="createassortment__container--input">
                  <label htmlFor="createassortment__document">CPF / CNPJ</label>
                  <TextInput
                    id="createassortment__document"
                    onChange={handleDocument}
                    mask={maskCFPCNPJ}
                    name="document"
                    disabled={!(onBoarding || permissionMaster)}
                    handleAnywhereChanges={handleChangesForm}
                    returnUnmasked
                  />
                </div>
                <div className="createassortment__container--input">
                  <label htmlFor="createassortment__state_registration">Inscrição Estadual</label>
                  <TextInput
                    name="state_registration"
                    id="createassortment__state_registration"
                    disabled={!(onBoarding || permissionMaster)}
                    handleAnywhereChanges={handleChangesForm}
                  />
                </div>
                <Scope path="address">
                  <section className="createassortment__container--address">
                    <div className="createassortment__container--input">
                      <label htmlFor="createassortment__city">*UF</label>
                      <Select
                        handleAnywhereChanges={handleChangesForm}
                        noOptionsMessage={() => <span>-</span>}
                        getSelectedData={handleUFSelected}
                        id="createassortment__title"
                        closeMenuOnSelect={true}
                        options={listUfs}
                        placeholder=""
                        isSearchable
                        name="state"
                        isDisabled={!(onBoarding || permissionMaster)}
                      />
                    </div>
                    <div className="createassortment__container--input">
                      <label htmlFor="createassortment__city">*Cidade</label>
                      <Select
                        noOptionsMessage={() => <span>Não foram encotradas cidades</span>}
                        handleAnywhereChanges={handleChangesForm}
                        id="createassortment__city"
                        isDisabled={!ufHasSelected || !(onBoarding || permissionMaster)}
                        closeMenuOnSelect={true}
                        placeholder="Selecione"
                        options={listCities}
                        isSearchable
                        name="city"
                      />
                    </div>
                  </section>
                  <div className="createassortment__container--input">
                    <label htmlFor="createassortment__street">Endereço</label>
                    <TextInput name="street" id="createassortment__street" disabled={!(onBoarding || permissionMaster)} />
                  </div>
                </Scope>
              </AnimateHeight>
            </section>
          </Form>
        </section>
      </main>
      {!onlyRead && (
        <footer>
          <FooterControlls nextStepEvent={handleFormSubmit} />
        </footer>
      )}
    </>
  );
}
