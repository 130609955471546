import { useEffect } from "react";
import "./styles.css";
import { useOnboarding } from "../../../hooks/useOnboarding";
import { ReactComponent as LogoPastejo } from "../../../assets/svg/logo/logoPastejo.svg";
import { StepByStep } from "../../../components/Onboarding/Template/StepByStep/Index";
import { CreateProfiles } from "../Register/CreateProfiles/Index";
import { AnimalCategory } from "../Register/AnimalCategory/Index";
import { CreateAreas } from "../Register/CreateAreas/Index";
import { CreateAssortment } from "../Register/CreateAssortment/Index";
import { CreateBirthGoals } from "../Register/CreateBirthGoals/Index";
import { CreateDeathGoals } from "../Register/CreateDeathGoals/Index";
import { CreateFarms } from "../Register/CreateFarms/Index";
import { CreateLots } from "../Register/CreateLots/Index";
import { CreateMicroAreas } from "../Register/CreateMicroAreas/Index";
import { CreateLotsNew } from "../Register/CreateLots/newCreateLots/Index";
import { PasturesUnavailable } from "../Register/PasturesUnavailable/Index";

export function Onboarding() {
  const { screenComponent, screenNumber, subScreenNumber, userProgress, modalError, setModalError } = useOnboarding();

  function handleTitle() {
    const section = userProgress.find((sec) => sec.section === screenNumber);
    if (section && section.screens) document.title = `Pastejo | ${section?.screens[subScreenNumber]?.name}`;
    else document.title = "Pastejo";
  }

  useEffect(() => {
    handleTitle();
  }, [userProgress]);

  return (
    <section className="onboarding__container">
      <div className="onboarding__logo">
        <LogoPastejo />
      </div>
      <div className="onboarding__nav">
        <div className="onboarding__nav--menu">
          <div className="onboarding__nav--items">
            <StepByStep
              title="Registro"
              stepNumber={1}
              subSteps={[
                { name: "Perfil", element: <CreateProfiles onBoarding={true} /> },
                { name: "Agropecuária", element: <CreateAssortment onBoarding={true} /> },
                { name: "Fazenda", element: <CreateFarms onBoarding={true} /> },
                { name: "Retiro", element: <CreateAreas /> },
                { name: "Pasto", element: <CreateMicroAreas onBoarding={true} /> },
              ]}
            />

            <StepByStep
              title="Mapa de Gado"
              stepNumber={2}
              subSteps={[
                { name: "Categoria Animal", element: <AnimalCategory /> },
                { name: "Criação de Lotes", element: <CreateLotsNew onBoarding={true} /> },
                { name: "Pastos não utilizados", element: <PasturesUnavailable /> },
                { name: "Meta de Nascimento", element: <CreateBirthGoals /> },
                { name: "Meta de Morte", element: <CreateDeathGoals /> },
              ]}
            />
          </div>
        </div>
      </div>
      <section className="onboarding__content">{screenComponent}</section>
    </section>
  );
}
