import "./styles.css";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { ButtonHTMLAttributes, useState } from "react";

type ButtonCollapseProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
  click: () => void;
};

export function ButtonCollapse({ label, click, ...props }: ButtonCollapseProps) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <button
      {...props}
      className="tabs-button-collapse"
      onClick={() => {
        setIsOpen(!isOpen);
        !!click && click();
      }}
    >
      <span>{label}</span>
      <MdOutlineArrowDropDown
        style={
          isOpen
            ? { transform: "rotateZ(360deg)" }
            : { transform: "rotateZ(180deg)" }
        }
      />
    </button>
  );
}
