import { useEffect, useRef, useState } from 'react';
import './styles.css';
import { Form } from '@unform/web';
import { CreateBirthGoal } from '../../../../components/Onboarding/Register/CreateBirthGoal/Index';
import { FooterControlls } from '../../../../components/Onboarding/Template/FooterControlls/Index';
import { useYupValidate } from '../../../../hooks/useYupValidate';
import Constants from '../../../../constants/Index'; 
import * as yup from 'yup';
import { useOnboarding } from '../../../../hooks/useOnboarding';
import useSmoothScroll from 'react-smooth-scroll-hook';
import { IBirthGoal, IGoal, IParamGoal } from '../../../../@types/app/IGoal';
import { IGoalMonthQuantity, IGoalsOnboarding } from '../../../../@types/Onboarding/IGoal';
import axios from "../../../../services/axios";

const birthGoalSchema = yup.object().shape({
  goals: yup.array().of(
    yup.object().shape({
      quantities: yup.array().of(
        yup.object({
          quantity: yup
            .number()
            .required('Campo obrigatório não informado.')
            .moreThan(-1, 'Campo obrigatório não informado.')
        })
      )
    })
  )
});

const birthGoalBase = {} as IBirthGoal;

interface CreateBirthGoalsProps {
  onlyRead?: boolean,
}

export function CreateBirthGoals({
  onlyRead = false
}: CreateBirthGoalsProps) {
  const mainRef = useRef<HTMLElement>(null);
  const formRef = useRef<HTMLFormElement>();
  const [birthGoalList, setBirthGoalList] = useState<Array<IGoalMonthQuantity>>([]);
  const [paramGoalList, setParamGoalList] = useState<Array<IGoal>>([]);
  const { payload, setPayload, setBlockNextStep } = useOnboarding();
  const [errors, setErrors] = useState<string | undefined>();

  const { scrollTo } = useSmoothScroll({
    ref: mainRef,
    speed: 30,
    direction: 'y'
  });

  async function handleChangesBirthGoal() {
    const isValid = await validateGoalForm(false);
    setBlockNextStep(!isValid);
  }

  async function validateGoalForm(isShowAllMessage = true) {
    const formData = formRef.current?.getData() || null;
    const goals: IGoal = formData.goal;
    formRef.current?.setErrors({});
    const errors = {} as any;
    let isValide = false;
    if (formData) {
      try {
        await birthGoalSchema.validate({ goals: goals }, { abortEarly: false });
        isValide = true;
      } catch (err) {
        setBlockNextStep(true);
        if (err instanceof yup.ValidationError) {
          err.inner.forEach((e) => {
            if (isShowAllMessage) errors[e.path as string] = e.message;
          });
        }
      } finally {
        if (formRef && formRef.current) {
          formRef.current.setErrors(errors);
        }
        return isValide;
      }
    }
  }

  async function handleForm() {
    const formData = formRef.current?.getData();
    const birthGoals = formData.goal;

    const canBeSubmit = await useYupValidate(formRef, { goal: birthGoals }, birthGoalSchema);
    if (canBeSubmit) {
      //@ts-ignore
      const updated = birthGoals.reduce((total, next) => {
        let goals = [...total];
        //@ts-ignore
        next.quantities.forEach(goal => goal.quantity > 0 && goals.push(goal));
        return goals;
      }, []);
      const data: IGoalsOnboarding = {
        beginning: updated,
        end: payload.goals?.end
      };
      setPayload((payload) => {
        const response = { ...payload, goals: data };
        localStorage.setItem(Constants.LOCALSTORAGE_PAYLOAD, JSON.stringify(response));
        return response;
      });
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (!onlyRead) setBlockNextStep(false);
    if (formRef && formRef.current) {
      const { birthGoalList } = formRef.current?.getData();
      setBirthGoalList(() => birthGoalList || [birthGoalBase]);
    }
    scrollTo('#pageStart');
    if (payload && payload.goals && payload.goals.beginning) {
      setBirthGoalList(() => payload.goals.beginning);
    }
    loadGoalsList();
  }, []);

  const loadGoalsList = () => {
    axios.get("/goals").then(({ data }) => {
      if (data?.beginning) {
        setParamGoalList(data.beginning);
      }
    });
  }

  useEffect(() => {
    if(onlyRead){
      var values = paramGoalList.map(goal=>{
        return goal.months.map(month=>{
          var goalMonth = {
            category_id: goal.category.id,
            month: month.month,
            quantity: month.quantity
          } as IGoalMonthQuantity
          return goalMonth
        })
        
      })
      setBirthGoalList(values.flat())
    }
  },[paramGoalList])

  return (
    <>
      <main ref={mainRef as any}>
        <section className="createBirthGoal__container">
          <h1 id="pageStart">Meta de Nascimento</h1>
          <Form ref={formRef as any} onSubmit={() => {}}>
              <CreateBirthGoal
                list={paramGoalList}
                formRef={formRef}
                initData={birthGoalList}
                onAnywhereChanges={handleChangesBirthGoal}
                inputError={(err) => setErrors(() => err)}
                onlyRead={onlyRead}
              />
              <span className="modalEdit__message--error">{errors}</span>
          </Form>
        </section>
      </main>
      {!onlyRead && 
        <footer>
          <FooterControlls
            nextStepEvent={async () => {
              return await handleForm();
            }}
            backStepEvent={async () => {
              return await handleForm();
            }}
          />
        </footer>
      }
    </>
  );
}
