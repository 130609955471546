import { GoogleMap } from "@react-google-maps/api";
import { CSSProperties, ReactNode, useCallback, useEffect, useState } from "react";
import "./styles.css";
import { ICoord } from "../../../@types/GoogleMaps/ICoord";
import { useGoogleMaps } from "../../../hooks/useGoogleMaps";
import { generateuuid } from "../../../utils/uuid/generate";

type MapProps = {
  children?: ReactNode;
  currentCoords?: ICoord;
  zoomControl?: boolean;
  bounds?: google.maps.LatLngBounds;
  fitBounds?: ICoord[];
  onClick?: (event: google.maps.MapMouseEvent) => void;
};

const mapStyles = {
  width: "100%",
  height: "100%",
} as CSSProperties;

export function Map({ children, currentCoords, onClick, zoomControl = true, bounds, fitBounds }: MapProps) {
  const {
    toolMode,
    getGeoposition,
    setNewPolygon,
    currentZoom,
    currentPosition,
    mapLoaded,
    getBounds,
  } = useGoogleMaps();

  const [mapref, setMap] = useState<google.maps.Map>();

  const [defaultCoords, setDefaultCoords] = useState<ICoord | undefined>(undefined);

  const [framesLoaded, setFramesLodaded] = useState<boolean>(false);

  // Isso lida com o clique no mapa
  function onClickMouse(event: google.maps.MapMouseEvent) {
    onClick && onClick(event);
    if (toolMode === "draw")
      setNewPolygon((prev) => {
        return {
          uuid: prev.uuid ? prev.uuid : generateuuid(),
          coords: [...prev.coords, getGeoposition(event)],
        };
      });
  }

  // Isso faz o primeiro carregamento do mapa
  const onLoad = useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  useEffect(() => setDefaultCoords(currentCoords), [currentCoords]);

  useEffect(() => {
    if (fitBounds !== undefined && mapref !== undefined) {
      mapref.fitBounds(getBounds(fitBounds))
    }
  }, [fitBounds, mapref])

  // Enquanto o mapa não estiver carregado não exibimos
  if (!mapLoaded) return <></>;

  return (
    mapLoaded && (
      <GoogleMap
        onLoad={onLoad}
        mapContainerStyle={mapStyles}
        center={defaultCoords || currentPosition}
        zoom={18}
        options={{
          streetViewControl: false,
          scaleControl: true,
          fullscreenControl: false,
          styles: [
            {
              featureType: "poi.business",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
          ],
          gestureHandling: "greedy",
          disableDoubleClickZoom: true,
          minZoom: 10,
          mapTypeControl: true,
          mapTypeId: google.maps.MapTypeId.SATELLITE,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: google.maps.ControlPosition.BOTTOM_CENTER,
            mapTypeIds: [
              google.maps.MapTypeId.ROADMAP,
              google.maps.MapTypeId.SATELLITE,
              google.maps.MapTypeId.HYBRID,
            ],
          },
          clickableIcons: false,
          draggableCursor: "grab",
        }}
        onClick={onClickMouse}
        onTilesLoaded={() => setFramesLodaded(true)}
        onUnmount={() => setFramesLodaded(false)}
      >
        {framesLoaded && children}
      </GoogleMap>
    )
  );
}
