import { Marker, Polygon } from "react-leaflet";
import { ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { FlyToBounds } from "../../../Leaflet/FlyToBounds/Index";
import { LeafletStaticMap } from "../../../Leaflet/LeafletStaticMap/Index";
import Constants from "../../../../constants/Index";
import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";
import L from "leaflet";

interface StaticMapRenderProps {
    farm?: ICoords
    microAreas: ICoords[]
    mainPolygon: ICoords;
    fitCoords?: ICoords;
    staticsElementsOptions?: IStaticMapOptions;
    allMicroAreas?: any[];
    setMicroAreaSelected?: (id: any) => void;
}

interface IStaticMapOptions {
    color: string;
    dashArray: string;
}


export const StaticMapRender = ({ mainPolygon, farm, microAreas, fitCoords, staticsElementsOptions, allMicroAreas, setMicroAreaSelected }: StaticMapRenderProps) => {
    const svgIcon = L.divIcon({
        html: `
            <div class="iconBatch"/>
        `,
        iconAnchor: [0, 0]
    });

    return <>
        <LeafletStaticMap >
            <FlyToBounds fitCoords={fitCoords || mainPolygon}>
                {farm &&
                    <Polygon
                        dashArray={Constants.LEAFLET_DASHED_GREEN.dashArray}
                        color={Constants.LEAFLET_DASHED_GREEN.color}
                        positions={farm}
                        fill={false}
                    />
                }
                {[...microAreas].map((staticElement, index) => <Polygon
                    color={!staticsElementsOptions ? Constants.LEAFLET_UNFOCUS.color : staticsElementsOptions.color}
                    fillColor='rgba(0,0,0,0)'
                    positions={staticElement}
                    dashArray={staticsElementsOptions ? staticsElementsOptions.dashArray : ""}
                    fillOpacity={
                        Constants.LEAFLET_UNFOCUS_TRANSLUCENT.fillOpacity
                    }
                    key={`${index}-${staticElement[0].lat}-${staticElement[0].lng}`}
                    eventHandlers={{
                        click: () => {
                            if (setMicroAreaSelected) {
                                setMicroAreaSelected(allMicroAreas?.find(el => el.map_coords == staticElement))
                            }
                        }
                    }}
                />)}
                <Polygon
                    color={Constants.LEAFLET_UNFOCUS_TRANSLUCENT.color}
                    fillColor={Constants.LEAFLET_NORMAL_BLUE.fillColor}
                    positions={mainPolygon}
                    fillOpacity={
                        Constants.LEAFLET_UNFOCUS_TRANSLUCENT.fillOpacity
                    }
                />
                {allMicroAreas && allMicroAreas.map((el: any) => el.micro_area_route?.current_micro_area ?
                    <Marker position={getCenterCoords(el.map_coords)} icon={svgIcon} />
                    :
                    <></>
                )}
            </FlyToBounds>
        </LeafletStaticMap>
    </>
}