import { CSSProperties, ReactNode, useEffect, useState } from "react";
//@ts-ignore
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import "./styles.css";

type AnimationModal =
  | "zoom"
  | "fade"
  | "flip"
  | "door"
  | "rotate"
  | "slideUp"
  | "slideDown"
  | "slideLeft"
  | "slideRight";

type ModalComponentProps = {
  children: ReactNode | ReactNode[];
  visible: boolean;
  contentStyle?: CSSProperties;
  animation?: AnimationModal;
  showMask?: boolean;
};

export function Modal({
  animation = "slideDown",
  contentStyle,
  children,
  visible,
  showMask = true,
}: ModalComponentProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => setShowModal(visible), [visible]);

  return (
    <Rodal
      customStyles={contentStyle}
      animation={animation}
      visible={showModal}
      onClose={() => {}}
      showMask={showMask}
    >
      {children}
    </Rodal>
  );
}
