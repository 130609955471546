import { SliderItem } from "react-compound-slider";

interface ITickProps {
  key: string;
  tick: SliderItem;
  count: number;
  disable?: boolean;
  format: (value: number) => string;
}

export const Tick = ({ tick, count, format, disable }: ITickProps) => (
  <>
    <div
      className="divisaoMeses"
      style={{
        cursor: disable ? "default" : "pointer",
        left: `${tick.percent}%`,
      }}
    />
    <div
      className="listaMeses"
      style={{
        cursor: disable ? "default" : "pointer",
        marginLeft: `${-(100 / count) / 2}%`,
        width: `${100 / count}%`,
        left: `${tick.percent}%`,
      }}
    >
      {format(tick.value)}
    </div>
  </>
);
