import React, { useEffect, useState } from "react";
import "./style.css";
import axios from "../../../services/axios";
import {
  IOptionProps,
  IOptionsProps,
} from "../../../@types/reactSelect/IOption";
import { DropDownSelect } from "../../Filters/DropDown/Index";
import { MultipleSelect } from "../../MultipleSelect/Index";
import { sortBy } from "../../../utils/arrays/sortBy";
import { useForm } from "react-hook-form";
import { useTaskCreation } from "../../../hooks/useTaskCreation";
import { capitalize } from "../../../utils/strings/capitalize";
import { CounterInputEdit } from "../../EditOnboarding/components/Input/CounterInput/Index";

interface SprayingProps {}

interface SelectedProductProps extends IOptionProps {
  liter_per_ha?: number;
}

type SelectedProductsProps = Array<SelectedProductProps>;

export const Spraying: React.FC<SprayingProps> = () => {
  const { control } = useForm();
  const {
    payload,
    setPayload,
    setAllowTaskCreation,
    responsibleId,
    microAreaId,
  } = useTaskCreation();

  const [productOptions, setProductOptions] = useState<IOptionsProps>([]);
  const [applicationTypeOptions, setApplicationTypeOptions] =
    useState<IOptionsProps>([]);
  const [changes, setChanges] = useState<any>();

  const [selectedProducts, setSelectedProducts] =
    useState<SelectedProductsProps>([]);

  const [showProductsChooser, setShowProductsChooser] =
    useState<boolean>(false);
  const [showProductsCounter, setShowProductsCounter] =
    useState<boolean>(false);

  useEffect(() => {
    getProducts();
    getApplicationTypes();
  }, []);

  const getProducts = async () => {
    await axios.get("/tasks/spraying/products").then((response) => {
      if (!response.data) return;
      setProductOptions(
        response.data
          .map((prod: { name: string; id: number }) => ({
            label: prod.name.toLowerCase(),
            value: prod.id,
          }))
          .sort(sortBy("label"))
      );
    });
  };

  const getApplicationTypes = async () => {
    await axios.get("/tasks/spraying/application-types").then((response) => {
      if (!response.data) return;
      setApplicationTypeOptions(
        response.data
          .map((prod: { name: string; id: number }) => ({
            label: prod.name,
            value: prod.id,
          }))
          .sort(sortBy("label"))
      );
    });
  };

  const handleApplicationTypeChoice = (typeId: any) => {
    setShowProductsChooser(true);
    setChanges((prev: any) => ({
      ...prev,
      application_type_id: typeId,
    }));
  };

  const handleProductsSelection = (prodArray: IOptionsProps) => {
    let auxArray = prodArray.slice();
    prodArray.forEach((prod, index) => {
      selectedProducts.forEach((selProd) => {
        if (prod.value === selProd.value) auxArray[index] = selProd;
      });
    });
    setSelectedProducts(auxArray);
  };

  const handleProducLiters = (liters: any, index: number) => {
    let currProducts = selectedProducts.slice();

    currProducts[index] = { ...currProducts[index], liter_per_ha: +liters };

    setSelectedProducts(currProducts);
  };

  useEffect(() => {
    if (selectedProducts.length === 0) setShowProductsCounter(false);
    else setShowProductsCounter(true);

    if (
      selectedProducts.length === 0 ||
      selectedProducts.some((prod) => typeof prod.liter_per_ha === "undefined")
    )
      return setAllowTaskCreation(false);

    const noZeroCounter = !selectedProducts.some(
      (prod) => prod.liter_per_ha === 0
    );

    setAllowTaskCreation(noZeroCounter);

    if (noZeroCounter) {
      const products = selectedProducts.map((product) => ({
        product_id: product.value,
        liter_per_ha: product.liter_per_ha,
      }));

      setPayload({
        ...payload,
        changes: [
          {
            ...changes,
            assigned_user_id: responsibleId,
            micro_area_id: microAreaId,
            products: products,
          },
        ],
      });
    }
  }, [selectedProducts, changes]);

  return (
    <>
      <DropDownSelect
        options={applicationTypeOptions}
        placeholder="Selecionar tipo de aplicação... "
        onChange={(ev) => handleApplicationTypeChoice(ev)}
      />
      {showProductsChooser && (
        <>
          <MultipleSelect
            placeholder="Selecionar produto(s) utilizado(s)..."
            options={productOptions ?? []}
            onChange={(ev: IOptionsProps) => {
              handleProductsSelection(ev);
            }}
          />
          {showProductsCounter &&
            selectedProducts.map((product, index) => {
              return (
                <React.Fragment key={product.value}>
                  <div className="spraying-counter-container">
                    <span className="product-label">
                      {capitalize(product.label.toLowerCase())}
                    </span>
                    <CounterInputEdit
                      defaultValue={0}
                      control={control}
                      isInline
                      label="ml/ha"
                      onChangevalue={(ev) => handleProducLiters(ev, index)}
                      max={99999999}
                    />
                  </div>
                </React.Fragment>
              );
            })}
        </>
      )}
    </>
  );
};
