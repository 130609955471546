/*
  Isso lê um arquivo .kml e extrai as cordenadas de cada poligono 
  ou linha.
*/

import { ChangeEvent } from "react";
import { ICoords } from "../../@types/GoogleMaps/ICoord";
var file: File;

type PolygonCoords = {
  name: string;
  type: string;
  coords: ICoords;
};

interface PolygonsCoords extends Array<PolygonCoords> {}

// Isso pega o poligono ou linha e procura pelo children em busca das coordenadas
async function extractCoords(item: any, tagName: string) {
  return new Promise((resolve) => {
    const arrayOfPolygons = [];
    const lineStrings: any[] = item.getElementsByTagName(tagName);
    for (const lineString of lineStrings) {
      // Isso é o vetor de coordenadas que está sendo extraído
      const coords: string = lineString.getElementsByTagName("coordinates")[0].childNodes[0].nodeValue.trim();
      // Isso é o objeto resultante após a extração
      const polygon = {
        name: "",
        type: tagName,
        coords: [] as ICoords,
      } as PolygonCoords;

      let pairCoords = coords
        .replaceAll(/\n|\s/g, ";") // remove os espaços em branco e quebras de linha
        .replaceAll(",;", ",") // garante que as coordenadas sejam separadas por virgula
        .split(";") // transforma cada coordenada em uma posição do vetor
        .filter((e) => e); // filtra somentes as coordenadas válidas

      pairCoords = pairCoords.map((e) => e.trim());

      // Isso transforma a string de coordenadas em um objeto de coordenadas
      pairCoords.forEach((point) => {
        if (point !== "") {
          const coord = point.split(",");
          polygon.coords.push({ lat: +coord[1], lng: +coord[0] });
        }
      }, []);

      arrayOfPolygons.push(polygon);
    }
    // No fim resolvemos a promisse e devolvemos o resultado
    resolve(arrayOfPolygons.length > 0 ? arrayOfPolygons : null);
  });
}

// Isso verifica se no kml exite um polígono ou uma linha
async function extractGoogleCoords(plainText: any) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(plainText, "text/xml");
  const KMLData = [];
  for (const item of xmlDoc.getElementsByTagName("Placemark") as any) {
    // se existrir uma linha pegamos as coordenadas
    if (item.getElementsByTagName("LinearRing").length > 0) {
      KMLData.push(await extractCoords(item, "LinearRing"));
    }

    // se existrir um poligono pegamos as coordenadas
    if (item.getElementsByTagName("Polygon").length > 0) {
      KMLData.push(await extractCoords(item, "Polygon"));
    }
    
    // se existrir uma linha pegamos as coordenadas
    if (item.getElementsByTagName("LineString").length > 0) {
      KMLData.push(await extractCoords(item, "LineString"));
    }
  }
  return KMLData as PolygonsCoords;
}

// função principal
export async function readFileKML(event: ChangeEvent<HTMLInputElement>) {
  return new Promise((resolve) => {
    if (event && event.target && event.target.files) {
      file = event.target.files[0];
      let fileReader = new FileReader();
      fileReader.onload = async (e: any) => {
        const response: PolygonsCoords = await extractGoogleCoords(e.target.result);
        const [coordsMoreTwoPoints] = response.flat().filter((e) => e.coords.length >= 3);
        resolve([coordsMoreTwoPoints]);
      };
      fileReader.readAsText(file);
    }
  });
}
