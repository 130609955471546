import { ButtonOnlyTitle } from "../../../Buttons/ButtonOnlyTitle/Index";
import "./styles.css";

type FooterContentModal = {
  onEventCancel?: () => void;
  onEventSuccess?: () => void;
  disableSuccesButton?: boolean;
};

export function FooterContentModal({
  onEventCancel,
  onEventSuccess,
  disableSuccesButton = false,
}: FooterContentModal) {
  return (
    <section className="footerContentModal__container">
      <ButtonOnlyTitle
        title="cancelar"
        theme="light"
        onClick={() => onEventCancel && onEventCancel()}
      />
      <ButtonOnlyTitle
        title="confirmar"
        theme="info"
        disabled={disableSuccesButton}
        onClick={() => onEventSuccess && onEventSuccess()}
      />
    </section>
  );
}
