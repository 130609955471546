import { Form } from "@unform/web";
import "./style.css";
import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { ButtonOnlyTitle } from "../../../../components/Buttons/ButtonOnlyTitle/Index";
import { ButtonWithChildren } from "../../../../components/Buttons/ButtonWithChildren/Index";
import { AlertModal } from "../../../../components/Modal/Alert/Index";
import { SimpleButtonClose } from "../../../../components/Modal/Contents/SimpleButtonClose/Index";
import { TextModal } from "../../../../components/Modal/Contents/TextModal/Index";
import { TitleModal } from "../../../../components/Modal/Contents/TitleModal/Index";
import Constants from "../../../../constants/Index";
import { EditValues } from "../../../../hooks/EditValues";
import axios from "../../../../services/axios";
import { ReactComponent as PositiveIcon } from "../../../../assets/svg/icons/positive.svg";
import { ReactComponent as NegativeIcon } from "../../../../assets/svg/icons/negative.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/svg/icons/CheckFull.svg";
import { Loading } from "../../../../components/Loading/Index";
import { useFilter } from "../../../../hooks/useFilter";

interface arrayItems {
  title?: string;
  element: ReactNode;
}

interface SubSectionProps {
  items: arrayItems[];
  setSection?: (item: number) => void;
  defaultValue: number;
}

const selectedSubsection = {
  color: "#4d4d4d",
  fontWeight: 600,
};

const contentStyleAlert = {
  width: "440px",
  height: "237px",
  borderRadius: "20px",
} as CSSProperties;

export function SubSection({ items, setSection, defaultValue }: SubSectionProps) {
  const [subSectionSelected, setSubSectionSelected] = useState(defaultValue);
  const {
    formRefValue,
    blockSave,
    refreshValues,
    setRefreshValues,
    setBlockSave,
    permissionMaster
  } = EditValues();
  const [formRef, setFormRef] = useState<any>([]);
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [concludedModal, setConcludedModal] = useState<boolean>(false);
  const [modalCancelarVisible, setModalCancelarVisible] = useState<boolean>(false);
  const [visibleLoading, setVisibleLoading] = useState<boolean>(false);
  const { setSideBarOptionSelected } = useFilter();


  function submitProfile() {
    var postProfiles: any[] = [];
    formRef?.users.forEach((user: any) => {
      if (user.isNew == "true")
        postProfiles.push({
          name: user.name,
          phone: user.phone,
          profile_id: Number(user.profile?.value),
          password: Constants.DEFAULT_PASSWORD,
        });
    });

    axios
      .post("/users/user", postProfiles)
      .then(() => {
        if (postProfiles.length != 0) {
          setConcludedModal(true)
          setRefreshValues(!refreshValues)
        }
      })
      .catch((error) => console.log(error));
  }

  function submitAssortments() {
    const assortments = {
      state_registration: formRef.state_registration,
      id: Number(formRef.id),
      address: {
        city: {
          code: formRef.address?.city?.value,
          name: formRef.address?.city?.label,
        },
        state: formRef.address?.state?.value,
        street: formRef.address?.street,
      },
      name: formRef.name,
      document: formRef.document != "" ? formRef.document : null,
    };

    axios
      .patch("/assortments", assortments)
      .then(() => { setConcludedModal(true) })
      .catch((error) => console.log(error));
  }

  function submitFarms() {
    var patchFarms: any[] = [];
    var postFarms: any[] = [];

    formRef?.farms.forEach((farm: any) => {
      if (farm.isNew == "false")
        patchFarms.push({
          farm_id: farm.uuid,
          name: farm.name,
          document: farm.document,
          state_registration: farm.state_registration,
          properties: {
            season: {
              dry_initial: farm.properties.season.dry_initial,
              rain_initial: farm.properties.season.rain_initial,
            },
          },
        });
      if (farm.isNew == "true")
        postFarms.push({
          name: farm.name,
          initials: farm.initials,
          document: farm.document != "" ? farm.document : null,
          owner_user_id: farm.manager_user_id.value,
          state_registration: farm.state_registration,
          map_coords: farm.file.coords,
          address: {
            city: {
              name: farm.address.city.label,
              code: farm.address.city.value,
            },
            state: farm.address.uf.label,
            street: farm.address.street,
          },
          properties: {
            season: {
              dry_initial: farm.properties.season.dry_initial,
              rain_initial: farm.properties.season.rain_initial,
            },
          },
        });
    });

    axios
      .patch("/farms", patchFarms)
      .then(() => {
        if (postFarms.length == 0)
          setConcludedModal(true)
      })
      .catch((error) => console.log(error));
    axios
      .post("/farms", postFarms)
      .then(() => {
        if (postFarms.length != 0) {
          setTitle('Agora crie retiros!')
          setModalVisible(true)
          setDescription('Continue cadastrando para criar tarefas dentro da sua nova fazenda.')
        }
      })
      .catch((error) => console.log(error));
  }

  function submitArea() {
    var patchAreas: any[] = [];
    var postAreas: any[] = [];

    formRef?.farms.forEach((farm: any) => {
      farm.areas.map((area: any) => {
        if (area.isNew == "false")
          patchAreas.push({
            area_id: area.uuid,
            name: area.name
          })
        if (area.isNew == "true")
          postAreas.push({
            name: area.name,
            initials: area.initials,
            farm_id: area.farm_id,
            acreage: area.acreage,
            owner_user_id: area.manager_user_id.value,
            map_coords: area.map_coords.coords
          })
      })
    });

    axios
      .patch("/areas", patchAreas)
      .then(() => {
        if (postAreas.length == 0)
          setConcludedModal(true)
      })
      .catch((error) => console.log(error));
    axios
      .post("/areas", postAreas)
      .then(() => {
        if (postAreas.length != 0) {
          setTitle('Agora crie pastos!')
          setModalVisible(true)
          setDescription('Continue cadastrando para criar tarefas dentro do seu novo retiro.')
        }
      })
      .catch((error) => console.log(error));
  }

  function sumbitMicroAreas() {
    var postMicroAreas: any[] = [];

    formRef.farms.map((farm: any) =>
      farm.areas.map((area: any) =>
        area.microareas.map((micro: any) => {
          if (micro.isNew == "true")
            postMicroAreas.push(
              {
                initials: micro.initials.split("-")[2],
                acreage: micro.acreage,
                area_id: micro.area_id,
                map_coords: micro.map_coords.coords
              }
            )
        })
      )
    )

    axios
      .post("/micro-areas", postMicroAreas)
      .then(() => {
        if (postMicroAreas.length != 0) {
          setTitle('Agora crie lotes!')
          setModalVisible(true)
          setDescription('Continue cadastrando para criar tarefas dentro do seu novo pasto.')
        }
      })
      .catch((error) => console.log(error));
  }

  function submitBatches() {
    var postBatches: any[] = [];
    var listCategories: any[] = [];
    formRef?.lots.forEach((batche: any) => {
      if (batche.isNew == "true") {
        batche.categories.map((categorie: any) => listCategories.push(
          {
            id: Number(categorie.id),
            month: Number(categorie.month),
            quantity: Number(categorie.quantity),
          }
        ))
        postBatches.push({
          area_id: batche.area.value,
          initials: batche.initials,
          current_micro_area_id: batche.current_micro_area.value,
          micro_area_route_ids: batche.micro_area_route_ids,
          categories: listCategories,
        });
      }
    })
    axios
      .post("/batches", postBatches)
      .then(() => {
        if (postBatches.length != 0) {
          setConcludedModal(true)
        }
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    switch (formRef?.form) {
      case "profile":
        submitProfile();
        break;
      case "assortment":
        submitAssortments();
        break;
      case "farms":
        submitFarms();
        break;
      case "areas":
        submitArea();
        break;
      case "microAreas":
        sumbitMicroAreas();
        break;
      case "batches":
        submitBatches();
        break;
    }
  }, [formRef]);

  useEffect(() => {
    setModalCancelarVisible(false);
  }, [refreshValues]);

  useEffect(() => {
    setVisibleLoading(true);
    setBlockSave(true);
  }, [subSectionSelected]);

  useEffect(() => {
    if (visibleLoading) {
      setVisibleLoading(false);
    }
  }, [visibleLoading]);

  return (
    <div className="bodyContent">
      <Loading visible={visibleLoading} />
      <AlertModal visible={modalVisible} contentStyle={contentStyleAlert}>
        <section className="modalEdit__alert">
          <SimpleButtonClose
            onClick={() => {
              setModalVisible(!modalVisible)
              setRefreshValues(!refreshValues)
            }}
          />
          <TitleModal>{title}</TitleModal>
          <TextModal>
            {description}
          </TextModal>
          <div className="buttonsModal-controll">
            <ButtonOnlyTitle
              onClick={() => {
                setModalVisible(false)
                setRefreshValues(!refreshValues)
                setBlockSave(true)
              }}
              title="mais tarde"
              theme="light"
            />
            <ButtonOnlyTitle
              onClick={() => {
                setSubSectionSelected(subSectionSelected + 1)
                setModalVisible(false)
                if (subSectionSelected == 3 && setSection) {
                  setSection(2)
                }
              }}
              title="continuar"
              theme="info"
            />
          </div>
        </section>
      </AlertModal>
      <AlertModal visible={modalCancelarVisible} contentStyle={contentStyleAlert}>
        <section className="modalEdit__alert">
          <SimpleButtonClose onClick={() => setModalCancelarVisible(false)} />
          <TitleModal>Você tem certeza?</TitleModal>
          <TextModal>
            Ao cancelar, você perderá todas as alterações realizadas!
          </TextModal>
          <div className="buttons-controll">
            <ButtonWithChildren onClick={() => {
              setSideBarOptionSelected("MAPA")
            }}>
              <div className="buttonWithChildren--content-inline">
                <PositiveIcon />
                <h6>Sim</h6>
              </div>
            </ButtonWithChildren>
            <ButtonWithChildren onClick={() => setModalCancelarVisible(false)}>
              <div className="buttonWithChildren--content-inline">
                <NegativeIcon />
                <h6>Não</h6>
              </div>
            </ButtonWithChildren>
          </div>
        </section>
      </AlertModal>
      {items[0].title && (
        <>
          <div className="itemSelected">
            {items.map((value, key) => (
              <h3
                key={key}
                style={subSectionSelected == key ? selectedSubsection : {}}
                onClick={() => setSubSectionSelected(key)}
              >
                {value.title}
              </h3>
            ))}
          </div>

          <span className="delimiterItemSelected" />
        </>
      )}

      <Form onSubmit={() => setFormRef(formRefValue.current?.getData())}>
        {items.map((value, key) =>
          subSectionSelected == key ? (
            <div
              className={
                permissionMaster ? `itemSelectedBody ${value.title ? "" : "withoutTitle"
                  }` : "withoutButton"
              }
            >
              {value.element}
            </div>
          ) : (
            <></>
          )
        )}
        {permissionMaster ? <span className="delimiterItemSelected" /> : <></>}

        <div
          className="footer-view__container d-flex"
          style={{ marginTop: 40 }}
        >
          <AlertModal visible={concludedModal} contentStyle={contentStyleAlert}>
            <section className="modalEdit__alert">
              <SimpleButtonClose onClick={() => { setConcludedModal(false) }} />
              <CheckIcon />
              <TitleModal>Concluído!</TitleModal>
              <TextModal>Você salvou com sucesso</TextModal>
              <div style={{ width: 152 }}>
                <ButtonOnlyTitle
                  title="voltar"
                  id="voltar"
                  theme="success"
                  type="button"
                  onClick={() => {
                    setConcludedModal(false)
                    setBlockSave(true);
                  }}
                />
              </div>
            </section>
          </AlertModal>
          {permissionMaster &&
            <ButtonOnlyTitle
              title="cancelar"
              id="cancel"
              theme="light"
              type="button"
              onClick={() => setModalCancelarVisible(true)}
            />
          }
          {permissionMaster &&
            <ButtonOnlyTitle
              title="salvar"
              id="save"
              theme="info"
              type="submit"
              disabled={blockSave}
            />
          }
        </div>
      </Form>
    </div>
  );
}