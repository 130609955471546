import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as BackIcon } from "../../../../assets/svg/icons/back.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as GrassIcon } from "../../../../assets/svg/icons/grass.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";
import { ButtonYesOrNo } from "../../../Buttons/ButtonYesOrNo";

interface InfoDetachedTaskProps {
  task: ITaskDetachedTask | undefined;
}

export default function InfoDetachedTask(task: InfoDetachedTaskProps) {
  const [taskDetachedTask, setTaskDetachedTask] = useState<ITaskDetachedTask>();
  useEffect(() => {
    setTaskDetachedTask(task.task)
  }, []);

  return (
    <>
      <MessageBalloon text={`${taskDetachedTask?.task_details}`} pointed />

      <div className="containerQuestion">
        <MessageBalloon text={`A tarefa foi concluída?`} pointed />
        <ButtonYesOrNo onlyResult optionSelected={!taskDetachedTask?.skip_reason ? "yes" : "no"} />
      </div>

      {taskDetachedTask?.skip_reason != undefined && <MessageBalloon text={`${taskDetachedTask?.skip_reason}`} />}
    </>
  )
}