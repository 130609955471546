import "./styles.css";

import { useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import { IoTriangle } from "react-icons/io5";
import { FaMinus } from "react-icons/fa";
import { GoPlus } from "react-icons/go";

type CustomInputCounterProps = JSX.IntrinsicElements["input"] & {
  name: string;
  min?: number;
  max?: number;
  getMessageError?: (error: string) => void;
  onChangevalue?: (value: number) => void;
  handleAnywhereChanges?: (change: any) => void;
  isInline?: boolean;
  label?: string;
  onlyRead?: boolean;
};

export function CounterInput({
  name,
  min,
  max,
  getMessageError,
  onChangevalue,
  isInline = false,
  label,
  onlyRead = false,
  handleAnywhereChanges,
  ...rest
}: CustomInputCounterProps) {
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [value, setValue] = useState<number>((defaultValue as number) || 0);
  const [errorField, setErrorField] = useState<string>("");

  function handleIncrementCount(value: number) {
    if (max && value > max) return max;
    if (inputRef && inputRef.current) inputRef.current.value = value.toString();
    if (onChangevalue) onChangevalue(value);
    return value;
  }

  function handleDecrementCount(value: number) {
    if ((min && value < min) || value < 0) return min || 0;
    if (inputRef && inputRef.current) inputRef.current.value = value.toString();
    if (onChangevalue) onChangevalue(value);
    return value;
  }

  function handleOnchangeInput(numb: string) {
    if (!isNaN(Number(numb)) && inputRef && inputRef.current) {
      const value = Math.abs(Number(numb));
      inputRef.current.value = value.toString();
      setValue(() => value);
    }
    if (onChangevalue) onChangevalue(value);
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        if (ref.current.value) {
          return Number(ref.current.value);
        }
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    const value = Number(inputRef.current?.value);
    setValue(() => Number(value));
    onChangevalue && onChangevalue(value);
  }, [inputRef.current?.value]);

  useEffect(() => {
    if (getMessageError) getMessageError(error ? error : "");
    else setErrorField(() => (error ? error : ""));
  }, [error]);

  return (
    <>
      <div
        className={`custominput__number${
          isInline ? "--container-inline" : "--container-block"
        }`}
      >
        <div
          className={`input__counter-container${
            isInline && label ? "--inline" : "--block"
          }`}
        >
          <input
            onWheel={(e) => e.target.addEventListener("blur", () => {})}
            onChange={(numb) => {
              handleOnchangeInput(numb.target.value)
              if (onChangevalue) onChangevalue(value)
              handleAnywhereChanges && handleAnywhereChanges(numb)
            }}
            defaultValue={defaultValue}
            ref={inputRef}
            type="number"
            disabled={onlyRead}
            {...rest}
          />

          {isInline && label && (
            <div
              className="label__only--inline"
              onClick={() => inputRef.current?.focus()}
            >
              <span>{label}</span>
            </div>
          )}
        </div>

        <div className={`btn__up--counter${isInline ? "-inline" : "-block"}`}>
          <button
            disabled={onlyRead || (max && value === max) || false}
            onClick={(event) => {
              setValue((prevState) => handleIncrementCount(prevState + 1));
              handleAnywhereChanges && handleAnywhereChanges(event)
            }}
            type="button"
          >
            {isInline ? <GoPlus /> : <IoTriangle />}
          </button>
        </div>

        <div className={`btn__down--counter${isInline ? "-inline" : "-block"}`}>
          <button
            disabled={onlyRead || (min && value === min) || value === 0}
            onClick={(event) => {
              setValue((prevState) => handleDecrementCount(prevState - 1));
              handleAnywhereChanges && handleAnywhereChanges(event);
            }}
            type="button"
          >
            {isInline ? <FaMinus /> : <IoTriangle />}
          </button>
        </div>
      </div>
      {errorField && (
        <small className="textInput--small-error animation__fadeIn">
          {errorField}
        </small>
      )}
    </>
  );
}
