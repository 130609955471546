import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { useTaskCreation } from "../../../hooks/useTaskCreation";
import { Modal } from "../../Modal/Default/Index";
import ChipPressableComponent from "../../Chips/ComponentChipPressable/Index";
import { RoundedCloseButton } from "../../Buttons/RoundedCloseButton/Index";
import { TaskCard } from "../../TaskCard/Index";
import { ReactComponent as FertillizingIcon } from "../../../assets/svg/icons/fertillizing.svg";
import { ReactComponent as DrinkingFountainIcon } from "../../../assets/svg/icons/drinking_fountain.svg";
import { ReactComponent as FenceIcon } from "../../../assets/svg/icons/fences.svg";
import { ReactComponent as ContourLineIcon } from "../../../assets/svg/icons/contour_line.svg";
import { ReactComponent as PlantingIcon } from "../../../assets/svg/icons/planting.svg";
import { ReactComponent as PulverizationIcon } from "../../../assets/svg/icons/pulverization.svg";
import { ReactComponent as MowingIcon } from "../../../assets/svg/icons/mowing.svg";
import { ReactComponent as SoilPreparationIcon } from "../../../assets/svg/icons/soil_preparation.svg";
import { ReactComponent as DetachedTask } from "../../../assets/svg/icons/detached_task.svg";
import { ReactComponent as BagFoodReceiveIcon } from "../../../assets/svg/icons/bagFoodReceive.svg";
import Pagination from "../../Pagination";
import { sortBy } from "../../../utils/arrays/sortBy";
import { CreateTaskModal } from "../../Modal/CreateTask/Index";
import { WaterTroughs } from "../../Tasks/WaterTroughs/Index";
import { SuccessModal } from "../../Modal/CreateTask/SuccessModal/Index";
import { SoilPreparation } from "../../Tasks/SoilPreparation/Index";
import { Planting } from "../../Tasks/Planting/Index";
import { Fertilization } from "../../Tasks/Fertilization/Index";
import { Spraying } from "../../Tasks/Spraying/Index";
import { ErrorModal } from "../../Modal/CreateTask/ErrorModal/Index";
import { Fencing } from "../../Tasks/Fencing/Index";
import { ButtonOnlyTitle } from "../../Buttons/ButtonOnlyTitle/Index";
import "./style.css";
import { BagReceive } from "../BagReceive/Index";

interface TasksModalsProps {}

const NO_TASK_PAGE = "NO_PAGE";

const createTaskModalStyle = {
  padding: "50px",
  width: "1185px",
  height: "662px",
  borderRadius: 20,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
} as CSSProperties;

export const TASKLIST = (
  [
    {
      title: "Adubação",
      filter: "pasture",
      icon: (<FertillizingIcon />) as JSX.Element,
      body: <Fertilization />,
      type: "FERTILIZATION",
    },
    {
      title: "Criar tarefa avulsa",
      filter: "pasture",
      icon: (<DetachedTask />) as JSX.Element,
      body: NO_TASK_PAGE,
      type: "DETACHED_TASK",
    },
    {
      title: "Bebedouros",
      filter: "pasture",
      icon: (<DrinkingFountainIcon />) as JSX.Element,
      body: <WaterTroughs />,
      type: "WATER_TROUGH",
    },
    {
      title: "Cercas",
      filter: "pasture",
      icon: (<FenceIcon />) as JSX.Element,
      body: <Fencing />,
      type: "FENCING",
    },
    {
      title: "Curva de nível",
      filter: "pasture",
      icon: (<ContourLineIcon />) as JSX.Element,
      body: NO_TASK_PAGE,
      type: "CONTOUR_FARMING",
    },
    {
      title: "Plantio",
      filter: "pasture",
      icon: (<PlantingIcon />) as JSX.Element,
      body: <Planting />,
      type: "PLANTING",
    },
    {
      title: "Preparo de solo",
      filter: "pasture",
      icon: (<SoilPreparationIcon style={{ marginLeft: -2 }} />) as JSX.Element,
      body: <SoilPreparation />,
      type: "SOIL_PREPARATION",
    },
    {
      title: "Pulverização",
      filter: "pasture",
      icon: (<PulverizationIcon style={{ marginLeft: -7 }} />) as JSX.Element,
      body: <Spraying />,
      type: "SPRAYING",
    },
    {
      title: "Roçagem",
      filter: "pasture",
      icon: (<MowingIcon />) as JSX.Element,
      body: NO_TASK_PAGE,
      type: "BRUSH_CUTTING",
    },
    {
      title: "Recebimento do produto",
      filter: "pasture",
      icon: (<BagFoodReceiveIcon />) as JSX.Element,
      body: <BagReceive />,
      type: "PRODUCT_INCOMING",
    },
  ] as ITasks
).sort(sortBy("title"));

const totalPages =
  TASKLIST.length % 6 == 0
    ? Math.floor(TASKLIST.length / 6)
    : Math.floor(TASKLIST.length / 6) + 1;

export const TasksModals: React.FC<TasksModalsProps> = () => {
  const {
    showTasklistModal,
    setShowTasklistModal,
    selectedTask,
    setSelectedTask,
    showCreateTaskModal,
    setShowCreateTaskModal,
    taskCreatedSuccessfully,
    taskCreationError,
    setTaskCreationError,
    setTaskCreatedSuccessfully,
    showMicroAreaUnavailableModal,
    setShowMicroAreaUnavailableModal,
    resetContext,
    setCurrentCoords,
    contextMicroArea,
    errorMessage
  } = useTaskCreation();
  const [currentPage, setCurrentPage] = useState<any[]>(TASKLIST.slice(0, 6));
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);

  const taskRef = useRef<ITask>();

  const handleTasksPagination = (value: number) => {
    setCurrentPageIndex(value);
    if (value === 1) setCurrentPage(TASKLIST.slice(0, 6));
    else setCurrentPage(TASKLIST.slice((value - 1) * 6, (value - 1) * 6 + 6));
  };

  const openTaskModal = (task: ITask) => {
    setShowTasklistModal(false);
    setShowCreateTaskModal(true);
  };

  const closeCreateTaskModal = () => {
    setShowCreateTaskModal(false);
    setSelectedTask(undefined);
    resetContext();
  };

  const closeTaskEndModal = () => {
    closeCreateTaskModal();
    setTaskCreatedSuccessfully(false);
    setTaskCreationError(false);
    setShowMicroAreaUnavailableModal(false);
  };


  useEffect(() => {
    if (!selectedTask) return;

    openTaskModal(selectedTask);
  }, [selectedTask]);

  const createNewTask = () => {
    closeTaskEndModal();
    setShowTasklistModal(true);
  };

  return (
    <>
      {showTasklistModal && (
        <div style={{ zIndex: 901 }}>
          <Modal
            visible={showTasklistModal}
            contentStyle={createTaskModalStyle}
          >
            <section className="createTask_section">
              <header className="createTask_header">
                <h2>Selecione uma tarefa</h2>
                <div className="createTask_header_buttonsWrapper">
                  <div className="createTask_chipsWrapper">
                    {/* <ChipPressableComponent theme="menu-light" label="Mapa do Gado" onClick={()=>{}}/>
                  <ChipPressableComponent theme="menu-light" label="Pastagem" onClick={()=>{}}/> */}
                  </div>
                  <RoundedCloseButton
                    onClick={() => setShowTasklistModal(false)}
                  />
                </div>
              </header>
              <main className="createTask_cardsWrapper">
                {currentPage &&
                  currentPage.map((task) => (
                    <TaskCard
                      title={task.title}
                      icon={task.icon}
                      key={task.title}
                      onClick={() => {
                        setSelectedTask(task);
                        taskRef.current = task;
                      }}
                    />
                  ))}
              </main>
            </section>
            <footer className="paginator paginator-divider">
              <Pagination
                currentPage={currentPageIndex}
                totalPages={totalPages}
                onClick={(value: number) => handleTasksPagination(value)}
              />
            </footer>
          </Modal>
        </div>
      )}

      {!showMicroAreaUnavailableModal &&
        selectedTask &&
        showCreateTaskModal && (
          <CreateTaskModal
            visible={showCreateTaskModal}
            onClose={() => closeCreateTaskModal()}
            task={selectedTask}
            children={selectedTask.body}
            onGoBack={() => {
              setCurrentCoords(contextMicroArea?.map_coords || []);
              closeCreateTaskModal();
              setShowTasklistModal(true);
            }}
          />
        )}

      {taskCreatedSuccessfully && (
        <SuccessModal
          onClose={() => closeTaskEndModal()}
          visible={taskCreatedSuccessfully}
          createNewTask={() => createNewTask()}
          monitoringScreen={() => {}}
        />
      )}

      {taskCreationError && (
        <ErrorModal
          visible={taskCreationError}
          onClose={() => closeTaskEndModal()}
          errorMessage={errorMessage}
        />
      )}

      {showMicroAreaUnavailableModal && (
        <div
          style={{
            zIndex: 1010,
          }}
        >
          <Modal
            visible={showMicroAreaUnavailableModal}
            contentStyle={{
              display: "flex",
              position: "relative",
              padding: 60,
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 30,
              flexDirection: "column",
              width: "600px",
              height: "auto",
              borderRadius: 25,
            }}
          >
            <header className="error__modal-header">
              <RoundedCloseButton onClick={() => closeTaskEndModal()} />
            </header>
            <main className="error__modal-message">
              <h2>Esse pasto possui lote!</h2>
              <span>
                Essa tarefa não pode ser criada enquanto o pasto possuir lote,
                você deve primeiro mover o lote para outro pasto.
              </span>
            </main>
            <footer className="batches-modal-footer">
              <ButtonOnlyTitle
                title="Criar outra tarefa"
                theme="light"
                onClick={() => createNewTask()}
              />
            </footer>
          </Modal>
        </div>
      )}
    </>
  );
};
