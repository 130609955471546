import { CSSProperties, useEffect, useRef, useState } from "react";
import "./styles.css";
import { Form } from "@unform/web";
import { CreateDeathGoal } from "../../../../components/Onboarding/Register/CreateDeathGoal/Index";
import { FooterControlls } from "../../../../components/Onboarding/Template/FooterControlls/Index";
import { useYupValidate } from "../../../../hooks/useYupValidate";
import Constants from "../../../../constants/Index";
import * as yup from "yup";
import { useOnboarding } from "../../../../hooks/useOnboarding";
import useSmoothScroll from "react-smooth-scroll-hook";
import { IDeathGoal, IGoal } from "../../../../@types/app/IGoal";
import { IGoalMonthQuantity, IGoalsOnboarding } from "../../../../@types/Onboarding/IGoal";
import axios from "../../../../services/axios";
import { useNavigate } from "react-router-dom";
import {
  exportAreasOnFormatOfAPI,
  exportAssortmentsOnFormatOfAPI,
  exportBatchesOnFormatOfAPI,
  exportEvolutionsOnFormatOfAPI,
  exportFarmsOnFormatOfAPI,
  exportGoalsOnFormatOfAPI,
  exportMicroAreasOnFormatOfAPI,
  exportProfilesOnFormatOfAPI,
} from "../../../../services/onboarding";
import { AlertModal } from "../../../../components/Modal/Alert/Index";
import { SimpleButtonClose } from "../../../../components/Modal/Contents/SimpleButtonClose/Index";
import { TitleModal } from "../../../../components/Modal/Contents/TitleModal/Index";
import { ButtonRedirect } from "../../../../components/Modal/Error/ButtonRedirect/Index";
import { Loading } from "../../../../components/Loading/Index";

const deathGoalSchema = yup.object().shape({
  goals: yup.array().of(
    yup.object().shape({
      quantities: yup.array().of(
        yup.object({
          quantity: yup
            .number()
            .required("Campo obrigatório não informado.")
            .moreThan(-1, "Campo obrigatório não informado."),
        })
      ),
    })
  ),
});

const deathGoalBase = {} as IDeathGoal;

interface CreateDeathGoalsProps {
  onlyRead?: boolean,
}

export function CreateDeathGoals({
  onlyRead = false
}: CreateDeathGoalsProps) {
  const mainRef = useRef<HTMLElement>(null);
  const formRef = useRef<HTMLFormElement>();
  const [deathGoalList, setDeathGoalList] = useState<Array<IGoalMonthQuantity>>([]);
  const [paramGoalList, setParamGoalList] = useState<Array<IGoal>>([]);
  const { payload: payloadContext, setPayload, setBlockNextStep } = useOnboarding();
  const [errors, setErrors] = useState<string | undefined>();
  const navigate = useNavigate();

  const { scrollTo } = useSmoothScroll({
    ref: mainRef,
    speed: 30,
    direction: "y",
  });

  async function handleChangesDeathGoal() {
    const isValid = await validateGoalForm(false);
    setBlockNextStep(!isValid);
  }

  async function validateGoalForm(isShowAllMessage = true) {
    const formData = formRef.current?.getData() || null;
    const goals: IGoal = formData.goal;
    formRef.current?.setErrors({});
    const errors = {} as any;
    let isValide = false;
    if (formData) {
      try {
        await deathGoalSchema.validate({ goals: goals }, { abortEarly: false });
        isValide = true;
      } catch (err) {
        setBlockNextStep(true);
        if (err instanceof yup.ValidationError) {
          err.inner.forEach((e) => {
            if (isShowAllMessage) errors[e.path as string] = e.message;
          });
        }
      } finally {
        if (formRef && formRef.current) {
          formRef.current.setErrors(errors);
        }
        return isValide;
      }
    }
  }

  // Isso diz a página quando mostrar o loading
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  async function handleForm() {
    const formData = formRef.current?.getData();
    const deathGoals = formData.goal;

    const canBeSubmit = await useYupValidate(formRef, { goal: deathGoals }, deathGoalSchema);
    if (canBeSubmit) {
      setIsLoading(true);
      //@ts-ignore
      const updated = deathGoals.reduce((total, next) => {
        let goals = [...total];
        //@ts-ignore
        next.quantities.forEach((goal) => goal.quantity > 0 && goals.push(goal));
        return goals;
      }, []);
      const data: IGoalsOnboarding = {
        end: updated,
        beginning: payloadContext.goals?.beginning,
      };
      setPayload((payload) => {
        const response = { ...payload, goals: data };
        localStorage.setItem(Constants.LOCALSTORAGE_PAYLOAD, JSON.stringify(response));
        return response;
      });
      let payload = {
        users: exportProfilesOnFormatOfAPI(payloadContext),
        farms: exportFarmsOnFormatOfAPI(payloadContext),
        areas: exportAreasOnFormatOfAPI(payloadContext),
        micro_areas: exportMicroAreasOnFormatOfAPI(payloadContext),
        evolutions: exportEvolutionsOnFormatOfAPI(payloadContext),
        batches: exportBatchesOnFormatOfAPI(payloadContext),
        goals: exportGoalsOnFormatOfAPI(payloadContext),
      } as any;
      const assortment = exportAssortmentsOnFormatOfAPI(payloadContext);
  
      if (!!assortment) {
        payload = { ...payload, assortment };
      }

      await axios
        .post("/onboarding", payload)
        .then(async () => {
          navigate(Constants.PADDOCKS_MAP);
          return true;
        })
        .catch((error) => {
          console.log(error.response)
          if (error.response.data.errors_fields[0] && error.response.data.errors_fields[0].field == "farm_initials_key") {
            setError('Sigla na tela de fazenda já cadastrada!')
          } else {
            setError(error.response.data.msg)
          }
          setAlertVisible(true);
          return false;
        })
        .finally(()=>{
          setIsLoading(false);
        });
    }
    return false;
  }

  async function handleBack() {
    const formData = formRef.current?.getData();
    const deathGoals = formData.goal;
    //@ts-ignore
    const updated = deathGoals.reduce((total, next) => {
      let goals = [...total];
      //@ts-ignore
      next.quantities.forEach((goal) => goal.quantity > 0 && goals.push(goal));
      return goals;
    }, []);
    const data: IGoalsOnboarding = {
      end: updated,
      beginning: payloadContext.goals?.beginning,
    };
    setPayload((payload) => {
      const response = { ...payload, goals: data };
      localStorage.setItem(Constants.LOCALSTORAGE_PAYLOAD, JSON.stringify(response));
      return response;
    });
    return true
  }

  useEffect(() => {
    if (!onlyRead) setBlockNextStep(false);
    if (formRef && formRef.current) {
      const { deathGoalList } = formRef.current?.getData();
      setDeathGoalList(() => deathGoalList || [deathGoalBase]);
    }
    scrollTo("#pageStart");
    if (payloadContext && payloadContext.goals && payloadContext.goals.end) {
      setDeathGoalList(() => payloadContext.goals.end);
    }
    loadGoalsList();
  }, []);

  const loadGoalsList = () => {
    axios.get("/goals").then(({ data }) => {
      if (data?.end) {
        setParamGoalList(data.end);
      }
    });
  };

  useEffect(() => {
    if (onlyRead) {
      var values = paramGoalList.map(goal => {
        return goal.months.map(month => {
          var goalMonth = {
            category_id: goal.category.id,
            month: month.month,
            quantity: month.quantity
          } as IGoalMonthQuantity
          return goalMonth
        })

      })
      setDeathGoalList(values.flat())
    }
  }, [paramGoalList])

  const contentStyle = {
    width: '440px',
    height: '218px',
    background: '#FFFFFF',
    borderRadius: '20px',
    padding: '10px'
  } as CSSProperties;

  const [alertVisible, setAlertVisible] = useState<boolean>(false);

  return (
    <>
      <Loading visible={isLoading} />
      <AlertModal visible={alertVisible} contentStyle={contentStyle}>
        <SimpleButtonClose onClick={() => setAlertVisible(false)} />
        <div className="createuser__alertModalBody">
          <TitleModal>{error}</TitleModal>
        </div>
        <ButtonRedirect onClick={() => setAlertVisible(false)}>VOLTAR</ButtonRedirect>
      </AlertModal>
      <main ref={mainRef as any}>
        <section className="createDeathGoal__container">
          <h1 id="pageStart">Meta de Morte</h1>
          <Form ref={formRef as any} onSubmit={() => { }}>
            <CreateDeathGoal
              type={Constants.CATEGORY_TYPE.femea}
              formRef={formRef}
              initData={deathGoalList}
              list={paramGoalList.filter(
                (goal) => goal.category.type == Constants.CATEGORY_TYPE.femea
              )}
              onAnywhereChanges={handleChangesDeathGoal}
              inputError={(err) => setErrors(() => err)}
              onlyRead={onlyRead}
            />
            <CreateDeathGoal
              type={Constants.CATEGORY_TYPE.macho}
              startIndex={
                paramGoalList.filter((goal) => goal.category.type == Constants.CATEGORY_TYPE.femea)
                  .length
              }
              formRef={formRef}
              initData={deathGoalList}
              list={paramGoalList.filter(
                (goal) => goal.category.type == Constants.CATEGORY_TYPE.macho
              )}
              onAnywhereChanges={handleChangesDeathGoal}
              inputError={(err) => setErrors(() => err)}
              onlyRead={onlyRead}
            />
            <span className="modalEdit__message--error">{errors}</span>
          </Form>
        </section>
      </main>
      {!onlyRead &&
        <footer>
          <FooterControlls
            nextStepEvent={async () => {
              return await handleForm();
            }}
            backStepEvent={async () => {
              return await handleBack();
            }}
          />
        </footer>
      }
    </>
  );
}
