import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "./styles.css";

import AnimationHeight from "react-animate-height";

import { ReactComponent as ChevronIcon } from "../../../assets/svg/icons/chevronDown.svg";

import { IOptionProps, IOptionsProps } from "../../../@types/reactSelect/IOption";
import { DropDownSelectProps } from "../../../@types/app/DropDownFilters/IDropDownSelect";

export type DropDownFilterRefProps = {
  resetDropDown: () => void;
};

export const DropDownSelect = forwardRef((props: DropDownSelectProps, ref) => {
  const {
    clearableLabel = "Sem filtros",
    options: componentOptions,
    clearable = false,
    defaultValue,
    placeholder,
    onChange,
    label,
  } = props;

  useImperativeHandle(ref, () => ({
    resetDropDown() {
      if (defaultValue && typeof defaultValue !== "string") setCurrentValue(defaultValue);
      else setCurrentValue({ label: clearableLabel, value: undefined });
    },
  }));

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<IOptionProps>({ label: "", value: "" });
  const [options, setOptions] = useState<IOptionsProps>([]);

  const toggleMenu = () => {
    if (componentOptions.length > 0) setIsOpen((open) => !open);
  };

  function handleValueSelected(id: string) {
    const filtered = options.find((option) => option.value === id);
    if (!!filtered) setCurrentValue(filtered);
    onChange && onChange(id);
  }

  function onBlurDropDown() {
    setTimeout(() => {
      setIsOpen(false);
    }, 200);
  }

  useEffect(() => {
    if (defaultValue && typeof defaultValue !== "string") setCurrentValue(defaultValue);
    else setCurrentValue({ label: clearableLabel, value: undefined });
  }, [defaultValue]);

  useEffect(() => {
    if (clearable) setOptions(() => [{ label: clearableLabel, value: undefined }, ...componentOptions]);
    else setOptions(() => componentOptions);
  }, [clearable, componentOptions]);

  return (
    <section className="dropdownfilter" onBlur={onBlurDropDown}>
      <button onClick={toggleMenu} className={isOpen ? "menuIsOpen" : ""}>
        {currentValue.value && label && <small>{label}</small>}
        {!currentValue.value && placeholder && <small>{placeholder}</small>}
        <h3>{currentValue.value ? currentValue.label : ""}</h3>
        <div className="icon">
          <ChevronIcon className={`rotate__${isOpen ? 0 : 180}deg`} />
        </div>
      </button>
      <AnimationHeight height={isOpen ? "auto" : 0}>
        <div className="dropdownfilter__menu--content">
          <ul className="dropdownfilter__menu--container">
            {options.map((option, index) => (
              <li key={index} className={currentValue?.value === option?.value ? "isSeleted" : ""}>
                <button onClick={() => handleValueSelected(option?.value)}>{option?.label}</button>
              </li>
            ))}
          </ul>
        </div>
      </AnimationHeight>
    </section>
  );
});
