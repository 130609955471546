import {
  ChangeEventHandler,
  FocusEventHandler,
  InputHTMLAttributes,
} from 'react'
import { TextInput } from '../TextInput/Index'
import './styles.css'

type ITextInputwithUnit = InputHTMLAttributes<HTMLInputElement> & {
  unit: string
  name: string
  onChange: ChangeEventHandler<HTMLInputElement>
  returnAsNumber?: boolean
  updateValue?: string | number
  returnUnmasked?: boolean
  mask?: string
  handleAnywhereChanges?: (data?: HTMLInputElement) => void
  onBlur?: FocusEventHandler<HTMLInputElement>
  id: any
}

export function TextInputWithUnit({
  unit,
  name,
  onChange,
  returnAsNumber,
  updateValue,
  returnUnmasked,
  mask,
  handleAnywhereChanges,
  onBlur,
  id,
  ...rest
}: ITextInputwithUnit) {
  return (
    <>
      <div className="TextInputWithUnit__container">
        <div className="TextInputWithUnit__unit">{unit}</div>
        <TextInput
          name={name}
          onChange={onChange}
          mask={mask}
          returnAsNumber
          handleAnywhereChanges={handleAnywhereChanges}
          onBlur={onBlur}
          id={id}
          {...rest}
        />
      </div>
    </>
  )
}
