import { MutableRefObject, useEffect, useState } from "react";
import { ReactComponent as EyeOpen } from "../../../../../assets/svg/icons/eyeClosed.svg";
import { ReactComponent as EyeClosed } from "../../../../../assets/svg/icons/eye.svg";
import AnimateHeight from "react-animate-height";
import { MicroArea } from "./MicroArea";
import { Scope } from "@unform/core";
import { ButtonOnlyTitle } from "../../../../Buttons/ButtonOnlyTitle/Index";
import { useOnboarding } from "../../../../../hooks/useOnboarding";
import { IMicroAreas } from "../../../../../@types/app/IMicroArea";
import { generateuuid } from "../../../../../utils/uuid/generate";
import Constants from "../../../../../constants/Index";
import { EditValues } from "../../../../../hooks/EditValues";
import * as yup from "yup";
import { TextInput } from "../../../../Inputs/TextInputs/TextInput/Index";

type AreaProps = {
  handleChangesMicroArea: () => void;
  savePayload: () => void;
  formRef: MutableRefObject<HTMLFormElement | undefined>;
  farm: any;
  area: any;
  keyFarm: number;
  keyArea: number;
  initValues: any;
  onlyRead?: boolean;
  errorMap: Map<string, string>;
  onBoarding: boolean;
  errorInfoAcreage: string;
};

const microForm = yup.object().shape({
  microareas: yup
    .array()
    .of(
      yup.object().shape({
        area_id: yup.string(),
        initials: yup
          .string()
          .matches(/^[^\s]+(\s+[^\s]+)*$/, "Esta sigla não é válida.")
          .required("SIGLA é necessário."),
        acreage: yup
          .string()
          .min(1, "Quantidade de hectares informado inválido!")
          .max(999, "Quantidade de hectares informado inválido!")
          .required("Campo obrigatório não informado."),
        map_coords: yup
          .object()
          .nullable()
          .required(Constants.MESSAGE_MAPPING_MICROAREA_NOT_FOUND)
          .shape({
            coords: yup
              .array()
              .min(1, Constants.MESSAGE_MAPPING_MICROAREA_NOT_FOUND)
              .required(Constants.MESSAGE_MAPPING_MICROAREA_NOT_FOUND),
          }),
      })
    )
})

export function Area({
  farm,
  area,
  keyFarm,
  keyArea,
  formRef,
  handleChangesMicroArea,
  initValues,
  onlyRead = false,
  savePayload,
  errorMap,
  errorInfoAcreage,
  onBoarding = false
}: AreaProps) {
  const [visibleFarm, setVisibleFarm] = useState<"auto" | 0>("auto");
  const { setBlockNextStep, setPayload, payload } = useOnboarding();
  const [numMicroAreas, setNumMicroAreas] = useState<number>(0);
  const { setBlockSave } = EditValues()
  const [errorMapMicroArea, setErrorMapMicroArea] = useState<Map<string, string>>(new Map());
  const [errorInfo, setErrorInfo] = useState("");
  const areaAcreage = Number(area.acreage)
  const [acreageAvailable, setAcreageAvailable] = useState<number>(areaAcreage);

  const baseMicroArea = {
    uuid: "",
    area_id: "",
    initials: "",
    acreage: 0,
    map_coords: {
      uuid: "",
      coords: [],
    },
  };

  const [microAreas, setMicroAreas] = useState<IMicroAreas>([baseMicroArea]);

  const handleVisibleFarm = async () => {
    setVisibleFarm((prev) => (prev === 0 ? "auto" : 0));
  };

  useEffect(() => {
    if (microAreas.length == 0) {
      setMicroAreas([baseMicroArea]);
    }
    onChange();
  }, [microAreas]);

  useEffect(() => {
    if (!onlyRead && payload && payload.micro_areas) {
      var listMicroAreas = payload.micro_areas;
      setMicroAreas(
        listMicroAreas.filter(
          (microArea) => microArea.area_id == area.uuid
        ) as any
      );
    } else if (onlyRead && initValues) {
      const listMicroAreas = initValues.filter(
        (obj: any) => obj.farm.id == farm.id && obj.area.id == area.id
      );
      setMicroAreas(listMicroAreas);
      setNumMicroAreas(listMicroAreas.length)
    }
  }, [initValues, payload]);

  async function validateMicroAreas() {
    const formData = formRef.current?.getData().farms[keyFarm].areas[keyArea];
    const errors = {} as any;
    let isValide = false;
    var regexNumber = /\d+/g;
    var listMap = new Map();

    if (formData) {
      try {
        await microForm.validate(formData, { abortEarly: false });
        isValide = true;
      } catch (err) {
        setBlockNextStep(true);
        if (err instanceof yup.ValidationError) {
          err.inner.forEach((e) => {
            errors[`farms[${keyFarm}].areas[${keyArea}].${e.path as string}`] = e.message;
            if ((e.path as string).includes("map_coords.coords")) {
              listMap.set(`farms[${keyFarm}].areas[${keyArea}].${e.path as string}`, e.message);
            }
            var microAreaError = Number((e.path as string).match(regexNumber))+1
            setErrorInfo(Constants.MESSAGE_MICROAREA_NOT_FILLED + microAreaError)
          });
          setErrorMapMicroArea(listMap)
        }

      } finally {
        console.log(errors)
        if (formRef && formRef.current) {
          formRef.current.setErrors(errors);
        }
        return isValide;
      }
    }
  }

  async function addNewMicroArea() {
    const isValid = await validateMicroAreas()
    if(isValid){
      if (!onlyRead) {
        setBlockNextStep(true);
        setMicroAreas((values) => {
          return [...values, baseMicroArea];
        });
      } else {
        setBlockSave(true)
        setMicroAreas((values) => {
          return [...values, baseMicroArea];
        })
      }}
  }

  async function removeMicroArea(uuid: number) {
    setErrorInfo("")
    if(!onlyRead){
      await savePayload();
      const formsmicroareas =
        formRef.current?.getData().farms[keyFarm].areas[keyArea];
      const microareas = microAreas
        .map((microarea, index) => {
          return {
            ...formsmicroareas.microareas[index],
            map_coords: formsmicroareas.microareas[index].map_coords,
            initials: formsmicroareas.microareas[index].initials.replace(
              farm.initials + "-" + area.initials + "-",
              ""
            ),
            area_id: area.uuid,
            uuid: microarea.uuid ? microarea.uuid : generateuuid(),
          };
        })
        .filter((_: any, index: number) => index !== uuid);
  
      setPayload((payload) => {
        const response = {
          ...payload,
          micro_areas: payload.micro_areas
            ? payload.micro_areas
                .filter((micro) => micro.area_id != area.uuid)
                .concat(microareas)
            : microareas,
        };
        localStorage.setItem(
          Constants.LOCALSTORAGE_PAYLOAD,
          JSON.stringify(response)
        );
        return response;
      });
    } else {
      const formsmicroareas = formRef.current?.getData().farms[keyFarm].areas[keyArea];
      const microareas = microAreas
      .map((_, index) => {
        return {
          ...formsmicroareas.microareas[index],
          map_coords: formsmicroareas.microareas[index].map_coords,
          initials: formsmicroareas.microareas[index].initials.replace(
            farm.initials + "-" + area.initials + "-",
            ""
          ),
          area_id: formsmicroareas.microareas[index].area_id,
          uuid: formsmicroareas.microareas[index].uuid ? formsmicroareas.microareas[index].uuid : generateuuid(),
        };
      })
      .filter((_: any, index: number) => index !== uuid);
      setMicroAreas(microareas)
    }
    handleChangesMicroArea()
  }

  useEffect(() => {
    setTimeout(()=>{
      setErrorInfo("");
    }, 50)
  }, [handleChangesMicroArea]);

  function onChange() {
    const usedAcreage = formRef.current?.getData().farms[keyFarm].areas[keyArea]
    .microareas?.map((micro:any)=>Number(micro.acreage.replace(",", "."))).reduce((accumulator: number, current: number) => {
      return accumulator + current;
    }, 0);
    handleChangesMicroArea()
    setAcreageAvailable(areaAcreage - usedAcreage);
  }

  return (
    <div style={{ marginLeft: 20 }}>
      <div className="microarea__title--container">
        <h3>{area.name}</h3>
        <button type="button" onClick={handleVisibleFarm}>
          {visibleFarm ? <EyeOpen /> : <EyeClosed />}
        </button>
      </div>
      <TextInput
        hidden={true}
        value={acreageAvailable}
        name="areaAvailable"
        returnAsNumber
      />
      <AnimateHeight height={visibleFarm}>
        <>
          {microAreas.map((micro, index) => (
            <Scope key={index} path={`microareas[${index}]`}>
              <MicroArea
                formRef={formRef}
                farm={farm}
                area={area}
                micro={micro}
                keyFarm={keyFarm}
                keyArea={keyArea}
                keyMicroArea={index}
                onAnywhereChanges={()=>{handleChangesMicroArea(), onChange()}}
                onRemoveMicroArea={removeMicroArea}
                isDeletable={onlyRead || microAreas.length > 1 || index > 0}
                onlyRead={onlyRead && !(index >= numMicroAreas)}
                initValues={initValues}
                errorMap={errorMap && errorMapMicroArea}
                acreageAvailable={Number(acreageAvailable.toFixed(2))}
              />
            </Scope>
          ))}
          {!onBoarding && 
            <div className="container___buttonadd">
              <ButtonOnlyTitle
                title="+ adicionar Pasto"
                theme="info"
                onClick={addNewMicroArea}
              />
            </div>
          }
        </>
      </AnimateHeight>

      <small className="textInput--small-error animation__fadeIn">
          {errorInfo==""&&acreageAvailable<0?errorInfoAcreage:errorInfo}
      </small>
    </div>
  );
}
