import "./styles.css"
import axios from '../../../../services/axios'
import { useEffect, useState } from "react";
import { Card } from "../../../Card/Index";
import { useEditOnboarding } from "../../../../hooks/useEditOnboarding";
import { useTaskCreation } from "../../../../hooks/useTaskCreation";

export function ListEvaluators() {
    const [listEvaluators, setListEvaluators] = useState<any[]>([]);
    const [areaMap, setAreaMap] = useState(new Map<number, any>());

    const {
        setIdSelected,
        setDefaultValue
    } = useEditOnboarding();
    const { modalCreateEvaluator, setModalCreateEvaluator, allAreas } = useTaskCreation();

    useEffect(() => {
        axios.get("/users/user/evaluator")
            .then(({ data }) => {
                axios.get("/areas")
                    .then(({ data: areas }) => {
                        areas.map((area: any) => {
                            setAreaMap(new Map(areaMap.set(area?.id, area)));
                        })
                        setListEvaluators(data.map((el: any) => ({
                            ...el,
                            area: areaMap.get(el.area_ids[0])
                        })))
                    })
                    .catch(err => console.error(err))
            })
            .catch(err => console.error(err))
        if (!modalCreateEvaluator) {
            setDefaultValue(undefined)
            setIdSelected(0)
        }
    }, [, modalCreateEvaluator]);

    return (<>
        <div className="grid__evaluators">
            {listEvaluators.filter(el => el.status == "ACTIVE").map((card, index) => (
                <Card
                    type={"micro-areas"}
                    initials={`${areaMap.get(card?.area?.id)?.farm?.initials}-${areaMap.get(card?.area?.id)?.initials}`}
                    name={areaMap.get(card?.area?.id)?.manager_user?.name}
                    profile={"Capataz"}
                    onClick={() => {
                        setModalCreateEvaluator(true)
                        setIdSelected(card.id)
                        setDefaultValue(card)
                    }}
                    key={index}
                />
            ))}
        </div>
    </>)
}