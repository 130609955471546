import { CSSProperties, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import './styles.css'

import { DrawOnMap } from '../../../../components/EditOnboarding/components/DrawOnMap/DrawOnMap'
import { SimpleButtonClose } from '../../../../components/Modal/Contents/SimpleButtonClose/Index'
import { ButtonRedirect } from '../../../../components/Modal/Error/ButtonRedirect/Index'
import { TitleModal } from '../../../../components/Modal/Contents/TitleModal/Index'
import { FooterEditOnboarding } from '../../../../components/EditOnboarding/Footer'
import { Dropdown } from '../../../../components/EditOnboarding/components/Select'
import { TextModal } from '../../../../components/Modal/Contents/TextModal/Index'
import { Input } from '../../../../components/EditOnboarding/components/Input'
import { AlertModal } from '../../../../components/Modal/Alert/Index'
import { Chips } from '../../../../components/Chips'

import { microAreaService } from '../../../../services/microAreaService'
import {
  IOptionProps,
  IOptionsProps,
} from '../../../../@types/reactSelect/IOption'
import { areaService } from '../../../../services/areaService'
import { userService } from '../../../../services/userService'
import { ICoords } from '../../../../@types/GoogleMaps/ICoord'
import { sortBy } from '../../../../utils/arrays/sortBy'
import { yupResolver } from '@hookform/resolvers/yup'
import Constants from '../../../../constants/Index'
import { farmService } from '../../../../services/farmService'
import { moveCharLeft } from '../../../../utils/arrays/moveChar'

const schema = yup
  .object({
    name: yup.string().required('Campo obrigatório não informado.'),
    initials: yup.string().required('Campo obrigatório não informado.'),
    acreage: yup.string().required('Campo obrigatório não informado.'),
    grazing_time: yup.object({
      indoor: yup
        .object({
          label: yup.string(),
          value: yup.string(),
        })
        .test(
          'is-null',
          'Campo obrigatório não informado.',
          ({ value }) => !!value,
        ),
      outdoor: yup
        .object({
          label: yup.string(),
          value: yup.string(),
        })
        .test(
          'is-null',
          'Campo obrigatório não informado.',
          ({ value }) => !!value,
        ),
    }),
    owner_user_id: yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .test(
        'is-null',
        'Campo obrigatório não informado.',
        ({ value }) => !!value,
      ),
    farm: yup
      .object({
        label: yup.string(),
        value: yup.string(),
      })
      .test(
        'is-null',
        'Campo obrigatório não informado.',
        ({ value }) => !!value,
      ),
    map: yup
      .array()
      .of(
        yup.object({
          lat: yup.number(),
          lng: yup.number(),
        }),
      )
      .test('is-null', 'Campo obrigatório não informado.', (value) =>
        value ? value.length > 0 : true,
      ),
  })
  .required()

interface IFormAreaData {
  name: string
  initials: string
  acreage: string
  grazing_time: {
    indoor: IOptionProps
    outdoor: IOptionProps
  }
  owner_user_id: IOptionProps
  map: ICoords
  farm: IOptionProps
}

const periods = [
  { label: '15 Dias', value: 15 },
  { label: '30 Dias', value: 30 },
  { label: '45 Dias', value: 45 },
  { label: '60 Dias', value: 60 },
  { label: '90 Dias', value: 90 },
]

interface EditAreasProps {
  editAreaFromId?: number
  setLoadingState?: React.Dispatch<React.SetStateAction<boolean>>
  onSuccessEditingSave?: () => void
  onSuccessCreate?: () => void
  onCancelButton?: () => void
  onDeleteEvent?: () => void
}

const contentStyleAlert = {
  width: '335px',
  height: '237px',
  borderRadius: '20px',
  padding: '20px',
} as CSSProperties

export function EditAreas({
  editAreaFromId,
  setLoadingState,
  onSuccessEditingSave,
  onSuccessCreate,
  onCancelButton,
  onDeleteEvent,
}: EditAreasProps) {
  const [microAreasExisting, setMicroAreasExisting] = useState<
    { id: number; name: string }[]
  >([])
  const [areaInitialData, setAreaInitialData] = useState<IFormAreaData>(
    {} as IFormAreaData,
  )
  const [acreageDynamicInputWidth, setAcreageDynamicInputWidth] =
    useState<number>(1)
  const [fitCoords, setFitCoords] = useState<ICoords | undefined>(undefined)
  const [farmInitials, setFarmInitials] = useState<string | null>(null)
  const [defautAreaCoords, setDefautAreaCoords] = useState<ICoords>([])
  const [acreageMask, setAcreageMask] = useState<string | null>(null)
  const [staticpolygons, setStaticpolygons] = useState<ICoords[]>([])
  const [managersList, setManagersList] = useState<IOptionsProps>([])
  const [prefixInitials, setPrefixInitials] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [initialsStr, setInitialsStr] = useState<string>('')
  const [msgError, setMsgError] = useState<string>('')
  const [farms, setFarms] = useState<IFarm[]>([])
  const [acreageFormat, setAcreageFormat] = useState<boolean>(false)

  const exportICreateBody = (data: IFormAreaData): ICreateArea[] => {
    let acreage = acreageInput?.replace(',', '.')
    let currentAcreage = moveCharLeft(acreage, acreage.charAt(2))

    return [
      {
        acreage: currentAcreage,
        farm_id: data.farm.value,
        indoor_grazing_time: parseInt(data.grazing_time.indoor.value),
        outdoor_grazing_time: parseInt(data.grazing_time.outdoor.value),
        initials: data.initials,
        map_coords: data.map,
        name: data.name,
        owner_user_id: data.owner_user_id.value,
      },
    ]
  }

  const exportIEditBody = (data: IFormAreaData): IEditArea[] => {
    let acreage = acreageInput?.replace(',', '.')
    let currentAcreage = moveCharLeft(acreage, acreage.charAt(2))

    return [
      {
        outdoor_grazing_time: parseInt(data.grazing_time.outdoor.value),
        indoor_grazing_time: parseInt(data.grazing_time.indoor.value),
        acreage: currentAcreage,
        owner_user_id: parseInt(data.owner_user_id.value),
        area_id: editAreaFromId || -1,
        name: data.name,
      },
    ]
  }

  const handleinitForm = async () => {
    if (editAreaFromId) {
      const microAreasData = await microAreaService.getMicroAreasByAreaId(
        editAreaFromId,
      )
      const microExistenting: { id: number; name: string }[] = [],
        microCoordsArray: ICoords[] = []
      microAreasData.map(({ id, farm, area, initials, map_coords }) => {
        microExistenting.push({
          id,
          name: `${farm.initials}-${area.initials}-${initials}`,
        })
        microCoordsArray.push(map_coords as ICoords)
      })
      setMicroAreasExisting(microExistenting.sort(sortBy('name')))
      setStaticpolygons(microCoordsArray)
      const response = await areaService.getAreabyId<IFormAreaData>(
        editAreaFromId,
        (data) => {
          setDefautAreaCoords(data.map_coords)
          setPrefixInitials(`${data.farm_inititals}-`)
          return {
            farm: {
              value: 1,
              label: `Fazenda Pastejo`,
            },
            name: data.name,
            initials: data.initials,
            acreage: data.acreage.toFixed(2),
            grazing_time: {
              indoor: {
                value: data.indoor_grazing_time,
                label: `${data.indoor_grazing_time} Dias`,
              },
              outdoor: {
                value: data.outdoor_grazing_time,
                label: `${data.outdoor_grazing_time} Dias`,
              },
            },
            map: data.map_coords,
            owner_user_id: {
              value: data.manager_user.id,
              label: data.manager_user.name,
            },
          }
        },
      )
      setAreaInitialData(response)
      return response
    }
    return {} as IFormAreaData
  }

  const { control, handleSubmit, register, watch, setValue } =
    useForm<IFormAreaData>({
      defaultValues: async () => await handleinitForm(),
      resolver: yupResolver(schema),
    })

  const InitialsInput = watch('initials')
  const acreageInput = watch('acreage')
  const nameInput = watch('name')
  const farmInput = watch('farm')

  const isValidString = (input: string) => {
    const pattern = /^[\d,\.]*$/
    return pattern.test(input)
  }

  const onSubmit = (data: any) => {
    if (
      (data?.acreage && data.acreage.length > 11) ||
      (data?.acreage && !isValidString(data.acreage))
    ) {
      setMsgError('Valor inválido de área útil')
    } else {
      if (!!editAreaFromId) {
        //Se chegamos aqui estamos editando um retiro
        areaService
          .editArea(exportIEditBody(data))
          .then(() => onSuccessEditingSave && onSuccessEditingSave())
          .catch((error) => setMsgError(error.response.data.msg))
          .finally(() => setLoadingState && setLoadingState(() => false))
      } else {
        //Se chegamos aqui estamos criando um retiro
        areaService
          .createNewArea(exportICreateBody(data))
          .then(() => onSuccessCreate && onSuccessCreate())
          .catch((error) => setMsgError(error.response.data.msg))
          .finally(() => setLoadingState && setLoadingState(() => false))
      }
    }
  }

  const deleteArea = () => {
    if (editAreaFromId) {
      areaService
        .deleteArea(editAreaFromId)
        .then(() => onDeleteEvent && onDeleteEvent())
        .catch((error) => setMsgError(error.response.data.msg))
    }
  }

  const toggleIsReadyOnly = () => {
    if (!editAreaFromId) {
      setValue('initials', '')
      setIsDisabled((readonly) => !readonly)
    }
  }

  function handleMaskAcreage(text: string) {
    switch (text.replace('.', '').length) {
      case 3:
        setAcreageMask('99.99999')
        break
      case 4:
        setAcreageMask('999.9999')
        break
      case 5:
        setAcreageMask('9999.9999')
        break
      case 6:
        setAcreageMask('99999.9999')
        break
      case 7:
        setAcreageMask('999999.99999')
        break
      case 8:
        setAcreageMask('9999999.99')
        break
      default:
        setAcreageMask('9999999.99')
        break
    }
  }
  const handleMaskInput = (acreage: string) => {
    if (!acreageFormat) {
      const [integerL = '', decimalL = ''] = acreage
        .toString()
        .replace('.', ',')
        .split(',')
      let final = integerL.length
      if (decimalL.length == 3) final++
      else if (decimalL.length === 0) final--
      if (final <= 10) {
        const intMask = Array.from(Array(final || 1).keys()).reduce(
          (acc, _) => acc + '9',
          '',
        )
        const decimalMask = Array.from(
          Array(final <= 3 ? decimalL.length + 1 : 2).keys(),
        ).reduce((acc, _) => acc + '9', '')
        const finalMask = `${intMask},${decimalMask}`
        setAcreageDynamicInputWidth(finalMask.length)
        setAcreageMask(finalMask)
        setAcreageFormat(true)
      }
    } else {
      handleMaskAcreage(acreage)
    }
  }

  //   function handleMaskInput(text: string) {
  //     switch (text.replace('.', '').length) {
  //       case 3:
  //         setAcreageMask('99.99999')
  //         break
  //       case 4:
  //         setAcreageMask('999.9999')
  //         break
  //       case 5:
  //         setAcreageMask('9999.9999')
  //         break
  //       case 6:
  //         setAcreageMask('99999.9999')
  //         break
  //       case 7:
  //         setAcreageMask('999999.99999')
  //         break
  //       case 8:
  //         setAcreageMask('9999999.99')
  //         break
  //       default:
  //         setAcreageMask('9999999.99')
  //         break
  //     }
  //     if (acreageMask) {
  //       setAcreageDynamicInputWidth(acreageMask.length)
  //     }
  //   }

  useEffect(() => {
    if (editAreaFromId) setValue('initials', areaInitialData.initials || '')
    else {
      if (!!nameInput && isDisabled) {
        if (
          editAreaFromId &&
          nameInput.toLocaleLowerCase() ===
            areaInitialData.name.toLocaleLowerCase()
        )
          setValue('initials', areaInitialData.initials || '')
        else {
          const arrayWords = nameInput.split(' ')
          if (arrayWords.length > 1) {
            const firstSentence = arrayWords[0].charAt(0)
            let secondSentence = ''
            if (arrayWords[1].length > 1)
              secondSentence = arrayWords[1].slice(0, 2)
            else
              secondSentence = `${arrayWords[1].charAt(
                0,
              )}${arrayWords[0].charAt(1)}`
            setValue(
              'initials',
              `${firstSentence}${secondSentence}`.toLocaleUpperCase(),
            )
          } else
            setValue(
              'initials',
              `${arrayWords[0]}`.slice(0, 3).padEnd(3, '').toLocaleUpperCase(),
            )
        }
      } else if (!nameInput && isDisabled) setValue('initials', '')
    }
  }, [nameInput, isDisabled])

  useEffect(() => {
    setInitialsStr(InitialsInput)
  }, [InitialsInput])

  // useEffect(() => {
  //   if (!!acreageInput) handleMaskInput(acreageInput)
  // }, [acreageInput])

  useEffect(() => {
    if (!farmInput) {
      setFitCoords(() => undefined)
      setFarmInitials(null)
      setStaticpolygons([])
    } else {
      const farm = farms.find((f) => f.id === parseInt(farmInput.value))
      if (farm) {
        setFitCoords(() => (!!farm ? farm.map_coords : undefined))
        setFarmInitials(farm?.initials || null)
        areaService.getAreabyFarmId(farmInput.value).then((data) => {
          setStaticpolygons(data.map((area) => area.map_coords))
        })
      }
    }
  }, [farmInput])

  useEffect(() => {
    userService
      .getUsersByOffice(Constants.PROFILE_CODE_CAPATAZ.toString())
      .then((data) => {
        setManagersList(() =>
          data.map((user) => ({ label: user.name, value: user.id })),
        )
      })
    if (!editAreaFromId) {
      //setAcreageMask('9,999')
      farmService.getFarmsByUser().then((data) => setFarms(data))
    }
  }, [])

  return (
    <>
      <AlertModal visible={!!msgError} contentStyle={contentStyleAlert}>
        <section className="EditAreas_modalalert-container">
          <SimpleButtonClose onClick={() => setMsgError('')} />
          <main className="EditAreas_modalalert-content">
            <TitleModal>Algo deu errado.</TitleModal>
            <TextModal>{msgError}</TextModal>
          </main>
          <ButtonRedirect onClick={() => setMsgError('')}>
            Voltar
          </ButtonRedirect>
        </section>
      </AlertModal>
      <form
        className="EditAreas_section-container"
        onSubmit={handleSubmit(onSubmit)}
      >
        <section className="EditAreas_container--form">
          <div className="EditAreas_container--form-left">
            {!editAreaFromId && (
              <>
                <Dropdown
                  label="Fazenda Associada"
                  control={control}
                  options={farms.map((farm) => ({
                    value: farm.id,
                    label: farm.name,
                  }))}
                  closeMenuOnSelect={true}
                  placeholderText={''}
                  {...register('farm')}
                />
              </>
            )}

            <Input
              label="Nome do Retiro"
              control={control}
              theme="normal"
              {...register('name')}
            />

            <Input
              label="Sigla"
              labelPrefix={farmInitials ? `${farmInitials}-` : prefixInitials}
              theme={isDisabled ? 'read-only' : 'normal'}
              disabled={isDisabled}
              control={control}
              mask="***"
              overrideStyles={{
                prefixLabel: {
                  fontFamily: 'Fira Code',
                },
                input: {
                  fontFamily: 'Fira Code',
                  textTransform: 'uppercase',
                },
              }}
              {...register('initials')}
            />

            <button
              type="button"
              className="EditAreas__button--alter-initials"
              disabled={!!editAreaFromId}
              onClick={toggleIsReadyOnly}
            >
              {isDisabled ? 'Alterar sigla' : 'Usar sigla automática'}
            </button>

            <Input
              label="Quantos hectares de área útil?"
              labelPrefix="ha"
              prefixPosition="right"
              control={control}
              type={'text'}
              theme="normal"
              overrideStyles={{
                prefixLabel: {
                  fontFamily: 'Fira Code',
                  color: '#999999',
                  marginLeft: '5px',
                },
                input: {
                  fontFamily: 'Fira Code',
                  textTransform: 'uppercase',
                  width: `${1000}ch`,
                },
              }}
              {...register('acreage')}
            />

            <Dropdown
              label="Tempo Máximo COM gado"
              closeMenuOnSelect={true}
              placeholderText={''}
              control={control}
              options={periods}
              {...register('grazing_time.indoor')}
            />

            <Dropdown
              label="Tempo Máximo SEM gado"
              closeMenuOnSelect={true}
              placeholderText={''}
              control={control}
              options={periods}
              {...register('grazing_time.outdoor')}
            />

            <Dropdown
              label="Responsável do Retiro"
              closeMenuOnSelect={true}
              placeholderText={''}
              control={control}
              options={managersList}
              {...register('owner_user_id')}
            />
          </div>
          <div className="EditAreas_container--form-right">
            <DrawOnMap
              initialsLabel={
                !!farmInitials
                  ? farmInitials
                  : `${prefixInitials}-${initialsStr}`
              }
              style={{ width: '400px', height: '300px' }}
              staticsElements={staticpolygons}
              mainPolygon={defautAreaCoords}
              fitCoords={fitCoords}
              control={control}
              name="map"
              editable={!!fitCoords}
            />
            <ul>
              {microAreasExisting.map((area) => (
                <li key={area.id}>
                  <Chips.Pressable
                    variationStyles={{ container: { width: '100%' } }}
                    theme={'link-light'}
                    label={area.name}
                  />
                </li>
              ))}
            </ul>
          </div>
        </section>
        <FooterEditOnboarding
          undoFunction={() => onCancelButton && onCancelButton()}
          saveFunction={() => {}}
          deleteFunction={deleteArea}
          isNew={!editAreaFromId}
        />
      </form>
    </>
  )
}
