import { ICoord } from "../../../@types/GoogleMaps/ICoord";
import { CustomPopup } from "../../Leaflet/CustomPopup/Index";
import "./styles.css";

import { ReactComponent as PointIcon } from "../../../assets/svg/icons/point.svg";
import { ReactComponent as FarmIcon } from "../../../assets/svg/icons/farmIcon.svg";

type ILabelFarm1XProps = {
  position: ICoord;
  initials?: string;
};

export function LabelFarm1X({ position, initials }: ILabelFarm1XProps) {
  return (
    <CustomPopup position={position} offset={[-20, 50]}>
      <article className="labelfarm1x__container">
        <PointIcon />
        <div className="labelfarm1x__initials--container">
          <FarmIcon />
          {initials && <h6>{initials}</h6>}
        </div>
      </article>
    </CustomPopup>
  );
}
