import { CSSProperties } from "react";
import { DrawNewFences } from "../../EditOnboarding/components/DrawNewFencesOnMap";
import { Coords } from "leaflet";
import { useTaskCreation } from "../../../hooks/useTaskCreation";

const DrawMapStyleModal = {
  width: "100%",
  height: "100%",
  overflow: "hidden",
} as CSSProperties;

interface DrawMapElementProps {
  isMulti?: boolean,
  coords: any,
  editable?: boolean,
  setCoords: (newCoords: any) => void
}

export const DrawMapElement = ({ isMulti = false, coords, editable=false, setCoords }: DrawMapElementProps) => {
  const {
    setAllowTaskCreation,
    payload,
    setPayload,
    contextMicroArea,
    setShowDrawOnMapModal,
    allMicroAreas,
    filterMicroAreas
  } = useTaskCreation();

  function drawOnMap(newCoords: Coords) {
    const changes = [
      {
        ...payload.changes[0],
        new_micro_area_details: newCoords
      }
    ]

    const reqPayload = {
      ...payload,
      changes
    };
    setPayload(reqPayload);
  }

  const filterMicroArea = filterMicroAreas?.map(el=>el.id) 

  if (!allMicroAreas) return <></>;
  return (
    <div style={{ zIndex: 902 }}>
      <DrawNewFences
        editable={editable}
        initialsLabel={`${contextMicroArea?.farm?.initials}-${contextMicroArea?.area?.initials}`}
        onChange={(ev: any) => {
          if(editable) {
            drawOnMap(ev)
            setCoords(ev)
          }
        }}
        onClose={() => {
          setShowDrawOnMapModal(false)
        }}
        onlyModal={true}
        microAreas={
            allMicroAreas
              .filter(microArea => contextMicroArea?.area?.id == microArea.area_id)
              .filter(microArea => !editable || contextMicroArea?.id != microArea.id)
              .filter(microArea => !filterMicroArea?.includes(microArea.id))
        }
        mainPolygon={contextMicroArea?.map_coords || []}
        value={coords}
        externalCoords={contextMicroArea?.area?.map_coords}
        fitCoords={contextMicroArea?.area?.map_coords}
        overrideStyleModal={DrawMapStyleModal}
        microArea={contextMicroArea}
        isMulti={isMulti}
      />
    </div>
  )
}