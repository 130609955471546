import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "./style.css";

import AnimationHeight from "react-animate-height";

import { ReactComponent as ChevronIcon } from "../../assets/svg/icons/chevronDown.svg";

import { IOptionsProps } from "../../@types/reactSelect/IOption";
import { DropDownSelectProps } from "../../@types/app/DropDownFilters/IDropDownSelect";
import { capitalize } from "../../utils/strings/capitalize";

export type MultipleSelectRefProps = {
  resetDropDown: () => void;
};

interface MultipleSelectProps
  extends Omit<DropDownSelectProps, "defaultValue" | "onChange"> {
  defaultValue?: IOptionsProps;
  onChange: (v: IOptionsProps) => void;
}

export const MultipleSelect = forwardRef((props: MultipleSelectProps, ref) => {
  const {
    clearableLabel = "Sem filtros",
    options: componentOptions,
    clearable = false,
    defaultValue,
    placeholder,
    onChange,
    label,
  } = props;

  useImperativeHandle(ref, () => ({
    resetDropDown() {
      if (defaultValue && typeof defaultValue !== "string")
        setCurrentValue(defaultValue);
    },
  }));

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<IOptionsProps>([]);
  const [options, setOptions] = useState<IOptionsProps>([]);

  const toggleMenu = () => {
    if (componentOptions.length > 0) setIsOpen((open) => !open);
  };

  function handleValueSelected(id: string) {
    let auxArray = undefined;
    if (currentValue.some((op) => op.value === id)) {
      auxArray = currentValue.filter((op) => op.value !== id);
      setCurrentValue(auxArray);
    } else {
      let option = options.find((option) => option.value === id);
      if (!!option) {
        auxArray = [...currentValue, option];
        setCurrentValue(auxArray);
      }
    }

    onChange && onChange(auxArray ?? []);
  }

  useEffect(() => {
    if (defaultValue && typeof defaultValue !== "string")
      setCurrentValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (clearable)
      setOptions(() => [
        { label: clearableLabel, value: undefined },
        ...componentOptions,
      ]);
    else setOptions(() => componentOptions);
  }, [clearable, componentOptions]);

  return (
    <section className="dropdownfilter multiple-select">
      <button onClick={toggleMenu} className={isOpen ? "menuIsOpen" : ""}>
        {currentValue.length === 0 && placeholder && (
          <small>{capitalize(placeholder)}</small>
        )}
        <h3>
          {currentValue
            .map((option, index) => {
              return index === 0
                ? capitalize(option.label)
                : " " + capitalize(option.label);
            })
            .toString()}
        </h3>
        <div className="icon">
          <ChevronIcon className={`rotate__${isOpen ? 0 : 180}deg`} />
        </div>
      </button>
      <AnimationHeight height={isOpen ? "auto" : 0}>
        <div className="dropdownfilter__menu--content">
          <ul className="dropdownfilter__menu--container">
            {options.map((option, index) => (
              <li
                key={option.value ?? index}
                className={
                  currentValue.some((op) => op.value === option.value)
                    ? "isSeleted"
                    : ""
                }
              >
                <button onClick={() => handleValueSelected(option.value)}>
                  <div className="checkbox-button"></div>
                  {capitalize(option.label)}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </AnimationHeight>
    </section>
  );
});
