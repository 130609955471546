import React from "react";
import { Modal } from "../../Default/Index";
import { RoundedCloseButton } from "../../../Buttons/RoundedCloseButton/Index";
import { ReactComponent as Check } from "../../../../assets/svg/icons/CheckFull.svg";
import "./style.css";
import { ButtonOnlyTitle } from "../../../Buttons/ButtonOnlyTitle/Index";

interface SuccessModalProps {
  visible: boolean;
  onClose: () => void;
  createNewTask: () => void;
  monitoringScreen: () => void;
}

export const SuccessModal: React.FC<SuccessModalProps> = ({
  visible,
  onClose,
  createNewTask,
  monitoringScreen
}) => {
  return (
    <div style={{ zIndex: 910 }}>
      <Modal
        visible={visible}
        contentStyle={{
          padding: "60px",
          width: "600px",
          height: "auto",
          borderRadius: 25,
          position: "relative",
        }}
      >
        <header className="success__modal-header">
          <RoundedCloseButton onClick={() => onClose()} />
        </header>
        <main className="success__modal-main">
          <Check width={40} height={40} />
          <div className="success__modal-message">
            <h2>Concluído!</h2>
            <span>Você criou essa tarefa com sucesso!</span>
          </div>
        </main>
        <footer className="success__modal-button-container">
          <ButtonOnlyTitle title="Criar mais uma tarefa" theme="light" onClick={() => createNewTask()}/>
          {/* <ButtonOnlyTitle title="Ir para tela de monitoramento" theme="info" onClick={() => monitoringScreen()}/> */}
        </footer>
      </Modal>
    </div>
  );
};
