import { ReactNode } from "react";
import "./styles.css";

type TextErrorProps = {
  children: ReactNode;
};

export function TextModal({ children }: TextErrorProps) {
  return (
    <div className="modalerror__text--container">
      <h6>{children}</h6>
    </div>
  );
}
