import React from "react";
import "./style.css";
import { ReactComponent as CloseIcon } from "../../../assets/svg/icons/close.svg";

interface RCloseButtonProps {
  onClick: () => void;
}

export const RoundedCloseButton: React.FC<RCloseButtonProps> = ({
  onClick,
}) => {
  return (
    <button className="close-button" onClick={onClick} type="button">
      <CloseIcon />
    </button>
  );
};
