import { useEffect, useState } from "react";
import "./styles.css";
import { HiChevronDoubleDown } from "react-icons/hi";
import { TalkWhatsApp } from "../../components/LandingPage/TalkWhatsApp/Index";
import MapDotted from "../../assets/png/mapDotted.png";
import Monitor from "../../assets/png/monitor.png";
import { Footer } from "../../components/LandingPage/Footer";
import { NavBar } from "../../components/LandingPage/NavBar/Index";
import { TabContent } from "../../components/CattleMap/TabContent";
import { ButtonCollapse } from "../../components/LandingPage/ButtonCollapse/Index";


export function Home() {
  const [navBarTop, setNavBarTop] = useState<boolean>(false);
  const [tabSelected, setTabSelected] = useState<number>(0);
  const [collapseOpen, setCollapseOpen] = useState<Array<boolean>>([true, true, true]);

  const handleNavBar = () => {
    window.scrollY >= document.documentElement.clientHeight * 0.8 || 0
      ? setNavBarTop(true)
      : setNavBarTop(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleNavBar, true);

    return () => {
      window.removeEventListener("scroll", handleNavBar, true);
    };
  }, []);
  return (
    <>
      <TalkWhatsApp />
      <section className="hero-full-page lannding-page">
        <div className="landing-container">
          <NavBar isOnTop={navBarTop} setProps={setTabSelected} />

          <main className="landing-main">
            <header>
              <img src={MapDotted} alt="mapa pontilhado" />
            </header>

            <footer>
              <h6>Plataforma Digital para</h6>
              <h3>Controle e Gestão das Pastagens, do Mapa de Gado e da Suplementação</h3>

            </footer>
          </main>

          <footer>
            <HiChevronDoubleDown id="chevron" />
          </footer>
        </div>
      </section>

      <section className="section-interface-container" id="aPlataforma">
        <div className="interface-container">
          <aside>
            <img src={Monitor} alt="tela de um macbook" />
          </aside>
          <main>
            <h3>
              Plataforma de Pecuária Digital, desenvolvida por quem está do lado de dentro
              da porteira.
            </h3>
            <div className="interface-card">
              <h6>
                Fácil de
                <br />
                utilizar
              </h6>
              <p>
                Alimentação do banco de dados é a base para gerar informação de qualidade,
                baseado nisso, nossa plataforma foi desenvolvida com olhar de quem está no
                dia a dia do campo.
              </p>
            </div>

            <div className="interface-card">
              <h6>
                Confiável e
                <br />
                atualizada
              </h6>
              <p>
                Foco nos controles e na alimentação do sistema, utilizando a tecnologia
                para aumentar a confiabilidade e agilidade na transformação de dados em
                informações uteis para gestão.
              </p>
            </div>

            <div className="interface-card">
              <h6>
                Informação
                <br />
                com foco
              </h6>
              <p>
                Informação útil é aquela utilizada para auxiliar no planejamento, validar
                uma estratégia ou mesmo acelerar uma mudança. Estes são os controles que
                buscamos trazer, sempre de forma simples e objetiva.
              </p>
            </div>
          </main>

        </div>
      </section>

      <section
        className="hero-page section-tabs"
        style={{ paddingTop: 100 }}
        id="section2"
      >


        <div className="information-container">
          <h5>O que Fazemos?</h5>
          <h3>Conheça as ferramentas do PASTEJO para sua fazenda:</h3>

          <div className="container-tabs">
            <nav>
              <ul>
                <li>
                  <span
                    onClick={() => setTabSelected(0)}
                    className={tabSelected === 0 ? "tab-active" : ""}
                    id="mapaDeGado"
                  >
                    Mapa de Gado
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => setTabSelected(1)}
                    className={tabSelected === 1 ? "tab-active" : ""}
                  >
                    Gestão das Pastagens
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => setTabSelected(2)}
                    className={tabSelected === 2 ? "tab-active" : ""}
                  >
                    Controle de Suplementação
                  </span>
                </li>
              </ul>
            </nav>

            <main>
              <TabContent render={tabSelected} />
            </main>
          </div>

          {/* inicio - tabs responsivas */}
          <section className="responsive-colapse">
            <section className="content-collapse">
              <ButtonCollapse
                label="Mapa de Gado"
                click={() => {
                  setTabSelected(0);
                  setCollapseOpen((prev) => [!prev[0], prev[1], prev[2]]);
                }}
              />
              <div
                className={`content-tab ${collapseOpen[0] ? "show-collapse" : "hide-collapse"
                  }`}
              >
                <TabContent render={0} />
              </div>
            </section>

            <section className="content-collapse">
              <ButtonCollapse
                label="Manejo de Pastagens"
                click={() => {
                  setTabSelected(1);
                  setCollapseOpen((prev) => [prev[0], !prev[1], prev[2]]);
                }}
              />
              <div
                className={`content-tab ${collapseOpen[1] ? "show-collapse" : "hide-collapse"
                  }`}
              >
                <TabContent render={1} />
              </div>
            </section>

            <section className="content-collapse">
              <ButtonCollapse
                label="Suplementação"
                click={() => {
                  setTabSelected(2);
                  setCollapseOpen((prev) => [prev[0], prev[1], !prev[2]]);
                }}
              />
              <div
                className={`content-tab ${collapseOpen[2] ? "show-collapse" : "hide-collapse"
                  }`}
              >
                <TabContent render={2} />
              </div>
            </section>
          </section>
          {/* fim - tabs responsivas */}
        </div>

        <section className="contact">
          <div className="container-900" id="contact-us">
            <h1>
              Quer saber qual o valor do investimento para elevar sua gestão com a
              plataforma de pecuária digital PASTEJO?
            </h1>
            <a style={{ marginBottom: 100 }} href="https://wa.me/553598550684">
              Fale com um consultor
            </a>

          </div>
        </section>
      </section>


      <Footer />
    </>
  );
}
