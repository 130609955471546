import { CSSProperties, useEffect, useState } from "react";
import "./styles.css";

type ILegendRoundedProps = {
  bgColor?: string;
  borderColor?: string;
  label?: string;
  styleBorder?: "solid" | "dashed";
};

export function LegendRounded(props: ILegendRoundedProps) {
  const { bgColor, borderColor, label, styleBorder = "solid" } = props;

  return (
    <div className="legendRounded__container">
      <span
        style={{
          background: bgColor || "#62b8ae",
          border: `${styleBorder === "dashed" ? 4 : 2}px ${styleBorder} ${borderColor || "#FFF"}`,
        }}
      />
      {label && <strong>{label}</strong>}
    </div>
  );
}
