import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as BackIcon } from "../../../../assets/svg/icons/back.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as GrassIcon } from "../../../../assets/svg/icons/grass.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";
import { ButtonYesOrNo } from "../../../Buttons/ButtonYesOrNo";

interface AnimalReceiveProps {
  task: ITaskAnimalTransfer | undefined;
}

export default function InfoAnimalReceive(task: AnimalReceiveProps) {
  const [taskAnimalReceive, setTaskAnimalReceive] = useState<ITaskAnimalTransfer>();
  useEffect(() => {
    setTaskAnimalReceive(task.task)
  }, []);

  return (
    <>
      <div >
        <span>Do retiro</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <AreaIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskAnimalReceive?.source_area_full_initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <MessageBalloon text={`Você recebeu esses animais com essas especificações?`} pointed />

        {taskAnimalReceive?.categories?.map((category, index) => (
          <div style={{ display: "flex", gap: 10 }} key={index}>
            <MessageBalloon text={`${category.quantity}`} />
            <MessageBalloon text={`${category.category_description.name} ${category.category_description.age_description}`} />
          </div>
        ))}

        <ButtonYesOrNo onlyResult optionSelected={!taskAnimalReceive?.skip_reason ? "yes" : "no"} />
      </div>

      <div className="containerQuestion">
        <MessageBalloon text={`Deseja criar um novo lote?`} pointed />
        <ButtonYesOrNo onlyResult optionSelected={taskAnimalReceive?.is_new_batch ? "yes" : "no"} />
      </div>

      <div >
        <span>No lote</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <AnimalsIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskAnimalReceive?.batch_initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
        <MessageBalloon text={`O novo lote se chamará ${taskAnimalReceive?.batch_initials}`} pointed />
      </div>

      <MessageBalloon text={`Nesta rota estão os pastos ${taskAnimalReceive?.micro_area_route.map(el => el.initials).join(", ")}.`} pointed />
    </>
  )
}