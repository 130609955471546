import { ReactComponent as CalendarIcon } from "../../../../assets/svg/icons/calendar.svg";
import { format } from "date-fns";
import { useFilter } from "../../../../hooks/useFilter";
import "../styles.css"

export function FilterDateDashboard() {
    const {
        setModalCalendarFilter,
        rangeDays,
        setFocusLastDay
    } = useFilter();

    return (
        <section className="dashBoardSupplement__container" style={{ marginTop: 30 }}>
            <div className="dashBoardInfoSupplement__container">
                <div className="dataFilter__owner--container" onClick={() => {
                    setModalCalendarFilter(true)
                    setFocusLastDay(false)
                }}>
                    <small>De</small>
                    <div>
                        <h6>{format(rangeDays?.from ? new Date(rangeDays.from) : new Date(), 'dd/MM/yyyy')}</h6>
                        <CalendarIcon />
                    </div>
                </div>

                <div className="dataFilter__owner--container" onClick={() => {
                    setModalCalendarFilter(true)
                    if (rangeDays?.from?.toDateString() != rangeDays?.to?.toDateString()) {
                        setFocusLastDay(true)
                    }
                    else {
                        setFocusLastDay(false)
                    }
                }}>
                    <small>Até</small>
                    <div>
                        <h6>{format(rangeDays?.to ? new Date(rangeDays.to) : new Date(), 'dd/MM/yyyy')}</h6>
                        <CalendarIcon />
                    </div>
                </div>
            </div>

            <button
                className="dashBoardInfo__exportButton no-print"
                onClick={() => window.print()}
            >
                Exportar PDF
            </button>
        </section>
    );
}