import { useEffect, useState } from "react";
import { ReactComponent as InfoIcon } from "../../../../assets/svg/icons/info.svg";
import { useEditOnboarding } from "../../../../hooks/useEditOnboarding";
import "./styles.css";

type TableVisualizationRowProps = {
  evaluation: IMicroAreaEvaluation;
  setSelectedId: (e: number) => void;
};

export default function TableVisualizationRow({
  evaluation,
  setSelectedId
}: TableVisualizationRowProps) {
  const created = new Date(evaluation?.created_at)
  const updated = new Date(evaluation?.updated_at)

  const statusMap = new Map<string, string>()
  statusMap.set("COMPLETED", "Avaliado")
  statusMap.set("PENDING", "Pendente")
  statusMap.set("ERROR", "Erro")

  const {
    setIdSelected
  } = useEditOnboarding();

  return (
    <div className="tableRowEvaluation">
      <div>{evaluation.evaluator}</div>
      <div>{evaluation.micro_area_initials}</div>
      <div>{statusMap.get(evaluation.status)}</div>
      <div>
        {evaluation?.created_at ?
          `${String(created.getDate()).padStart(2, "0")
          }/${String(created.getMonth() + 1).padStart(2, "0")
          }/${String(created.getFullYear() % 100).padStart(2, "0")
          } - ${String(created.getHours()).padStart(2, "0")
          }:${String(created.getMinutes()).padStart(2, "0")
          }` :
          ""}
      </div>
      <div>
        {evaluation?.updated_at ?
          `${String(updated.getDate()).padStart(2, "0")
          }/${String(updated.getMonth() + 1).padStart(2, "0")
          }/${String(updated.getFullYear() % 100).padStart(2, "0")
          } - ${String(updated.getHours()).padStart(2, "0")
          }:${String(updated.getMinutes()).padStart(2, "0")
          }` :
          ""}
      </div>
      <div className="infoButton">
        <div hidden={evaluation.status != "COMPLETED"}>
          <InfoIcon
            onClick={() => {
              setSelectedId(evaluation.task_id)
              setIdSelected(evaluation.micro_area_id)
            }}
          />
        </div>
      </div>
    </div>
  );
}
