import React, { useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { generateuuid } from '../../../utils/uuid/generate';

interface Props {
    label: string;
    data: any;
    fullWidth?: boolean;
}

const ChartPie: React.FC<Props> = ({ label, data, fullWidth }) => {
    Chart.register(ChartDataLabels);

    const idRandom = generateuuid()

    const chartData = {
        labels: data?Array.from(data.filter((el: any) => el?.value != 0), (el: any) => el?.label):[""],
        datasets: [
            {
                label,
                data: data?data.filter((el: any) => el?.value != 0).map((el: any) => (el?.value * 100).toFixed(0)):[""],
                backgroundColor: [
                    "#57CE71",
                    "#5AC8FA",
                    "#FF9501",
                    "#4A90E2",
                    "#FF3B30",
                    "#5AC8FA",
                    "#FFCC00",
                    "#5856D6",
                    "#34C759",
                    "#FF2D55",
                    "#007AFF",
                    "#FF2D55",
                    "#007AFF",
                    "#FFCC00",
                    "#5856D6",
                    "#34C759",
                    "#2737FA",
                    "#8A2BE2",
                ],
                borderWidth: 2,
                borderColor: "white",
            },
        ],
    };

    useEffect(() => {
        const element = document.getElementById(`js-legend${idRandom}`)
        if (fullWidth && element) {
            element.innerHTML += chartData?.labels.map((label, i) => {
                return `
                <div class="chart-legend__item">
                    <div class="chart-legend__icon" style="background-color: ${chartData.datasets[0].backgroundColor[i]}"></div>
                    <div class="chart-legend__text">${label}</div>
                </div>
            `
            }).join('')
        }
    }, []);
    if(!data) return <></>

    return (
        <div>
            <span className='titleGraph'>{label}</span>
            <div className={`contentGraphBorder ${fullWidth ? "containerGraphFull" : ""}`}>
                {data.every((el: any) => el?.value === 0) ?
                    <div className='noData'>
                        <span>Sem dados</span>
                    </div>
                    :
                    <>
                        <Pie
                            style={{ maxHeight: fullWidth ? 250 : 285 }}
                            data={chartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: true,
                                aspectRatio: 1,
                                devicePixelRatio: 4,
                                animation: {
                                    duration: 0
                                },
                                plugins: {
                                    legend: {
                                        display: !fullWidth,
                                        position: 'bottom',
                                        labels: {
                                            boxWidth: 10,
                                            font: {
                                                size: 10,
                                            },
                                        },
                                    },
                                    datalabels: {
                                        display: true,
                                        align: 'end',
                                        color: 'white',
                                        offset: 5,
                                        formatter: (value) => {
                                            return value + '%';
                                        },
                                        font: {
                                            size: 10,
                                            weight: "bold"
                                        },
                                        borderRadius: 3,
                                    },
                                },
                                layout: {
                                    padding: {
                                        top: 30,
                                    }
                                },
                            }}
                            height={285}
                            width={285}
                            redraw={true}
                        />
                        <div id={`js-legend${idRandom}`} className='chart-legend__container'></div>
                    </>
                }
            </div>

        </div>
    )
};

export default ChartPie;