import { ReactNode } from "react";
import "./styles.css";

type TitleErrorProps = {
  children: ReactNode;
};

export function TitleModal({ children }: TitleErrorProps) {
  return (
    <div className="modalerror__title--container">
      <h3>{children}</h3>
    </div>
  );
}
