import React from 'react';
import "./styles.css"

interface ImageComponentProps {
  url: string;
}

export default function ImageComponent({ url }: ImageComponentProps) {
  return (
    <img src={url} alt="description" className='containerImageComponent'/>
  );
}

