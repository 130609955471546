import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ReactComponent as GrassIcon } from "../../../../assets/svg/icons/grass.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";
import { ButtonYesOrNo } from "../../../Buttons/ButtonYesOrNo";
import { capitalize } from "../../../../utils/strings/capitalize";

interface InfoWaterTroughProps {
  task: ITaskWaterTrough | undefined;
}


export default function InfoWaterTrough(task: InfoWaterTroughProps) {
  const [taskWaterTrough, setWaterTrough] = useState<ITaskWaterTrough>();
  useEffect(() => {
    setWaterTrough(task.task)
  }, []);


  return (
    <>
      <div >
        <span>No pasto</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <GrassIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskWaterTrough?.initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <MessageBalloon text={`${capitalize(taskWaterTrough?.activity?.toLowerCase() || "")} bebedouro ${taskWaterTrough?.shape ? `${taskWaterTrough?.shape?.toLocaleLowerCase()} de ${taskWaterTrough?.material?.toLocaleLowerCase()} - ${taskWaterTrough?.access_area}m²` : ""}`} pointed />

      <div className="containerQuestion">
        <MessageBalloon text={`A tarefa foi concluída?`} pointed />
        <ButtonYesOrNo onlyResult optionSelected={!taskWaterTrough?.skip_reason ? "yes" : "no"} />
      </div>

      {taskWaterTrough?.skip_reason != undefined && <MessageBalloon text={`${taskWaterTrough?.skip_reason}`} />}
    </>
  )
}