import { createContext, ReactNode, useEffect, useState } from "react";
import axios from "../services/axios";
import { IMicroAreas } from "../@types/API/IMicroArea";
import { IAreas } from "../@types/API/IArea";
import { ICoords } from "../@types/GoogleMaps/ICoord";
import { AxiosError, AxiosResponse } from "axios";

interface ITaskCreation {
  allowTaskCreation: boolean;
  taskCreatedSuccessfully: boolean;
  taskCreationError: boolean;
  showTasklistModal: boolean;
  showCreateTaskModal: boolean;
  showMicroAreaUnavailableModal: boolean;
  selectedTask?: ITask;
  farmId?: string;
  areaId?: string;
  responsibleId?: string;
  microAreaId?: string;
  payload: IReqPayload;
  allFarms?: IFarm[];
  allAreas?: IAreas;
  allMicroAreas?: IMicroAreas;
  contextMicroArea?: Partial<IMicroArea>
  showDrawOnMapModal?: boolean;
  filterMicroAreas?: IMicroAreas;
  currentCoords?: ICoords;
  errorMessage?: string
  editFences: boolean;
  modalCreateEvaluator: boolean;
  modalStartEvaluator: boolean;
  setModalStartEvaluator: (s: boolean) => void;
  setModalCreateEvaluator: (s: boolean) => void;
  setEditFences: (s: boolean) => void;
  setErrorMessage: (error: string) => void;
  setCurrentCoords: (s: ICoords) => void;
  setFilterMicroAreas?: (s: IMicroAreas) => void;
  setShowDrawOnMapModal: (s: boolean) => void;
  setAllowTaskCreation: (s: boolean) => void;
  setTaskCreatedSuccessfully: (s: boolean) => void;
  setTaskCreationError: (s: boolean) => void;
  setShowTasklistModal: (s: boolean) => void;
  setShowCreateTaskModal: (s: boolean) => void;
  setShowMicroAreaUnavailableModal: (s: boolean) => void;
  setSelectedTask: (s: ITask | undefined) => void;
  setFarmId: (s: any | undefined) => void;
  setAreaId: (s: any | undefined) => void;
  setResponsibleId: (s: any | undefined) => void;
  setMicroAreaId: (s: any | undefined) => void;
  setPayload: (s: IReqPayload) => void;
  setAllFarms: (s: IFarm[]) => void;
  setAllAreas: (s: IAreas) => void;
  setAllMicroAreas: (s: IMicroAreas) => void;
  setContextMicroArea: (s: Partial<IMicroArea> | undefined) => void;
  resetContext: () => void;
  createTask: () => void;
}

interface TaskCreationProviderProps {
  children: ReactNode;
}

interface IReqPayload {
  type: TaskType;
  messages: { type: "TEXT"; value: string }[];
  changes: any[];
}

export const TaskCreationContext = createContext({} as ITaskCreation);

export const TaskCreationProvider = ({
  children,
}: TaskCreationProviderProps) => {
  const [allowTaskCreation, setAllowTaskCreation] = useState<boolean>(false);
  const [taskCreatedSuccessfully, setTaskCreatedSuccessfully] =
    useState<boolean>(false);
  const [taskCreationError, setTaskCreationError] = useState<boolean>(false);
  const [editFences, setEditFences] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<ITask>();
  const [farmId, setFarmId] = useState();
  const [areaId, setAreaId] = useState();
  const [responsibleId, setResponsibleId] = useState();
  const [microAreaId, setMicroAreaId] = useState();
  const [allFarms, setAllFarms] = useState<IFarm[]>();
  const [allAreas, setAllAreas] = useState<IAreas>();
  const [allMicroAreas, setAllMicroAreas] = useState<IMicroAreas>([]);
  const [filterMicroAreas, setFilterMicroAreas] = useState<IMicroAreas>();
  const [contextMicroArea, setContextMicroArea] = useState<Partial<IMicroArea>>();
  const [currentCoords, setCurrentCoords] = useState<ICoords>([]);
  const [payload, setPayload] = useState<IReqPayload>({} as IReqPayload);

  const [showTasklistModal, setShowTasklistModal] = useState<boolean>(false);
  const [showCreateTaskModal, setShowCreateTaskModal] =
    useState<boolean>(false);
  const [showMicroAreaUnavailableModal, setShowMicroAreaUnavailableModal] =
    useState<boolean>(false);
  const [showDrawOnMapModal, setShowDrawOnMapModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [modalCreateEvaluator, setModalCreateEvaluator] = useState<boolean>(false);
  const [modalStartEvaluator, setModalStartEvaluator] = useState<boolean>(false);

  const resetContext = () => {
    setSelectedTask(undefined);
    setFarmId(undefined);
    setAreaId(undefined);
    setResponsibleId(undefined);
    setMicroAreaId(undefined);
    setPayload({} as IReqPayload);
    setAllowTaskCreation(false);
    setContextMicroArea(undefined);
  };

  useEffect(() => {
    console.log(payload)
  }, [payload]);

  const createTask = () => {
    if (!payload || !selectedTask) return;

    const messages = [
      {
        type: "TEXT",
        value: "string",
      },
    ];

    const reqPayload = {
      ...payload,
      type: selectedTask.type,
      messages: messages,
    };

    axios
      .post("/tasks", reqPayload)
      .then(() => {
        setTaskCreatedSuccessfully(true);
      })
      .catch((error) => {
        setTaskCreationError(true);
        setErrorMessage(error.response.data["msg"]);
      });
  };

  return (
    <TaskCreationContext.Provider
      value={{
        allowTaskCreation,
        taskCreatedSuccessfully,
        taskCreationError,
        showTasklistModal,
        showCreateTaskModal,
        showMicroAreaUnavailableModal,
        selectedTask,
        farmId,
        areaId,
        responsibleId,
        microAreaId,
        payload,
        allFarms,
        allAreas, 
        allMicroAreas,
        contextMicroArea,
        showDrawOnMapModal,
        filterMicroAreas,
        currentCoords,
        errorMessage,
        editFences,
        modalStartEvaluator,
        setModalStartEvaluator,
        setEditFences,
        setErrorMessage,
        setCurrentCoords,
        setFilterMicroAreas,
        setShowDrawOnMapModal,
        setAllowTaskCreation,
        setTaskCreatedSuccessfully,
        setTaskCreationError,
        setSelectedTask,
        setFarmId,
        setAreaId,
        setResponsibleId,
        setMicroAreaId,
        setPayload,
        resetContext,
        createTask,
        setAllFarms,
        setAllAreas,
        setAllMicroAreas,
        setShowTasklistModal,
        setShowCreateTaskModal,
        setShowMicroAreaUnavailableModal,
        setContextMicroArea,
        modalCreateEvaluator,
        setModalCreateEvaluator
      }}
    >
      {children}
    </TaskCreationContext.Provider>
  );
};
