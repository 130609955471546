import { CSSProperties, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Input } from "../../components/Input";
import { FooterEditOnboarding } from "../../Footer";
import { useEditOnboarding } from "../../../../hooks/useEditOnboarding";
import "./styles.css";
import { Dropdown } from "../../components/Select";
import axios from "../../../../services/axios";
import {
  IOptionProps,
  IOptionsProps,
} from "../../../../@types/reactSelect/IOption";
import { ICategory } from "../../../../@types/app/ICategory";
import { CategoryInputQtd } from "../../components/QtdCategory";
import { IMicroArea } from "../../../../@types/app/IMicroArea";
import { ICoords } from "../../../../@types/GoogleMaps/ICoord";
import {
  FlyToBounds,
  FlyToBoundsRefProps,
} from "../../../Leaflet/FlyToBounds/Index";
import { LeafletStaticMap } from "../../../Leaflet/LeafletStaticMap/Index";
import Constants from "../../../../constants/Index";
import { Polygon } from "react-leaflet";
import { ReactComponent as GrassIcon } from "./../../../../assets/svg/icons/grass.svg";
import { ReactComponent as Animais } from "./../../../../assets/svg/icons/animais.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as Circleicon } from "../../../../assets/svg/icons/ellipse.svg";
import { AlertModal } from "../../../Modal/Alert/Index";
import { ButtonRedirect } from "../../../Modal/Error/ButtonRedirect/Index";
import { TitleModal } from "../../../Modal/Contents/TitleModal/Index";
import { TextModal } from "../../../Modal/Contents/TextModal/Index";
import { SimpleButtonClose } from "../../../Modal/Contents/SimpleButtonClose/Index";

const schemaBatch = yup.object().shape({
  farm: yup
    .object({
      label: yup.string(),
      value: yup.string().required("Fazenda é necessária."),
    })
    .nullable()
    .required("Fazenda é necessária."),
  area: yup
    .object({
      label: yup.string(),
      value: yup.string().required("Retiro é necessário."),
    })
    .nullable()
    .required("Retiro é necessário."),
  initials: yup
    .string()
    .matches(/^\S+(\s+\S+)*$/, "Esta sigla não é válida.")
    .required("Sigla é necessário."),
  category: yup
    .array()
    .min(1, "Categoria é necessária.")
    .required("Categoria é necessária."),
  categories: yup.array().of(
    yup.object({
      quantity: yup.number().min(1, "Adicione animais para essa categoria."),
    })
  ),
  current_micro_area_id: yup
    .object({
      label: yup.string(),
      value: yup.string().required("Pasto atual é necessário."),
    })
    .nullable()
    .required("Pasto atual é necessário."),
  micro_area_route_ids: yup
    .array()
    .nullable()
    .min(1, "Rota de pastejo é necessária.")
    .required("Rota de pastejo é necessária."),
});

export function CreateBatch() {
  const [categories, setCategories] = useState<Map<number, ICategory>>(
    new Map()
  );
  const { setOptionForm, defaultValue } = useEditOnboarding();
  const isNew = !defaultValue;
  const [initialsOnlyRead, setInitialsOnlyRead] = useState<boolean>(isNew);
  const [microAreaAvailable, setMicroAreaAvailable] = useState<IOptionsProps>();
  const [microAreas, setMicroAreas] = useState<any[]>();
  const [currentMicroAreaOptions, setCurrentMicroAreaOptions] =
    useState<IOptionsProps>([]);
  const [listMicroAreasMap, setListMicroAreasMap] = useState<
    Map<number, IMicroArea>
  >(new Map());
  const [areaCoords, setAreaCoords] = useState<ICoords>([]);
  const flytoBoundsRef = useRef<FlyToBoundsRefProps>();
  const initialArea = defaultValue?.initialsArea;
  const farmId = defaultValue?.farm_id;
  const areaId = defaultValue?.area_id;
  const [listFarms, setListFarms] = useState<IOptionsProps>();
  const [listAreas, setListAreas] = useState<IOptionsProps>();
  const [currentFarm, setCurrentFarm] = useState<any>();
  const [currentArea, setCurrentArea] = useState<any>();
  const [currentInitials, setCurrentInitials] = useState<string>("");
  const [microAreaFiltred, setMicroAreaFiltred] = useState<IOptionsProps>();
  const [listBatches, setListBatches] = useState<any>();
  const [msgError, setMsgError] = useState<string>("");
  const [lenghtBatch, setLenghtBatch] = useState(0);
  const contentStyleAlert = {
    width: "335px",
    height: "237px",
    borderRadius: "20px",
    padding: "20px",
  } as CSSProperties;

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(schemaBatch),
  });
  const categoryQtd = watch("categories");
  const microAreaRouteIds = watch("micro_area_route_ids");
  const currentMicroArea = watch("current_micro_area_id");
  const ua = watch("ua");

  const onSubmit = (data: any) => {
    if (isNew) {
      var formCreateBatch = {
        initials: data?.initials,
        categories: data?.categories.map((category: any) => ({
          id: category?.id,
          month: category?.month,
          quantity: category?.quantity,
        })),
        area_id: data?.area?.value,
        current_micro_area_id: data?.current_micro_area_id?.value,
        micro_area_route_ids: data?.micro_area_route_ids?.map(
          (microArea: any) => microArea.value
        ),
      };

      axios
        .post("/batches", [formCreateBatch])
        .then(() => {
          setOptionForm(undefined);
        })
        .catch((err) => setMsgError(err.response.data.msg));
    }
  };

  useEffect(() => {
    axios.get("/evolutions").then(({ data }) => {
      const currentMonth = new Date().getMonth() + 1;
      var values = data.map((type: any) =>
        type.categories.map((category: any) => {
          var json = {
            ...category,
            month: currentMonth,
            unit: category.month_evolution[currentMonth - 1].weight.unit,
            middle_weight:
              category.month_evolution[currentMonth - 1].weight.value,
          };
          return json;
        })
      );
      setCategories(
        new Map(
          values.flat().map((value: any) => {
            return [value.id, value];
          })
        )
      );
    });

    axios
      .get("/micro-areas/available", {
        params: { area_id: areaId },
      })
      .then(({ data }) => {
        const available: IOptionsProps = data.map((microArea: any) => ({
          label: `${microArea.farm.initials}-${microArea.area.initials}-${microArea.initials}`,
          value: microArea.id,
          info: microArea,
        }));
        if (defaultValue)
          defaultValue?.micro_area_route_ids.map((value: any) => {
            available.push(value);
            setValue("current_micro_area_id", value);
            if (isNew) setCurrentMicroAreaOptions([value]);
          });
        setMicroAreaAvailable(available);
      });

    axios
      .get("/micro-areas", {
        params: { farm_id: farmId, area_id: areaId },
      })
      .then(({ data }) => {
        setMicroAreas(data);
        setListMicroAreasMap(
          new Map(
            data.map((microArea: any) => {
              return [microArea.id, microArea];
            })
          )
        );
      });

    axios.get("/farms/user").then(({ data }) => {
      setListFarms(
        data.map((farm: any) => ({
          label: farm.name,
          value: farm.id,
          info: farm,
        }))
      );
    });

    axios.get("/areas/user").then(({ data }) => {
      setListAreas(
        data.map((area: any) => ({
          label: area.name,
          value: area.id,
          info: area,
        }))
      );
    });

    if (!isNew) {
      axios
        .get("/batches", {
          params: { farm_id: farmId, area_id: areaId },
        })
        .then(({ data }) => {
          setAreaCoords(data[0]?.area?.map_coords);
          setListBatches(
            data.map((batch: any) => ({
              initials: `${initialArea}-${batch?.initials}`,
              numberPastures: (batch?.micro_area_route).length,
              numberAnimals: batch?.animals_quantity,
              id: batch?.id,
            }))
          );
        });
    }
  }, []);

  useEffect(() => {
    if (categories) {
      setValue(
        "category",
        defaultValue?.category?.map((option: any) => ({
          label: categories.get(Number(option?.value))?.name,
          value: `${option?.value}`,
        }))
      );
    }
  }, [categories]);

  useEffect(() => {
    if (initialsOnlyRead)
      setValue(
        "initials",
        `LT${(lenghtBatch + 1).toString().padStart(3, "0")}`
      );
  }, [initialsOnlyRead, lenghtBatch]);

  useEffect(() => {
    if (currentFarm) {
      setAreaCoords(currentFarm?.map_coords);
      setCurrentArea(undefined);
      setCurrentInitials(`${currentFarm?.initials}-`);
      setCurrentMicroAreaOptions([]);
      setValue("area", null);
      setValue("micro_area_route_ids", null);
      axios
        .get("/batches", {
          params: { farm_id: currentFarm?.id },
        })
        .then(({ data }) => {
          setLenghtBatch(data.length);
        });
    }
  }, [currentFarm]);

  useEffect(() => {
    if (currentArea) {
      setAreaCoords(currentArea?.map_coords);
      setCurrentInitials(`${currentFarm?.initials}-${currentArea?.initials}-`);
      setValue("micro_area_route_ids", undefined);
      setCurrentMicroAreaOptions([]);
    }
  }, [currentArea]);

  useEffect(() => {
    if (listFarms)
      setValue(
        "farm",
        listFarms.filter((farm) => farm?.value == defaultValue?.farm_id)[0]
      );
  }, [listFarms, defaultValue]);

  useEffect(() => {
    if (listAreas)
      setValue(
        "area",
        listAreas.filter((area) => area?.value == defaultValue?.area_id)[0]
      );
  }, [listAreas, defaultValue]);

  useEffect(() => {
    if (microAreaAvailable && currentArea) {
      setMicroAreaFiltred(
        microAreaAvailable.filter(
          (micro: any) => micro?.info?.area_id == currentArea?.id
        )
      );
    } else {
      setMicroAreaFiltred([]);
    }
  }, [currentArea]);

  useEffect(() => {
    setCapacityRate();
  }, [currentMicroAreaOptions, listMicroAreasMap]);

  function getUA() {
    const categories = getValues("categories");
    if (categories) {
      let sumUA = categories.reduce(
        (sum: number, current: any) =>
          sum + (current.quantity * Number(current.middle_weight)) / 450,
        0
      );

      setValue("ua", sumUA.toFixed(2));
      setCapacityRate();
    }
  }

  function setCapacityRate() {
    let acreageUsed = 0;

    currentMicroAreaOptions?.forEach((microArea) => {
      const pasture = listMicroAreasMap.get(Number(microArea.value));
      if (pasture) {
        acreageUsed += parseFloat(pasture.acreage + "");
      }
    });

    let capacityRate = 0;
    if (acreageUsed > 0) {
      capacityRate = getValues("ua") / acreageUsed;
    }
    setValue("batch_capacity_rate", capacityRate.toFixed(2));
  }

  useEffect(() => {
    setCurrentMicroAreaOptions(microAreaRouteIds);
    var microAreas: any[] = microAreaRouteIds?.map((micro: any) => micro.value);
    var current: IOptionProps = currentMicroArea?.value;
    if (microAreas && current && !microAreas.includes(current))
      setValue("current_micro_area_id", undefined);
  }, [microAreaRouteIds]);

  const deleteBatch = () => {
    axios
      .delete(`/batches/${defaultValue.id}`)
      .then(() => {
        setOptionForm(undefined);
      })
      .catch((err) => setMsgError(err.response.data.msg));
  };

  return (
    <>
      <AlertModal visible={!!msgError} contentStyle={contentStyleAlert}>
        <section className="EditAreas_modalalert-container">
          <SimpleButtonClose onClick={() => setMsgError("")} />
          <main className="EditAreas_modalalert-content">
            <TitleModal>Algo deu errado.</TitleModal>
            <TextModal>{msgError}</TextModal>
          </main>
          <ButtonRedirect onClick={() => setMsgError("")}>
            Voltar
          </ButtonRedirect>
        </section>
      </AlertModal>
      <main className={`body__formEditOnboarding${isNew ? "" : "--onlyRead "}`}>
        <div className="firstColumn">
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: 500 }}>
            <Dropdown
              name={"farm"}
              control={control}
              label={"Fazenda"}
              placeholderText={"Selecione..."}
              options={listFarms}
              isDisabled={!isNew}
              getSelectedData={(option: any) => {
                setCurrentFarm(option.info);
              }}
              closeMenuOnSelect={true}
              errorText={(errors?.farm as any)?.value?.message}
            />

            <Dropdown
              name={"area"}
              control={control}
              label={"Retiro"}
              placeholderText={"Selecione..."}
              options={
                listAreas
                  ? listAreas.filter(
                      (area: any) => area?.info?.farm?.id == currentFarm?.id
                    )
                  : []
              }
              getSelectedData={(option: any) => {
                setCurrentArea(option.info);
              }}
              isDisabled={!isNew}
              closeMenuOnSelect={true}
              errorText={(errors?.area as any)?.value?.message}
              noOptionsMessage={() => (
                <span>Nenhum retiro disponível</span>
              )}
            />

            <Input
              name={"initials"}
              control={control}
              label={"Sigla"}
              theme={!isNew || initialsOnlyRead ? "read-only" : "normal"}
              disabled={!isNew || initialsOnlyRead}
              maxLength={5}
              labelPrefix={`${isNew ? currentInitials : `${initialArea}-`}`}
              overrideStyles={{
                input: {
                  width: 200,
                },
              }}
              errorText={errors?.initials?.message as any}
            />
            {isNew && (
              <label
                onClick={() => {
                  setInitialsOnlyRead(!initialsOnlyRead);
                }}
                className="initial--readOnly"
              >
                {initialsOnlyRead ? "Alterar sigla" : "Usar sigla automática"}
              </label>
            )}

            <CategoryInputQtd
              control={control}
              categories={categories}
              name={"categories"}
              calculateUA={getUA}
              defaultValues={defaultValue?.category}
              isNew={isNew}
              errors={errors}
            />

            <Input
              name="ua"
              control={control}
              label={"UA total"}
              theme={"read-only"}
              disabled={true}
              labelPrefix={`UA`}
              prefixPosition={"right"}
              overrideStyles={{
                prefixLabel: {
                  color: "#999999",
                  marginLeft: "5px",
                },
                input: {
                  width: `${ua?.length}ch`,
                },
              }}
            />

            <Dropdown
              name={"micro_area_route_ids"}
              control={control}
              label={"Selecione a rota de pastejo"}
              placeholderText={"Selecione..."}
              isMulti={true}
              getSelectedData={(option: any) =>
                setCurrentMicroAreaOptions(option)
              }
              isDisabled={!isNew}
              options={isNew ? microAreaFiltred : microAreaAvailable}
              errorText={errors?.micro_area_route_ids?.message as any}
              noOptionsMessage={() => (
                <span>Todos os pastos estão sendo utilizados</span>
              )}
            />

            <Dropdown
              name={"current_micro_area_id"}
              control={control}
              label={"Pasto atual"}
              placeholderText={"Selecione..."}
              options={currentMicroAreaOptions}
              isDisabled={!isNew}
              closeMenuOnSelect={true}
              errorText={(errors?.current_micro_area_id as any)?.value?.message}
              noOptionsMessage={() => (
                <span>Não existem pastos disponíveis</span>
              )}
            />

            <Input
              name="batch_capacity_rate"
              control={control}
              label={"Taxa de lotaçao do lote"}
              theme={"read-only"}
              disabled={true}
            />
          </form>
        </div>
        <div className="secondColumn">
          <div className={`area__drawarea--container mapBatches`}>
            <LeafletStaticMap>
              <FlyToBounds ref={flytoBoundsRef} fitCoords={areaCoords}>
                {/* Informações no mapa */}
                <div className="FlytoBounds_container">
                  <div className="subtitle__animals">
                    <Animais />
                    <span>
                      {categoryQtd &&
                        categoryQtd.reduce(
                          (sum: number, current: any) => sum + current.quantity,
                          0
                        )}
                    </span>
                  </div>
                  <div className="subtitle__grass ">
                    <GrassIcon />
                    <span>
                      {currentMicroAreaOptions?.length || 0}/
                      {isNew
                        ? microAreaFiltred?.length || 0
                        : microAreaAvailable?.length}
                    </span>
                  </div>
                </div>
                {/* area */}
                <Polygon
                  positions={areaCoords}
                  color={Constants.LEAFLET_UNFOCUS.color}
                />
                {/* microAreas */}
                {microAreas &&
                  microAreas.map((micro: any) =>
                    currentMicroAreaOptions
                      ?.map((micro) => micro.value)
                      .includes(String(micro.id)) ? (
                      <Polygon
                        positions={micro.map_coords || []}
                        color={Constants.LEAFLET_UNFOCUS_TRANSLUCENT.color}
                        fillColor={Constants.LEAFLET_NORMAL_BLUE.fillColor}
                        fillOpacity={
                          Constants.LEAFLET_UNFOCUS_TRANSLUCENT.fillOpacity
                        }
                      />
                    ) : (
                      ""
                    )
                  )}
              </FlyToBounds>
            </LeafletStaticMap>
          </div>
          <div className="grid_batches">
            {listBatches &&
              listBatches
                .filter((batch: any) => batch.id != defaultValue.id)
                .map((values: any) => (
                  <a className="cardBatch">
                    <span className="initials">{values.initials}</span>
                    <span className="info">
                      {values.numberPastures || 0} pastos <Circleicon />{" "}
                      {values.numberAnimals || 0} animais
                    </span>
                  </a>
                ))}
          </div>
        </div>
      </main>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FooterEditOnboarding
          undoFunction={() => setOptionForm(undefined)}
          saveFunction={() => console.log("Save")}
          deleteFunction={() => deleteBatch()}
          isNew={isNew}
          onlyRead
        />
      </form>
    </>
  );
}
