import { CSSProperties, useEffect, useState } from 'react'
import { Polygon as GooglePolygon, Polyline } from "@react-google-maps/api"
import { Controller } from 'react-hook-form'
import { Polygon } from 'react-leaflet'

import "leaflet-draw/dist/leaflet.draw-src.css";
import './styles.css'

import { ButtonWithChildren } from '../../../Buttons/ButtonWithChildren/Index'
import { LeafletStaticMap } from '../../../Leaflet/LeafletStaticMap/Index'
import { ToolBar } from '../../../GoogleMaps/DrawPolygon/ToolBar/Index'
import { FlyToBounds } from '../../../Leaflet/FlyToBounds/Index'
import { ICoords } from '../../../../@types/GoogleMaps/ICoord'
import { Modal } from '../../../Modal/Default/Index'
import { Map } from '../../../GoogleMaps/Map/Index'
import Constants from '../../../../constants/Index'
import { Chips } from '../../../Chips'

import { ReactComponent as MicroAreaIcon } from "../../../../assets/svg/icons/grass.svg"
import { ReactComponent as FarmIcon } from "../../../../assets/svg/icons/farmIcon.svg"
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg"
import { ReactComponent as GrassIcon } from "../../../../assets/svg/icons/grass.svg"
import { ReactComponent as PenIcon } from "../../../../assets/svg/icons/penEdit.svg"
import { ReactComponent as CloseIcon } from "../../../../assets/svg/icons/close.svg"
import { StaticMapRender } from '../StaticMapRender/Index';

const contentStyleModal = {
    width: "95%",
    height: "95vh",
    maxWidth: "1350px",
    maxHeight: "937px",
    borderRadius: "20px",
    overflow: "hidden",
} as CSSProperties;

const closeButtonModalStyles = {
    width: '52px',
    height: '52px',
    position: 'absolute',
    top: '20px',
    right: '20px',
    zIndex: '999'
} as CSSProperties

const chipModalStyles = {
    position: 'absolute',
    top: '20px',
    left: '20px',
    zIndex: '999'
} as CSSProperties

const toolBarStyles = {
    bottom: '20px',
    left: '20px',
    zIndex: '999'
} as CSSProperties


interface StaticAsideControllsProps {
    onEventEdit: () => void;
    counterEntities: number;
    editable: boolean;
}

export const StaticAsideControlls = ({ onEventEdit, counterEntities, editable }: StaticAsideControllsProps) => {
    return <>
        <aside className='DrawOnMap__aside--wrapper-controlls'>
            <div className="DrawOnMap__aside--container-controlls">
                <ButtonWithChildren
                    style={{ width: '52px', height: '52px', visibility: editable ? 'visible' : 'hidden' }}
                    onClick={onEventEdit}
                    theme='info'
                >
                    <PenIcon className='DrawOnMap__aside--pen-icon' />
                </ButtonWithChildren>
                <Chips.Label
                    label={counterEntities.toString()}
                    IConComponent={<GrassIcon />}
                    bgTranslucentLevel={.9}
                    theme='dark-highlight'
                    variationStyles={{
                        container: {
                            'visibility': !!counterEntities ? 'visible' : 'hidden'
                        }
                    }}
                />
            </div>
        </aside>
    </>
}

interface DynamicMapsControllsProps {
    onDiscardChanges: () => void;
    onEventClose: () => void;
    initialsLabel: string;
}

export const DynamicMapsControlls = ({ onEventClose, initialsLabel, onDiscardChanges }: DynamicMapsControllsProps) => {
    const handleTypeIcon = () => {
        switch (initialsLabel.split('-').length) {
            case 2: return <AreaIcon />
            case 3: return <MicroAreaIcon />
            default: return <FarmIcon />
        }
    }
    return <>
        <Chips.Label
            variationStyles={{ container: chipModalStyles }}
            IConComponent={handleTypeIcon()}
            label={initialsLabel}
            theme='dark'
        />
        <ButtonWithChildren style={closeButtonModalStyles} theme='info' onClick={onDiscardChanges}>
            <CloseIcon className='DrawOnMap__aside--close-icon' />
        </ButtonWithChildren>
        <ToolBar
            handleDiscardChanges={onDiscardChanges}
            handleSuccess={onEventClose}
            style={toolBarStyles}
            hiddenExploreButton
            hiddenDrawButton
            label=''
        />
    </>
}

interface MainComponentProps {
    staticsElements: ICoords[];
    initialsLabel: string;
    mainPolygon: ICoords;
    editable: boolean;
    onChange: any;
    value: any;
    style?: CSSProperties;
    fitCoords?: ICoords;
    onlyModal?: boolean;
    onClose?: () => void;
    overrideStyleModal?: CSSProperties;
}

export const MainComponent = ({ onChange, onClose, overrideStyleModal, value = [], mainPolygon, staticsElements, fitCoords, onlyModal = false, ...rest }: MainComponentProps) => {
    const [modalVisible, setModalVisible] = useState<boolean>(onlyModal || false);

    const [polygon, setPolygon] = useState<ICoords>([]);
    const [initialPolygon, setInitialPolygon] = useState<ICoords>([])
    const [polygonComponet, setPolygonComponet] = useState<google.maps.Polygon | null>(null);

    const onClickMouse = (event: google.maps.MapMouseEvent) => {
        const lat = event.latLng?.lat();
        const lng = event.latLng?.lng();
        if (!!lat && !!lng)
            setPolygon(prev => ([...prev, { lat, lng }]))
    }

    const onLoadPolygon = (polygon: google.maps.Polygon) => setPolygonComponet(polygon)

    function onMouseUp(event: google.maps.MapMouseEvent) {
        const coords = polygonComponet?.getPath().getArray().map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
        }) as ICoords;
        if (!!coords) setPolygon(coords)
    }

    const onDiscardChanges = () => {
        setPolygon(initialPolygon)
        setModalVisible(false)
        onClose && onClose()
    }

    const onEventClose = () => {
        setInitialPolygon(polygon)
        setModalVisible(false)
        onClose && onClose()
    }

    useEffect(() => {
        onChange && onChange(polygon)
    }, [polygon])

    useEffect(() => {
        if (polygon.length < 1) {
            setInitialPolygon(value)
            setPolygon(value)
        }
    }, [])

    return <>
        <Modal visible={modalVisible} contentStyle={overrideStyleModal ? overrideStyleModal : contentStyleModal}>
            {modalVisible && <>
                <Map fitBounds={fitCoords || mainPolygon} onClick={onClickMouse}>
                    <Polyline
                        options={Constants.POLYLINE_DASHED_GREEN}
                        path={fitCoords}
                        draggable={false}
                        editable={false}
                    />
                    {fitCoords && <>
                        {staticsElements.map((coords) => (
                            <Polyline
                                options={Constants.POLYGON_STROKE}
                                draggable={false}
                                editable={false}
                                path={[...coords, coords[0]]}

                            />
                        ))}
                    </>}

                    <GooglePolygon
                        options={Constants.POLYGON_YELLOW}
                        onLoad={onLoadPolygon}
                        onMouseUp={onMouseUp}
                        path={polygon}
                        editable
                    />
                </Map>
                <DynamicMapsControlls
                    onDiscardChanges={onDiscardChanges}
                    initialsLabel={rest.initialsLabel}
                    onEventClose={onEventClose}
                />
            </>}
        </Modal>
        {!onlyModal && (<section className='DrawOnMap__section--view-results' style={rest.style}>
            <StaticAsideControlls
                onEventEdit={() => setModalVisible(true)}
                counterEntities={staticsElements.length}
                {...rest}
            />
            {(!fitCoords && polygon.length < 1) ? <>
                <div className='DrawOnMap__section--view-default'>

                </div>
            </> : <StaticMapRender fitCoords={fitCoords} mainPolygon={polygon} microAreas={staticsElements} />}
        </section >)}
    </>
}

interface DrawOnMapProps {
    staticsElements: ICoords[];
    initialsLabel: string;
    mainPolygon: ICoords;
    control: any;
    name: string;
    style?: CSSProperties;
    editable?: boolean;
    fitCoords?: ICoords
}

export function DrawOnMap(props: DrawOnMapProps) {
    const [fieldError, setFieldError] = useState<string | null>(null);
    return <>
        <Controller
            name={props.name}
            control={props.control}
            render={({ field, formState: { errors } }) => {
                setFieldError(!!errors[props.name] ? errors[props.name]?.message as string : null)
                return (
                    <section>
                        <MainComponent {...field} {...props} editable={props.editable || false} />
                        {(!!fieldError) && (
                            <small className="textInput--small-error animation__fadeIn">
                                {fieldError}
                            </small>
                        )}
                    </section>
                )
            }}
        />
    </>
}