import { HTMLAttributes, ReactNode } from "react";
import "./styles.css";

interface ButtonWithTextProps extends HTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  theme?: "dark" | "info" | "light" | "success" | "dark" | "disabled";
}

export function ButtonWithChildren({ children, theme, ...rest }: ButtonWithTextProps) {
  return (
    <button className={`buttonwithchildren__container buttonwithchildren__${theme}`} type="button" disabled={theme === 'disabled'} {...rest}>
      {children}
    </button>
  );
}
