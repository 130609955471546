import { useEffect, useRef, useState } from "react";
import { Polygon, Tooltip } from "react-leaflet";
import "./styles.css";

import { useFilter } from "../../../../hooks/useFilter";

import { FlyToBounds, FlyToBoundsRefProps, IAnimationState } from "../../../../components/Leaflet/FlyToBounds/Index";
import { LegendRounded } from "../../../../components/PaddocksMap/LegendRounded/Index";
import ContainerZoom1X from "../../../../components/Leaflet/ContainerZoom1X/Index";
import ContainerZoom2X from "../../../../components/Leaflet/ContainerZoom2X/Index";
import ContainerZoom4X from "../../../../components/Leaflet/ContainerZoom4X/Index";
import ContainerZoom3X from "../../../../components/Leaflet/ContainerZoom3X/Index";
import { PolylineFarm } from "../../../../components/PaddocksMap/PolylineFarm/Index";
import { BatchesIcons } from "../../../../components/PaddocksMap/BatchesIcons/Index";
import { LabelFarm1X } from "../../../../components/PaddocksMap/LabelFarm1X/Index";
import { AnimatedZoom } from "../../../../components/Leaflet/AnimatedZoom/Index";

import { ICoord, ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { IFarms } from "../../../../@types/API/IFarm";
import { IBatch } from "../../../../@types/API/IBatches";
import { IAreas } from "../../../../@types/API/IArea";

import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";
import Constants from "../../../../constants/Index";
import axios from "../../../../services/axios";
import { CustomControls } from "../../../../components/Leaflet/CustomControls/Index";
import { BatchesAvailableDetails } from "../../../../components/PaddocksMap/BatchesAvailable";
import { BatchesSpecsDetails } from "../../../../components/PaddocksMap/BatchesSpecsDetails";
import { SpecsMouseOver } from "../../../../components/PaddocksMap/SpecsMouseOver/Index";

type ICustomMicroArea = {
  microInitials: any;
  areaInitials: string;
  amountAnimals: number;
  capacityRate: number;
  id: string;
  initials: string;
  map_coords: ICoords;
  bgColor: string;
  areaId: string;
  visibility: "FOCUS" | "UNFOCUS";
  farmId: string;
};

type ICustomArea = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  bgColor: string;
  farmId: string;
};

type ICustomBatch = {
  id: string;
  coords: ICoord;
  farmId: string;
  areaId: string;
  visible: boolean;
  initials: string;
};

type ICustomFarm = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  bgColor: string;
};

interface ICustomCurrentArea extends ICustomArea {
  farmId: string;
}

interface ICustomAreas extends Array<ICustomArea> {}
interface ICustomBatches extends Array<ICustomBatch> {}
interface ICustomMicroAreas extends Array<ICustomMicroArea> {}
interface ICustomFarms extends Array<ICustomFarm> {}

export function GrazingRoutes() {
  const flytoBoundsRef = useRef<FlyToBoundsRefProps>();

  const [farmsData, setfarmsData] = useState<IFarms>([]);

  const [currentFarm, setCurrentFarm] = useState<ICustomFarm | undefined>(undefined);
  const [currentArea, setCurrentArea] = useState<ICustomCurrentArea | undefined>(undefined);

  const [areasPolygons, setAreasPolygons] = useState<ICustomAreas>([]);

  const [microPolygons, setMicroPolygons] = useState<ICustomMicroAreas>([]);

  const [batchesPolygons, setBatchesPolygons] = useState<ICustomBatches>([]);

  const [farmsPolygons, setFarmsPolygons] = useState<ICustomFarms>([]);

  const { farmFilter, setFarmFilter, areaFilter, defaultBGFarm, setHideMicroAreaFilter, setHideBatchFilter } =
    useFilter();

  const [stateOfAnimation, setStateOfAnimation] = useState<IAnimationState>("end");

  const [reasons, setReasons] = useState<Map<number, string>>(new Map());
  
  const [mapMicroAreas, setMapMicroAreas] = useState<Map<number, string>>(new Map());

  function getRandomColor() {
    const colors: Array<string> = Object.values(Constants.GRAZINGMAP_COLORS);
    return colors[Math.floor(Math.random() * colors.length)];
  }

  async function handleBatches(farms: IFarms, micros: ICustomMicroAreas) {
    const microParsed = await Promise.all(
      farms.map(async (farm) => {
        const { data, status } = await axios.get("/batches", { params: { farm_id: farm.id } });
        return data.map((batch: IBatch) => ({
          area: { ...batch.area, areaID: batch.area.id },
          currentMicro: batch.current_micro_area,
          routes: batch.micro_area_route,
          bgColor: getRandomColor(),
          id: batch.id,
          farmId: farm.id,
          capacityRate: batch.batch_capacity_rate,
          amountAnimals: batch.animals_quantity,
          areaInitials: batch.area.initials,
          initials: batch.initials
        }));
      })
    );
    const flatMicros = microParsed.flat();
    const microParsedColors = micros.map((micro) => {
      const { area_id: areaId, id, initials, map_coords, farmId } = micro as any;
      const founded = flatMicros.find(
        (f) => f.currentMicro.id === micro.id || f.routes.some((e: any) => e.id === micro.id)
      );
      if (founded)
        return {
          areaId,
          id,
          microInitials: initials,
          initials: founded?.initials,
          map_coords,
          bgColor: founded.bgColor,
          visibility: "FOCUS",
          farmId,
          capacityRate: founded.capacityRate,
          amountAnimals: founded.amountAnimals,
          areaInitials: founded.areaInitials,
        };
      else
        return {
          areaId,
          id,
          microInitials: initials,
          map_coords,
          bgColor: Constants.PADDOCKTIME_COLORS.WHITE,
          visibility: "UNFOCUS",
          farmId,
        };
    });
    setMicroPolygons(() => microParsedColors as ICustomMicroAreas);
    setBatchesPolygons(() =>
      flatMicros.map((micro) => ({
        id: micro.id,
        coords: getCenterCoords(micro.currentMicro.map_coords),
        areaId: micro.area.id,
        farmId: micro.farmId,
        initials: micro.currentMicro.initials,
        visible: areaFilter && micro.farmId === farmFilter.id ? micro.area.id === areaFilter.id : true,
      }))
    );
  }

  async function handleMicroAreas(farms: IFarms) {
    const microParsed = await Promise.all(
      farms.map(async (farm) => {
        const { data, status } = await axios.get("/micro-areas", { params: { farm_id: farm.id } });
        const response = data.map((data: ICustomMicroArea) => ({ ...data, farmId: farm.id }));

        return status === Constants.STATUS_CODE_OK ? response : [];
      })
    );
    handleBatches(farms, microParsed.flat());
  }

  function handleAreasAPI(areas: IAreas, farmId: string) {
    return areas.map(
      ({ id, initials, map_coords, name }) =>
        ({ id, initials, coords: map_coords, bgColor: getRandomColor(), name, farmId } as ICustomArea)
    ) as ICustomAreas;
  }

  function handleAreas(farms: IFarms) {
    farms.forEach((farm) => {
      axios
        .get("/areas", { params: { farm_id: farm.id } })
        .then(({ data }) => {
          setAreasPolygons((prev) => [...prev, ...handleAreasAPI(data, farm.id as string)]);
        })
        .catch((error) => console.log(error));
    });
  }

  function handleFarmsAPI(farms: IFarms) {
    setfarmsData(farms);
    const parsedFarms = farms.map(
      ({ id, initials, name, map_coords }) =>
        ({
          id,
          initials,
          name,
          coords: map_coords,
          bgColor: getRandomColor(),
        } as ICustomFarm)
    );
    handleAreas(farms);
    handleMicroAreas(farms);
    setFarmsPolygons(() => parsedFarms);
    const [current] = parsedFarms;
    if (current && !farmFilter) setFarmFilter(current);
  }

  function handleColorArea(area: ICustomArea) {
    if (areaFilter && area.farmId === farmFilter.id && area.id !== areaFilter.id)
      return Constants.PADDOCKTIME_COLORS.WHITE;
    return area.bgColor;
  }

  function handleColorMicroArea(microArea: ICustomMicroArea) {
    if (microArea.farmId !== farmFilter.id) return microArea.bgColor;
    if (areaFilter && microArea.areaId !== areaFilter.id) return Constants.PADDOCKTIME_COLORS.WHITE;
    return microArea.bgColor;
  }

  function handleBorderColor(farmId: string, areaId: string) {
    if (farmId !== farmFilter.id) return Constants.PADDOCKTIME_COLORS.WHITE;
    if (areaFilter && areaId !== areaFilter.id) return Constants.LEAFLET_UNFOCUS.color;
    return Constants.PADDOCKTIME_COLORS.WHITE;
  }

  function handleBGOppacity(farmId: string, areaId: string) {
    if (farmId !== farmFilter.id) return 0.55;
    if (areaFilter && areaId !== areaFilter.id) return 0.1;
    return 0.55;
  }

  function overrideCentermap() {
    setStateOfAnimation("start");
    if (flytoBoundsRef && flytoBoundsRef.current && farmFilter) flytoBoundsRef.current?.flyToBounds(farmFilter?.coords);
  }

  function handleFocusCenter() {
    const coords: ICoords = areaFilter ? areaFilter.coords : farmFilter.coords;
    if (coords && flytoBoundsRef.current?.canZoomAnimate(coords, 50)) {
      flytoBoundsRef.current.flyToBounds(coords);
    } else setStateOfAnimation("end");
  }

  useEffect(() => {
    if (farmFilter && flytoBoundsRef) handleFocusCenter();
    setCurrentFarm(farmFilter);
  }, [farmFilter, flytoBoundsRef]);

  useEffect(() => {
    if (farmFilter) {
      let current: ICustomArea | undefined = areaFilter;
      setCurrentArea(current);
      setBatchesPolygons((batches) =>
        batches.map((batch) => ({
          ...batch,
          visible: current && current?.farmId === batch.farmId ? current.id === batch.areaId : true,
        }))
      );
      handleFocusCenter();
    }
  }, [areaFilter, farmFilter]);

  useEffect(() => {
    axios
      .get("/farms/user")
      .then(({ data }) => {
        // Isso garante que não haja o reload dos dados
        if (data.length !== farmsData.length) {
          handleFarmsAPI(data);
        }
      })
      .catch((error) => console.log(error));
    document.title = Constants.TITLE_PADDOCKS_MAP_ROUTES;
    setHideMicroAreaFilter(true);
    setHideBatchFilter(true);

    axios
      .get("/micro-areas/reasons")
      .then(({ data }) => {
        setReasons(
          () =>
            new Map(
              data.map((value: any) => {
                return [value.id, value.description];
              })
            ) as Map<number, string>
        );
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get("/micro-areas/unavailable")
      .then(({ data }) => {
        setMapMicroAreas(
          new Map(
            data.map((value: any) => {
              return [value.micro_area_id, reasons.get(value.micro_area_reason_id)];
            })
          ) as Map<number, string>
        );
      })
      .catch((error) => console.log(error));
  }, [reasons]);

  return (
    <div className="defaultmap__container">
      <FlyToBounds ref={flytoBoundsRef} onStateAnimation={(state) => setStateOfAnimation(state)}>
        {farmsPolygons.map((farm, key) => (
          <AnimatedZoom key={key} coordsToBoundZoom={farm.coords} updateZoomVisualization={stateOfAnimation}>
            <ContainerZoom1X>
              <LabelFarm1X
                initials={`${farm.initials}${
                  currentArea && currentArea.farmId === farm.id ? `-${currentArea.initials}` : ""
                }`}
                position={getCenterCoords(farm.coords)}
                key={farm.initials}
              />
            </ContainerZoom1X>

            <ContainerZoom2X>
              <Polygon
                positions={
                  currentArea && currentArea.farmId === farm.id
                    ? (currentArea.coords as ICoords)
                    : (farm.coords as ICoords)
                }
                fillColor={defaultBGFarm}
                fillOpacity={0.55}
                color="#FFFFFF"
                key={farm.id}
                fill={true}
                weight={2}
                noClip
              />
            </ContainerZoom2X>

            <ContainerZoom3X>
              <PolylineFarm path={farm.coords} />
              <BatchesIcons batches={batchesPolygons.filter((f) => f.farmId === farm.id)} />
              {areasPolygons
                .filter((f) => f.farmId === farm.id)
                .map((areas) => {
                  return (
                    <Polygon
                      key={areas.id}
                      pathOptions={{
                        fillOpacity: handleBGOppacity(areas.farmId, areas.id),
                        color: handleBorderColor(areas.farmId, areas.id),
                        fillColor: handleColorArea(areas),
                      }}
                      positions={areas.coords || []}
                      fill={true}
                      weight={2}
                    />
                  );
                })}
            </ContainerZoom3X>

            <ContainerZoom4X>
              <PolylineFarm path={farm.coords} />
              <BatchesIcons batches={batchesPolygons.filter((f) => f.farmId === farm.id)} />
              {microPolygons
                .filter((f) => f.farmId === farm.id)
                .map((paddock) => {
                  return (
                    <Polygon
                      pathOptions={{
                        fill: true,
                        fillOpacity: handleBGOppacity(paddock.farmId, paddock.areaId),
                        color: handleBorderColor(paddock.farmId, paddock.areaId),
                        fillColor: handleColorMicroArea(paddock),
                      }}
                      positions={paddock.map_coords}
                      fillOpacity={0.55}
                      key={paddock.id}
                      weight={2}
                    >
                      <Tooltip className="customToolTip__container" sticky>
                        <SpecsMouseOver
                          className={"havebatch"}
                          microAreaLabel={`${currentFarm?.initials}-${
                            paddock.areaInitials ||
                            areasPolygons.filter((area) => area.id === paddock.areaId)[0].initials
                          }-${paddock.microInitials}`}
                          type={"batch"}
                        />
                        {paddock.amountAnimals ? (
                          <BatchesSpecsDetails
                            batchLabel={`${currentFarm?.initials}-${paddock.areaInitials}-${paddock.initials}`}
                            qtdLabel={`${paddock.amountAnimals} animais`}
                          />
                        ) : (
                          <BatchesAvailableDetails reason={mapMicroAreas.get(Number(paddock.id)) || "Não utilizado"} />
                        )}
                      </Tooltip>
                    </Polygon>
                  );
                })}
            </ContainerZoom4X>
          </AnimatedZoom>
        ))}
      </FlyToBounds>
      <CustomControls overrideCentermap={overrideCentermap} />
      <section className="footerlegend__container">
        <h6>Rota de pastejo</h6>
        <article>
          <div className="footerlegend__content">
            <LegendRounded bgColor={Constants.GRAZINGMAP_COLORS.CYAN} />
            <LegendRounded bgColor={Constants.GRAZINGMAP_COLORS.GREEN} />
            <LegendRounded bgColor={Constants.GRAZINGMAP_COLORS.ORANGE} />
            <LegendRounded bgColor={Constants.GRAZINGMAP_COLORS.RED} />
            <LegendRounded bgColor={Constants.GRAZINGMAP_COLORS.PURPLE} />
            <LegendRounded bgColor={Constants.GRAZINGMAP_COLORS.BLUE} />
          </div>
          <strong>Pasto atual do lote</strong>
        </article>
      </section>
    </div>
  );
}
