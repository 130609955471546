import { CSSProperties, useRef, useState } from 'react'
import useEventListener from '@use-it/event-listener'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import './styles.css'
import { TextInputWithLabel } from '../../../components/Inputs/TextInputs/TextInputWithLabel/Index'
import { Loading } from '../../../components/Loading/Index'
import { useAuth } from '../../../hooks/useAuth'
import { ButtonOnlyTitle } from '../../../components/Buttons/ButtonOnlyTitle/Index'
import { AlertModal } from '../../../components/Modal/Alert/Index'
import { SimpleButtonClose } from '../../../components/Modal/Contents/SimpleButtonClose/Index'
import { useYupOnlyVerify } from '../../../hooks/useYupValidate'
import Constants from '../../../constants/Index'

const schemaLogin = Yup.object().shape({
  phone: Yup.string()
    .trim('Diga seu Telefone')
    .min(15, '*Telefone informado inválido!')
    .max(15, '*Telefone informado inválido!')
    .required('*Campo obrigatório não informado.'),
  password: Yup.string()
    .trim('Diga sua Senha')
    .required('*Campo obrigatório não informado.'),
})

const schemaPassword = Yup.object().shape({
  password: Yup.string().matches(/[0-9]{6,}/, ' '),
})

export type UserProps = {
  phone: string
  password: string
}

export function Login() {
  const { signIn } = useAuth()

  let [searchParams] = useSearchParams()

  let redirectURL = searchParams.get('redirect')

  const navigate = useNavigate()

  // Isso tem o formulário e as propriedades
  const formRef = useRef<HTMLFormElement | null>(null)

  // Isso diz se o usuário é válido ou não
  const [invalidUser, setInvalidUser] = useState<boolean>(false)

  // Isso diz a página quando mostrar o loading
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<any>('')

  async function handleSubmit() {
    if (formRef) {
      // ao tentar logar redefinimos os erros para null
      formRef.current?.setErrors({})
      // isso mostra a mensagem de erro se o usuário colocar senha inválida
      setInvalidUser(false)
      // Isso é o formulário
      const formData = formRef.current?.getData()
      const errors = {} as any
      try {
        // Isso valida o formulário
        await schemaLogin.validate(formData, { abortEarly: false })
        formData['phone'] = formData['phone'].replace(/[^0-9]+/g, '')

        // Isso abre o loading
        setIsLoading(true)
        // isso chama a função de sigin no contexto
        const response = await signIn(formData)
        // Fecha loading
        setIsLoading(false)
        setErrorMessage(response.message)
        // Se a APi retorna 401 as credenciais inseridas estão incorretas
        localStorage.setItem('token', response.token || '')
        if (response.statusCode === 401) {
          setInvalidUser(true)
        } else {
          // Se o usuário nao confirmou e-mail redirecionamos pra /bemvindo
          if (response.changePassword) navigate(Constants.RESET_PASSWORD)
          else {
            //Se o usuário vier por redirecionamento voltamos pra tela anterior
            if (redirectURL) navigate(redirectURL)
            else navigate(Constants.PADDOCKS_MAP)
          }
        }
      } catch (err) {
        // Se chegamos aqui existem erros no form
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((e) => {
            errors[e.path as string] = e.message
          })
        }
        setIsLoading(false)
      } finally {
        if (formRef && formRef.current) {
          formRef.current.setErrors(errors)
        }
      }
    }
  }

  useEventListener('keyup', async (event: KeyboardEvent) => {
    const { key } = event
    if (key === 'Enter') await handleSubmit()
  })

  const contentStyleAlert = {
    width: '440px',
    height: '218px',
    borderRadius: '20px',
  } as CSSProperties

  const [visibleAlert, setVisibleAlert] = useState<boolean>(false)

  async function handleChangesPassword() {
    const formData = formRef.current?.getData()
    const error = await useYupOnlyVerify(formRef, formData, schemaPassword)
    if (formRef && formRef.current && error) {
      formRef.current.setErrors(error)
    }
  }

  return (
    <section className="login__container">
      <Loading visible={isLoading} />
      <h1 className="login__container--title">Faça seu login.</h1>

      <Form ref={formRef as any} onSubmit={handleSubmit}>
        <div className="login__form--input">
          <TextInputWithLabel
            type="tel"
            state={invalidUser ? 'Error' : 'Normal'}
            label="Telefone"
            name="phone"
            mask={'(99) 99999-9999'}
            id="phone"
          />
        </div>

        <div
          className="login__form--input"
          onBlurCapture={handleChangesPassword}
        >
          <TextInputWithLabel
            state={invalidUser ? 'Error' : 'Normal'}
            type="password"
            label="Senha"
            name="password"
            maxLength={32}
            max={32}
            regex={/\d+/g}
            onAnywhereChanges={handleChangesPassword}
            id="password"
          />
        </div>

        {/* Se as credenciais inseridas não forem válidas mostramos a msg de erro */}
        {invalidUser && (
          <strong className="login__container--error" id="erro">
            {errorMessage['msg']}
          </strong>
        )}

        <button
          type="button"
          className="login__form--forgot"
          onClick={() => setVisibleAlert(true)}
          id="esqueceuSuaSenha"
        >
          Esqueceu sua senha?
        </button>

        <AlertModal
          contentStyle={contentStyleAlert}
          visible={visibleAlert}
          animation="slideUp"
        >
          <SimpleButtonClose onClick={() => setVisibleAlert(false)} />
          <section className="alert-modal--section-forget">
            <h2 id="titulo">Esqueceu sua senha?</h2>
            <p id="mensagem">
              Entre em contato com o seu Administrador da fazenda.
            </p>
            <div className="alert-modal--section-buttons-voltar ">
              <button
                onClick={() => setVisibleAlert(false)}
                id="bt_voltar"
                type="button"
              >
                <strong>VOLTAR</strong>
              </button>
            </div>
          </section>
        </AlertModal>

        <div style={{ width: 193 }}>
          <ButtonOnlyTitle
            title="Continuar"
            theme="success"
            onClick={handleSubmit}
            id="bt_continuar"
          />
        </div>
      </Form>
    </section>
  )
}
