import { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "../../../assets/svg/icons/plus.svg";
import { useEditOnboarding } from "../../../hooks/useEditOnboarding";
import TableVisualizationRow from "./TableVisualizationRow/Index";
import "./styles.css"

interface TableProductsProps {
  listTable: IProducts[]
}

export default function TableProducts({ listTable }: TableProductsProps) {

  const {
    setOptionForm,
  } = useEditOnboarding();

  return (
    <>
      {listTable && listTable.length > 0 ?
        <>
          <section className="tableColumn">
            <div>Classificação</div>
            <div>Nome comercial</div>
            <div>Fabricante</div>
            <div />
          </section>

          <section className="tableViewRows">
            {listTable.map(product =>
              <TableVisualizationRow product={product}/>
            )}
          </section>
        </>
        :
        <div className="emptyTable_container">
          <span>cadastre seu primeiro produto</span>
          <div
            className="addProduct_button"
            onClick={() => {
              setOptionForm("create_products")
            }}
          >
            <PlusIcon />
            <span>cadastrar produto</span>
          </div>
        </div>
      }
    </>
  )
}