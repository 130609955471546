import { MutableRefObject, useEffect, useState } from "react";
import { ReactComponent as EyeOpen } from "../../../../../assets/svg/icons/eyeClosed.svg";
import { ReactComponent as EyeClosed } from "../../../../../assets/svg/icons/eye.svg";
import AnimateHeight from "react-animate-height";
import { IAreas } from "../../../../../@types/API/IArea";
import { Area } from "./Area";
import "./styles.css";
import { useOnboarding } from "../../../../../hooks/useOnboarding";
import { Scope } from "@unform/core";
import axios from "../../../../../services/axios";

type FarmProps = {
  handleChangesMicroArea: () => void;
  formRef: MutableRefObject<HTMLFormElement | undefined>;
  farm: any;
  keyFarm: number;
  initValues: any;
  onlyRead?: boolean;
  savePayload: () => void;
  errorMap: Map<string, string>;
  onBoarding: boolean;
  errorInfoAcreage: string;
};

export function Farm({
  farm,
  formRef,
  keyFarm,
  onlyRead = false,
  handleChangesMicroArea,
  initValues,
  savePayload,
  errorMap,
  errorInfoAcreage,
  onBoarding = false
}: FarmProps) {
  const [visibleFarm, setVisibleFarm] = useState<"auto" | 0>("auto");

  const [areas, setAreas] = useState<any>([]);
  const { payload } = useOnboarding();

  const handleVisibleFarm = async () => {
    setVisibleFarm((prev) => (prev === 0 ? "auto" : 0));
  };

  useEffect(() => {
    if (!onlyRead && payload && payload.areas) {
      var listAreas = payload.areas;
      setAreas(listAreas.filter((area) => area.farm_id == farm.uuid) as IAreas);
    } else if (initValues) {
      axios
        .get("/areas", { params: { farm_id: farm.id } })
        .then(({data})=>{
          const distinctArea = data
          .map((obj: any) => {
            var areaForm = {
              ...obj,
              map_coords: {
                coords: obj.map_coords
              }
            }
            return areaForm
          });
          setAreas(distinctArea)
        })
    }
  }, [initValues]);

  return (
    <>
      <div className="area__title--container">
        <h2>{farm.name}</h2>
        <button
          type="button"
          onClick={handleVisibleFarm}
          style={{ marginTop: 40 }}
        >
          {visibleFarm ? <EyeOpen /> : <EyeClosed />}
        </button>
      </div>

      <AnimateHeight height={visibleFarm}>
        {areas.map((area: any, key: number) => (
          <Scope key={key} path={`areas[${key}]`}>
            <Area
              formRef={formRef}
              farm={farm}
              area={area}
              keyFarm={keyFarm}
              keyArea={key}
              handleChangesMicroArea={handleChangesMicroArea}
              onlyRead={onlyRead}
              initValues={initValues}
              savePayload={savePayload}
              errorMap={errorMap}
              onBoarding={onBoarding} 
              errorInfoAcreage={errorInfoAcreage}
            />
            <div className="microarea__divider"/>
          </Scope>
        ))}
      </AnimateHeight>

    </>
  );
}
