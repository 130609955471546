import "./styles.css";

import { ReactComponent as PenEdit } from "../../../../../../assets/svg/icons/penEdit.svg";

type TitleTableProps = {
  title: string;
  handleClick: () => void;
  onlyRead?: boolean,
};

export function TitleTable({ title, handleClick, onlyRead = false }: TitleTableProps) {
  return (
    <section className="containerTable_container--title">
      <h2>{`Evolução de ${title === "MALE" ? "Macho" : "Fêmea"}`}</h2>
      {!onlyRead && 
        <button onClick={handleClick}>
          <PenEdit />
        </button>
      }
    </section>
  );
}
