import { HTMLAttributes, MouseEvent, ReactNode } from "react";
import { IToolsMode } from "../../../../@types/GoogleMaps/ToolsMode";
import { useGoogleMaps } from "../../../../hooks/useGoogleMaps";
import "./styles.css";

interface ToolOptionProps extends HTMLAttributes<HTMLButtonElement> {
  theme: "normal" | "success";
  noSelectable?: boolean;
  defaultMode: IToolsMode;
  children: ReactNode;
  title?: string;
}

export function ToolOption({
  noSelectable = false,
  defaultMode,
  children,
  theme,
  title,
  ...rest
}: ToolOptionProps) {
  const { toolMode, setToolMode } = useGoogleMaps();

  function handleOnclick(event: MouseEvent<HTMLButtonElement>) {
    !noSelectable && setToolMode(defaultMode);
    rest.onClick && rest.onClick(event);
  }

  return (
    <button
      className={`
        toolMode__option toolMode__option-${theme} ${
        toolMode === defaultMode && !noSelectable ? "isselected" : ""
      }
      `}
      onClick={handleOnclick}
      type="button"
    >
      {children}
      <span className="tooltiptext">{title}</span>
    </button>
  );
}
