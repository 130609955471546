import { ReactNode } from "react";
import Constants from "../../../../../../constants/Index";
import "./styles.css";

type TableEditContainerProps = {
  children: ReactNode | ReactNode[];
};

export function TableEditContainer({ children }: TableEditContainerProps) {
  return (
    <table className="tableEdit__container">
      <thead>
        <tr>
          <th scope="col">categoria</th>
          <th scope="col">idade</th>
          {Object.values(Constants.MONTHS_SHORT_NAME).map((month) => (
            <th scope="col" key={month}>
              {month}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}
