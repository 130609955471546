import React, { useEffect, useRef, useState } from "react";
import { useTaskCreation } from "../../../hooks/useTaskCreation";
import { IOptionsProps } from "../../../@types/reactSelect/IOption";
import {
  DropDownFilterRefProps,
  DropDownSelect,
} from "../../Filters/DropDown/Index";
import axios from "../../../services/axios";
import "./style.css";
import { MessageBalloon } from "../../MessageBalloon/Index";
import { CounterInputEdit } from "../../EditOnboarding/components/Input/CounterInput/Index";
import { useForm } from "react-hook-form";

interface PlantingProps {}

interface IFodderSpecie {
  id: number;
  genus_name: string;
  fodder_types: IFodderType[];
}

interface IFodderType {
  fodder_species: string;
  fodder_species_id: number;
}

export const Planting: React.FC<PlantingProps> = () => {
  const {
    payload,
    setPayload,
    setAllowTaskCreation,
    responsibleId,
    microAreaId,
  } = useTaskCreation();

  const { control } = useForm();

  const [plantingTypes, setPlantingTypes] = useState<IOptionsProps>([]);
  const [fodderSpecies, setFodderSpecies] = useState<IOptionsProps>([]);
  const [fodderTypes, setFodderTypes] = useState<IOptionsProps>([]);
  const [seedTypes, setSeedTypes] = useState<IOptionsProps>([]);

  const [showSpecies, setShowSpecies] = useState<boolean>(false);
  const [showTypes, setShowTypes] = useState<boolean>(false);
  const [showSeedTypes, setShowSeedTypes] = useState<boolean>(false);
  const [showCounters, setShowCounters] = useState<boolean>(false);

  const [vcPercentage, setVcPercentage] = useState<number>(0);
  const [seedWeight, setSeedWeight] = useState<number>(0);

  const [allFodderTypes, setAllFodderTypes] = useState<IFodderSpecie[]>([]);

  const [changes, setChanges] = useState<any>();

  const fodderTypeRef = useRef<DropDownFilterRefProps>();

  useEffect(() => {
    getPlantingTypes();
    getPlantingSpecies();
    getSeedTypes();
  }, []);

  const getPlantingTypes = async () => {
    await axios
      .get("/tasks/planting/types")
      .then((response) => {
        setPlantingTypes(
          response.data.map((type: { name: string; id: number }) => ({
            label: type.name,
            value: type.id,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPlantingSpecies = async () => {
    await axios
      .get("/tasks/pasture-evaluation/fodder/species")
      .then((response) => {
        const fodderTypes = response.data.fodder_genus;
        setAllFodderTypes(fodderTypes);

        setFodderSpecies(
          fodderTypes.map((type: { genus_name: string; id: number }) => ({
            label: type.genus_name,
            value: type.id,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSeedTypes = async () => {
    await axios
      .get("/tasks/planting/seed-types")
      .then((response) => {
        const seedTypes = response.data;

        setSeedTypes(
          seedTypes.map((type: { name: string; id: number }) => ({
            label: type.name,
            value: type.id,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePlantingTypeChoice = (plantingTypeId: any) => {
    setChanges((prev: any) => ({ ...prev, planting_type_id: plantingTypeId }));
    setShowSpecies(true);
  };

  const handleFodderSpecieChoice = (fodderSpecieId: any) => {
    if (fodderTypeRef && fodderTypeRef.current)
      fodderTypeRef.current.resetDropDown();
    const fodderSpecie = allFodderTypes.find(
      (type) => type.id === fodderSpecieId
    );
    setChanges((prev: any) => ({ ...prev, fodder_species_id: fodderSpecieId }));

    if (!fodderSpecie) return;
    const fodderTypeOptions = fodderSpecie.fodder_types.map(
      (type: IFodderType) => ({
        label: type.fodder_species,
        value: type.fodder_species_id,
      })
    );
    setFodderTypes(fodderTypeOptions);

    setShowTypes(true);
  };

  const handleFodderTypeChange = (fodderTypeId: any) => {
    // TODO ALTERAR
    // setChanges((prev: any) => ({ ...prev, seed_type_id: fodderTypeId }));
    setShowSeedTypes(true);
  };

  const handleSeedTypeChange = (fodderTypeId: any) => {
    setChanges((prev: any) => ({ ...prev, seed_type_id: fodderTypeId }));
    setShowSeedTypes(true);
    setShowCounters(true);
  };

  const handleVCPercentageChange = (value: any) => {
    if (+value > 100) setVcPercentage(100);
    else setVcPercentage(+value);
  };

  const handleSeedWeightChange = (value: any) => {
    setSeedWeight(+value);
    setChanges((prev: any) => ({ ...prev, seed_quantity_kg: value }));
  };

  useEffect(() => {
    if (seedWeight === 0 || vcPercentage === 0 || vcPercentage > 100)
      return setAllowTaskCreation(false);

    const reqChanges = {
      ...changes,
      micro_area_id: microAreaId,
      assigned_user_id: responsibleId,
    };

    const reqPayload = {
      ...payload,
      changes: [reqChanges],
    };

    setPayload(reqPayload);

    setAllowTaskCreation(true);
  }, [seedWeight, vcPercentage]);

  useEffect(() => {
    if (vcPercentage > 100) return setVcPercentage(100);

    setChanges((prev: any) => ({ ...prev, vc_percentage: vcPercentage }));
  }, [vcPercentage]);

  return (
    <>
      <div className="seed-dropdown-container">
        <DropDownSelect
          options={plantingTypes}
          placeholder="Selecionar o tipo de plantio..."
          onChange={(ev) => handlePlantingTypeChoice(ev)}
        />
      </div>
      {showSpecies && (
        <>
          <div className="seed-dropdown-container">
            <DropDownSelect
              options={fodderSpecies}
              placeholder="Selecionar gênero..."
              onChange={(ev) => handleFodderSpecieChoice(ev)}
            />
          </div>
          {showTypes && (
            <>
              <div className="seed-dropdown-container">
                <DropDownSelect
                  ref={fodderTypeRef}
                  options={fodderTypes}
                  placeholder="Selecionar capim..."
                  onChange={(ev) => handleFodderTypeChange(ev)}
                />
              </div>
              {showSeedTypes && (
                <>
                  <div className="seed-dropdown-container">
                    <DropDownSelect
                      options={seedTypes}
                      placeholder="Selecionar tipo de sementes..."
                      onChange={(ev) => handleSeedTypeChange(ev)}
                    />
                  </div>
                  {showCounters && (
                    <>
                      <div className="balloon-container">
                        <MessageBalloon text="Qual o valor cultural (VC) da semente?" />
                      </div>
                      <CounterInputEdit
                        defaultValue={0}
                        max={100}
                        control={control}
                        isInline
                        label="%"
                        onChangevalue={(ev) => handleVCPercentageChange(ev)}
                      />
                      <div className="balloon-container">
                        <MessageBalloon text="Qual a quantidade de semente em quilos por hectare?" />
                      </div>
                      <div className="seed-dropdown-container">
                        <CounterInputEdit
                          defaultValue={0}
                          max={99999999}
                          control={control}
                          isInline
                          label="kg/ha"
                          onChangevalue={(ev) => handleSeedWeightChange(ev)}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
