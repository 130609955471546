import "./styles.css";
import Logo from "../../../assets/png/logo.png";

export function Footer() {
  return (
    <footer className="footer">
      <img src={Logo} alt="logo pastejo" />  
    </footer>
  );
}
