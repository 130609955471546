import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";
import { ButtonYesOrNo } from "../../../Buttons/ButtonYesOrNo";

interface InfoSplitBatchProps {
  task: ITaskSplitBatch | undefined;
}


export default function InfoSplitBatch(task: InfoSplitBatchProps) {
  const [taskSplitBatch, setTaskSplitBatch] = useState<ITaskSplitBatch>();
  useEffect(() => {
    setTaskSplitBatch(task.task)
  }, []);


  return (
    <>
      <div >
        <span>No lote</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <AnimalsIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskSplitBatch?.source_batch_initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>


      <div className="containerQuestion">
        <MessageBalloon text={`Deseja criar um novo lote?`} pointed />
        <ButtonYesOrNo onlyResult optionSelected={taskSplitBatch?.inventoryBatch_split_type == "Novo lote" ? "yes" : "no"} />
      </div>

      {taskSplitBatch?.inventoryBatch_split_type == "Novo lote" ?
        <MessageBalloon text={`O novo lote se chamará ${taskSplitBatch?.destination_batch_initials}`} pointed />
        :
        <div >
          <span>Para o lote</span>
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            <AnimalsIcon width={30} className="iconInitialTaskInfo" />
            <div className="labelInitialTaskInfo">
              <span>{taskSplitBatch?.destination_batch_initials}</span>
              <PointIcon width={15} />
            </div>
          </div>
        </div>
      }

      {taskSplitBatch?.categories.map((category, index) => (
        <div key={index}>
          <div className="lot__title--category" style={{ marginTop: 0 }}>{category.category_description.name}</div>
          <div className="lot__subtitle--category">{category.category_description.age_description}</div>
          <CounterInputEdit isDisabled defaultValue={category.quantity} control={undefined} isInline />
        </div>
      ))}

      {taskSplitBatch?.inventoryBatch_split_type == "Novo lote" ?
        <MessageBalloon text={`Nesta rota estão os pastos ${taskSplitBatch?.destination_micro_area_route
          .map(el => el.initials).join(", ")}.`} pointed />
        : <></>}
    </>
  )
}