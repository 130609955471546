import './styles.css'
import { useEffect, useRef, useState } from 'react'
import { FooterControlls } from '../../../../components/Onboarding/Template/FooterControlls/Index'
import { useOnboarding } from '../../../../hooks/useOnboarding'
import { Form } from '@unform/web'
import { Scope } from '@unform/core'
import { Farm } from '../../../../components/Onboarding/Register/CreateMicroArea/New/Farm'
import { IFarms } from '../../../../@types/API/IFarm'
import * as yup from 'yup'
import Constants from '../../../../constants/Index'
import { generateuuid } from '../../../../utils/uuid/generate'
import axios from '../../../../services/axios'
import { EditValues } from '../../../../hooks/EditValues'
import { TextInput } from '../../../../components/Inputs/TextInputs/TextInput/Index'

interface CreateMicroAreasProps {
  onlyRead?: boolean
  onBoarding?: boolean
}

yup.addMethod(yup.array, 'unique', function (mapper = (a: any) => a) {
  return this.test('unique', function (list: any, object) {
    let index = -1
    list.forEach((l: any, i: number) => {
      const lenght = list.filter((a: any) => a.initials === l.initials).length
      if (lenght > 1) index = i
    })
    if (index > -1)
      return this.createError({
        path: `${object.path}[${index}].initials`,
        message: Constants.MESSAGE_UNIQUE_INITIALS_MICROAREA,
      })
    return true
  })
})

const MicroAreaForm = yup.object().shape({
  farms: yup.array().of(
    yup.object().shape({
      areas: yup.array().of(
        yup.object().shape({
          microareas: yup
            .array()
            .of(
              yup.object().shape({
                area_id: yup.string(),
                initials: yup
                  .string()
                  .matches(/^[^\s]+(\s+[^\s]+)*$/, 'Esta sigla não é válida.')
                  .required('SIGLA é necessário.'),
                acreage: yup
                  .string()
                  .min(1, 'Quantidade de hectares informado inválido!')
                  .max(999, 'Quantidade de hectares informado inválido!')
                  .required('Campo obrigatório não informado.'),
                map_coords: yup
                  .object()
                  .nullable()
                  .required(Constants.MESSAGE_MAPPING_MICROAREA_NOT_FOUND)
                  .shape({
                    coords: yup
                      .array()
                      .min(1, Constants.MESSAGE_MAPPING_MICROAREA_NOT_FOUND)
                      .required(Constants.MESSAGE_MAPPING_MICROAREA_NOT_FOUND),
                  }),
              }),
            ) //@ts-ignore
            .unique((a) => a.acreage),
        }),
      ),
    }),
  ),
})

export function CreateMicroAreas({
  onlyRead = false,
  onBoarding = false,
}: CreateMicroAreasProps) {
  const mainRef = useRef<HTMLElement>(null)
  const [farms, setFarms] = useState<IFarms>([])
  const [initData, setInitData] = useState([])
  const formRef = useRef<HTMLFormElement>()
  const { setBlockNextStep, setPayload, payload } = useOnboarding()
  const [errorMap, setErrorMap] = useState<Map<string, string>>(new Map())
  const { setFormRefValue, setBlockSave, refreshValues, permissionMaster } =
    EditValues()
  const [errorInfoAcreage, setErrorInfoAcreage] = useState('')

  async function handleSubmit() {
    if (await validateForm()) {
      savePayload()
      return true
    }
    return false
  }

  async function savePayload() {
    const listMicroAreas: any[] = []
    const formMicro = formRef.current?.getData().farms
    formMicro.map((farm: any) =>
      farm.areas.map((area: any) =>
        area.microareas.map((micro: any) => {
          micro.initials
            ? listMicroAreas.push({
                ...micro,
                map_coords: micro.map_coords,
                initials: micro.initials ? micro.initials.split('-')[2] : '',
                area_id: micro.area_id,
                uuid: micro.uuid ? micro.uuid : generateuuid(),
              })
            : {}
        }),
      ),
    )
    setPayload((payload) => {
      const response = { ...payload, micro_areas: listMicroAreas }
      localStorage.setItem(
        Constants.LOCALSTORAGE_PAYLOAD,
        JSON.stringify(response),
      )
      return response
    })
  }

  useEffect(() => {
    setInitData([])
    if (!onlyRead && payload && payload.farms) {
      setFarms(payload.farms as IFarms)
    } else if (onlyRead) {
      axios.get('/micro-areas/user').then(({ data }) => {
        const microAreas = data.map((microArea: any) => {
          return {
            acreage: microArea.acreage.toFixed(2),
            area: microArea.area,
            farm: microArea.farm,
            id: microArea.id,
            initials: microArea.initials,
            map_coords: microArea.map_coords,
            micro_area_route: microArea.micro_area_route,
          }
        })
        setInitData(microAreas)
      })
      axios.get('/farms/user').then(({ data }) => {
        setFarms(data)
      })
    }
  }, [, refreshValues])

  async function validateForm() {
    const formData = formRef.current?.getData()
    if (formData) {
      var listMap = new Map()
      const newForm = formData.farms.map((farm: any) => {
        var newAreas = {
          areas: farm.areas.map((area: any) => {
            var newMicroAreas = {
              microareas: area?.microareas?.filter(
                (microarea: any) => microarea.initials,
              ),
              areaAvaliable: area.areaAvailable,
            }
            return newMicroAreas
          }),
        }
        return newAreas
      })

      formRef.current?.setErrors({})
      const errors = {} as any
      let isValide = false
      if (formData) {
        try {
          await MicroAreaForm.validate(
            { farms: newForm },
            { abortEarly: false },
          )
          isValide = true
        } catch (err) {
          setBlockNextStep(true)
          if (err instanceof yup.ValidationError) {
            err.inner.forEach((e) => {
              errors[e.path as string] = e.message
              if ((e.path as string).includes('map_coords.coords')) {
                listMap.set(e.path as string, e.message)
              }
            })
          }
          setErrorMap(listMap)
        } finally {
          if (formRef && formRef.current) {
            formRef.current.setErrors(errors)
          }
          return isValide
        }
      }
      return true
    }
    return false
  }

  async function handleChangesMicroArea() {
    setErrorInfoAcreage('')
    var isValid = await validateForm()
    const formData = formRef.current?.getData()
    formData?.farms
      ?.map((farm: any) => farm.areas)
      .flat()
      .map((area: any) => Number(area.areaAvailable))
      .forEach((acreage: any) => {
        console.log('o acreate', acreage)
        if (acreage < 0) {
          isValid = false
          setErrorInfoAcreage(Constants.MESSAGE_AREA_UNVAILABLE_ACREAGE)
        }
      })

    if (!onlyRead) {
      setBlockNextStep(!isValid)
    } else {
      setBlockSave(!isValid)
      setFormRefValue(formRef as any)
    }
  }

  return (
    <>
      <main ref={mainRef as any}>
        <section className="createArea__container">
          <h1 id="pageStart">Pasto</h1>
          <Form ref={formRef as any} onSubmit={handleSubmit}>
            <TextInput hidden={true} value={'microAreas'} name="form" />
            {farms.map((farm, key) => (
              <Scope key={key} path={`farms[${key}]`}>
                <Farm
                  farm={farm}
                  formRef={formRef}
                  keyFarm={key}
                  handleChangesMicroArea={handleChangesMicroArea}
                  onlyRead={onlyRead}
                  initValues={initData}
                  savePayload={savePayload}
                  errorMap={errorMap}
                  errorInfoAcreage={errorInfoAcreage}
                  onBoarding={!(onBoarding || permissionMaster)}
                />
              </Scope>
            ))}
          </Form>
        </section>
      </main>
      {!onlyRead && (
        <footer>
          <FooterControlls
            nextStepEvent={async () => {
              return await handleSubmit()
            }}
          />
        </footer>
      )}
    </>
  )
}
