import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";

interface InfoAnimalTransferProps {
  task: ITaskAnimalTransfer | undefined;
}


export default function InfoAnimalTransfer(task: InfoAnimalTransferProps) {
  const [taskAnimalTransfer, setTaskAnimalTransfer] = useState<ITaskAnimalTransfer>();
  useEffect(() => {
    setTaskAnimalTransfer(task.task)
  }, []);

  const mapText = new Map();
  mapText.set("RETIRO", "Retiro")
  mapText.set("FAZENDA", "Fazenda")

  return (
    <>
      <div >
        <span>Do lote</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <AnimalsIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskAnimalTransfer?.batch_initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <div className="containerQuestion">
        <MessageBalloon text={`Qual o tipo de transferência?`} pointed />
        <ButtonPressed onlyResult optionSelected={1} value={1} text={`${mapText.get(taskAnimalTransfer?.transfer_type)}`} />
      </div>

      <div>
        <span>Para o retiro</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <AreaIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskAnimalTransfer?.destination_area_full_initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      {taskAnimalTransfer?.categories.map((category, index) => (
        <div key={index}>
          <div className="lot__title--category" style={{ marginTop: 0 }}>{category.category_description.name}</div>
          <div className="lot__subtitle--category">{category.category_description.age_description}</div>
          <CounterInputEdit isDisabled defaultValue={category.quantity} control={undefined} isInline />
        </div>
      ))}
    </>
  )
}