/*
  * Este componente compoe o componente AnimatedZoom, 
    os filhos inseridos nele só serão exibidos na visão 3X
*/

import { ReactNode } from "react";
import Constants from "../../../constants/Index";

type ContainerZoom3XProps = {
  children: ReactNode | Array<ReactNode>;
};

const ContainerZoom3X = ({ children }: ContainerZoom3XProps) => <>{children}</>;

ContainerZoom3X.displayName = Constants.COMPONENT_3X_NAME;

export default ContainerZoom3X;
