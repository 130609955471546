import { ToolOption } from "../../ToolBox/Option/Index";
import "./styles.css";
import { BsHandIndexThumbFill } from "react-icons/bs";
import { IoAnalyticsOutline } from "react-icons/io5";
import { ReactComponent as CheckIcon } from "../../../../assets/svg/icons/check.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/svg/icons/trash.svg";
import { CSSProperties } from "react";

type IToolBar = {
  handleSuccess?: () => void;
  handleDiscardChanges?: () => void;
  style?: CSSProperties;
  hiddenExploreButton?: boolean;
  hiddenDrawButton?: boolean;
  label?: string
};

export function ToolBar({ handleSuccess, handleDiscardChanges, style, ...props }: IToolBar) {

  const discardChanges = () => handleDiscardChanges && handleDiscardChanges();
  const handleClickSuccess = () => handleSuccess && handleSuccess();

  return (
    <section className="toolbox__container" style={style}>
      <h3>{props.label !== undefined ? props.label : 'Editar arquivo'}</h3>
      <div>
        {!props.hiddenExploreButton && <>
          <ToolOption theme="normal" defaultMode="explore" title="Explorar">
            <BsHandIndexThumbFill />
          </ToolOption>
        </>}

        {!props.hiddenDrawButton && <>
          <ToolOption theme="normal" defaultMode="draw" title="Desenho livre">
            <IoAnalyticsOutline />
          </ToolOption>
        </>}

        <ToolOption
          theme="normal"
          noSelectable
          defaultMode="none"
          title="Descartar alterações"
          onClick={discardChanges}
        >
          <TrashIcon />
        </ToolOption>
        <ToolOption
          theme="success"
          noSelectable
          defaultMode="none"
          title="Confirmar alterações"
          onClick={handleClickSuccess}
        >
          <CheckIcon />
        </ToolOption>
      </div>
    </section>
  );
}
