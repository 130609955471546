import "./styles.css";
import { useEffect, useRef, useState } from "react";
import { FooterControlls } from "../../../../components/Onboarding/Template/FooterControlls/Index";
import { IOptionsProps } from "../../../../@types/reactSelect/IOption";
import { useOnboarding } from "../../../../hooks/useOnboarding";
import Constants from "../../../../constants/Index";
import { generateuuid } from "../../../../utils/uuid/generate";
import {
  CreateAreasRefProps,
  ICreateArea,
  ICreateAreas,
  IFarmsArea,
} from "../../../../components/Onboarding/Register/CreateArea/Index";

import { CreateAreas as CreateAreasForm } from "../../../../components/Onboarding/Register/CreateArea/Index";
import { setTimeout } from "timers";

export function CreateAreas() {
  const [farms, setFarms] = useState<IFarmsArea>([]);
  const [areas, setAreas] = useState<ICreateAreas>([]);
  const formRef = useRef<CreateAreasRefProps>();
  const { setBlockNextStep, payload, setPayload } = useOnboarding();

  const [managersList, setManagersList] = useState<IOptionsProps>([]);

  useEffect(() => {
    if (payload && payload.farms) {
      const farms = payload.farms.map(({ uuid: id, initials, name, map_coords: coords }) => ({
        id,
        initials,
        name,
        coords,
      }));
      setFarms(() => farms);
    }

    if (payload && payload.users) {
      const managers = payload.users
        .filter((p) => p.profile?.value === Constants.PROFILE_CODE_CAPATAZ.toString())
        .map((user) => ({ label: user.name, value: user.uuid }));
      setManagersList(() => managers);
    }

    if (payload && payload.areas) {
      const areas = payload.areas.map(
        (area) =>
          ({
            id: area.uuid,
            farmId: area.farm_id,
            name: area.name,
            initials: area.initials,
            managerUser: area.manager_user_id,
            coords: area.map_coords?.coords,
            openForm: false,
            acreage: area.acreage,
            indoor_grazing_time: area.indoor_grazing_time,
            outdoor_grazing_time: area.outdoor_grazing_time
          } as ICreateArea)
      );
      setAreas(() => areas);
    }
  }, [payload]);

  useEffect(() => {
    setTimeout(() => {
      if (formRef && formRef.current) {
        formRef.current.isFormValid().then((response) => setBlockNextStep(!response));
      }
    }, 250);
  }, [formRef]);

  function saveOnPayload(formData: any) {
    let areas: any[] = [];
    let farms = formData.farms;
    // Realiza o relacionamento entre o Retiro e sua respectiva Fazenda
    farms.map((farm: any, indexFarm: number) =>
      farm["areas"]
        .filter((area: any) => area?.name || area?.name == "")
        .map((area: any, indexArea: number) => {
          area["uuid"] = area.uuid ? area.uuid : generateuuid();
          area["farm_id"] = payload.farms[indexFarm]["uuid"];
          areas.push(farm["areas"][indexArea]);
        })
    );
    setPayload((payload) => {
      const response = { ...payload, areas };
      localStorage.setItem(Constants.LOCALSTORAGE_PAYLOAD, JSON.stringify(response));
      return response;
    });
  }

  async function nextStepEvent() {
    if (formRef && formRef.current) {
      const isValid = await formRef.current.showErrorsForm();
      setBlockNextStep(!isValid);
      if (isValid) {
        const formData = await formRef.current.getFormData();
        saveOnPayload(formData);
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <main>
        <CreateAreasForm
          ref={formRef}
          dataFarms={farms}
          dataAreas={areas}
          managersList={managersList}
          validateDataForm={(isValid) => setBlockNextStep(!isValid)}
        />
      </main>
      <footer>
        <FooterControlls nextStepEvent={nextStepEvent} />
      </footer>
    </>
  );
}
