import { CSSProperties, useEffect, useRef, useState } from "react";
import { SwiperSlide } from "swiper/react";
import { Scope } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
import "./styles.css";

import Constants from "../../../../constants/Index";
import axios from "../../../../services/axios";

import { MdClose } from "react-icons/md";

import { TableVisualizationContainer } from "../../../../components/Onboarding/CattleMap/CategoryAnimal/Table/VisualizationContainer/Index";
import { TableVisualizationRow } from "../../../../components/Onboarding/CattleMap/CategoryAnimal/Table/TableVisualizationRow/Index";
import { TableEditContainer } from "../../../../components/Onboarding/CattleMap/CategoryAnimal/Table/EditContainer/Index";
import { TableEditRow } from "../../../../components/Onboarding/CattleMap/CategoryAnimal/Table/EditRow/Index";
import { TitleTable } from "../../../../components/Onboarding/CattleMap/CategoryAnimal/Table/Title/Index";
import { FooterControlls } from "../../../../components/Onboarding/Template/FooterControlls/Index";
import { HeaderContentModal } from "../../../../components/Modal/Default/HeaderContentModal/Index";
import { FooterContentModal } from "../../../../components/Modal/Default/FooterContentModal/Index";
import { AlertModal } from "../../../../components/Modal/Alert/Index";
import { Modal } from "../../../../components/Modal/Default/Index";
import {
  IAnimalEvolution,
  IAnimalEvolutions,
} from "../../../../@types/app/AnimalCategory/IEvolution";
import { ReactComponent as PositiveIcon } from "../../../../assets/svg/icons/positive.svg";
import { ReactComponent as NegativeIcon } from "../../../../assets/svg/icons/negative.svg";
import { IEvolutionsOnboarding } from "../../../../@types/Onboarding/IEvolution";
import { useYupValidate } from "../../../../hooks/useYupValidate";
import { useOnboarding } from "../../../../hooks/useOnboarding";
import {
  IAnimalCategory,
} from "../../../../@types/app/AnimalCategory/ICategory";

const schema = Yup.object().shape({
  categories: Yup.array().of(
    Yup.object().shape({
      weights: Yup.array().of(
        Yup.object().shape({
          value: Yup.number()
            .required(Constants.MESSAGE_REQUIRED_FIELD)
            .moreThan(29, Constants.MESSAGE_INVALID_WEIGHT)
            .typeError(Constants.MESSAGE_REQUIRED_FIELD),
        })
      ),
    })
  ),
});

const contentStyleModal = {
  width: "95%",
  height: "95vh",
  maxWidth: "1350px",
  maxHeight: "937px",
  borderRadius: "20px",
  overflow: "hidden",
  padding: "40px",
} as CSSProperties;

const contentStyleAlert = {
  width: "335px",
  height: "237px",
  borderRadius: "20px",
} as CSSProperties;

interface AnimalCategoryProps {
  onlyRead?: boolean,
}

export function AnimalCategory({
  onlyRead = false
}: AnimalCategoryProps) {
  const { payload, setBlockNextStep, setPayload } = useOnboarding();

  // Referência do UNFORM
  const formRef = useRef<HTMLFormElement | null>(null);

  // Isso tem o objeto que esta sendo exibido no modal
  const [modalEvolution, setModalEvolution] = useState<IAnimalEvolution | null>(null);

  // Isso diz ao botao do modal se está ou não ativo
  const [disableModalSuccess, setDisableModalSuccess] = useState<boolean>(false);

  // Isso guarda a lista de evoluções ja no formato correto
  const [evolutionList, setEvolutionList] = useState<IAnimalEvolutions>([]);

  // Estado de aberto e fechado do modal
  const [visibleModal, setVisibleModal] = useState<boolean>(false);

  // Isto diz o estado de visualização do modal
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);

  // Isso diz qual o até qual mês o slider deve mostrar
  const [maxCategories, setMaxCategories] = useState<number>(0);

  // Meses a serem mostrados na tabela
  const [months] = useState<any>(Constants.MONTHS_FULL_NAME);

  // Isso diz o erro de qualquer input do modal
  const [errors, setErrors] = useState<string | undefined>();

  const [prevData, setPrevData] = useState<any>();

  // Isso verfica se existem alterações e devolve o vetor com as alterações
  function getChangesForm() {
    const categsForm: any = formRef.current?.getData().categories;
    const categories = categsForm.map(({ weights }: any) => ({ month: weights }));
    const categsEdited = modalEvolution?.categories.map((category, categInd) => {
      return category.month_evolution
        .map(({ month, weight }, index) => ({
          month,
          category_id: category.id,
          weight: { ...weight, value: categories[categInd].month[month - 1]?.value },
        }));
    });
    return categsEdited?.flat() || [];
  }

  // Isso lida com o fechamento do modal, se pode ou não fechar
  function handleEventCloseModal() {
    const haveChanges = getChangesForm().length > 0;
    if (haveChanges) setVisibleAlert(true);
    else toggleModalEdit("close");
  }

  // se o usuário desejar isso descarta todas as alterações que o usuário fez
  async function handleDiscardChanges() {
    toggleModalEdit("close");
    setModalEvolution(() => null);
    setVisibleAlert(false);
    formRef.current?.setData({categories: prevData});
    await validateForm();
  }

  function getPrevData(modalEvolution: IAnimalEvolution){
    return modalEvolution?.categories.map((value) => {
      const weights = value.month_evolution.map(value1 => {
        return {
          "value": value1.weight.value
        }
      });
      return {
        "weights": weights
      };
    })
  }

  // Isso lida com o toggle do modal
  function toggleModalEdit(action: "open" | "close", index?: number) {
    if (action === "open" && index !== undefined) {
      setModalEvolution(() => evolutionList[index]);
      setVisibleModal(true);
      const prevData = getPrevData(evolutionList[index]);
      setPrevData(prevData);
    } else {
      setVisibleModal(false);
      setModalEvolution(() => null);
    }
  }

  // Isso valida o formulário e bloqueia os botões do modal e de avançar
  async function validateForm() {
    const formData = formRef.current?.getData();
    const isFormValid = await useYupValidate(formRef, formData, schema);
    setDisableModalSuccess(!isFormValid);
    setBlockNextStep(!isFormValid);
    return isFormValid;
  }

  // Isso lida com as alterações dos inputs
  function handleChangesForm() {
    const newCategories = getChangesForm();
    handleDataObject(evolutionList, newCategories);
    toggleModalEdit("close");
    // Atualiza o Contexto e tbm o Storage
    setPayload((payload) => {
      const oldPayload = payload.evolutions
        ? payload.evolutions.filter(
            (f) =>
              !newCategories?.some((p) => p.category_id === f.category_id && p.month === f.month)
          )
        : [];
      const response = { ...payload, evolutions: [...oldPayload, ...newCategories] };
      localStorage.setItem(Constants.LOCALSTORAGE_PAYLOAD, JSON.stringify(response));
      return response;
    });
  }

  // Isso pega qualquer modificação existente e aplica no vetor de categorias principal
  function handleDataObject(data: IAnimalEvolutions, modifications?: IEvolutionsOnboarding) {
    if(!onlyRead) {
      const payloadData = modifications ? modifications : payload.evolutions;
      const newEvolutions = data.map((evolution) => {
        const newCategory = evolution.categories.map((category) => {
          const monthUpdated = payloadData?.filter((f) => f.category_id === category.id);
          if (monthUpdated && monthUpdated.length > 0) {
            const months = category.month_evolution.map((month) => {
              const founded = monthUpdated.find((f) => f.month === month.month);
              return founded ? { ...month, weight: founded.weight } : month;
            });
            return { ...category, month_evolution: months };
          }
          return category;
        });
        return { ...evolution, categories: newCategory };
      });
      setEvolutionList(() => newEvolutions as IAnimalEvolutions);
    } 
  }

  useEffect(() => {
    axios.get("/evolutions").then(({ data }) => {
      if (data) {
        const categories = new Map<number, IAnimalCategory>();
        // Isso diz até qual mês pode ser renderizado o slider
        data.forEach((evolution: IAnimalEvolution) =>
          evolution.categories.forEach((category) => {
            categories.set(category.id, category);
            const categoryLenght = category.month_evolution.length;
            setMaxCategories((count) => (count < categoryLenght ? categoryLenght : count));
          })
        );

        if(!onlyRead) {
          setPayload((payload) => {
            const response = { ...payload, categories: categories };
            localStorage.setItem(Constants.LOCALSTORAGE_PAYLOAD, JSON.stringify(response));
            return response;
          });
        } else {
          setEvolutionList(data as IAnimalEvolutions)
        }

        //Verificamos a existencia de modificações no vetor de categorias
        handleDataObject(data);

        const prevData = getPrevData(evolutionList[0]);
        formRef.current?.setData({categories: prevData});
        validateForm().catch(console.error);
      }
    });
  }, []);

  return (
    <>
      {/* [INICIO] Modal de edição de Evolução */}
      <Modal contentStyle={contentStyleModal} visible={visibleModal} animation="slideDown">
        {modalEvolution && (
          <section className="containerTable__modal--container">
            <div className="containertable__modal--header">
              <HeaderContentModal onEventClose={handleEventCloseModal} />
              <h2 className="containerTable__title">
                Evolução de {modalEvolution.type === "FEMALE" ? "Fêmea" : "Macho"}
              </h2>
            </div>
            <div className="containerTable__modal--container">
              <section className="containerTable__modal">
                <Form ref={formRef as any} onSubmit={() => {}}>
                  <TableEditContainer>
                    {modalEvolution.categories.map((category, key) => (
                      <Scope key={key} path={`categories[${key}]`}>
                        <TableEditRow
                          category={category}
                          evolutionName={modalEvolution.type}
                          handleInputChanges={validateForm}
                          inputError={(err) => setErrors(() => err)}
                          formRef={formRef}
                        />
                      </Scope>
                    ))}
                  </TableEditContainer>
                </Form>
              </section>
              <span className="modalEdit__message--error">{errors}</span>
            </div>
            <div className="footermodal__edit">
              <FooterContentModal
                onEventCancel={handleEventCloseModal}
                onEventSuccess={handleChangesForm}
                disableSuccesButton={disableModalSuccess}
              />
            </div>
          </section>
        )}
      </Modal>
      {/* [FIM] Modal de edição de Evolução */}

      {/* [INICIO] Modal de alerta para alterações no modal de edição */}
      <AlertModal contentStyle={contentStyleAlert} visible={visibleAlert} animation="slideUp">
        <section className="alert-modal--section">
          <button className="alert-modal--button-close" onClick={() => setVisibleAlert(false)}>
            <MdClose />
          </button>
          <h2>{Constants.MESSAGE_MODAL_ALERT_TITLE}</h2>
          <p>{Constants.MESSAGE_DISCARD_CHANGES}</p>
          <div className="alert-modal--section-buttons">
            <button onClick={handleDiscardChanges}>
              <PositiveIcon />
              <strong>sim</strong>
            </button>
            <button onClick={() => setVisibleAlert(false)}>
              <NegativeIcon />
              <strong>não</strong>
            </button>
          </div>
        </section>
      </AlertModal>
      {/* [FIm] Modal de alerta para alterações no modal de edição */}

      <main>
        <section className="animalcategory__container">
          <div className="animacategory__content">
            <h1 className="conteudo-title evolution__title">Categoria e Evolução Animal</h1>
            {evolutionList.map((evolution, key) => {
              return (
                <section key={key} className="evolution__container">
                  <TitleTable
                    title={evolution.type}
                    handleClick={() => toggleModalEdit("open", key)}
                    onlyRead={onlyRead}
                  />
                  <TableVisualizationContainer>
                    {Object.values(months).map((month, monthKey) => {
                      if (maxCategories > monthKey)
                        return (
                          <SwiperSlide key={monthKey}>
                            {evolution.categories.map((category, categoryKey) => {
                              category.month_evolution.sort((a, b) => (a.month < b.month) ? -1 : 1)
                              if (category.month_evolution[monthKey])
                                return (
                                  <TableVisualizationRow
                                    key={categoryKey}
                                    category={category}
                                    month={category.month_evolution[monthKey]}
                                  />
                                );
                            })}
                          </SwiperSlide>
                        );
                    })}
                  </TableVisualizationContainer>
                </section>
              );
            })}
          </div>
        </section>
      </main>
      {!onlyRead && 
        <footer>
          <FooterControlls
            nextStepEvent={async () => await validateForm()}
            backStepEvent={async () => await validateForm()}
          />
        </footer> 
      }
    </>
  );
}
