import { ICategory } from "../../../../@types/app/ICategory";
import { Dropdown } from "../Select";
import { Option } from "../../components/Select/OptionCheckBox";
import { IOptionProps } from "../../../../@types/reactSelect/IOption";
import { useEffect, useState } from "react";
import { FieldErrorsImpl, useFieldArray } from "react-hook-form";
import { CounterInputEdit } from "../Input/CounterInput/Index";

type CategoryInputQtdProps = {
  name: string;
  control: any;
  categories: Map<number | undefined, ICategory>;
  calculateUA: () => void;
  defaultValues?: any;
  isNew?: boolean;
  errors?: Partial<FieldErrorsImpl<any>>;
};

export function CategoryInputQtd({
  control,
  categories,
  name,
  calculateUA,
  defaultValues,
  isNew = false,
  errors,
}: CategoryInputQtdProps) {
  const [mapQuantity] = useState(new Map());
  const [qtdDefault] = useState(new Map());

  const { append, remove } = useFieldArray({
    control,
    name,
  });
  const [categoriesSelected, setCategoriesSelected] = useState<Array<number>>(
    []
  );

  function handleCategoryChange(option: any) {
    const selected = option?.map((item: IOptionProps) => {
      return parseInt(item?.value);
    });
    setCategoriesSelected(selected);
  }

  useEffect(() => {
    refreshMap();
  }, [categoriesSelected]);

  function refreshMap() {
    remove();
    categories.forEach((category) => {
      if (categoriesSelected.includes(category.id)) {
        const toAppend = {
          id: category?.id,
          quantity: mapQuantity.get(category?.id!),
          middle_weight: category?.middle_weight.toString(),
          month: category?.month,
        };
        append(toAppend);
      }
    });
    calculateUA();
  }

  useEffect(() => {
    handleCategoryChange(defaultValues);
    if (defaultValues)
      defaultValues.map((category: any) => {
        qtdDefault.set(Number(category?.value), category?.info);
      });
  }, [defaultValues]);

  return (
    <>
      <Dropdown
        className="dropdownCategory"
        label={"Categoria"}
        control={control}
        options={Array.from(categories.values()).map((category: any) => ({
          label: category.name,
          value: category.id + "",
          info: `${category.middle_weight} ${category.unit} - ${category.age_description}`,
        }))}
        getSelectedData={(option) => handleCategoryChange(option)}
        components={{ Option }}
        hideSelectedOptions={false}
        placeholderText={"Selecione..."}
        isMulti={true}
        name={"category"}
        isDisabled={!isNew}
        errorText={(errors?.category as any)?.message}
      />

      {categoriesSelected &&
        Array.from(categories.values()).map(
          (category) =>
            categoriesSelected.includes(category.id) && (
              <>
                <div className="lot__title--category">{category?.name}</div>
                <div className="lot__subtitle--category">{`${category?.middle_weight} ${category?.unit} - ${category?.age_description}`}</div>
                <CounterInputEdit
                  isInline
                  onChangevalue={(value) => {
                    if (category) {
                      mapQuantity.set(category.id, value);
                      refreshMap();
                    }
                  }}
                  control={control}
                  defaultValue={qtdDefault.get(category.id) || 0}
                  isDisabled={!isNew}
                  errorText={
                    errors?.categories
                      ? (errors?.categories as any)[
                          categoriesSelected.indexOf(category.id)
                        ]?.quantity?.message
                      : ""
                  }
                  max={9999}
                />
              </>
            )
        )}
    </>
  );
}
