import "./styles.css";
import { useState } from "react";
import { ReactComponent as EyeOpen } from "../../../../assets/svg/icons/eyeClosed.svg";
import { ReactComponent as EyeClosed } from "../../../../assets/svg/icons/eye.svg";
import { Select } from "../../../Inputs/Select/Index";
import AnimateHeight from "react-animate-height";
import { TextInput } from "../../../Inputs/TextInputs/TextInput/Index";

interface PastureUnavailableProps {
  initial: string;
  indexComponent: number;
  onAnywhereChanges: () => void;
  microAreaId: string;
  microAreasReasons: any;
}

export function PastureUnavailable({ initial, indexComponent, onAnywhereChanges, microAreaId, microAreasReasons }: PastureUnavailableProps) {
  const [visiblepastures, setVisiblepastures] = useState<"auto" | 0>("auto");
  const handleVisiblepastures = () => setVisiblepastures((prev) => (prev === 0 ? "auto" : 0));

  return (
    <>
      <section className="pasturesUnavailable_container">
        <div style={{ marginLeft: 20 }}></div>
        <div className="pasturesUnavailable__title--container">
          <h3>
            Pasto {indexComponent + 1} ({initial})
          </h3>
          <button type="button" onClick={handleVisiblepastures}>
            {visiblepastures ? <EyeOpen /> : <EyeClosed />}
          </button>
        </div>

        <AnimateHeight height={visiblepastures}>
          <section className="pasturesUnavailable__content">
            <div className="pasturesUnavailable__form">
              <section className="pasturesUnavailable__textinput--container">
                <label>Motivo para não utilizar o pasto</label>
                <Select
                  handleAnywhereChanges={onAnywhereChanges}
                  placeholder=""
                  closeMenuOnSelect={true}
                  name="reason"
                  options={microAreasReasons}
                />
                <TextInput
                  hidden={true}
                  value={microAreaId}
                  name="microAreaId"
                />
              </section>
            </div>
          </section>
        </AnimateHeight>
        <div className="pasturesUnavailable__divider" />
      </section>
    </>
  );
}
