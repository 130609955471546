import { ReactNode, useEffect, useState } from "react";
import "./styles.css";
import { Outlet, useLocation } from "react-router-dom";
import { ReactComponent as LogoPastejo } from "../../../assets/svg/logo/logoPastejo.svg";

type SessionTemplateProps = {
  children?: ReactNode | Array<ReactNode>;
};

export function SessionTemplate({ children }: SessionTemplateProps) {
  const { pathname } = useLocation();
  const [headerOnTop, setHeaderOnTop] = useState<boolean>(false);

  function handleTitlepage(path: string) {
    switch (path) {
      case "/entrar":
        return "Login";
      case "/cadastro":
        return "Cadastro";
      case "/bemvindo":
        return "Bem Vindo";
      default:
        "";
    }
  }

  useEffect(() => {
    document.title = `Pastejo | ${handleTitlepage(pathname)}`;
  }, [pathname]);

  useEffect(() => {
    function handleScroll(event: Event) {
      const target = event.target as Element;
      if (target.scrollTop > 10) setHeaderOnTop(true);
      else setHeaderOnTop(false);
    }

    document
      .querySelector(".session-template__container")
      ?.addEventListener("scroll", handleScroll);

    return () =>
      document
        .querySelector(".session-template__container")
        ?.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="session-template__container">
      <header className={`${headerOnTop ? "onTop" : ""}`}>
        <div className="session-template__header--logo">
          <LogoPastejo />
        </div>
      </header>
      <main>{children ? <>{children}</> : <Outlet />}</main>
    </div>
  );
}
