import { Scope } from "@unform/core";
import { MutableRefObject, useCallback, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import * as yup from "yup";
import { ILot, ILots } from "../../../../../@types/app/ILot";
import { IMicroArea } from "../../../../../@types/app/IMicroArea";
import { IOptionsProps } from "../../../../../@types/reactSelect/IOption";
import { ReactComponent as EyeClosed } from "../../../../../assets/svg/icons/eye.svg";
import { ReactComponent as EyeOpen } from "../../../../../assets/svg/icons/eyeClosed.svg";
import { EditValues } from "../../../../../hooks/EditValues";
import { useOnboarding } from "../../../../../hooks/useOnboarding";
import { useYupValidate } from "../../../../../hooks/useYupValidate";
import { ButtonOnlyTitle } from "../../../../Buttons/ButtonOnlyTitle/Index";
import { Batch } from "./Batch";

type AreaProps = {
  formRef: MutableRefObject<HTMLFormElement | undefined>;
  farm: any;
  area: any;
  keyFarm: number;
  keyArea: number;
  onlyRead?: boolean;
  onBoarding: boolean;
  handleAnyChanges: (index?: number) => void;
  refreshBatches: () => void;
  batchSelected: (id: number) => void;
};

const baseBatch = {
  farm_id: "",
  farm: null,
  area_id: "",
  area: null,
  initials: "",
  categories: [],
  current_micro_area: null,
  current_micro_area_id: "",
  micro_area_route_ids: [],
  batch_capacity_rate: 0,
  total_ua: 0,
} as ILot;

const batchesSchema = yup.object().shape({
  lots: yup.array().of(
    yup.object().shape({
      initials: yup
        .string()
        .matches(/^\S+(\s+\S+)*$/, "Esta sigla não é válida.")
        .required("SIGLA é necessário."),
      categoryMap: yup
        .array()
        .min(1, "Categoria é necessário.")
        .required("Categoria é necessário."),
      categories: yup.array().of(
        yup.object({
          quantity: yup
            .number()
            .min(1, "Adicione animais para essa categoria."),
        })
      ),
      current_micro_area: yup
        .object({
          label: yup.string(),
          value: yup.string(),
        })
        .nullable()
        .required("Pasto atual é necessário."),
      micro_area_route_ids: yup
        .array()
        .required("Rota de pastejo é necessário."),
    })
  ),
});

export function AreaLots({
  farm,
  area,
  keyFarm,
  keyArea,
  formRef,
  onlyRead = false,
  onBoarding = false,
  handleAnyChanges,
  batchSelected,
}: AreaProps) {
  const { setBlockNextStep, payload } = useOnboarding();
  const { setBlockSave } = EditValues();
  const [visibleFarm, setVisibleFarm] = useState<"auto" | 0>("auto");
  const [listLots, setListLots] = useState<any>([baseBatch]);
  const [microAreaOptions, setMicroAreaOptions] = useState<IOptionsProps>([]);
  const [listMicroAreasMap, setListMicroAreasMap] = useState<
    Map<string, IMicroArea | undefined>
  >(new Map());

  const handleVisibleFarm = async () => {
    setVisibleFarm((prev) => (prev === 0 ? "auto" : 0));
  };

  async function addNewBatch() {
    const formData = formRef.current?.getData().farms[keyFarm].areas[keyArea];
    const isValid = await useYupValidate(formRef, formData, batchesSchema);
    if (isValid) {
      !onlyRead ? setBlockNextStep(true) : setBlockSave(true);
      setListLots((values: any) => {
        return [...values, baseBatch];
      });
    } else {
      handleChanges();
    }
  }

  async function removeLot(uuid: number) {
    formRef.current?.setErrors({});
    const formData = formRef.current?.getData().farms[keyFarm].areas[keyArea].lots;

    let filtered: ILots = formData.filter((_: any, index: number) => index !== uuid);
    setListLots(filtered);

    handleChanges();
    batchSelected(0);
  }

  function updateMicroAreaRoutes() {
    setTimeout(async () => {
      const usedMicroAreas = await formRef.current
        ?.getData()
        .farms[keyFarm].areas[keyArea].lots.map(
          (lot: any) => lot.micro_area_route_ids
        )
        .flat();
      if(usedMicroAreas){
        const microAreaList = payload.micro_areas
          .filter((microArea) => microArea.area_id == area.uuid)
          .filter((microArea) => !usedMicroAreas.includes(microArea.uuid))
          .map((microArea) => ({
            label: `${farm.initials}-${area.initials}-${microArea.initials}`,
            value: microArea.uuid,
            info: microArea.area_id,
          }));
        setMicroAreaOptions(microAreaList);
      }
    }, 100);
  }

  useEffect(() => {
    setListMicroAreasMap(() => {
      return new Map(
        payload.micro_areas
          .filter((microArea) => microArea.area_id == area.uuid)
          .map((microArea) => {
            return [microArea.uuid, microArea];
          })
      );
    });

    if (payload.batches)
      setListLots(
        payload.batches.filter((batch: any) => batch.area_id == area.uuid)
      );
  }, []);

  const handleChanges = useCallback(() => {
    handleAnyChanges && handleAnyChanges();
  }, [handleAnyChanges]);

  useEffect(() => {
    if (listLots.length == 0) setListLots([baseBatch]);
  }, [listLots]);

  return (
    <div style={{ marginLeft: 20 }}>
      <div className="microarea__title--container" style={{ width: 530 }}>
        <h3>{area.name}</h3>
        <button type="button" onClick={handleVisibleFarm}>
          {visibleFarm ? <EyeOpen /> : <EyeClosed />}
        </button>
      </div>

      <AnimateHeight height={visibleFarm}>
        {listLots.map((lot: any, key: number) => (
          <Scope key={key} path={`lots[${key}]`}>
            <Batch
              formRef={formRef}
              farm={farm}
              area={area}
              keyFarm={keyFarm}
              keyArea={keyArea}
              keyBatch={key}
              batch={lot}
              isDeletable={listLots.length > 1 || key > 0}
              onEventRemove={removeLot}
              microAreasMap={listMicroAreasMap}
              microAreaAvailable={microAreaOptions}
              updateAvailableMicroArea={updateMicroAreaRoutes}
              handleAnyChanges={handleChanges}
              batchSelected={(value) => batchSelected(value)}
            />
          </Scope>
        ))}
        {onBoarding && (
          <div className="container___buttonaddBatch">
            <ButtonOnlyTitle
              title="+ adicionar Lote"
              theme="info"
              onClick={addNewBatch}
            />
          </div>
        )}
      </AnimateHeight>
    </div>
  );
}
