import { CSSProperties, ReactElement, useEffect, useState } from 'react'
import "./styles.css";

export const DEFAULT_CHIP_COLORS = {
    WHITE_900: '#FFFFFF',
    GRAY_900: '#F1F1F1',
    GRAY_600: '#999999',
    DARK_100: '#4D4D4D',
}

export const DEFAULT_THEMES_CHIP_CONTAINER = {
    light: {
        background: DEFAULT_CHIP_COLORS.WHITE_900,
        border: `2px solid ${DEFAULT_CHIP_COLORS.GRAY_900}`
    } as CSSProperties,
    dark: {
        background: DEFAULT_CHIP_COLORS.DARK_100,
        border: `2px solid ${DEFAULT_CHIP_COLORS.DARK_100}`
    } as CSSProperties
}

export const DEFAULT_THEMES_CHIP_LABEL = {
    light: {
        color: DEFAULT_CHIP_COLORS.GRAY_600
    } as CSSProperties,
    dark: {
        color: DEFAULT_CHIP_COLORS.WHITE_900
    } as CSSProperties
}

const ChipBaseComponent = <P,>(props: IComponentChipBase<P>): ReactElement | null => {
    const { IConComponent, label, theme, bgTranslucentLevel, variationStyles,
        overrideContainerTheme, overrideLabelTheme } = props

    const [CHIP_TEXT_THEMES] = useState<any>(overrideLabelTheme || DEFAULT_THEMES_CHIP_LABEL);
    const CHIP_CONTAINER_THEMES = overrideContainerTheme || DEFAULT_THEMES_CHIP_CONTAINER;

    const [variationContainerStyles, setVariationContainerStyles] = useState<CSSProperties>({});
    const [variationLabelStyles, setVariationLabelStyles] = useState<CSSProperties>({});

    const handleBackgroundColor = () => {
        if (bgTranslucentLevel) {
            const { background } = CHIP_CONTAINER_THEMES[theme]
            var bigint = parseInt(background.toString().slice(1, background.toString().lenght), 16);
            var r = (bigint >> 16) & 255;
            var g = (bigint >> 8) & 255;
            var b = bigint & 255;
            return ({
                ...CHIP_CONTAINER_THEMES[theme],
                background: `rgba(${r}, ${g}, ${b}, ${bgTranslucentLevel})`
            })
        }
        return CHIP_CONTAINER_THEMES[theme]
    }

    useEffect(() => {
        setVariationContainerStyles(() => ({ ...handleBackgroundColor(), ...variationStyles?.container }))
        setVariationLabelStyles(() => ({ ...CHIP_TEXT_THEMES[theme], ...variationStyles?.label }))
    }, [theme, variationStyles])

    return <>
        <div className='chip-base__container' style={variationContainerStyles}>
            <>{IConComponent}</>
            <h3 style={variationLabelStyles}>{label}</h3>
        </div>
    </>
}

export default ChipBaseComponent