import axios from './axios'

const getMicroAreasByAreaId = (areaId: number): Promise<IMicroArea[]> => {
    return axios.get<IMicroArea[]>(`/micro-areas?area_id=${areaId}`).then(({ data }) => {
        return data
    })
}

export const microAreaService = {
    getMicroAreasByAreaId
}