import { useEffect, useState } from "react";
import "./styles.css"

interface ButtonPressedProps {
  optionSelected: any;
  value: any;
  text: string;
  onlyResult?: boolean;
}

export function ButtonPressed({
  optionSelected,
  value,
  text,
  onlyResult = false
}: ButtonPressedProps) {
  const [buttonTheme, setButtonTheme] = useState("");

  useEffect(() => {
    setButtonTheme(optionSelected == value ? "buttonPressed" : "buttonNotPressed")
    if (!optionSelected) setButtonTheme("unselected")
  }, [optionSelected]);

  return (
    <button className={`buttonHeight ${buttonTheme}`} disabled={onlyResult}>
      <span className="textHeight">{text}</span>
    </button>
  );
};
