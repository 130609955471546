import "./styles.css";
import { ReactComponent as TrashIcon } from "../../../assets/svg/icons/trash.svg";
import { ButtonOnlyTitle } from "../../Buttons/ButtonOnlyTitle/Index";

type FooterEditOnboardingProps = {
  undoFunction: (data: any) => void;
  saveFunction: (data: any) => void;
  deleteFunction?: (data: any) => void;
  onlyRead?: boolean;
  disabledSubmit?: boolean;
  isNew: boolean;
};

export function FooterEditOnboarding({
  undoFunction,
  saveFunction,
  deleteFunction,
  onlyRead = false,
  isNew = false,
  disabledSubmit = false

}: FooterEditOnboardingProps) {
  return (
    <div className="footer__EditOnboarding">
      {onlyRead && !isNew ? (
        ""
      ) : (
        <div className="footer__formEditOnboarding">
          <ButtonOnlyTitle
            title="Cancelar"
            theme="light"
            onClick={undoFunction}
            type="button"
            disabled={disabledSubmit}
          />

          <ButtonOnlyTitle
            title="Salvar"
            theme="info"
            onClick={saveFunction}
            type="submit"
            disabled={disabledSubmit}
          />
        </div>
      )}

      {!isNew && deleteFunction ? (
        <button
          className={`delete__button${onlyRead ? "-readValues" : ""}`}
          onClick={deleteFunction}
          type="button"
        >
          <TrashIcon />
        </button>
      ) : (
        ""
      )}
    </div>
  );
}
