import { ChangeEvent, useEffect, useState } from 'react';
import { TextInput } from '../../../../Inputs/TextInputs/TextInput/Index';
import './styles.css';

type TextInputEditWeight = {
  quantityInit?:string;
  month: number;
  category: number;
  handleInputChanges?: (data?: HTMLInputElement) => void;
  onInputError?: (err?: string) => void;
  defaultValue?: number
  onlyRead?: boolean
};

export function InputQuantity({
  month,
  category,
  handleInputChanges,
  onInputError,
  quantityInit,
  onlyRead = false
}: TextInputEditWeight) {
  const [error, setError] = useState<string>('');
  const [uuidInput, setUuidInput] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('0');

  useEffect(()=>{
    if(quantityInit) setQuantity(quantityInit)
  },[quantityInit])

  function handleInputs(data?: HTMLInputElement) {
    if (data && handleInputChanges) {
      handleInputChanges(data);
    }
  }

  function handleErrorInput(err: string) {
    onInputError && onInputError(err);
    setError(err);
  }

  function onChangeQuantityInput(event: ChangeEvent<HTMLInputElement>){
    const reg = /^\d+$/;
    if(reg.test(event.target.value) || event.target.value == ''){
      setQuantity(event.target.value)
    }
  }

  useEffect(() => {
    setUuidInput(`quantity-${category}-${month}`.toLocaleLowerCase());
  }, [category, month]);

  return (
    <label htmlFor={uuidInput} className="textinput_quantity">
      <TextInput
        id={uuidInput}
        name="quantity"
        placeholder={'0'}
        defaultValue={'0'}
        value={quantity}
        handleAnywhereChanges={handleInputs}
        getMessageError={handleErrorInput}
        onWheel={(event) => event.currentTarget.blur()}
        onChange={onChangeQuantityInput}
        disabled={onlyRead}
      />
    </label>
  );
}
