export function decimalToDMS(lat: number, lng: number) {
    var latDirection = lat >= 0 ? 'N' : 'S';
    lat = Math.abs(lat);
    var latDeg = Math.floor(lat);
    var latMin = Math.floor((lat - latDeg) * 60);
    var latSec = ((lat - latDeg - (latMin / 60)) * 3600).toFixed(2);

    var lngDirection = lng >= 0 ? 'E' : 'O';
    lng = Math.abs(lng);
    var lngDeg = Math.floor(lng);
    var lngMin = Math.floor((lng - lngDeg) * 60);
    var lngSec = ((lng - lngDeg - (lngMin / 60)) * 3600).toFixed(2);

    return {
      latitude: latDeg + '°' + latMin + "'" + latSec + '"' + latDirection,
      longitude: lngDeg + '°' + lngMin + "'" + lngSec + '"' + lngDirection,
    };
  }