import { Polygon } from "react-leaflet";
import { ICoords } from "../../../@types/GoogleMaps/ICoord";
import Constants from "../../../constants/Index";
import { generateuuid } from "../../../utils/uuid/generate";

type PolylineFarmProps = {
  path: ICoords;
};

export function PolylineFarm({ path }: PolylineFarmProps) {
  return (
    <Polygon
      key={generateuuid()}
      pathOptions={Constants.LEAFLET_DASHED_GREEN}
      weight={2}
      fill={true}
      positions={path as ICoords}
      noClip
    />
  );
}
