import axios from './axios'

const getAllUsers = async (): Promise<IUser[]> => {
    const { data } = await axios.get('/users')
    if (!!data) return data
    else return []
}

const getUsersByOffice = async (profileRole: string): Promise<IUser[]> => {
    const allusers = await getAllUsers()
    return allusers.filter((f: any) => f.profile_id === profileRole.toString())
}

export const userService = {
    getAllUsers,
    getUsersByOffice
}