import { CSSProperties, useEffect, useState } from "react";
//@ts-ignore
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import "./styles.css";

type AnimationModal =
  | "zoom"
  | "fade"
  | "flip"
  | "door"
  | "rotate"
  | "slideUp"
  | "slideDown"
  | "slideLeft"
  | "slideRight";

type ModalComponentProps = {
  visible: boolean;
  contentStyle?: CSSProperties;
  animation?: AnimationModal;
};

const defaultStyle = {
  background: "transparent",
  border: "none",
  outline: "none",
  boxShadow: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
} as CSSProperties;

export function Loading({
  animation = "zoom",
  contentStyle,
  visible,
}: ModalComponentProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => setIsLoading(visible), [visible]);

  return (
    <Rodal
      customStyles={defaultStyle || contentStyle}
      animation={animation}
      visible={isLoading}
      onClose={() => {}}
    >
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Rodal>
  );
}
