import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";

interface InfoAnimalDeathProps {
  task: ITaskAnimalDeath | undefined;
}


export default function InfoAnimalDeath(task: InfoAnimalDeathProps) {
  const [taskDeath, setTaskDeath] = useState<ITaskAnimalDeath>();
  useEffect(() => {
    setTaskDeath(task.task)
  }, []);


  return (
    <>
      <div >
        <span>Do lote</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <AnimalsIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskDeath?.batch_initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <div className="containerQuestion">
        <MessageBalloon text={`Causa da morte`} pointed />
        <MessageBalloon text={`${taskDeath?.reason}`} />
      </div>

      {taskDeath?.categories.map((category, index) => (
        <div key={index}>
          <div className="lot__title--category" style={{ marginTop: 0 }}>{category.category_description.name}</div>
          <div className="lot__subtitle--category">{category.category_description.age_description}</div>
          <CounterInputEdit isDisabled defaultValue={category.quantity} control={undefined} isInline />
        </div>
      ))}
    </>
  )
}