import { Tooltip, TooltipProps } from "react-leaflet";
import "./styles.css";


import { ReactNode } from "react";

interface CustomToolTip extends TooltipProps {
  children?: ReactNode;
}

export function CustomToolTip({ children, ...rest }: CustomToolTip) {
  return (
    <Tooltip {...rest} className="customToolTip__container">
      {children}
    </Tooltip>
  );
}
