/*
  * Este componente compoe o componente AnimatedZoom, 
    os filhos inseridos nele só serão exibidos na visão 1X
*/

import { ReactNode } from "react";
import Constants from "../../../constants/Index";

type ContainerZoom1XProps = {
  children: ReactNode | Array<ReactNode>;
};

const ContainerZoom1X = ({ children }: ContainerZoom1XProps) => <>{children}</>;

ContainerZoom1X.displayName = Constants.COMPONENT_1X_NAME;

export default ContainerZoom1X;
