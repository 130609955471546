import { useEffect, useRef, useState } from 'react';
import ReactSelect, { Props as SelectProps } from 'react-select';
import { ReactComponent as ChevronIcon } from '../../../assets/svg/icons/chevronDown.svg';
import { IOptionProps, IOptionsProps } from '../../../@types/reactSelect/IOption';

interface ReactSelectProps extends SelectProps<any> {
  handleAnywhereChanges?: (event?: any) => void;
  getSelectedData?: (data: IOptionProps[], action?: string) => void;
}

const mHeight = '300px';
const speed = '300ms';

export function DropdownMultiSelect({
  options,
  getSelectedData,
  handleAnywhereChanges,
  ...rest
}: ReactSelectProps) {
  const selectRef = useRef<any>(null);
  const [open, setOpen] = useState(rest.menuIsOpen);
  const [value, setvalue] = useState<any>(null);
  const [selectOptions, setSelectOption] = useState<IOptionsProps>([]);
  const [upData, setUpData] = useState<boolean>(false);

  const selectStyles = () => ({
    option: (provided: any, state: any) => ({
      ...provided,
      position: 'relative',
      background: 'transparent',
      color: state.isSelected ? '#4D4D4D' : '#999999',
      textTransform: 'captilize',
      opacity: open ? 1 : 0.5,
      transition: `all ${speed} ease-in-out`,
      paddingLeft: '12px',
      height: '51px',
      '&:hover': {
        background: 'transparent',
        color: '#4D4D4D',
        cursor: 'pointer'
      },
      '&:last-child': {
      }
    }),
    control: (provided: any, { isDisabled }: any) => ({
      ...provided,
      borderRadius: !open ? '9px' : '9px 9px 0px 0px',
      background: rest.isDisabled ? '#F1F1F1' : '#FFF',
      outline: 'none',
      minHeight: '56px',
      boxShadow: 'none',
      border: `1px solid #e5e5e5 !important`,
      fontSize: 12,
      transition: `all ${speed} ease-in-out`,
      zIndex: 3,
      paddingLeft: '20px',
      paddingRight: '20px',
      cursor: isDisabled ? 'not-allowed' : 'default'
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: '0px 0px 9px 9px ',
      outline: 'none',
      boxShadow: 'none',
      borderLeft: `1px solid #e5e5e5 !important`,
      borderRight: `1px solid #e5e5e5 !important`,
      borderBottom: `1px solid #e5e5e5 !important`,
      position: 'relative',
      marginTop: '-10px',
      paddingTop: '10px',
      paddingLeft: '12px',
      paddingRight: '12px',
      marginBottom: '0px',
      fontSize: 18,
      maxHeight: open ? mHeight : '0px',
      overflow: 'hidden',
      opacity: open ? 1 : 0.5,
      transition: `all ${speed} ease-in-out`,
      visibility: open ? 'visible' : 'hidden',
      animation: open ? `ease selectOpen ${speed} forwards` : `ease selectClose ${speed} forwards`,
      zIndex: 2,
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '270px'
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      transition: 'ease-in-out 400ms',
      transform: open ? 'rotateZ(0deg)' : 'rotateZ(180deg)',
    }),
    input: (defaultStyles: any) => ({
      ...defaultStyles,
      margin: 0,
      padding: 0
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: 0
    }),
    noOptionsMessage: (defaultStyles: any) => ({
      ...defaultStyles,
      with: '100%',
      padding: '12px 0',
      margin: '0px'
    })
  });

  function onSelectChanges(event?: any) {
    if (event && handleAnywhereChanges) handleAnywhereChanges(event);
  }

  useEffect(() => {
    if (rest.defaultMenuIsOpen) {
      setOpen(rest.defaultMenuIsOpen);
    }
  }, [rest.defaultMenuIsOpen]);

  useEffect(() => {
    if (value) onSelectChanges(value);
  }, [value]);

  useEffect(() => {
    if (options && options.length) {
      setSelectOption(() =>
        options.map((option: IOptionProps) => ({
          label: String(option.label),
          value: String(option.value),
          info: option?.info
        }))
      );
      setUpData(!upData)
    }
    else {
      setSelectOption([])
    }
  }, [options]);

  useEffect(() => {
    if (rest.isDisabled) {
      setOpen(false)
    }
  }, [rest.isDisabled])

  return (
    <div
      onClick={() => {
        if (!rest.isDisabled) setOpen((prevState) => !prevState);
      }}>
      <ReactSelect
        {...rest}
        placeholder={`${rest.placeholder}`.toUpperCase()}
        ref={selectRef}
        options={selectOptions}
        menuIsOpen={true}
        defaultMenuIsOpen={false}
        styles={selectStyles()}
        components={{
          ...rest.components,
          ClearIndicator: () => null,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => <ChevronIcon />
        }}
        onChange={(data, { action }) => {
          getSelectedData && getSelectedData(data, action);
          if (action) setOpen(rest.closeMenuOnSelect);
          if (action) setvalue(data);
        }}
        className="react-select"
      />
    </div>
  );
}
