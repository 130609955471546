import { FilterDateDashboard } from "../../../../components/Dashboard/ViewDatas/FilterDate";
import { ReactComponent as BagFood } from "../../../../assets/svg/icons/bagFood.svg";
import { DateModal } from "../../../../components/Modal/DateFilter";
import { useFilter } from "../../../../hooks/useFilter";
import Constants from "../../../../constants/Index";
import { useEffect } from "react";

export default function DashboardSupplementationOutro() {
  const {
    setHideMicroAreaFilter,
    setHideBatchFilter,
    setDashboard,
    setSideBarOptionSelected,
  } = useFilter();

  useEffect(() => {
    setHideMicroAreaFilter(true)
    setHideBatchFilter(true)
    setSideBarOptionSelected("DASHBOARD")
    setDashboard("SUPPLEMENTATION")
    document.title = Constants.TITLE_DASHBOARD;
  }, []);

  const IconMarq = (value: number, min: number, max: number) => {
    let hex = "FFFFFF";

    if (value <= min) {
      hex = "FF0000";
    } else if (value >= min && value <= max) {
      hex = "FFFF00";
    } else {
      hex = "00FF00";
    }

    return (
      <div style={{
        width: 20,
        height: 20,
        background: `#${hex}`,
        borderRadius: 50,
        border: "1px solid #FFF"
      }} />
    )
  }

  const LegendaTable = (title: string, red: number, green: number) => {

    return (
      <div className="tableDashboard" id={"estoque_suple"}>
        <table>
          <thead style={{
            color: "#fff"
          }}>
            <th scope="col" rowSpan={2} style={{
              background: "#324440",
              width: "180px",
              textAlign: "left"
            }}>
              {title}
            </th>

            {[{
              legenda: `>${red}%`
            },
            {
              legenda: `>=${green} <=${red}%`
            },
            {
              legenda: `<=${green}%`
            }].map((item, index) => (
              <th scope="col" rowSpan={2} style={{
                background: "#324440",
                width: "150px",
                textAlign: "left"
              }}>
                {item.legenda}
              </th>
            ))}
          </thead>

          <tbody>
            <tr className="bg_white">
              <th scope="row"></th>
              <td style={{
                textAlign: "left"
              }}>{IconMarq(1, 30, 50)}</td>
              <td style={{
                textAlign: "left"
              }}>{IconMarq(35, 30, 50)}</td>
              <td style={{
                textAlign: "left"
              }}>{IconMarq(55, 30, 50)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="cattleMap_background">
      <DateModal />

      <div className="cattleMap_page">
        <div className="cattleMap_header">
          <BagFood height={72} />
          <div style={{
            display: "flex",
            flexDirection: "column",
          }}>
            <h2 className="cattleMap_title">
              Suplementação
            </h2>
            <h3 className="cattleMap_subtitle">
              Controle de estoque
            </h3>
          </div>
        </div>

        <FilterDateDashboard />

        {
          <div className="tableDashboard" id={"estoque_suple"}>
            <h2>Análise por Produto</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "230px",
                  textAlign: "left"
                }}>
                  NOME DO PRODUTO
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  CLASSIFICAÇÃO DO PRODUTO
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px",
                }} >
                  CONSUMO TOTAL NO PERÍODO (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  PERÍODO ANALISADO (dias)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px"
                }}>
                  ANIMAIS SUPLEMENTADOS
                </th>

                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  PESO MÉDIO DOS ANIMAIS SUPLEMENTADOS
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px"
                }}>
                  PESO TOTAL DOS ANIMAIS SUPLEMENTADOS
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  UAs TOTAL DOS ANIMAIS SUPLEMENTADOS
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px"
                }}>
                  CONSUMO MÉDIO NO PERÍODO (g/kgPV.dia)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  CONSUMO MÉDIO NO PERÍODO (g.UA/dia)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px"
                }}>
                  FREQUÊNCIA DE REPOSIÇÃO (média de dias)
                </th>

              </thead>

              <tbody>
                {
                  [{
                    nome: "Produto A",
                    classificacao: "Mineral",
                    con_total: 20810,
                    periodo_ana: 30,
                    ani_suplem: 8432,
                    pes_medio: 440,
                    pes_total: 3710080,
                    uas_total: 8245,
                    cons_medioKGPV: 82,
                    cons_medioUA: 84,
                    freq: 2.3
                  },
                  {
                    nome: "Produto B",
                    classificacao: "Mineral",
                    con_total: 0,
                    periodo_ana: 30,
                    ani_suplem: 0,
                    pes_medio: 0,
                    pes_total: 0,
                    uas_total: 0,
                    cons_medioKGPV: 0,
                    cons_medioUA: 0,
                    freq: 0
                  },
                  {
                    nome: "Produto C",
                    classificacao: "Mineral",
                    con_total: 12030,
                    periodo_ana: 30,
                    ani_suplem: 4332,
                    pes_medio: 300,
                    pes_total: 1299600,
                    uas_total: 2888,
                    cons_medioKGPV: 92,
                    cons_medioUA: 138,
                    freq: 2.3
                  },
                  {
                    nome: "Produto D",
                    classificacao: "Proteico",
                    con_total: 60060,
                    periodo_ana: 30,
                    ani_suplem: 2412,
                    pes_medio: 430,
                    pes_total: 1037160,
                    uas_total: 2305,
                    cons_medioKGPV: 830,
                    cons_medioUA: 868,
                    freq: 3.0
                  },
                  {
                    nome: "Produto E",
                    classificacao: "Proteico",
                    con_total: 93180,
                    periodo_ana: 30,
                    ani_suplem: 3511,
                    pes_medio: 380,
                    pes_total: 1334180,
                    uas_total: 2965,
                    cons_medioKGPV: 884,
                    cons_medioUA: 1047,
                    freq: 3.0
                  },
                  {
                    nome: "Produto F",
                    classificacao: "Ração",
                    con_total: 72120,
                    periodo_ana: 30,
                    ani_suplem: 640,
                    pes_medio: 510,
                    pes_total: 326400,
                    uas_total: 725,
                    cons_medioKGPV: 3756,
                    cons_medioUA: 3314,
                    freq: 4.0
                  }].map((item, index) => (
                    <tr className={index % 2 === 0 ? " bg_white" : "bg_gray"}>
                      <th scope="row">{item.nome}</th>
                      <td>{item.classificacao}</td>
                      <td>{item.con_total}</td>
                      <td>{item.periodo_ana}</td>
                      <td>{item.ani_suplem}</td>
                      <td>{item.pes_medio}</td>
                      <td>{item.pes_total}</td>
                      <td>{item.uas_total}</td>
                      <td>
                        <div style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between"
                        }}>
                          {IconMarq(item.cons_medioKGPV, 5, 15)}
                          {item.cons_medioKGPV}
                        </div>
                      </td>
                      <td>
                        <div style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between"
                        }}>
                          {IconMarq(item.cons_medioUA, 5, 15)}
                          {item.cons_medioUA}
                        </div>
                      </td>
                      <td>
                        <div style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between"
                        }}>
                          {IconMarq(item.freq, 15, 30)}
                          {item.freq}
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <div style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 10
            }}>
              {LegendaTable("LEGENDA CONSUMO", 15, 5)}
              {LegendaTable("LEGENDA FREQUÊNCIA", 30, 15)}
            </div>
          </div>
        }


        {
          <div className="tableDashboard" id={"analise_geral"}>
            <h2>Consumo por mês</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "280px",
                  textAlign: "left"
                }}>
                  RETIRO
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"
                }}>
                  Jan-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }} >
                  Fev-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"

                }}>
                  Mar-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }}>
                  Abr-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"

                }}>
                  May-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }} >
                  Jun-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"
                }}>
                  Jul-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }}>
                  Aug-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"

                }}>
                  Set-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }} >
                  Out-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"
                }}>
                  Nov-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "150px"
                }}>
                  Dec-22
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px"
                }}>
                  Média Anual
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "210px"
                }}>
                  Frequência de Reposição (média de dias)
                </th>
              </thead>

              <tbody>
                {
                  [{
                    retiro: "Retiro 1",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  },
                  {
                    retiro: "Retiro 2",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 3",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 4",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 5",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 6",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 7",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 8",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 9",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }, {
                    retiro: "Retiro 10",
                    jan: 0,
                    fev: 0,
                    mar: 0,
                    abr: 0,
                    may: 0,
                    jun: 0,
                    jul: 0,
                    aug: 0,
                    set: 0,
                    out: 0,
                    nov: 0,
                    dec: 0,
                    media: 0,
                    freq: 0
                  }].map((item, index) => (
                    <tr className={index % 2 === 0 ? " bg_white" : "bg_gray"}>
                      <th scope="row">{`${item.retiro}`}</th>
                      <td>{item.jan}</td>
                      <td>{item.fev}</td>
                      <td>{item.mar}</td>
                      <td>{item.abr}</td>
                      <td>{item.may}</td>
                      <td>{item.jun}</td>
                      <td>{item.jul}</td>
                      <td>{item.aug}</td>
                      <td>{item.set}</td>
                      <td>{item.out}</td>
                      <td>{item.nov}</td>
                      <td>{item.dec}</td>
                      <td>{item.media}</td>
                      <td>{item.freq}</td>
                    </tr>
                  ))
                }
              </tbody>
              <tfoot
                style={{
                  background: "#324440",
                  color: "#fff"
                }}
              >
                <th style={{
                  textAlign: "left"
                }}>Média Geral Fazenda</th>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
                <td>{0}</td>
              </tfoot>
            </table>
          </div>
        }

      </div>
    </div>
  );
}
