/*
  * Este componente compoe o componente AnimatedZoom, 
    os filhos inseridos nele só serão exibidos na visão 2X
*/

import { ReactNode } from "react";
import Constants from "../../../constants/Index";

type ContainerZoom2XProps = {
  children: ReactNode | Array<ReactNode>;
};

const ContainerZoom2X = ({ children }: ContainerZoom2XProps) => <>{children}</>;

ContainerZoom2X.displayName = Constants.COMPONENT_2X_NAME;

export default ContainerZoom2X;
