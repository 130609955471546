import { CSSProperties, useEffect, useRef, useState } from "react";
import Constants from "../../../../constants/Index";
import "./styles.css";

import axios from "../../../../services/axios";

import { Polygon } from "react-leaflet";
import { IFarms } from "../../../../@types/API/IFarm";
import { ICoord, ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { AnimatedZoom } from "../../../../components/Leaflet/AnimatedZoom/Index";
import ContainerZoom1X from "../../../../components/Leaflet/ContainerZoom1X/Index";
import ContainerZoom2X from "../../../../components/Leaflet/ContainerZoom2X/Index";
import ContainerZoom3X from "../../../../components/Leaflet/ContainerZoom3X/Index";
import ContainerZoom4X from "../../../../components/Leaflet/ContainerZoom4X/Index";
import { CustomControls } from "../../../../components/Leaflet/CustomControls/Index";
import { CustomToolTip } from "../../../../components/Leaflet/CustomToolTip/Index";
import {
  FlyToBounds,
  FlyToBoundsRefProps,
  IAnimationState
} from "../../../../components/Leaflet/FlyToBounds/Index";
import { BatchIcon } from "../../../../components/PaddocksMap/BatchIcon/Index";
import { LabelFarm1X } from "../../../../components/PaddocksMap/LabelFarm1X/Index";
import { PolylineFarm } from "../../../../components/PaddocksMap/PolylineFarm/Index";
import { SpecsMouseOver } from "../../../../components/PaddocksMap/SpecsMouseOver/Index";
import { useFilter } from "../../../../hooks/useFilter";
import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";


type ICustomFarm = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  bgColor: string;
};

type ICustomArea = {
  id: string;
  initials: string;
  coords: ICoords;
  name: string;
  bgColor: string;
  farmId?: string;
  batches: Array<{
    visualization: boolean;
    coord: ICoord | undefined;
    microAreaId: string;
  }>;
};

type ICustomMicroArea = {
  areaId: string;
  id: string;
  initials: string;
  coords: ICoords;
  currentMicroArea?: boolean;
  farmId?: string;
  areaInitials?: string;
  batchVisualization: boolean;
};

interface ICustomFarms extends Array<ICustomFarm> {}
interface ICustomAreas extends Array<ICustomArea> {}
interface ICustomMicroAreas extends Array<ICustomMicroArea> {}

export function Index() {
  const flytoBoundsRef = useRef<FlyToBoundsRefProps>(null);

  const [farmPolygons, setFarmPolygons] = useState<ICustomFarms>([]);

  const [currentArea, setCurrentArea] = useState<ICustomArea | undefined>(undefined);

  const { farmFilter, areaFilter, microAreaFilter, setHideMicroAreaFilter, batchFilter, setHideBatchFilter } =
    useFilter();

  const [areasPolygons, setAreasPolygons] = useState<ICustomAreas>([]);

  const [microAreasPolygons, setMicroAreasPolygons] = useState<ICustomMicroAreas>([]);

  const [stateOfAnimation, setStateOfAnimation] = useState<IAnimationState>("end");

  async function handleMicroAreasData(farms: IFarms, areas: ICustomAreas) {
    const microParsed = await Promise.all(
      farms.map(async (farm) => {
        const { data, status } = await axios.get("/micro-areas", { params: { farm_id: farm.id } });
        const response = data.map((microArea: ICustomMicroArea) => ({ ...microArea, farmId: farm.id }));
        return status === Constants.STATUS_CODE_OK ? response : [];
      })
    );
    const microAreasFlat = microParsed.flat();
    const responseMicroAreas = microAreasFlat.map((micro) => {
      return {
        areaId: micro.area_id,
        id: micro.id,
        initials: micro.initials,
        coords: micro.map_coords,
        currentMicroArea: micro.micro_area_route?.current_micro_area,
        farmId: micro.farmId,
        areaInitials: areas.find((f) => f.id === micro.area_id)?.initials,
        batchVisualization: handleBatchVisualization(micro.farmId as string, micro.area_id, micro.id),
      } as ICustomMicroArea;
    });

    setAreasPolygons((areas) =>
      areas.map((area) => {
        const microAreas = responseMicroAreas
          .filter((m) => m.areaId === area.id && m.currentMicroArea)
          .map((micro) => ({ coords: micro.coords, id: micro.id }));
        return {
          ...area,
          batches: microAreas.map((micro) => ({
            visualization: handleBatchVisualization(area.farmId as string, area.id, micro.id),
            coord: getCenterCoords(micro.coords),
            microAreaId: micro.id,
          })),
        };
      })
    );

    setMicroAreasPolygons(() => responseMicroAreas);
  }

  async function handleAreasData(farms: IFarms) {
    const areasData = await Promise.all(
      farms.map(async (farm) => {
        const { data, status } = await axios.get("/areas", { params: { farm_id: farm.id } });
        const response = data.map((area: ICustomArea) => ({ ...area, farmId: farm.id }));
        return status === Constants.STATUS_CODE_OK ? response : [];
      })
    );
    const response = areasData.flat().map((area) => ({
      id: area.id,
      initials: area.initials,
      coords: area.map_coords,
      name: area.name,
      bgColor: "",
      farmId: area.farmId,
      batches: [],
    }));
    setAreasPolygons(() => response);
    return response;
  }

  function handleBatchVisualization(farmId: string, areaId: string, microAreaId?: string | undefined) {
    if (batchFilter && farmId === farmFilter.id && areaFilter)
      return areaFilter.id === areaId && microAreaId === batchFilter.microAreaId;
    if (areaFilter && farmId === farmFilter.id) return areaFilter.id === areaId;
    return true;
  }

  function handleFocusCenter() {
    const coords: ICoords = areaFilter ? areaFilter.coords : farmFilter.coords;
    if (coords && flytoBoundsRef.current?.canZoomAnimate(coords, 50)) {
      flytoBoundsRef.current.flyToBounds(coords, { duration: areaFilter ? 0.5 : 5 });
    } else setStateOfAnimation("end");
  }

  useEffect(() => {
    setMicroAreasPolygons((microAreas) =>
      microAreas.map((microArea) => {
        return {
          ...microArea,
          batchVisualization: handleBatchVisualization(
            microArea.farmId as string,
            microArea.areaId,
            microArea.id
          ),
        };
      })
    );
    setAreasPolygons((areas) =>
      areas.map((area) => ({
        ...area,
        batches: area.batches.map((batch) => {
          return {
            ...batch,
            visualization: handleBatchVisualization(area.farmId as string, area.id, batch.microAreaId),
          };
        }),
      }))
    );
  }, [batchFilter]);

  useEffect(() => {
    if (farmFilter && flytoBoundsRef) {
      handleFocusCenter();
      setCurrentArea(undefined);
    }
  }, [farmFilter, flytoBoundsRef]);

  useEffect(() => {
    if (farmFilter) {
      if (areaFilter) {
        const { id, initials, coords, name } = areaFilter as ICustomArea;
        setCurrentArea({ id, initials, coords, name, farmId: farmFilter.id } as ICustomArea);
      }
      setMicroAreasPolygons((microAreas) =>
        microAreas.map((microArea) => ({
          ...microArea,
          batchVisualization: handleBatchVisualization(
            microArea.farmId as string,
            microArea.areaId,
            microArea.id
          ),
        }))
      );
      setAreasPolygons((areas) =>
        areas.map((area) => ({
          ...area,
          batches: area.batches.map((batch) => ({
            ...batch,
            visualization: handleBatchVisualization(area.farmId as string, area.id, batch.microAreaId),
          })),
        }))
      );
      handleFocusCenter();
    }
  }, [areaFilter]);

  useEffect(() => {
    axios
      .get("/farms/user")
      .then(async ({ data }) => {
        const response: IFarms = data;
        setFarmPolygons(() =>
          response.map(
            ({ id, initials, map_coords: coords, name }) => ({ id, initials, coords, name } as ICustomFarm)
          )
        );
        const areas = await handleAreasData(data);
        handleMicroAreasData(data, areas);
        const [farm] = response;
        if (farm || farmFilter) {
          const coords = farmFilter ? farmFilter.coords : farm.map_coords;
          if (flytoBoundsRef.current?.canZoomAnimate(coords as ICoords))
            flytoBoundsRef.current?.flyToBounds(coords as ICoords);
        }
      })
      .catch((error) => console.log(error));
    document.title = Constants.TITLE_PADDOCKS_MAP_INDEX;
    setHideMicroAreaFilter(false);
    setHideBatchFilter(false);
  }, []);

  function handleBGOppacity(farmId: string, areaId: string) {
    if (farmId !== farmFilter.id) return 0.55;
    if (areaFilter && areaId !== areaFilter.id) return 0.1;
    return 0.55;
  }

  function handleBorderColor(farmId: string, areaId: string) {
    if (farmId !== farmFilter.id) return Constants.PADDOCKTIME_COLORS.WHITE;
    if (areaFilter && areaId !== areaFilter.id) return Constants.LEAFLET_UNFOCUS.color;
    return Constants.PADDOCKTIME_COLORS.WHITE;
  }

  function handleMicroAreaBGColor(farmId: string, areaId: string, microId?: string) {
    if (farmId === farmFilter.id) {
      if (microAreaFilter && microAreaFilter.id === microId) return Constants.LEAFLET_NORMAL_BLUE.fillColor;
      if (microAreaFilter || (areaFilter && areaId !== areaFilter.id)) return Constants.LEAFLET_UNFOCUS.color;
    }
    return Constants.LEAFLET_NORMAL_BLUE.fillColor;
  }

  function handleAreaBGColor(farmId: string, areaId: string) {
    if (farmId === farmFilter.id)
      if (areaFilter && areaId !== areaFilter.id) return Constants.LEAFLET_UNFOCUS.color;
    return Constants.LEAFLET_NORMAL_BLUE.fillColor;
  }

  return (
    <div className="defaultmap__container">
      <FlyToBounds ref={flytoBoundsRef} onStateAnimation={(state) => setStateOfAnimation(state)}>
        {farmPolygons.map((farm, key) => (
          <AnimatedZoom key={key} coordsToBoundZoom={farm.coords} updateZoomVisualization={stateOfAnimation}>
            <ContainerZoom1X>
              <LabelFarm1X
                key={farm.initials}
                position={getCenterCoords(farm.coords)}
                initials={`${farm.initials}${
                  currentArea && currentArea.farmId === farm.id ? `-${currentArea.initials}` : ""
                }`}
              />
            </ContainerZoom1X>

            <ContainerZoom2X>
              <Polygon
                key={farm.id}
                fillColor={Constants.LEAFLET_NORMAL_BLUE.fillColor}
                color={Constants.LEAFLET_NORMAL_BLUE.color}
                positions={farm.coords}
                fillOpacity={0.55}
                fill={true}
                weight={2}
                noClip
              />
            </ContainerZoom2X>
            <ContainerZoom3X>
              <PolylineFarm path={farm.coords} />
              {areasPolygons
                .filter((f) => f.farmId === farm.id)
                .map((area, key) => {
                  return (
                    <div key={key}>
                      <Polygon
                        key={area.id}
                        pathOptions={{
                          fillOpacity: handleBGOppacity(area.farmId as string, area.id),
                          color: handleBorderColor(area.farmId as string, area.id),
                          fillColor: handleAreaBGColor(area.farmId as string, area.id),
                        }}
                        positions={area.coords}
                        fill={true}
                        weight={2}
                        noClip
                      />
                      {area.batches.map((batch, key) => {
                        return (
                          batch.visualization && batch.coord && <BatchIcon key={key} coords={batch.coord} />
                        );
                      })}
                    </div>
                  );
                })}
            </ContainerZoom3X>

            <ContainerZoom4X>
              <PolylineFarm path={farm.coords} />
              {areasPolygons
                .filter((f) => f.farmId === farm.id)
                .map((area, key) => {
                  return (
                    <div key={key}>
                      <Polygon
                        key={area.id}
                        pathOptions={{
                          color: handleBorderColor(area.farmId as string, area.id),
                          fillColor: handleAreaBGColor(area.farmId as string, area.id),
                        }}
                        positions={area.coords}
                        fill={false}
                        weight={1}
                        noClip
                      />
                    </div>
                  );
                })
              }

              {microAreasPolygons
                .filter((f) => f.farmId === farm.id)
                .map((microArea, key) => (
                  <div key={key}>
                    <Polygon
                      key={microArea.id}
                      pathOptions={{
                        fillOpacity: handleBGOppacity(microArea.farmId as string, microArea.areaId),
                        color: handleBorderColor(microArea.farmId as string, microArea.areaId),
                        fillColor: handleMicroAreaBGColor(
                          microArea.farmId as string,
                          microArea.areaId,
                          microArea.id
                        ),
                      }}
                      positions={microArea.coords}
                      fill={true}
                      weight={2}
                      noClip
                    >
                      <CustomToolTip>
                        <SpecsMouseOver
                          className="havebatch"
                          microAreaLabel={`${farm?.initials}-${microArea.areaInitials}-${microArea.initials}`}
                          type={"batch"}
                        />
                      </CustomToolTip>
                    </Polygon>
                    {microArea.currentMicroArea && microArea.batchVisualization && (
                      <BatchIcon coords={getCenterCoords(microArea.coords)} />
                    )}
                  </div>
                ))}
            </ContainerZoom4X>
          </AnimatedZoom>
        ))}
        <CustomControls defaultLocation={farmFilter ? farmFilter.coords : undefined} />
      </FlyToBounds>
    </div>
  );
}
