import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dropdown } from "../../EditOnboarding/components/Select";
import { useEditOnboarding } from "../../../hooks/useEditOnboarding";
import { ButtonOnlyTitle } from "../../Buttons/ButtonOnlyTitle/Index";
import { LeafletStaticMap } from "../../Leaflet/LeafletStaticMap/Index";
import { ReactComponent as Animais } from "../../../assets/svg/icons/animais.svg";
import { IOptionProps, IOptionsProps } from "../../../@types/reactSelect/IOption";
import { CounterInputEdit } from "../../EditOnboarding/components/Input/CounterInput/Index";
import {
    FlyToBounds,
    FlyToBoundsRefProps,
} from "../../Leaflet/FlyToBounds/Index";
import { IBatch } from "../../../@types/API/IBatches";
import Constants from "../../../constants/Index";
import axios from "../../../services/axios"
import { Polygon } from "react-leaflet";
import * as yup from 'yup'
import "./styles.css"

const optionsTypePasture: IOptionsProps = [
    {
        label: "Sem monitoramento",
        value: 1,
    },
    {
        label: "Contínuo",
        value: 2,
    },
    {
        label: "Rotacionado",
        value: 3,
    },
    {
        label: "Em faixa",
        value: 4,
    },
]

const optionsTypeMonitors: IOptionsProps = [
    {
        label: "Altura",
        value: 1,
    },
    {
        label: "Dias",
        value: 2,
    },
    {
        label: "Nota",
        value: 3,
    }
]

export default function CreatePastureManagement() {
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const pastureManagementSchema = yup.object()
        .shape({
            batch: yup
                .object()
                .nullable()
                .required('Lote é necessário.'),
            type: yup
                .object()
                .nullable()
                .required('Tipo de pastejo é necessário.'),
            monitor: yup
                .object()
                .nullable()
                .required('Tipo de monitoramento é necessário.'),
            heightInput: yup
                .number()
                .when(['type', 'monitor'], {
                    is: (type: IOptionProps, monitor: IOptionProps) => 
                        (type?.value == '2' || type?.value == '3') && monitor?.value == '1',
                    then: yup.number().test('', 'Altura de entrada é necessária.', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            heightOutput: yup
                .number()
                .when(['type', 'monitor'], {
                    is: (type: IOptionProps, monitor: IOptionProps) => 
                        (type?.value == '2' || type?.value == '3') && monitor?.value == '1',
                    then: yup.number().test('', 'Campo obrigatório não informado. ', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            occupationDays: yup
                .number()
                .when(['type', 'monitor'], {
                    is: (type: IOptionProps, monitor: IOptionProps) =>
                        type?.value == '3' && monitor?.value == '2',
                    then: yup.number().test('', 'Campo obrigatório não informado. ', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            restDays: yup
                .number()
                .when(['type', 'monitor'], {
                    is: (type: IOptionProps, monitor: IOptionProps) =>
                        type?.value == '3' && monitor?.value == '2',
                    then: yup.number().test('', 'Campo obrigatório não informado. ', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            rate: yup
                .number()
                .when(['type', 'monitor'], {
                    is: (type: IOptionProps, monitor: IOptionProps) => 
                        (type?.value == '2' || type?.value == '3') && monitor?.value == '3',
                    then: yup.number().test('', 'Campo obrigatório não informado. ', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            range: yup
                .number()
                .when(['type'], {
                    is: (type: IOptionProps) => type?.value == '4',
                    then: yup.number().test('', 'Campo obrigatório não informado. ', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
            frequency: yup
                .number()
                .when(['type'], {
                    is: (type: IOptionProps) => type?.value != '1',
                    then: yup.number().test('', 'Campo obrigatório não informado. ', (value) => {
                        setShowErrorMessage(true)
                        return !!value
                    })
                }),
        })

    const [optionsBatches, setOptionsBatches] = useState<IOptionsProps>();
    const [optionsTypeManagement, setOptionsTypeManagement] = useState<IOptionsProps>(optionsTypePasture);
    const [optionsTypeMonitor, setOptionsTypeMonitor] = useState<IOptionsProps>(optionsTypeMonitors);

    const [microAreaRoute, setMicroAreaRoute] = useState<any>();
    const [area, setArea] = useState<IArea>();
    const [batch, setBatch] = useState<IBatch>();

    const flytoBoundsRef = useRef<FlyToBoundsRefProps>();
    const {
        defaultValue,
        idSelected,
    } = useEditOnboarding();

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
        trigger
    } = useForm({
        defaultValues: defaultValue,
        resolver: yupResolver(pastureManagementSchema),
    });

    useEffect(() => {
        axios
            .get("batches")
            .then((response) => {
                setOptionsBatches(response.data.map((batch: any) => ({
                    label: `${batch.farm.initials}-${batch.area.initials}-${batch.initials}`,
                    value: batch.id,
                    info: batch
                })) as IOptionsProps);
            });
    }, []);

    function onSubmit(data: any) {
        console.log(data);
    }

    const typePasture = watch("type");
    const typeMonitor = watch("monitor");

    const [heightInputError, setHeightInputError] = useState("");
    const [heightOutputError, setHeightOutputError] = useState("");
    const [occupationDaysError, setOccupationDaysError] = useState("");
    const [restDaysError, setRestDaysError] = useState("");
    const [rangeError, setRangeError] = useState("");
    const [rateError, setRateError] = useState("");
    const [frequencyError, setFrequencyError] = useState("");

    function handleChange(name: string) {
        if (showErrorMessage) {
            trigger(name)
        }
    }

    const heightOptions = ["2", "3"]
    const daysOptions = ["3"]
    const rateOptions = ["2", "3"]

    return (
        <>
            <div className="body__formPastureManagement">
                <form onSubmit={() => handleSubmit(onSubmit)}>
                    <div className="firstColumn columnPastureManagement">
                        <div>
                            <span className="labelCreateEvaluator">Lote</span>
                            <div className="filters-dropdown filters-dropdown__full-width">
                                <Dropdown
                                    options={optionsBatches}
                                    placeholderText="Selecionar lote..."
                                    getSelectedData={(el) => {
                                        setBatch(el.info)
                                        setArea(el.info.area)
                                        setMicroAreaRoute(el.info.micro_area_route)
                                    }}
                                    name={"batch"}
                                    control={control}
                                    closeMenuOnSelect={true}
                                    isDisabled={idSelected != 0}
                                    noOptionsMessage={() => <span>Nenhum lote disponível</span>}
                                />
                            </div>
                        </div>

                        <div>
                            <span className="labelCreateEvaluator">Tipo de Pastejo</span>
                            <div className="filters-dropdown filters-dropdown__full-width">
                                <Dropdown
                                    options={optionsTypeManagement}
                                    placeholderText="Selecionar o tipo de pastejo..."
                                    getSelectedData={(el) => { }}
                                    name={"type"}
                                    control={control}
                                    closeMenuOnSelect={true}
                                    isDisabled={idSelected != 0}
                                    noOptionsMessage={() => <span>Nenhum tipo de pastejo disponível</span>}
                                />
                            </div>
                        </div>

                        {(typePasture?.value != 1) ?
                            <>
                                {!(typePasture?.value == 4) &&
                                    <>
                                        <div>
                                            <span className="labelCreateEvaluator">Tipo de Monitoramento</span>
                                            <div className="filters-dropdown filters-dropdown__full-width">
                                                <Dropdown
                                                    options={
                                                        optionsTypeMonitor
                                                            .filter((el) => typePasture?.value == 2 ? el.value != 2 : true)
                                                    }
                                                    placeholderText="Selecionar o tipo de monitoramento..."
                                                    getSelectedData={(el) => { }}
                                                    name={"monitor"}
                                                    control={control}
                                                    closeMenuOnSelect={true}
                                                    isDisabled={idSelected != 0}
                                                    noOptionsMessage={() => <span>Nenhum tipo de monitoramento disponível</span>}
                                                />
                                            </div>
                                        </div>
                                    </>
                                }

                                {typeMonitor?.value == '1' && heightOptions.includes(typePasture?.value) &&
                                    <>
                                        <div>
                                            <span className="labelCreateEvaluator">Altura da entrada</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    setValue("heightInput", value)
                                                    if (value != 0) {
                                                        setHeightInputError("")
                                                    }
                                                    else setHeightInputError(`Altura de entrada é necessária.`)
                                                    handleChange("heightInput")
                                                }}
                                                defaultValue={0}
                                                name={"heightInput"}
                                                control={control}
                                                label={"cm"}
                                                errorText={showErrorMessage ? heightInputError : ""}
                                                max={999}
                                            />
                                        </div>

                                        <div>
                                            <span className="labelCreateEvaluator">Altura da saída</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    setValue("heightOutput", value)
                                                    if (value != 0) {
                                                        setHeightOutputError("")
                                                    }
                                                    else setHeightOutputError(`Altura de saída é necessária.`)
                                                    handleChange("heightOutput")
                                                }}
                                                defaultValue={0}
                                                name={"heightOutput"}
                                                control={control}
                                                max={999}
                                                label={"cm"}
                                                errorText={showErrorMessage ? heightOutputError : ""}
                                            />
                                        </div>
                                    </>
                                }

                                {typeMonitor?.value == '2' && daysOptions.includes(typePasture?.value) &&
                                    <>
                                        <div>
                                            <span className="labelCreateEvaluator">Dias de ocupação</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    setValue("occupationDays", value)
                                                    if (value != 0) {
                                                        setOccupationDaysError("")
                                                    }
                                                    else setOccupationDaysError(`Dias de ocupação é necessário.`)
                                                    handleChange("occupationDays")
                                                }}
                                                defaultValue={0}
                                                name={"occupationDays"}
                                                control={control}
                                                max={999}
                                                label={"dias"}
                                                errorText={showErrorMessage ? occupationDaysError : ""}
                                            />
                                        </div>

                                        <div>
                                            <span className="labelCreateEvaluator">Dias de descanso</span>
                                            <CounterInputEdit
                                                isInline
                                                onChangevalue={(value) => {
                                                    setValue("restDays", value)
                                                    if (value != 0) {
                                                        setRestDaysError("")
                                                    }
                                                    else setRestDaysError(`Dias de descanso é necessário.`)
                                                    handleChange("restDays")
                                                }}
                                                defaultValue={0}
                                                name={"restDays"}
                                                control={control}
                                                max={999}
                                                label={"dias"}
                                                errorText={showErrorMessage ? restDaysError : ""}
                                            />
                                        </div>
                                    </>
                                }

                                {typeMonitor?.value == '3' && rateOptions.includes(typePasture?.value) &&
                                    <div>
                                        <span className="labelCreateEvaluator">Nota do pasto (0 a 10)</span>
                                        <CounterInputEdit
                                            isInline
                                            onChangevalue={(value) => {
                                                setValue("rate", value)
                                                if (value != 0) {
                                                    setRateError("")
                                                }
                                                else setRateError(`Nota do pasto é necessário.`)
                                                handleChange("rate")
                                            }}
                                            defaultValue={0}
                                            name={"rate"}
                                            control={control}
                                            max={10}
                                            errorText={showErrorMessage ? rateError : ""}
                                        />
                                    </div>
                                }

                                {typePasture?.value == '4' &&
                                    <div>
                                        <span className="labelCreateEvaluator">Quantidade em faixas</span>
                                        <CounterInputEdit
                                            isInline
                                            onChangevalue={(value) => {
                                                setValue("range", value)
                                                if (value != 0) {
                                                    setRangeError("")
                                                }
                                                else setRangeError(`Quantidade é necessário.`)
                                                handleChange("range")
                                            }}
                                            defaultValue={0}
                                            name={"range"}
                                            control={control}
                                            max={999}
                                            errorText={showErrorMessage ? rangeError : ""}
                                        />
                                    </div>
                                }

                                <div>
                                    <span className="labelCreateEvaluator">Frequência de monitoramento</span>
                                    <CounterInputEdit
                                        isInline
                                        onChangevalue={(value) => {
                                            setValue("frequency", value)
                                            if (value != 0) {
                                                setFrequencyError("")
                                            }
                                            else setFrequencyError(`Frequência de monitoramento é necessária.`)
                                            handleChange("frequency")
                                        }}
                                        defaultValue={0}
                                        name={"frequency"}
                                        control={control}
                                        max={999}
                                        label={"semanas"}
                                        errorText={showErrorMessage ? frequencyError : ""}
                                    />
                                </div>
                            </> : <></>
                        }
                    </div>
                </form >

                <div
                    className="batch_column_mapa"
                    style={{ height: 550 }}
                >
                    <div
                        className={`area__drawarea--container`}
                        style={{ height: 400 }}
                    >
                        <LeafletStaticMap>
                            <FlyToBounds ref={flytoBoundsRef} fitCoords={area?.map_coords}>
                                <div className="FlytoBounds_container">
                                    <div className="subtitle__animals">
                                        <Animais />
                                        <span id={`numberAnimals`}>
                                            {batch?.animals_quantity || 0}
                                        </span>
                                    </div>
                                </div>
                                {/* area */}
                                <Polygon
                                    positions={area?.map_coords || []}
                                    color={Constants.LEAFLET_UNFOCUS.color}
                                />
                                {/* batch */}
                                {microAreaRoute &&
                                    microAreaRoute.map((micro: any) => (
                                        <Polygon
                                            positions={micro.map_coords || []}
                                            color={Constants.LEAFLET_UNFOCUS_TRANSLUCENT.color}
                                            fillColor={Constants.LEAFLET_NORMAL_BLUE.fillColor}
                                            fillOpacity={
                                                Constants.LEAFLET_UNFOCUS_TRANSLUCENT.fillOpacity
                                            }
                                        />
                                    ))}
                            </FlyToBounds>
                        </LeafletStaticMap>
                    </div>
                </div>
            </div>


            <footer style={{ width: 300 }}>
                <ButtonOnlyTitle
                    title={"Salvar"}
                    theme="info"
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={Object.keys(errors).length !== 0}
                />
            </footer>
        </>
    )
}