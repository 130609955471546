import React from "react";
import "./style.css";

interface MessageBalloonProps {
  text: string;
  pointed?: boolean;
  url?: string;
}

export const MessageBalloon: React.FC<MessageBalloonProps> = ({
  text,
  pointed = false,
  url
}) => {
  return (
    <div className={`balloon ${pointed && "pointed"}`}>
      {url ? <a href={url}>{text}</a> : <>{text}</>}
    </div>
  );
};