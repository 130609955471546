import axios from './axios'

const getFarmsByUser = (): Promise<IFarm[]> => {
    return axios.get<IFarm[]>(`/farms/user`).then(({ data }) => {
        return data
    })
}

export const farmService = {
    getFarmsByUser
}