import { components } from "react-select";

export const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props} className="lot__select--category">
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        {props.label}
        <br />
        <span>{props.data.info}</span>
      </components.Option>
    </div>
  );
};

