import { Scope } from "@unform/core";
import { Form } from "@unform/web";
import { useEffect, useRef, useState } from "react";
import { IFarms } from "../../../../../@types/API/IFarm";
import { ILots } from "../../../../../@types/app/ILot";
import { ILotOnboarding } from "../../../../../@types/Onboarding/ILot";
import { TextInput } from "../../../../../components/Inputs/TextInputs/TextInput/Index";
import { FarmLots } from "../../../../../components/Onboarding/Register/CreateLot/New/Farm";
import { FooterControlls } from "../../../../../components/Onboarding/Template/FooterControlls/Index";
import Constants from "../../../../../constants/Index";
import { EditValues } from "../../../../../hooks/EditValues";
import { useOnboarding } from "../../../../../hooks/useOnboarding";
import axios from "../../../../../services/axios";
import * as yup from "yup";
import "./../styles.css"

interface CreateLotsProps {
  onlyRead?: boolean;
  onBoarding?: boolean;
}

const batchesSchema = yup.object().shape({
  farms: yup.array().of(
    yup.object().shape({
      areas: yup.array().of(
        yup.object().shape({
          lots: yup.array().of(
            yup.object().shape({
              initials: yup
                .string()
                .matches(/^\S+(\s+\S+)*$/, "Esta sigla não é válida.")
                .required("SIGLA é necessário."),
              categoryMap: yup
                .array()
                .min(1, "Categoria é necessário.")
                .required("Categoria é necessário."),
              categories: yup
                .array().of(yup
                  .object({
                    quantity: yup.number()
                      .min(1, "Adicione animais para essa categoria.")
                  })),
              current_micro_area: yup
                .object({
                  label: yup.string(),
                  value: yup.string(),
                })
                .nullable()
                .required("Pasto atual é necessário."),
              micro_area_route_ids: yup
                .array()
                .min(1, "Rota de pastejo é necessário.")
                .required("Rota de pastejo é necessário."),
            })
          ),
        })
      ),
    })
  ),
});

export function CreateLotsNew({
  onlyRead = false,
  onBoarding = false,
}: CreateLotsProps) {
  const mainRef = useRef<HTMLElement>(null);
  const formRef = useRef<HTMLFormElement>();
  const [farms, setFarms] = useState<IFarms>([]);
  const [initData, setInitData] = useState([]);
  const { payload, setPayload, setBlockNextStep } = useOnboarding();
  const { refreshValues } = EditValues();


  useEffect(() => {
    setInitData([]);
    if (!onlyRead && payload && payload.farms) {
      setFarms(payload.farms as IFarms);
    } else if (onlyRead) {
      axios.get("/micro-areas/user").then(({ data }) => {
        setInitData(data);
      });
      axios.get("/farms/user").then(({ data }) => {
        setFarms(data);
      });
    }
  }, [refreshValues]);

  async function savePayload() {
    const formData: ILots = formRef.current?.getData()
      .farms.map((farm: any) => farm
        .areas.map((area: any) => area
          .lots.map(((lot: any) => lot)).flat()).flat()).flat();

    const data = formData.map((lot) => {
      const validCategories = lot.categories?.filter((element) => {
        return element !== null && element !== undefined;
      });
      return {
        ...lot,
        current_micro_area: lot.current_micro_area,
        farm_id: lot.farm_id,
        initials: lot.initials,
        area_id: lot.area_id,
        categories: validCategories,
        batch_capacity_rate: lot.batch_capacity_rate,
        micro_area_route_ids: lot.micro_area_route_ids,
        current_micro_area_id: lot.current_micro_area?.value,
        total_ua: lot.total_ua,
      } as ILotOnboarding;
    });
    setPayload((payload) => {
      const response = { ...payload, batches: data };
      localStorage.setItem(
        Constants.LOCALSTORAGE_PAYLOAD,
        JSON.stringify(response)
      );
      return response;
    });
  }

  async function validateLotForm(data?: any) {
    const formData = data || formRef.current?.getData();
    formRef.current?.setErrors({});
    const errors = {} as any;
    let isValid = false;
    if (formData) {
      try {
        await batchesSchema.validate(formData, { abortEarly: false });
        isValid = true;
      } catch (err) {
        if (!onlyRead) setBlockNextStep(true);
        if (err instanceof yup.ValidationError) {
          err.inner.forEach((e) => {
            errors[e.path as string] = e.message;
          });
        }
      } finally {
        if (formRef && formRef.current) {
          formRef.current.setErrors(errors);
        }
      }
      return isValid;
    }
  }

  async function handleForm() {
    if (await validateLotForm()) {
      savePayload();
      return true;
    }
    return false;
  }

  async function handleChanges(index?: number) {
    const isValid = await validateLotForm();
    if (!onlyRead) {
      setBlockNextStep(!isValid);
    }
  }

  function mountBatches(areaId: string | undefined) {
    if (payload.batches && areaId) {
      const lots = payload.batches.filter(f => f.area_id === areaId).map((batch: any) => ({
        ...batch,
        isNew: true,
        keyFarm: "1",
        keyArea: "1",
        keyBatch: "0",
        farmAreaInitials: "MA-OE",
      }))
      return { lots }
    }
  }

  function mountAureas(farmId: string | undefined) {
    if (payload.areas && farmId) {
      const areasArr = payload.areas.filter(f => f.farm_id === farmId)
      const areas = areasArr.map(area => mountBatches(area.uuid))
      return { areas }
    }
  }


  useEffect(() => {
    if (payload.farms) {
      const farms = payload.farms.map((farm: any) => mountAureas(farm.uuid))
      validateLotForm({
        form: "batches",
        farms
      })
    }
  }, [])



  return (
    <>
      <main ref={mainRef as any}>
        <section className="createLots__container">
          <h1 id="pageStart">Criação de Lotes</h1>
          <Form ref={formRef as any} onSubmit={t => console.log('form ', t)}>
            <TextInput hidden={true} value={"batches"} name="form" />
            {farms.map((farm, key) => (
              <Scope key={key} path={`farms[${key}]`}>
                <div className="batch_column">
                  <FarmLots
                    formRef={formRef as any}
                    farm={farm}
                    keyFarm={key}
                    initValues={initData}
                    onBoarding={onBoarding}
                    handleAnyChanges={() => handleChanges(key)}
                  />
                </div>
              </Scope>
            ))}
          </Form>
        </section>
      </main>
      {!onlyRead && (
        <footer>
          <FooterControlls
            nextStepEvent={async () => {
              return handleForm();
            }}
          />
        </footer>
      )}
    </>
  );
}
