import { createContext, useState, ReactNode, useEffect } from "react";
import axios from "../services/axios";
import { UserProps } from "../pages/Session/Login/Index";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Constants from "../constants/Index";

export type UserContextProps = {
  username: string;
  role: string;
  phone: string;
};

type SignInProps = {
  statusCode: number;
  message?: string;
  changePassword?: string;
  token?: string;
};

type AuthContextData = {
  user: UserContextProps;
  signIn: (formData: UserProps) => Promise<SignInProps>;
  decodeToken: (token: string) => void;
};

type AuthContextProps = {
  children: ReactNode;
};

const AuthContext = createContext({} as AuthContextData);

function AuthProvider({ children }: AuthContextProps) {
  const [user, setUser] = useState<UserContextProps>({} as UserContextProps);
  const navigate = useNavigate();

  useEffect(() => {
    var token = localStorage.getItem("token");
    if (token) {
      const decode = decodeToken(token)
      setUser(decode)
    }
  }, []);


  function decodeToken(token: string) {
    try {
      const decode: any = jwt_decode(token);
      const loginUser = {
        username: decode?.USER_NAME,
        phone: decode?.sub,
        role: decode?.ROLE,
      } as UserContextProps;
      return loginUser
    } catch (error) {
      return {} as UserContextProps;
    }
  }

  useEffect(() => {
    if (user.username)
      if (user.role !== "MASTER" && (Constants.PERMISSION_ROUTES).includes(window.location.pathname)) {
        if (user.role === "GERENTE") {
          if (!(Constants.GERENTE_ROUTES).includes(window.location.pathname))
            navigate("/mapadepastos")
        } else
          navigate("/mapadepastos")
      }
  }, [user, window.location.pathname]);

  // Isso Lida com o login de qualquer usuário
  async function signIn(formData: UserProps) {
    return await axios
      .post("/auth/login", formData)
      // Se tudo ocorreu bem retornamos sucesso para que chamou
      .then(async ({ data, status }) => {
        const decode = decodeToken(data["token"])
        setUser(decode)

        return {
          message: "usuário autenticado",
          statusCode: status,
          changePassword: data["change_password"],
          token: data["token"],
        } as SignInProps;
      })
      .catch((error) => {
        if (error.response) {
          // Se cheamos aqui ocorreu um erro tratado na API
          return {
            message: error.response.data,
            statusCode: error.response.status,
          } as SignInProps;
        }
        // Se cheamos aqui ocorreu um erro não tratado na API
        return {
          message: "Erro no Servidor",
          statusCode: 500,
        };
      });
  }

  return <AuthContext.Provider value={{ user, signIn, decodeToken }}>{children}</AuthContext.Provider>;
}

export { AuthProvider, AuthContext };
