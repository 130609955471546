import "../styles.css";
import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as LogoPastejoIcon } from "../../../assets/svg/logo/logoPastejo.svg";
import { splitArray } from "../../../utils/arrays/split";
import { StaticMapRender } from "../../EditOnboarding/components/StaticMapRender/Index";
import Constants from "../../../constants/Index";
import { GoogleMap, Polygon, Polyline } from "@react-google-maps/api";
import { useGoogleMaps } from "../../../hooks/useGoogleMaps";
import { ReactComponent as AreaIcon } from "../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as LocateIcon } from "../../../assets/svg/icons/point.svg";
import { ICoord } from "../../../@types/GoogleMaps/ICoord";
import { capitalize } from "../../../utils/strings/capitalize";
import { IOptionProps } from "../../../@types/reactSelect/IOption";
import { format } from "date-fns";

interface PDFEvaluationProps {
    farm: IOptionProps;
    areas: IOptionProps[];
    dataReport: IReportMicroAreaPastureEvaluator;
}

interface AvaliacaoPDFProps {
    items?: {
        item: string;
        result: string | undefined;
    }[]
}

export const PDFEvaluation = ({
    farm,
    areas,
    dataReport
}: PDFEvaluationProps) => {
    const [mapPages, setMapPages] = useState(new Map<string, number>());
    const [summaryPages, setSummaryPages] = useState(0);
    const pageRef = useRef(null);
    const [pageHeight, setPageHeight] = useState(0);

    useEffect(() => {
        if (pageRef.current) {
            setPageHeight(1440);
        }
    }, [pageRef]);

    useEffect(() => {
        setSummaryPages(splitArray(Array.from(mapPages.keys()), 31).length)
    }, [mapPages]);

    var mapNumbers = new Map();
    var mapText = new Map();

    let images: any[][] = []
    let result: any[][] = []

    mapText.set("ANUAL_MOLE", "Anual mole")
    mapText.set("FOLHA_LARGA", "Folha larga")
    mapText.set("FOLHA_ESTREITA", "Folha estreita")
    mapNumbers.set(1, "primária")
    mapNumbers.set(2, "secundária")
    mapNumbers.set(3, "terciária")

    const [imagesEvaluation, setImagesEvaluation] = useState<any[][]>([]);

    useEffect(() => {

        const report = dataReport as IReportMicroAreaPastureEvaluator
        report?.micro_areas_task_info?.forEach((microArea: IMicroAreasTaskInfo) => {
            const el = [];

            microArea.pasture_evaluation_fodder_content?.fodder_content.map((fodder, index) => {
                el.push({
                    label: `Presença de forragem ${mapNumbers.get(index + 1)}`,
                    url: fodder.photo_details?.url
                })
            })

            microArea.pasture_evaluation_invasive_content?.invasive_content.map((invasive, index) => {
                if (invasive.photo_details?.url && microArea.pasture_evaluation_invasive_content)
                    el.push({
                        label: `Presença de invasoras ${microArea.pasture_evaluation_invasive_content.invasive_content?.length > 1 ?
                            index + 1 : ""}`,
                        url: invasive.photo_details?.url
                    })
            })

            microArea.pasture_evaluation_plague_content?.plague_content.map((plague, index) => {
                if (plague.photo_details?.url && microArea.pasture_evaluation_plague_content)
                    el.push({
                        label: `Presença de pragas ou doenças ${microArea.pasture_evaluation_plague_content.plague_content?.length > 1 ?
                            index + 1 : ""}`,
                        url: plague.photo_details?.url
                    })
            })

            if (microArea.pasture_evaluation_waterhole_content?.photo_details?.url)
                el.push({
                    label: `Presença de aguada`,
                    url: microArea.pasture_evaluation_waterhole_content?.photo_details?.url
                })

            if (microArea?.pasture_evaluation_suplement_content?.photo_details?.url)
                el.push({
                    label: `Presença de depósito de suplemento`,
                    url: microArea?.pasture_evaluation_suplement_content?.photo_details?.url
                })

            if (microArea?.pasture_evaluation_trough_content?.photo_details?.url)
                el.push({
                    label: `Presença de cocho`,
                    url: microArea?.pasture_evaluation_trough_content?.photo_details?.url
                })

            if (microArea?.pasture_evaluation_fence_content?.photo_details?.url)
                el.push({
                    label: `Tipo de cerca`,
                    url: microArea?.pasture_evaluation_fence_content?.photo_details?.url
                })

            if (microArea?.pasture_evaluation_terrain_content?.photo_details?.url)
                el.push({
                    label: `Tipo de relevo`,
                    url: microArea?.pasture_evaluation_terrain_content?.photo_details?.url
                })

            for (let i = 0; i < el.length; i += 3) {
                images.push(el.slice(i, i + 3));
            }

            result.push(images);

            images = []
        })

        setImagesEvaluation(result)
    }, []);

    const FooterPDF = ({
        pageSugest,
        light = false,
    }: any) => {
        const [page, setPage] = useState(1);
        const footerRef = useRef(null);

        useEffect(() => {
            if (footerRef.current && !pageSugest) {
                const componentTop = (footerRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setPage(currentPage);
            } else {
                setPage(pageSugest);
            }
        }, [footerRef, pageHeight]);

        return (
            <div className="pdfFooter" id="footer" ref={footerRef}>
                <div className="pdfLogoPastejo">
                    <LogoPastejoIcon />
                </div>
                <span style={{ color: light ? "#FFF" : "#000" }}>
                    {pageSugest ? pageSugest : page}
                </span>
            </div>
        );
    }

    const AvaliacaoPDF = ({
        items
    }: AvaliacaoPDFProps) => {
        return (
            <div className="pdfTableBorder">
                <section className="pdfTableColumn">
                    <div>Itens avaliados</div>
                    <div>Resultado</div>
                </section>
                {items && items.map((item, index) => (
                    <section className="pdfTableViewRows">
                        <div className={`pdfTableRow ${index % 2 === 0 ? "bg_white" : "bg_gray"}`}>
                            <div>{item.item}</div>
                            <div>{capitalize((item.result || "-").toLocaleLowerCase())}</div>
                        </div>
                    </section>
                ))}
            </div>
        );
    }

    const MicroAreaRelatorioPDFpg1 = (microArea: IMicroAreasTaskInfo, index: number, mapCoord: ICoord[]) => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`${index} ${microArea.micro_area_details.micro_area_initials}`, currentPage + 1)))
                setMapPages(new Map(mapPages.set(`${index}.1 Resultados da avaliação de pastagem por pasto`, currentPage + 1)))
            }
        }, [, pageHeight]);

        return (
            <div>
                <div className="pdfTopicContainer" >
                    <h2 className="pdfMicroArea" style={{ width: 45 }}>{index}</h2>
                    <h2 className="pdfMicroArea" ref={resultadosRef}>{microArea.micro_area_details.micro_area_initials}</h2>
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1</h2>
                    <h2 className="pdfTopic">Resultados da avaliação de pastagem por pasto</h2>
                </div>


                <div className="pdfContainerResult">
                    {/* Tabela com detalhes da avaliação */}
                    <div className="pdfTableBorder" style={{ width: "70%" }}>
                        <section className="pdfTableColumn">
                            <div>Fazenda</div>
                            <div>Retiro</div>
                        </section>
                        <section className="pdfTableViewRows">
                            <div className="pdfTableRow">
                                <div>{microArea.micro_area_details.farm}</div>
                                <div>{microArea.micro_area_details.area}</div>
                            </div>
                        </section>

                        <section className="pdfTableColumn">
                            <div>Pasto</div>
                            <div>Área útil do pasto</div>
                        </section>
                        <section className="pdfTableViewRows">
                            <div className="pdfTableRow">
                                <div>{microArea.micro_area_details.micro_area_initials}</div>
                                <div>{microArea.micro_area_details.useful_area} ha</div>
                            </div>
                        </section>

                        <section className="pdfTableColumn">
                            <div>Avaliador</div>
                            <div>Dia da avaliação</div>
                        </section>
                        <section className="pdfTableViewRows">
                            <div className="pdfTableRow">
                                <div>{microArea.micro_area_details.evaluator_name}</div>
                                <div>{microArea?.micro_area_details?.evaluate_date ? format(new Date(microArea?.micro_area_details?.evaluate_date || ""), `dd/MM/yyyy - hh:mm`) : "-"}</div>
                            </div>
                        </section>
                    </div>

                    <div className="mapContainerInfoEvaluation pdfContainerResultMap">
                        <StaticMapRender
                            farm={[]}
                            mainPolygon={mapCoord}
                            microAreas={[]}
                            fitCoords={mapCoord}
                        />
                    </div>
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.1</h2>
                    <h2 className="pdfTopic">Forrageira</h2>
                </div>
                {/* Tabela com detalhes da Forrageira */}
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de forragem primária",
                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[0] ? "Sim" : "Não"
                    },

                    ...(microArea?.pasture_evaluation_fodder_content?.fodder_content[0] ?
                        [
                            {
                                item: "Gênero da forrageira",
                                result: microArea?.pasture_evaluation_fodder_content?.fodder_content[0]?.fodder_details?.fodder_genus
                            },
                            {
                                item: "Espécie da forrageira",
                                result: microArea?.pasture_evaluation_fodder_content?.fodder_content[0]?.fodder_details?.fodder_species
                            },
                            {
                                item: "Proporção da forrageira",
                                result: microArea?.pasture_evaluation_fodder_content?.fodder_content[0]?.proportion ?
                                    microArea?.pasture_evaluation_fodder_content?.fodder_content[0]?.proportion + "%"
                                    : "-"
                            },
                            {
                                item: "Área efetiva (ha)",
                                result: `${(
                                    microArea.micro_area_details.useful_area *
                                    ((microArea?.pasture_evaluation_fodder_content?.fodder_content[0]?.proportion || 0) / 100)
                                ).toPrecision(2)} ha`
                            },
                            {
                                item: "Altura do capim",
                                result: microArea?.pasture_evaluation_fodder_content?.fodder_content[0].height
                            },
                            {
                                item: "Presença da forragem secundária",
                                result: microArea?.pasture_evaluation_fodder_content?.fodder_content[1] ? "Sim" : "Não"
                            },
                            ...(microArea?.pasture_evaluation_fodder_content?.fodder_content[1] ?
                                [
                                    {
                                        item: "Gênero da forrageira",
                                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[1]?.fodder_details?.fodder_genus
                                    },
                                    {
                                        item: "Espécie da forrageira",
                                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[1]?.fodder_details?.fodder_species
                                    },
                                    {
                                        item: "Proporção da forrageira",
                                        result: microArea.pasture_evaluation_fodder_content?.fodder_content[1]?.proportion ?
                                            microArea?.pasture_evaluation_fodder_content?.fodder_content[1]?.proportion + "%"
                                            : "-"
                                    },
                                    {
                                        item: "Área efetiva (ha)",
                                        result: `${(
                                            microArea.micro_area_details.useful_area *
                                            ((microArea?.pasture_evaluation_fodder_content?.fodder_content[1]?.proportion || 0) / 100)
                                        ).toPrecision(2)} ha`
                                    },
                                    {
                                        item: "Altura do capim",
                                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[1]?.height
                                    },
                                    {
                                        item: "Presença da forragem terciária",
                                        result: microArea?.pasture_evaluation_fodder_content?.fodder_content[2] ? "Sim" : "Não"
                                    },
                                    ...(microArea?.pasture_evaluation_fodder_content?.fodder_content[2] ?
                                        [
                                            {
                                                item: "Gênero da forrageira",
                                                result: microArea?.pasture_evaluation_fodder_content?.fodder_content[2]?.fodder_details?.fodder_genus
                                            },
                                            {
                                                item: "Espécie da forrageira",
                                                result: microArea?.pasture_evaluation_fodder_content?.fodder_content[2]?.fodder_details?.fodder_species
                                            },
                                            {
                                                item: "Proporção da forrageira",
                                                result: microArea.pasture_evaluation_fodder_content?.fodder_content[2]?.proportion ?
                                                    microArea?.pasture_evaluation_fodder_content?.fodder_content[2]?.proportion + "%"
                                                    : "-"
                                            },
                                            {
                                                item: "Área efetiva (ha)",
                                                result: `${(
                                                    microArea.micro_area_details.useful_area *
                                                    ((microArea?.pasture_evaluation_fodder_content?.fodder_content[2]?.proportion || 0) / 100)
                                                ).toPrecision(2)} ha`
                                            },
                                            {
                                                item: "Altura do capim",
                                                result: microArea?.pasture_evaluation_fodder_content?.fodder_content[2]?.height
                                            }
                                        ] : [])
                                ] : []
                            )
                        ] : []
                    ),

                ]} />
            </div>
        );
    }

    const MicroAreaRelatorioPDFpg2 = (microArea: IMicroAreasTaskInfo, index: number) => {
        return (
            <div>
                {/* Tabela com detalhes da Invasoras */}
                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.2</h2>
                    <h2 className="pdfTopic">Invasoras</h2>
                </div>
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de invasora",
                        result: microArea?.pasture_evaluation_invasive_content?.invasive_content[0] ? "Sim" : "Não"
                    },
                    ...(microArea?.pasture_evaluation_invasive_content?.invasive_content[0] ?
                        [{
                            item: "Tipo de folha",
                            result: mapText.get(microArea?.pasture_evaluation_invasive_content?.invasive_content[0]?.leaf_type)
                        }] : []
                    ),
                    ...(microArea?.pasture_evaluation_invasive_content?.invasive_content[0]?.leaf_type == "FOLHA_ESTREITA" ?
                        [{
                            item: "Nota da invasora",
                            result: microArea?.pasture_evaluation_invasive_content?.invasive_content[0]?.rating ?
                                `${microArea?.pasture_evaluation_invasive_content?.invasive_content[0]?.rating}` : "-"
                        }] : []
                    ),
                    ...(microArea?.pasture_evaluation_invasive_content?.invasive_content[0]?.invasive_type == "ANUAL_MOLE" ?
                        [
                            {
                                item: "Tipo de invasora",
                                result: mapText.get(microArea?.pasture_evaluation_invasive_content?.invasive_content[0]?.invasive_type)
                            },

                            {
                                item: "Cobertura da invasora",
                                result: microArea?.pasture_evaluation_invasive_content?.invasive_content[0]?.coverage ?
                                    microArea.pasture_evaluation_invasive_content?.invasive_content[0]?.coverage + "%"
                                    : "-"
                            }
                        ] : []
                    ),
                    {
                        item: "Presença de invasora 2",
                        result: microArea?.pasture_evaluation_invasive_content?.invasive_content[1] ? "Sim" : "Não"
                    },
                    ...(microArea?.pasture_evaluation_fodder_content?.fodder_content[1] ?
                        [

                            ...(microArea?.pasture_evaluation_invasive_content?.invasive_content[1] ?
                                [{
                                    item: "Tipo de folha",
                                    result: mapText.get(microArea?.pasture_evaluation_invasive_content?.invasive_content[1]?.leaf_type)
                                }] : []
                            ),
                            ...(microArea?.pasture_evaluation_invasive_content?.invasive_content[1]?.leaf_type == "FOLHA_ESTREITA" ?
                                [{
                                    item: "Nota da invasora",
                                    result: microArea?.pasture_evaluation_invasive_content?.invasive_content[1]?.rating ?
                                        `${microArea?.pasture_evaluation_invasive_content?.invasive_content[1]?.rating}` : "-"
                                }] : []
                            ),
                            ...(microArea?.pasture_evaluation_invasive_content?.invasive_content[1]?.invasive_type == "ANUAL_MOLE" ?
                                [
                                    {
                                        item: "Tipo de invasora",
                                        result: mapText.get(microArea?.pasture_evaluation_invasive_content?.invasive_content[1]?.invasive_type)
                                    },
                                    {
                                        item: "Cobertura da invasora",
                                        result: microArea?.pasture_evaluation_invasive_content?.invasive_content[1]?.coverage ?
                                            microArea.pasture_evaluation_invasive_content?.invasive_content[1]?.coverage + "%"
                                            : "-"
                                    },

                                ] : []
                            ),
                            {
                                item: "Presença de invasora 3",
                                result: microArea?.pasture_evaluation_invasive_content?.invasive_content[2] ? "Sim" : "Não"
                            },
                            ...(microArea?.pasture_evaluation_fodder_content?.fodder_content[2] ?
                                [

                                    ...(microArea?.pasture_evaluation_invasive_content?.invasive_content[2] ?
                                        [{
                                            item: "Tipo de folha",
                                            result: mapText.get(microArea?.pasture_evaluation_invasive_content?.invasive_content[2]?.leaf_type)
                                        }] : []
                                    ),
                                    ...(microArea?.pasture_evaluation_invasive_content?.invasive_content[2]?.leaf_type == "FOLHA_ESTREITA" ?
                                        [{
                                            item: "Nota da invasora",
                                            result: microArea?.pasture_evaluation_invasive_content?.invasive_content[2]?.rating ?
                                                `${microArea?.pasture_evaluation_invasive_content?.invasive_content[2]?.rating}` : "-"
                                        }] : []
                                    ),
                                    ...(microArea?.pasture_evaluation_invasive_content?.invasive_content[2]?.invasive_type == "ANUAL_MOLE" ?
                                        [
                                            {
                                                item: "Tipo de invasora",
                                                result: mapText.get(microArea?.pasture_evaluation_invasive_content?.invasive_content[2]?.invasive_type)
                                            },
                                            {
                                                item: "Cobertura da invasora",
                                                result: microArea?.pasture_evaluation_invasive_content?.invasive_content[2]?.coverage ?
                                                    microArea.pasture_evaluation_invasive_content?.invasive_content[2]?.coverage + "%"
                                                    : "-"
                                            }
                                        ] : []
                                    ),
                                ] : [])
                        ] : [])
                ]} />


                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.3</h2>
                    <h2 className="pdfTopic" style={{ marginTop: 0 }}>Pragas ou doenças</h2>
                </div>
                {/* Tabela com detalhes da Pragas ou doenças */}
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de praga ou doença",
                        result: microArea?.pasture_evaluation_plague_content?.has_plague ? "Sim" : "Não"
                    },
                    ...(microArea?.pasture_evaluation_plague_content?.has_plague ?
                        [
                            {
                                item: "Tipo da praga ou doença",
                                result: microArea?.pasture_evaluation_plague_content?.plague_content.map(el => el.plague_name).join(", ")
                            },
                        ] : [])
                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.4</h2>
                    <h2 className="pdfTopic">Aguada</h2>
                </div>
                {/* Tabela com detalhes da Aguada */}
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de aguada",
                        result: microArea?.pasture_evaluation_waterhole_content?.has_waterhole ? "Sim" : "Não"
                    },
                    ...(microArea?.pasture_evaluation_waterhole_content?.has_waterhole ?
                        [
                            {
                                item: "Tipo de aguada",
                                result: microArea?.pasture_evaluation_waterhole_content?.structure_type
                            },
                            {
                                item: "Nível de acesso",
                                result: microArea?.pasture_evaluation_waterhole_content?.access_level
                            },
                            ...(microArea?.pasture_evaluation_waterhole_content?.structure_type == 'Bebedouro artificial' ?
                                [
                                    {
                                        item: "Tamanho da área de acesso",
                                        result: microArea?.pasture_evaluation_waterhole_content?.access_area ? `${microArea.pasture_evaluation_waterhole_content?.access_area} metros` : "-"
                                    }
                                ] : []),
                            {
                                item: "Precisa de reparo",
                                result: microArea?.pasture_evaluation_waterhole_content?.has_waterhole ?
                                    microArea?.pasture_evaluation_waterhole_content?.needs_repair ? "Sim" : "Não" :
                                    "-"
                            },
                            ...(microArea?.pasture_evaluation_waterhole_content?.needs_repair && microArea?.pasture_evaluation_waterhole_content?.structure_type == "Bebedouro artificial" ?
                                [
                                    {
                                        item: "Tipo de reparo",
                                        result: microArea?.pasture_evaluation_waterhole_content?.repair_type
                                    }
                                ] : []
                            )
                        ] : []
                    )
                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.5</h2>
                    <h2 className="pdfTopic">Suplemento</h2>
                </div>
                {/* Tabela com detalhes da Suplemento */}
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de depósito de suplemento",
                        result: microArea?.pasture_evaluation_suplement_content?.has_storage ? "Sim" : "Não"
                    },
                    ...(microArea?.pasture_evaluation_suplement_content?.has_storage ?
                        [
                            {
                                item: "Capacidade do depósito",
                                result: microArea?.pasture_evaluation_suplement_content?.weight ? `${microArea.pasture_evaluation_suplement_content?.weight} kg` : "-"
                            }
                        ] : []
                    )

                ]} />
            </div>
        )
    }

    const MicroAreaRelatorioPDFpg3 = (microArea: IMicroAreasTaskInfo, index: number) => {
        const fotosRef = useRef(null);

        useEffect(() => {
            if (fotosRef.current) {
                const componentTop = (fotosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`${index}.2 Fotos`, currentPage + 2)))
            }
        }, [, pageHeight]);

        return (
            <div ref={fotosRef}>
                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.6</h2>
                    <h2 className="pdfTopic">Cocho</h2>
                </div>
                {/* Tabela com detalhes da Cocho */}
                <AvaliacaoPDF items={[
                    {
                        item: "Presença de cocho",
                        result: microArea?.pasture_evaluation_trough_content?.has_trough ? "Sim" : "Não"
                    },
                    ...(microArea?.pasture_evaluation_trough_content?.has_trough ?
                        [
                            {
                                item: "Coberto",
                                result: microArea?.pasture_evaluation_trough_content?.has_trough ?
                                    microArea?.pasture_evaluation_trough_content?.has_coverage ? "Sim" : "Não" :
                                    "-"
                            },
                            {
                                item: "Tamanho em comprimento",
                                result: microArea?.pasture_evaluation_trough_content?.length ? `${microArea.pasture_evaluation_trough_content?.length} m` : "-"
                            },
                            {
                                item: "Tamanho em largura",
                                result: microArea?.pasture_evaluation_trough_content?.width ? `${microArea.pasture_evaluation_trough_content?.width} m` : "-"
                            },
                            {
                                item: "Tipo de acesso",
                                result: microArea?.pasture_evaluation_trough_content?.sides ? `${microArea.pasture_evaluation_trough_content?.sides} lados` : "-"
                            },
                            {
                                item: "Capacidade do cocho",
                                result: microArea?.pasture_evaluation_trough_content?.capacity ? `${microArea.pasture_evaluation_trough_content?.capacity} kg` : "-"
                            },
                            {
                                item: "Necessita reparo",
                                result: microArea?.pasture_evaluation_trough_content?.has_trough ?
                                    microArea?.pasture_evaluation_trough_content?.need_repair ? "Sim" : "Não" :
                                    "-"
                            },
                            ...(microArea?.pasture_evaluation_trough_content?.need_repair ?
                                [
                                    {
                                        item: "Tipo de reparo",
                                        result: microArea?.pasture_evaluation_trough_content?.repair_type
                                    }
                                ] : []
                            )
                        ] : []
                    )

                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.7</h2>
                    <h2 className="pdfTopic">Cerca</h2>
                </div>
                <AvaliacaoPDF items={[
                    {
                        item: "Tipo de cerca",
                        result: microArea?.pasture_evaluation_fence_content?.fence_type
                    },
                    {
                        item: "Tipo de material",
                        result: microArea?.pasture_evaluation_fence_content?.fence_structure
                    },
                    {
                        item: "Quantidade de fios",
                        result: `${microArea?.pasture_evaluation_fence_content?.wire_quantity}`
                    },
                    {
                        item: "Precisa de reparo",
                        result: microArea?.pasture_evaluation_fence_content?.need_repair ? "Sim" : "Não"
                    }
                ]} />

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">{index}.1.8</h2>
                    <h2 className="pdfTopic">Relevo</h2>
                </div>
                {/* Tabela com detalhes da Relevo e solo */}
                <AvaliacaoPDF items={[
                    {
                        item: "Relevo e solo",
                        result: microArea?.pasture_evaluation_terrain_content?.terrain_type
                    },
                    {
                        item: "Tipo de solo",
                        result: microArea?.pasture_evaluation_terrain_content?.soil_moisture
                    }
                ]} />
            </div>
        );
    }

    const MicroAreaRelatorioPDFPhoto = (index: number, arrayIndex: number) => {
        return (
            <div>
                {arrayIndex == 0 && (
                    <div className="pdfTopicContainer">
                        <h2 className="pdfTopicId">{index + 1}.2</h2>
                        <h2 className="pdfTopic">Fotos</h2>
                    </div>
                )}

                {imagesEvaluation[index] && imagesEvaluation[index].length >= arrayIndex &&
                    imagesEvaluation[index][arrayIndex] &&
                    imagesEvaluation[index][arrayIndex].map((el: any, indexImage: number) =>
                        <>
                            <div className="pdfTopicContainer">
                                <h2 className="pdfTopicId">{index + 1}.2.{arrayIndex * 3 + indexImage + 1}</h2>
                                <h2 className="pdfTopic">{el.label}</h2>
                            </div>
                            <div className="pdfContainerPhoto">
                                <img className="pdfPhoto"
                                    src={el.url}
                                    alt={el.label}
                                />
                            </div>
                        </>
                    )
                }
            </div>
        );
    }

    const MicroAreaRelatorioMapaPDF = () => {
        const mapRefPosition = useRef(null);
        const [mapref, setMap] = useState<google.maps.Map>();
        const [centerCoords, setCenterCoords] = useState<any>([]);
        const [colorsArea, setColorsArea] = useState(new Map<Number, string>());

        const mapStyles = {
            width: "100%",
            height: "100%",
        } as CSSProperties;

        function getRandomColor() {
            const colors: Array<string> = Object.values(Constants.GRAZINGMAP_COLORS);
            return colors[Math.floor(Math.random() * colors.length)];
        }

        const {
            getBounds
        } = useGoogleMaps();

        const onLoad = useCallback(function callback(map: any) {
            setMap(map);
        }, []);

        useEffect(() => {
            setMapPages(new Map(mapPages.set(`${1} Mapa`, summaryPages + 2)))

            areas.map((area: any) => {
                setColorsArea(new Map(colorsArea.set(Number(area.value), getRandomColor())))
            })
        }, [summaryPages]);

        useEffect(() => {
            if (centerCoords !== undefined && mapref !== undefined) {
                mapref.fitBounds(getBounds(centerCoords))
            }
        }, [centerCoords, mapref])

        useEffect(() => {
            setCenterCoords(farm.info?.map_coords)
        }, [farm]);

        if (areas.length == 0)
            return;

        return (
            <div className="pdfPage" style={{ padding: 0, overflow: "hidden" }}>
                <div className="pdfPageMap">
                    <div className="pdfContent">
                        <div>
                            <h1>MAPA</h1>
                            <div className="areaContainer">
                                <AreaIcon width={25} height={25} className="whiteColor" />
                                <h1>{farm.info?.initials}</h1>
                                <LocateIcon width={14} height={14} className="greenColor" />
                            </div>
                            <div className="detailareaContainer">
                                {areas.length > 0 && <h1>{areas.length > 1 ? `${areas.length} Retiros` : areas[0]?.label}</h1>}
                                <span>{dataReport?.micro_areas_task_info?.length} pasto(s) avaliado(s)
                                    ({dataReport?.micro_areas_task_info?.reduce((acc, el) => acc + el.micro_area_details.useful_area, 0).toFixed(2)} ha)
                                </span>
                            </div>
                        </div>
                    </div>

                    <FooterPDF pageSugest={summaryPages + 2} light />
                </div>
                {dataReport?.micro_areas_task_info &&
                    <GoogleMap
                        ref={mapRefPosition}
                        onTilesLoaded={() => {
                            Promise.all(
                                Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; })))
                                .finally(() => {
                                    window.print()
                                });
                        }}
                        onLoad={onLoad}
                        mapContainerStyle={mapStyles}
                        options={{
                            streetViewControl: false,
                            scaleControl: true,
                            fullscreenControl: false,
                            styles: [
                                {
                                    featureType: "poi.business",
                                    elementType: "labels",
                                    stylers: [
                                        {
                                            visibility: "off",
                                        },
                                    ],
                                },
                            ],
                            gestureHandling: "greedy",
                            disableDoubleClickZoom: true,
                            minZoom: 5,
                            mapTypeControl: true,
                            mapTypeId: google.maps.MapTypeId.SATELLITE,
                            mapTypeControlOptions: {
                                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                                position: google.maps.ControlPosition.BOTTOM_CENTER,
                                mapTypeIds: [
                                    google.maps.MapTypeId.ROADMAP,
                                    google.maps.MapTypeId.SATELLITE,
                                    google.maps.MapTypeId.HYBRID,
                                ],
                            },
                            clickableIcons: false,
                            draggableCursor: "default",
                            draggable: false,
                            zoomControl: false
                        }}
                    >
                        <Polyline
                            options={Constants.POLYLINE_DASHED_GREEN}
                            path={farm.info?.map_coords}
                            draggable={false}
                            editable={false}
                        />

                        {dataReport?.micro_areas_task_info && dataReport?.micro_areas_task_info.length > 0
                            ? dataReport?.micro_areas_task_info
                                .map((polygonMicroArea) =>
                                    <Polygon
                                        options={{
                                            ...Constants.POLYGON_SELECTED,
                                            fillOpacity: 0.5,
                                            fillColor: colorsArea.get(areas.filter(el => el.label == polygonMicroArea.micro_area_details.area)[0]?.value),
                                        }}
                                        path={polygonMicroArea?.micro_area_details?.micro_area_map_coords || []}
                                        draggable={false}
                                        editable={false}
                                    />
                                )
                            : <></>}
                    </GoogleMap>}
            </div>
        )
    }

    const [mapref, setMap] = useState<google.maps.Map>();
    const [centerCoords, setCenterCoords] = useState<any>([]);
    const {
        getBounds
    } = useGoogleMaps();

    useEffect(() => {
        if (centerCoords !== undefined && mapref !== undefined) {
            mapref.fitBounds(getBounds(centerCoords))
        }
    }, [centerCoords, mapref])

    useEffect(() => {
        setCenterCoords(farm.info?.map_coords)
    }, [farm]);

    return (
        <div className="pdfContainer">
            <div className="pdfPage" style={{ background: "#365951" }} ref={pageRef}>
                <div className="pdfHeader">
                    <div className="pdfHeaderContainer">
                        <h2>{farm?.label}</h2>
                        <h2>{areas && areas?.length > 0 && areas[0] ? `${areas?.length} Retiros` : areas[0]?.label}</h2>
                        <h1>Relatório por pasto</h1>
                    </div>
                    <div className="pdfLogoPastejo">
                        <LogoPastejoIcon />
                    </div>
                </div>
            </div>

            {splitArray(Array.from(mapPages.keys()), 31).map((pg: any) => (
                <div className="pdfSumario">
                    <h2>Sumário</h2>
                    <div>
                        {pg.map((element: any) => (
                            <div className="pdfPageContent">
                                <span className="pdfPageIndice">{element}</span>
                                <span className="pdfPageLine">{".".repeat(315)}</span>
                                <span className="pdfPageNumber">{(mapPages.get(element) || 0)}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            <div className="pdfPageMapContainer">
                {MicroAreaRelatorioMapaPDF()}
            </div>

            {dataReport.micro_areas_task_info && dataReport.micro_areas_task_info.length > 0 ?
                dataReport?.micro_areas_task_info?.map((microArea, index) => (
                    <>
                        <div className="pdfPage">
                            <div className="pdfContent">
                                {MicroAreaRelatorioPDFpg1(microArea, index + 1, microArea.micro_area_details.micro_area_map_coords)}
                            </div>
                            <FooterPDF />
                        </div>
                        <div className="pdfPage">
                            <div className="pdfContent">
                                {MicroAreaRelatorioPDFpg2(microArea, index + 1)}
                            </div>
                            <FooterPDF />
                        </div>
                        <div className="pdfPage">
                            <div className="pdfContent">
                                {MicroAreaRelatorioPDFpg3(microArea, index + 1)}
                            </div>
                            <FooterPDF />
                        </div>
                        {imagesEvaluation[index] && imagesEvaluation[index].map((el, indexImage) => (
                            <div className="pdfPage">
                                <div className="pdfContent">
                                    {MicroAreaRelatorioPDFPhoto(index, indexImage)}
                                </div>
                                <FooterPDF />
                            </div>
                        ))}


                        {/* {imagesEvaluation[index] && imagesEvaluation[index][1] &&
                            <div className="pdfPage">
                                <div className="pdfContent">
                                    {MicroAreaRelatorioPDFPhoto(index, 0, 1)}
                                </div>
                                <FooterPDF />
                            </div>
                        }
                        {imagesEvaluation[index] && imagesEvaluation[index][2] &&
                            <div className="pdfPage">
                                <div className="pdfContent">
                                    {MicroAreaRelatorioPDFPhoto(index, 1, 4)}
                                </div>
                                <FooterPDF />
                            </div>
                        }
                        {imagesEvaluation[index] && imagesEvaluation[index][3] &&
                            <div className="pdfPage">
                                <div className="pdfContent">
                                    {MicroAreaRelatorioPDFPhoto(index, 2, 7)}
                                </div>
                                <FooterPDF />
                            </div>
                        }
                        {imagesEvaluation[index] && imagesEvaluation[index][4] &&
                            <div className="pdfPage">
                                <div className="pdfContent">
                                    {MicroAreaRelatorioPDFPhoto(index, 3, 10)}
                                </div>
                                <FooterPDF />
                            </div>
                        } */}
                    </>
                ))
                : <></>
            }
        </div>
    );
};
