import React from "react";
import "./style.css";

interface TaskCardProps {
  title: string;
  icon: JSX.Element;
  onClick: () => void;
}

export const TaskCard: React.FC<TaskCardProps> = ({ title, icon, onClick }) => {
  return (
    <button className="outter-task-card" type="button" onClick={() => onClick()}>
      <div className="inner-task-card">
        <div className="task-card-icon">{icon}</div>
        <p>{title}</p>
      </div>
    </button>
  );
};
