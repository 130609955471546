import { CSSProperties, useCallback, useEffect, useState } from "react";
import { ICoords } from "../../../../@types/GoogleMaps/ICoord";
import { Modal } from "../../../Modal/Default/Index";
import { Map as MapElement } from "../../../GoogleMaps/Map/Index";
import Constants from "../../../../constants/Index";
import { GoogleMap, Polygon as GooglePolygon, OverlayView, Polyline } from "@react-google-maps/api"
import { DynamicMapsControlls } from "../DrawOnMap/DrawOnMap";
import { IMicroArea, IMicroAreas } from "../../../../@types/API/IMicroArea";
import { getCenterCoords } from "../../../../utils/leaflet/getCenterCoords";
import { CreateMicroArea } from "./CreateMicroArea";
import { ReactComponent as Logo } from '../../../../assets/svg/logo/logoPastejo.svg'
import { useGoogleMaps } from "../../../../hooks/useGoogleMaps";
import { ButtonOnlyTitle } from "../../../Buttons/ButtonOnlyTitle/Index";
import { ButtonWithChildren } from "../../../Buttons/ButtonWithChildren/Index";
import { ReactComponent as Pen } from '../../../../assets/svg/icons/penEdit.svg'
import { useTaskCreation } from "../../../../hooks/useTaskCreation";
import { AlertModal } from "../../../Modal/Alert/Index";
import { TitleModal } from "../../../Modal/Contents/TitleModal/Index";
import { TextModal } from "../../../Modal/Contents/TextModal/Index";
import { ButtonRedirect } from "../../../Modal/Error/ButtonRedirect/Index";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import axios from '../../../../services/axios'
import * as geolib from 'geolib';
import * as turf from '@turf/turf'
import "./styles.css"

const contentStyleModal = {
    width: "95%",
    height: "95vh",
    maxHeight: "937px",
    borderRadius: "20px",
    overflow: "hidden",
} as CSSProperties;

const contentDrawNewFenceStyleModal = {
    width: '490px',
    height: '250px',
    borderRadius: '20px',
} as CSSProperties

const mapStyles = {
    width: "100%",
    height: "100%",
} as CSSProperties;

interface DrawNewFencesProps {
    isMulti?: boolean;
    microAreas: IMicroAreas;
    initialsLabel: string;
    mainPolygon: ICoords;
    editable: boolean;
    onChange: any;
    value: any;
    style?: CSSProperties;
    externalCoords?: ICoords;
    fitCoords?: ICoords;
    onlyModal?: boolean;
    onClose?: () => void;
    overrideStyleModal?: CSSProperties;
    microArea: any;
}

export const DrawNewFences = ({ isMulti, onChange, onClose, overrideStyleModal, value = [], mainPolygon, microAreas, microArea, externalCoords, fitCoords, onlyModal = false, editable, ...rest }: DrawNewFencesProps) => {
    const [modalVisible, setModalVisible] = useState<boolean>(onlyModal || false);
    const [polygon, setPolygon] = useState<ICoords>([]);
    const [mapMicroAreaSelected, setMapMicroAreaSelected] = useState(new Map<string, IMicroArea>());
    const [mapMicroAreaDraw] = useState(new Map());
    const [polygonComponents] = useState(new Map());
    const [microAreaSelected, setMicroAreaSelected] = useState(0);
    const [colorPolygon, setColorPolygon] = useState<any>(Constants.POLYGON_YELLOW);
    const [centerCoords, setCenterCoords] = useState<any>([]);
    const [mapref, setMap] = useState<google.maps.Map>();
    const [stepMap, setStepMap] = useState(1);
    const [polygonComponet, setPolygonComponet] = useState<google.maps.Polygon | null>(null);
    const [canSubmit, setCanSubmit] = useState(false);
    const [modalDrawNewFence, setModalDrawNewFence] = useState(false);
    const [modalDrawAtLeastOneMicroArea, setModalDrawAtLeastOneMicroArea] = useState(false);
    const {
        payload,
        setPayload,
        createTask,
        setAllowTaskCreation,
    } = useTaskCreation();
    const {
        getBounds
    } = useGoogleMaps();

    function onClickMouse(event: google.maps.MapMouseEvent) {
        if (editable) {
            const lat = event.latLng?.lat();
            const lng = event.latLng?.lng();

            if ((polygon?.length > 2) && isMulti) {
                setMicroAreaSelected(Array.from(mapMicroAreaDraw.values()).length)
                setPolygon([])
            }
            if (!!lat && !!lng)
                setPolygon(prev => ([...prev, { lat, lng }]))
        }
    }

    function nextStep() {
        if (stepMap == 1) {
            if (payload.changes[0].activity == "REMOVER") {

                if (mapMicroAreaSelected.size == 1) {
                    setModalDrawAtLeastOneMicroArea(true)
                    return;
                }
                const coords = Array
                    .from(mapMicroAreaSelected.values())
                    .map(el => el.map_coords || [])

                let coordsMerged = coords.map(el => el.map(latLng => ([
                    latLng.lat, latLng.lng
                ])));

                let union = turf.union(turf.polygon([[...coordsMerged[0], coordsMerged[0][0]]]), turf.multiPolygon(coordsMerged.map(el => [[...el, el[0]]])))

                if (union?.geometry.type != "MultiPolygon") {
                    const polygon = union?.geometry.coordinates[0].map((el) => (
                        {
                            lat: el[0],
                            lng: el[1]
                        }
                    )) as ICoords;
                    setPolygon(polygon)
                    setNewMicroAreas([polygon])
                    setStepMap(3)
                } else {
                    setModalDrawNewFence(true)
                }
            } else if (payload.changes[0].activity == "CONSTRUIR") {
                setStepMap(3)
            }
        } else if (stepMap == 2) {
            setStepMap(3)
        }
    }

    useEffect(() => {
        if (stepMap == 3 && payload.changes[0].activity == "REMOVER") {
            setNewMicroAreas([polygon])
        }
    }, [stepMap]);

    useEffect(() => {
        onChange && onChange(polygon)
        mapMicroAreaDraw.set(microAreaSelected, polygon)
    }, [polygon])

    useEffect(() => {
        onChange && onChange(Array.from(mapMicroAreaSelected.keys()))
    }, [mapMicroAreaSelected]);

    useEffect(() => {
        if (polygon.length < 1) {
            setPolygon(value)
        }

        if (microArea?.id) {
            mapMicroAreaSelected.set(microArea?.id, microArea)
        }

        if (!editable)
            setColorPolygon(Constants.POLYGON_NEW_POLYGON)
    }, [])

    useEffect(() => {
        if (externalCoords) {
            const topPoint = Math.max(...externalCoords.map((el: any) => el.lat))
            const bottomPoint = Math.min(...externalCoords.map((el: any) => el.lat))
            const rightPoint = Math.max(...externalCoords.map((el: any) => el.lng))
            const leftPoint = Math.min(...externalCoords.map((el: any) => el.lng))

            setCenterCoords([{
                lat: topPoint,
                lng: leftPoint - (rightPoint - leftPoint) * 1.5
            },
            {
                lat: bottomPoint,
                lng: rightPoint
            }])
        }
    }, [externalCoords]);

    useEffect(() => {
        if (centerCoords !== undefined && mapref !== undefined) {
            mapref.fitBounds(getBounds(centerCoords))
        }
    }, [centerCoords, mapref])

    const onLoad = useCallback(function callback(map: any) {
        setMap(map);
    }, []);

    function onEventCloseFinish() {
        setAllowTaskCreation(false)
        setModalVisible(false)
        onClose && onClose()
    }

    function onLoadPolygon(polygon: google.maps.Polygon) {
        setPolygonComponet(polygon)
    }

    function onMouseUp() {
        const coords = polygonComponet?.getPath().getArray().map((latLng) => {
            return { lat: latLng.lat(), lng: latLng.lng() };
        }) as ICoords;
        if (!!coords) setPolygon(coords)
    }

    function onSubmitForm(data: any) {
        if (payload.changes[0].activity == "REMOVER") {
            const changes = {
                ...payload.changes[0],
                micro_areas_to_delete_ids: Array.from(mapMicroAreaSelected.keys()).filter(el => el != payload.changes[0].micro_area_id),
                new_micro_areas_details: [{
                    initials: data.microArea[0].initials,
                    acreage: Number(data.microArea[0].acreage),
                    map_coords: polygon
                }]
            }

            const reqPayload = {
                ...payload,
                changes: [changes]
            };

            setPayload(reqPayload);
        } else if (payload.changes[0].activity == "CONSTRUIR") {
            const changes = {
                ...payload.changes[0],
                new_micro_areas_details: data.microArea.map((microArea: any, key: number) => ({
                    initials: microArea.initials,
                    acreage: Number(microArea.acreage),
                    map_coords: newMicroAreas[key]
                }))
            }

            const reqPayload = {
                ...payload,
                changes: [changes]
            };

            setPayload(reqPayload);
        }
        setCanSubmit(true);
    }

    useEffect(() => {
        if (canSubmit) {
            setStepMap(1);
            setModalVisible(false);
            onClose && onClose()
            createTask();
        }
    }, [canSubmit]);

    const [newMicroAreas, setNewMicroAreas] = useState<any>(undefined);
    const [currentMicroArea, setCurrentMicroArea] = useState<number>(0);

    const microAreaSchema = yup.object()
        .shape({
            microArea: yup
                .array()
                .of(
                    yup.object().shape({
                        initials: yup
                            .string()
                            .matches(/^\S+(\s+\S+)*$/, 'Esta sigla não é válida.')
                            .required('Sigla é necessário.'),
                        acreage: yup
                            .string()
                            .required('Campo obrigatório não informado.')
                    })
                )
        })

    const methods = useForm({
        resolver: yupResolver(microAreaSchema),
    });

    const [mapAcreageUsed] = useState(new Map<number, number>());
    const [initialsNumber, setInitialsNumber] = useState(0);

    useEffect(() => {
        if (stepMap == 3) {
            const acreageUsed = Number(microAreas
                .filter((microArea: any) => !mapMicroAreaSelected.has(microArea.id))
                .reduce(
                    (sum: number, current: any) => sum + current.acreage,
                    0,
                ))

            mapAcreageUsed.set(0, acreageUsed)
            mapAcreageUsed.set(1, geolib.getAreaOfPolygon(polygon) / 10000)

            if (payload.changes[0].activity != "REMOVER") {
                newMicroAreas.forEach((element: any, index: number) => {
                    mapAcreageUsed.set(index + 1, geolib.getAreaOfPolygon(element) / 10000)
                });
            }
        }
    }, [stepMap]);

    useEffect(() => {
        setPolygon(mainPolygon)
        setNewMicroAreas([mainPolygon])
        mapMicroAreaDraw.clear()
        mapMicroAreaDraw.set(0, mainPolygon)
        axios
            .get("/micro-areas/generate-initials", {
                params: { area_id: microArea.area.id },
            })
            .then(({ data }) => setInitialsNumber(data.next_available_initials))
    }, []);

    return <>
        <Modal visible={modalVisible} contentStyle={overrideStyleModal ? overrideStyleModal : contentStyleModal}>
            {stepMap == 1 &&
                <>
                    <MapElement fitBounds={fitCoords || mainPolygon} onClick={onClickMouse}>
                        <Polyline
                            options={Constants.POLYLINE_DASHED_GREEN}
                            path={externalCoords}
                            draggable={false}
                            editable={false}
                        />
                        {fitCoords && <>
                            {microAreas && microAreas.map((micro: any) => {
                                const selectedCoords = new Map<string, IMicroArea>(mapMicroAreaSelected);
                                return (
                                    <>
                                        <GooglePolygon
                                            options={
                                                microArea.id == micro.id ?
                                                    Constants.POLYGON_MAIN
                                                    :
                                                    (micro.id && mapMicroAreaSelected.has(micro.id) ?
                                                        Constants.POLYGON_SELECTED :
                                                        Constants.POLYGON_NOT_SELECTED)
                                            }
                                            draggable={false}
                                            editable={false}
                                            path={micro.map_coords ? [...micro.map_coords, micro.map_coords[0]] : []}
                                            onClick={() => {
                                                if (
                                                    !editable &&
                                                    micro.id &&
                                                    (microArea.id != micro?.id && !micro?.micro_area_route?.current_micro_area)
                                                ) {
                                                    selectedCoords.has(micro.id) ? selectedCoords.delete(micro.id) : selectedCoords.set(micro.id, micro)
                                                    setMapMicroAreaSelected(selectedCoords)
                                                }
                                            }}
                                        />

                                        {micro?.micro_area_route?.current_micro_area &&
                                            <OverlayView
                                                position={getCenterCoords(micro.map_coords)}
                                                mapPaneName={OverlayView.FLOAT_PANE}
                                            >
                                                <div className="iconBatch" />
                                            </OverlayView>
                                        }
                                    </>
                                )
                            })}
                        </>}

                        {Array.from(mapMicroAreaDraw.values()).map((micro, key) => {
                            return <GooglePolygon
                                options={colorPolygon}
                                onLoad={(polygon) => {
                                    polygonComponents.set(key, polygon)
                                    setNewMicroAreas(
                                        Array.from(
                                            polygonComponents.values()
                                        ).map(micro => micro.getPath().getArray().map((latLng: any) => {
                                            return { lat: latLng.lat(), lng: latLng.lng() };
                                        }) as ICoords))
                                }}
                                onMouseOver={() => {
                                    setNewMicroAreas(
                                        Array.from(
                                            polygonComponents.values()
                                        ).map(micro => micro.getPath().getArray().map((latLng: any) => {
                                            return { lat: latLng.lat(), lng: latLng.lng() };
                                        }) as ICoords))
                                }}
                                editable={editable}
                                path={micro}
                            />
                        })}

                    </MapElement>
                    <DynamicMapsControlls
                        onDiscardChanges={onEventCloseFinish}
                        initialsLabel={rest.initialsLabel}
                        onEventClose={nextStep}
                    />
                </>
            }

            {modalDrawAtLeastOneMicroArea && (
                <AlertModal visible={modalDrawAtLeastOneMicroArea} contentStyle={{
                    width: '490px',
                    height: '140px',
                    borderRadius: '20px',
                }}>
                    <section className="modalEdit__alert">
                        <TitleModal>Selecione pelo menos um pasto</TitleModal>
                        <ButtonRedirect onClick={() => {
                            setModalDrawAtLeastOneMicroArea(false)
                        }}>
                            CONTINUAR
                        </ButtonRedirect>
                    </section>
                </AlertModal>
            )}


            {modalDrawNewFence && (
                <AlertModal visible={modalDrawNewFence} contentStyle={contentDrawNewFenceStyleModal}>
                    <section className="modalEdit__alert">
                        <TitleModal>Necessário desenhar a nova cerca</TitleModal>
                        <TextModal>
                            Alguns pastos não estão conectados, sendo necessario desenhar a nova cerca.
                        </TextModal>
                        <ButtonRedirect onClick={() => {
                            setStepMap(2)
                            setModalDrawNewFence(!modalDrawNewFence)
                        }}>
                            CONTINUAR
                        </ButtonRedirect>
                    </section>
                </AlertModal>
            )}

            {stepMap == 2 &&
                <>
                    <MapElement fitBounds={fitCoords || mainPolygon} onClick={onClickMouse}>
                        <Polyline
                            options={Constants.POLYLINE_DASHED_GREEN}
                            path={externalCoords}
                            draggable={false}
                            editable={false}
                        />

                        {microAreas && microAreas
                            .filter((micro: any) => !mapMicroAreaSelected.has(micro.id))
                            .map((micro: any) =>
                                <GooglePolygon
                                    options={Constants.POLYGON_NOT_SELECTED}
                                    draggable={false}
                                    editable={false}
                                    path={micro.map_coords ? [...micro.map_coords, micro.map_coords[0]] : []}
                                />
                            )
                        }

                        <GooglePolygon
                            options={Constants.POLYGON_YELLOW}
                            path={polygon}
                            onLoad={onLoadPolygon}
                            onMouseUp={onMouseUp}
                            editable
                        />
                    </MapElement>

                    <DynamicMapsControlls
                        onDiscardChanges={onEventCloseFinish}
                        initialsLabel={rest.initialsLabel}
                        onEventClose={nextStep}
                    />
                </>
            }

            {stepMap == 3 &&
                <>
                    <div className="formItens">
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmitForm)} id="microArea">
                                <header className="formHeader">
                                    <Logo className="logoPastejo" />
                                </header>

                                <div className="formContainer">
                                    {newMicroAreas
                                        .filter((micro: any) => micro.length > 2)
                                        .map((microCoords: any, key: number) => {
                                            mapMicroAreaDraw.set(key, microCoords)
                                            return <CreateMicroArea
                                                microArea={mapMicroAreaSelected.get(microArea?.id)}
                                                id={Number(initialsNumber) - 1}
                                                coordsMicroAreas={newMicroAreas.filter((micro: any) => micro.length > 2)}
                                                currentMicroArea={currentMicroArea}
                                                setCurrentMicroArea={setCurrentMicroArea}
                                                index={key}
                                                useForm={methods}
                                                acreageUsed={mapAcreageUsed}
                                            />
                                        })
                                    }

                                    <ButtonWithChildren
                                        theme={"info"}
                                        onClick={() => setStepMap(payload.changes[0].activity == "CONSTRUIR" ? 1 : 2)}
                                        style={{ width: 500 }}
                                    >
                                        <div className="buttonWithChildren--content-inline button__createTask">
                                            <Pen width={18} height={18} fill="#fff" />
                                            <h6>Editar Área no mapa</h6>
                                        </div>
                                    </ButtonWithChildren>
                                </div>


                                <footer className="formFooter">
                                    {Array.of((methods.formState.errors).microArea)[0] &&
                                        <span className="errorFooter textInput--small-error animation__fadeIn">
                                            {
                                                `Preencha todos os campos do pasto ${(Array.of((methods.formState.errors).microArea)[0] as any).map((_: any, key: number) => key + 1).filter((el: any) => el).join(", ")}`
                                            }
                                        </span>
                                    }

                                    <div className="buttons-container buttonsFooter">
                                        <div className="cancel">
                                            <ButtonOnlyTitle
                                                title="Cancelar"
                                                theme="light"
                                                onClick={onEventCloseFinish}
                                            />
                                        </div>
                                        <div>
                                            <ButtonOnlyTitle
                                                title={newMicroAreas.length > 1 ? "Confirmar todos" : "Confirmar"}
                                                theme="info"
                                                type="submit"
                                                form="microArea"
                                                disabled={methods.formState.errors.microArea ? true : false}
                                                onClick={methods.handleSubmit(onSubmitForm)}
                                            />
                                        </div>
                                    </div>
                                </footer>
                            </form>
                        </FormProvider>
                    </div>

                    <div className="backgroundMap">
                        <GoogleMap
                            onLoad={onLoad}
                            mapContainerStyle={mapStyles}
                            options={{
                                streetViewControl: false,
                                scaleControl: true,
                                fullscreenControl: false,
                                styles: [
                                    {
                                        featureType: "poi.business",
                                        elementType: "labels",
                                        stylers: [
                                            {
                                                visibility: "off",
                                            },
                                        ],
                                    },
                                ],
                                gestureHandling: "greedy",
                                disableDoubleClickZoom: true,
                                minZoom: 10,
                                mapTypeControl: true,
                                mapTypeId: google.maps.MapTypeId.SATELLITE,
                                mapTypeControlOptions: {
                                    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                                    position: google.maps.ControlPosition.BOTTOM_CENTER,
                                    mapTypeIds: [
                                        google.maps.MapTypeId.ROADMAP,
                                        google.maps.MapTypeId.SATELLITE,
                                        google.maps.MapTypeId.HYBRID,
                                    ],
                                },
                                clickableIcons: false,
                                draggableCursor: "default",
                                draggable: false,
                                zoomControl: false
                            }}
                        >
                            <Polyline
                                options={Constants.POLYLINE_DASHED_GREEN}
                                path={externalCoords}
                                draggable={false}
                                editable={false}
                            />

                            {newMicroAreas
                                .filter((micro: any) => micro.length > 2)
                                .map((polygonMicroArea: any, key: number) =>
                                    <GooglePolygon
                                        options={
                                            currentMicroArea == key ?
                                                Constants.POLYGON_SELECTED :
                                                Constants.POLYGON_NOT_SELECTED
                                        }
                                        path={polygonMicroArea}
                                        draggable={false}
                                        editable={false}
                                        onClick={() => setCurrentMicroArea(key)}
                                    />
                                )}
                        </GoogleMap>
                    </div>
                </>
            }
        </Modal >
    </>
}