import { Scope } from '@unform/core';
import { MutableRefObject, useEffect, useState } from 'react';
import { IAnimalCategory } from '../../../../../../@types/app/AnimalCategory/ICategory';
import { InputWeight } from '../../InputWeight/Index';
import './styles.css';

type TableEditRowProps = {
  category: IAnimalCategory;
  evolutionName: string;
  handleInputChanges?: (data?: HTMLInputElement) => void;
  inputsErrors?: (err: string) => void;
  inputError?: (err?: string) => void;
  formRef: MutableRefObject<HTMLFormElement | null | undefined>;
};

export function TableEditRow({
  category: defaultCategory,
  evolutionName,
  handleInputChanges,
  inputError,
  formRef
}: TableEditRowProps) {
  const [category, setCategory] = useState<IAnimalCategory>();

  useEffect(() => {
    setCategory(() => defaultCategory);
    setTimeout(() => {
      category?.month_evolution.map((monthProps, key) => {
        formRef.current?.setFieldValue(`categories[0].weights[0].value`, monthProps.weight.value);
      });
    }, 1);
  }, [defaultCategory]);

  function handleInputs(data?: HTMLInputElement) {
    if (data && handleInputChanges) {
      handleInputChanges(data);
    }
  }

  return (
    <tr className="tableItemEdit__row">
      <td>
        <div>
          <h4>{category?.name}</h4>
        </div>
      </td>
      <td>
        <div>
          <h4>{category?.age_description}</h4>
        </div>
      </td>
      {category?.month_evolution.map((monthProps, key) => {
        return (
          <td key={key}>
            <Scope path={`weights[${key}]`}>
              <InputWeight
                category={category}
                monthProps={monthProps}
                evolutionName={evolutionName}
                handleInputChanges={handleInputs}
                onInputError={(err) => inputError && inputError(err)}
              />
            </Scope>
          </td>
        );
      })}
    </tr>
  );
}
