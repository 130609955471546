import "./styles.css";
import { Children, ElementType, ReactElement, ReactNode, useEffect } from "react";
import { SectionProps, SectionUrlProps } from "../../../../contexts/CattleMapNavMenuContext";

type MenuContainerProps = {
  icon?: any;
  title?: string;
  children?: ReactNode | Array<ReactNode>;
  borderColor?: string;
  background?: string;
};

export function MenuContainer({ icon, title, children, borderColor, background }: MenuContainerProps) {
  const Icon = icon as ElementType;
  // Isto armazena todas as urls passadas nos componentes filhos
  useEffect(() => {
    const listUrls: Array<SectionUrlProps> = Children.map(children as ReactElement, (child) => {
      const { name, title } = child.props;
      return { nameUrl: name, titleUrl: title };
    });
    const section = { title, icon: icon, urls: listUrls } as SectionProps;
  }, []);

  return (
    <section
      className="sidebar__card"
      style={{ backgroundColor: background || "#FFFFFF", borderLeftColor: borderColor || "transparent" }}
    >
      <div>{icon && <Icon />}</div>
      <div className="sidebar__card--right">
        <strong>{title}</strong>
        <div className="sidebar__card--options">{children}</div>
      </div>
    </section>
  );
}
