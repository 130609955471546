import { useEffect, useState } from "react";
import "./styles.css";

import { SubStepsScreenProps } from "../StepByStep/Index";

import { useOnboarding } from "../../../../hooks/useOnboarding";

type SubStepProps = {
  subStep?: SubStepsScreenProps;
  sectionNumb: number;
  screenNumb: number;
};

export function SubStep({ subStep, screenNumb, sectionNumb }: SubStepProps) {
  const { userProgress, setShowScreen } = useOnboarding();

  // Diz qual o estado atual da tela "nada","avançado","pulado"
  const [state, setState] = useState<string>("nothing");

  // Monitora o Estado da subtela e exibe as estilização certa
  useEffect(() => {
    if (userProgress.length >= sectionNumb) {
      //precisa ser "sectionNumb - 1" pois estamos manipulando o indice do stado
      const section = userProgress[sectionNumb - 1];
      if (section.screens) setState(section.screens[screenNumb].type);
    }
  }, [userProgress, screenNumb, sectionNumb]);

  return (
    <section className="subStep__container">
      <div className="subStep__verticalLine" />
      <button
        className="subStep__container--button"
        onClick={() => setShowScreen(userProgress, sectionNumb, screenNumb)}
        disabled={state === "nothing" ? true : false}
      >
        <div className={`subStep__content subStep__border--${state}`}>
          {subStep?.name && (
            <span className={`subStep__title subStep__title--${state}`}>{subStep?.name}</span>
          )}
          <div className={`subStep__rounded--content subStep__rounded--${state}`} />
        </div>
      </button>
    </section>
  );
}
