import React, { useEffect } from 'react';
import { Chart as ElementChart } from 'react-chartjs-2';
import Chart, { ChartData } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "../styles.css"

interface Props {
    label: string;
    labelOptions: string[];
    optionsColumn1: LabelElement[];
    optionsColumn2: LabelElement[];
    fullWidth?: boolean;
}

interface LabelElement {
    label: string[] | string;
    value: number;
}

const ChartBarDouble: React.FC<Props> = ({ label, labelOptions, optionsColumn1, optionsColumn2, fullWidth }) => {
    Chart.register(ChartDataLabels);

    const charData: ChartData = {
        labels: optionsColumn1?.map((el) => el.label),
        datasets: [
            {
                type: 'bar',
                label: labelOptions[0],
                backgroundColor: "#57CE71",
                data: optionsColumn1?.map((el) => Number(el.value.toFixed(2))),
                borderWidth: 1,
            },
            {
                type: 'bar',
                label: labelOptions[1],
                backgroundColor: "#FF9501",
                data: optionsColumn2?.map((el) => Number(el.value.toFixed(2))),
                borderWidth: 1,
            }
        ],
    };


    return (
        <div>
            <span className='titleGraph'>{label}</span>
            <div className={`contentGraph contentGraphBorder ${fullWidth ? "containerGraphFull" : ""}`}>
                {optionsColumn1?.every((el: any) => el?.value === 0) && optionsColumn2?.every((el: any) => el?.value === 0)?
                    <div className='noData'>
                        <span>Sem dados</span>
                    </div>
                    :
                    <>
                        <ElementChart
                            className={` ${fullWidth ? "fullWidth" : ""}`}
                            type='bar'
                            data={charData}
                            options={{
                                devicePixelRatio: 4,
                                animation: {
                                    duration: 0
                                },
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                        labels: {
                                            boxWidth: 10,
                                        },
                                    },
                                    datalabels: {
                                        display: true,
                                        anchor: "end",
                                        align: "start",
                                        formatter: Math.round,
                                        offset: -20,
                                        font: {
                                            size: 10,
                                            weight: "bold"
                                        },
                                    },
                                },
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                        }
                                    }
                                },
                                layout: {
                                    padding: {
                                        top: 30
                                    }
                                },
                            }
                            }
                            width={fullWidth ? 1360 : 200}
                            height={fullWidth ? 500 : 200}
                        />
                    </>
                }
            </div>
        </div>
    )
};

export default ChartBarDouble;