import { HTMLAttributes, useEffect, useState } from 'react';
import '../Base/ComponentChipBase/styles.css'

import ChipBaseComponent,
{ DEFAULT_CHIP_COLORS, DEFAULT_THEMES_CHIP_CONTAINER, DEFAULT_THEMES_CHIP_LABEL }
    from '../Base/ComponentChipBase/Index'

type IPressableThemes = 'menu-light' | 'menu-dark' | 'link-dark' | 'link-light'

interface ChipPressableComponentProps
    extends HTMLAttributes<HTMLButtonElement>, Omit<IComponentChipBase<IPressableThemes>, 'theme'> {
    theme: IPressableThemes | {
        variations: { normal: IPressableThemes, actived: IPressableThemes };
    },
    actived?: boolean
}

export const PRESSABLE_CONTAINER_THEMES = {
    'menu-light': DEFAULT_THEMES_CHIP_CONTAINER.light,
    'menu-dark': DEFAULT_THEMES_CHIP_CONTAINER.dark,
    'link-dark': DEFAULT_THEMES_CHIP_CONTAINER.dark,
    'link-light': DEFAULT_THEMES_CHIP_CONTAINER.light
}

export const PRESSABLE_LABEL_THEMES = {
    'menu-light': DEFAULT_THEMES_CHIP_LABEL.light,
    'menu-dark': DEFAULT_THEMES_CHIP_LABEL.dark,
    'link-dark': {
        ...DEFAULT_THEMES_CHIP_LABEL.light,
        'font-family': 'Fira Code',
        'font-weight': '400',
        'font-size': '20px',
        'color': DEFAULT_CHIP_COLORS.WHITE_900,
    },
    'link-light': {
        ...DEFAULT_THEMES_CHIP_LABEL.light,
        'font-family': 'Fira Code',
        'font-weight': '400',
        'font-size': '20px',
        'color': DEFAULT_CHIP_COLORS.DARK_100,
    },
}

const PRESSABLE_THEME_VARIATION = {
    label: {
        'font-size': '22px'
    }
} as IChipComponentVariationStyles

export default function ChipPressableComponent({ theme, actived, variationStyles, ...rest }: ChipPressableComponentProps) {
    const [currentTheme, setcurrentTheme] = useState<IPressableThemes>('menu-light');

    useEffect(() => {
        if (actived !== undefined && typeof theme !== 'string')
            setcurrentTheme(() => actived ? theme.variations.actived : theme.variations.normal)
        else if (actived === undefined && typeof theme !== 'string')
            setcurrentTheme(theme.variations.normal)
        else if (typeof theme === 'string') setcurrentTheme(theme)
    }, [actived, theme])

    return <>
        <button className='chip-pressable__container' type='button' style={variationStyles?.container} {...rest}>
            <ChipBaseComponent<IPressableThemes>
                variationStyles={{ ...PRESSABLE_THEME_VARIATION, ...variationStyles }}
                overrideContainerTheme={PRESSABLE_CONTAINER_THEMES}
                overrideLabelTheme={PRESSABLE_LABEL_THEMES}
                theme={currentTheme}
                {...rest}
            />
        </button>
    </>
}