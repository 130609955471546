import { Polygon, Polyline } from "@react-google-maps/api";
import { useField } from "@unform/core";
import useEventListener from "@use-it/event-listener";
import { ReactNode, useEffect, useRef, useState } from "react";
import "./styles.css";
import { ReactComponent as FarmIcon } from "../../../../assets/svg/icons/farmIcon.svg";
import { Map } from "../../Map/Index";
import { FixedAreas } from "../FixedAreas/Index";
import { LabelIcon } from "../LabelIcon/Index";
import { ToolBar } from "../ToolBar/Index";
import { IFarm } from "../../../../@types/app/IFarm";
import { ICoord } from "../../../../@types/GoogleMaps/ICoord";
import { baseIPositionProps, IPositionProps } from "../../../../@types/GoogleMaps/IPositionProps";
import Constants from "../../../../constants/Index";
import { CustomPolygonProps } from "../../../../contexts/GoogleMapsContext";
import { useGoogleMaps } from "../../../../hooks/useGoogleMaps";
import { generateuuid } from "../../../../utils/uuid/generate";

type CreateAreaProps = {
  onHandleSuccess?: (polygon?: IPositionProps) => void;
  getMessageError?: (error: string) => void;
  onHandleValidate?: () => void;
  onHandleDiscardChanges?: () => void;
  labelContainerMap?: string;
  children?: ReactNode;
  farm?: IFarm;
  area?: IPositionProps;
  areas?: any;
  fixedlastName?: string;
};

export function DrawPolygon({
  labelContainerMap,
  getMessageError,
  onHandleSuccess,
  farm,
  area,
  onHandleValidate,
  onHandleDiscardChanges,
}: CreateAreaProps) {
  const {
    setPointSelected,
    getCenterPolygon,
    getGeoposition,
    pointSelected,
    onRemovePoint,
    onEditPoint,
    setToolMode,
    toolMode,
    setExistingMaps,
    existingMaps,
  } = useGoogleMaps();

  // Manipulador de eventos do teclado
  useEventListener("keydown", handlerKeyboard);

  // Referência do componente
  const polygonRef = useRef<CustomPolygonProps | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [centerMap, setCenterMap] = useState<ICoord>();

  const [polygon, setPolygon] = useState<IPositionProps>(baseIPositionProps);

  // Armazena o componente para depois ser repassado para a referência
  const [polygonComponet, setPolygonComponet] = useState<CustomPolygonProps | null>(null);

  // Isto define as configurações iniciais do polígono e a referência
  useEffect(() => {
    if (polygonComponet && polygonRef) {
      polygonRef.current = polygonComponet;
    }
  }, [polygonComponet, polygonRef]);

  const { fieldName, registerField, error } = useField("map_coords");

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef,
      getValue: (ref) => {
        const value = ref.current.value;
        if (value && typeof value === "string") {
          const parser = JSON.parse(value);
          return parser;
        } else return { uuid: "", coords: [] };
      },
      setValue: (ref, value: any) => {
        new Promise((resolve) => {
          setPolygon({ uuid: generateuuid(), coords: [] });
          if (value) {
            const exists = existingMaps.filter((e) => e.uuid === value.uuid);
            setPolygon({
              ...value,
              uuid: !!exists ? generateuuid() : value.uuid,
            } as IPositionProps);
          }
          ref.current.value = JSON.stringify(value) || "";
          resolve(value);
        }).then(() => {
          onHandleValidate && onHandleValidate();
        });
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => getMessageError && getMessageError(error as string), [error]);

  // Isto Manipula as ações de teclado
  function handlerKeyboard({ key }: KeyboardEvent) {
    const DELETE_KEYS = ["Delete", "Backspace"];

    if (DELETE_KEYS.includes(String(key))) {
      if (pointSelected) {
        const newArea = onRemovePoint(polygon.coords, pointSelected.coords);
        setPolygon((prevState) => {
          return { ...prevState, coords: newArea };
        });
        setPointSelected(undefined);
      }
    }
  }

  function onMouseUp(event: google.maps.MapMouseEvent) {
    const coordsEdited = onEditPoint(polygonRef);
    const coords = getGeoposition(event);
    // Diz ao contexto qual ponto foi selecionado
    setPointSelected(() => {
      return { uuid: polygon.uuid, coords };
    });
    // Repassa o novo poligono editado pro contexto
    setPolygon((prevState) => {
      return { uuid: prevState.uuid, coords: coordsEdited };
    });
  }

  useEffect(() => {
    if (farm && farm.map_coords) {
      setCenterMap(getCenterPolygon(farm.map_coords));
    }
  }, [farm]);

  useEffect(() => {
    if (area && area.coords) {
      setCenterMap(getCenterPolygon(area.coords));
    }
  }, [area]);

  const handleSuccess = () => {
    setExistingMaps((maps) => {
      const filtered = maps.filter((map) => map.uuid !== polygon.uuid);
      return [...filtered, polygon];
    });
    onHandleSuccess && onHandleSuccess(polygon);
  };

  useEffect(() => {
    setPolygon(() => ({ uuid: generateuuid(), coords: [] }));
  }, []);

  function onClickMouse(event: google.maps.MapMouseEvent) {
    if (toolMode === "draw")
      setPolygon((prev) => {
        const response = {
          ...prev,
          coords: [...prev.coords, getGeoposition(event)],
        };

        return response;
      });
  }

  function discardChanges() {
    setPolygon((prev) => ({ ...prev, coords: [] }));
    if (textareaRef && textareaRef.current) textareaRef.current.value = "";
    onHandleDiscardChanges && onHandleDiscardChanges();
  }

  useEffect(() => {
    if (polygon.coords.length > 0 && textareaRef && textareaRef.current)
      textareaRef.current.value = JSON.stringify(polygon);
  }, [polygon]);

  return (
    <>
      <section className="drawpolygon__container">
        <textarea ref={textareaRef} id={fieldName} />
        <LabelIcon Icon={FarmIcon} label={labelContainerMap || farm?.initials} />
        <Map currentCoords={centerMap} onClick={onClickMouse}>
          {farm && (
            <Polyline
              options={Constants.POLYLINE_DASHED_GREEN}
              path={[...farm.map_coords, farm.map_coords[0]]}
              draggable={false}
              editable={false}
            />
          )}
          {area && (
            <Polyline
              options={Constants.POLYLINE_DASHED_GREEN}
              path={[...area.coords, area.coords[0]]}
              draggable={false}
              editable={false}
            />
          )}
          {existingMaps &&
            existingMaps?.map((area, index) => {
              return <FixedAreas area={area} farmInitials={""} areaInitials={area.nameMap} key={index} />;
            })}
          {polygon && (
            <Polygon
              onLoad={(polygon) => setPolygonComponet(polygon)}
              onClick={() => setToolMode("draw")}
              options={Constants.POLYGON_YELLOW}
              editable={toolMode === "draw"}
              path={polygon.coords}
              onMouseUp={onMouseUp}
            />
          )}
        </Map>
        <ToolBar handleSuccess={handleSuccess} handleDiscardChanges={discardChanges} />
      </section>
    </>
  );
}
