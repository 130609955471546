import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ReactComponent as GrassIcon } from "../../../../assets/svg/icons/grass.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";
import { ButtonYesOrNo } from "../../../Buttons/ButtonYesOrNo";
import { format } from "date-fns";

interface InfoPlantingProps {
  task: ITaskPlanting | undefined;
}


export default function InfoPlanting(task: InfoPlantingProps) {
  const [taskPlanting, setTaskPlanting] = useState<ITaskPlanting>();
  useEffect(() => {
    setTaskPlanting(task.task)
  }, []);


  return (
    <>
      <div >
        <span>No pasto</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <GrassIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskPlanting?.initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <MessageBalloon text={`${taskPlanting?.planting_type} - ${taskPlanting?.fodder_genus} - ${taskPlanting?.fodder_species} - ${taskPlanting?.seed_type} - ${taskPlanting?.vc_percentage}%VC - ${taskPlanting?.seed_quantity_kg} Kg`} />

      <div className="containerQuestion">
        <MessageBalloon text={`A tarefa foi iniciada?`} pointed />
        <ButtonYesOrNo onlyResult optionSelected={!taskPlanting?.skip_reason  ? "yes" : "no"} />
      </div>

      {taskPlanting?.skip_reason ?
        <MessageBalloon text={`${taskPlanting?.skip_reason}`} pointed />
        :
        <>
          <div className="containerQuestion">
            <MessageBalloon text={`Qual o tipo de operação?`} pointed />
            <ButtonPressed onlyResult optionSelected={1} value={1} text={`${taskPlanting?.operation_type}`} />
          </div>


          {taskPlanting?.operation_type == "MECANIZADA" &&
            <>
              <MessageBalloon text={`${format(new Date(taskPlanting?.start_time || 0), 'dd/MM/yyyy - HH:mm')}`} />

              <MessageBalloon text={`Informar horímetro inicial`} />
              <CounterInputEdit isDisabled defaultValue={taskPlanting?.initial_hour_meter || 0} control={undefined} isInline label="h" />


              <ButtonPressed onlyResult optionSelected={1} value={1} text={`Horímetro final`} />

              <MessageBalloon text={`${format(new Date(taskPlanting?.end_time || 0), 'dd/MM/yyyy - HH:mm')}`} />

              <MessageBalloon text={`Informar horímetro final`} />
              <CounterInputEdit isDisabled defaultValue={taskPlanting?.final_hour_meter || 0} control={undefined} isInline label="h" />
            </>
          }
        </>
      }
    </>
  )
}