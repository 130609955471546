import { FilterDateDashboard } from "../../../../components/Dashboard/ViewDatas/FilterDate";
import { ReactComponent as BagFood } from "../../../../assets/svg/icons/bagFood.svg";
import { IOptionProps } from "../../../../@types/reactSelect/IOption";
import { DateModal } from "../../../../components/Modal/DateFilter";
import { useFilter } from "../../../../hooks/useFilter";
import Constants from "../../../../constants/Index";
import axios from "../../../../services/axios";
import { useEffect, useState } from "react";
import { format } from "date-fns";

export default function DashboardSupplementationInventoryControl() {
  const {
    farmFilter,
    areasIdsFilter,
    rangeDays,
    setHideMicroAreaFilter,
    setHideBatchFilter,
    setDashboard,
    setSideBarOptionSelected } = useFilter();

  const [centralWarehouse, setCentralWarehouse] = useState<ICentralWarehouseStock[]>();
  const [depositeInPasture, setDepositeInPasture] = useState<IDepositInPasture[]>();
  const [generalAnalysis, setGeneralAnalysis] = useState<IGeneralAnalysis[]>();
  const [allAreas, setAllAreas] = useState<IOptionProps[]>([]);

  useEffect(() => {
    setHideMicroAreaFilter(true)
    setHideBatchFilter(true)
    setSideBarOptionSelected("DASHBOARD")
    setDashboard("SUPPLEMENTATION")
    document.title = Constants.TITLE_DASHBOARD;
  }, []);

  useEffect(() => {
    if (farmFilter && areasIdsFilter.length == 0) {
      axios.get("/areas", {
        params: {
          farm_id: farmFilter.id
        }
      }).then((response) => {
        const areas = response.data.map((item: IArea) => ({
          label: item.name,
          value: item.id
        }))
        setAllAreas(areas)
      })
    }
  }, [, farmFilter]);

  useEffect(() => {
    if (farmFilter?.id && (rangeDays || areasIdsFilter.length > 0 || allAreas.length > 0)) {
      let startDate = format(rangeDays?.from ? new Date(rangeDays?.from) : new Date(), 'yyyy-MM-dd HH:mm:ss')
      let endDate = format(rangeDays?.to ? new Date(rangeDays?.to) : new Date(), 'yyyy-MM-dd HH:mm:ss')

      const params = {
        farmId: farmFilter.id,
        areaIds: areasIdsFilter.length > 0 ? areasIdsFilter.map((item) => item.value) : allAreas.map((item) => item.value),
        startDate: startDate,
        endDate: endDate
      }

      axios.get("/supplement/central-warehouse-stock", {
        params,
        paramsSerializer: params => {
          return new URLSearchParams(params).toString();
        }
      }).then((response) => {
        setCentralWarehouse(response.data)
      })

      axios.get("/supplement/general-analysis", {
        params,
        paramsSerializer: params => {
          return new URLSearchParams(params).toString();
        }
      }).then((response) => {
        setGeneralAnalysis(response.data)
      })

      axios.get("/supplement/stock-deposit-in-the-pasture", {
        params,
        paramsSerializer: params => {
          return new URLSearchParams(params).toString();
        }
      }).then((response) => {
        setDepositeInPasture(response.data)
      })
    }
  }, [rangeDays, areasIdsFilter, allAreas]);

  const IconMarq = (value: number, min: number, max: number) => {
    let hex = "FFFFFF";

    if (value <= min) {
      hex = "FF0000";
    } else if (value >= min && value <= max) {
      hex = "FFFF00";
    } else {
      hex = "00FF00";
    }

    return (
      <div style={{
        width: 20,
        height: 20,
        background: `#${hex}`,
        borderRadius: 50,
        border: "1px solid #FFF"
      }} />
    )
  }

  return (
    <div className="cattleMap_background">
      <DateModal />

      <div className="cattleMap_page">
        <div className="cattleMap_header">
          <BagFood height={72} />
          <div style={{
            display: "flex",
            flexDirection: "column",
          }}>
            <h2 className="cattleMap_title">
              Suplementação
            </h2>
            <h3 className="cattleMap_subtitle">
              Controle de estoque
            </h3>
          </div>
        </div>

        <FilterDateDashboard />

        {centralWarehouse &&
          <div className="tableDashboard" id={"estoque_suple"}>
            <h2>Estoque Depósito Central</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "300px",
                  textAlign: "left"
                }}>
                  Linha de Suplementação
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  Produto
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px",
                }} >
                  Estoque total
                  <br />
                  (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  Média de saídas diárias
                  <br />
                  (Kg/dia)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px"
                }}>
                  Dias em estoque
                  <br />
                  (dias)
                </th>
              </thead>

              <tbody>
                {
                  centralWarehouse?.map((item, index) => (
                    <tr className={index % 2 === 0 ? " bg_white" : "bg_gray"}>
                      <th scope="row">{item.name}</th>
                      <td style={{
                        textAlign: "left"
                      }}>{item.trade_name}</td>
                      <td>{item.actual_quantity}</td>
                      <td>{item.average_daily_output}</td>
                      <td>{item.days_in_stock}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        }


        {generalAnalysis &&
          <div className="tableDashboard" id={"analise_geral"}>
            <h2>Análise Geral</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px",
                  textAlign: "left"
                }}>
                  Classificação do Produto
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "200px",
                  padding: "0 20px"
                }}>
                  Consumo Total no Período
                  <br />
                  (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px",
                }} >
                  Período Analisado
                  <br />
                  (dias)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "150px",
                  padding: "0 20px"
                }}>
                  Animais Suplementados
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "250px"
                }}>
                  Peso Médio dos Animais Suplementados
                  <br />
                  (Média de Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "240px",
                  padding: "0 20px"
                }}>
                  Peso Total dos Animais Suplementados
                  <br />
                  (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "210px",
                }} >
                  Total de UA
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  Consumo Médio no Período
                  <br />
                  (g/kgPV.dia)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px"
                }}>
                  Consumo Médio no Período
                  <br />
                  (g/UA.dia)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  Frequência de Reposição
                  <br />
                  (Média de dias)
                </th>
              </thead>

              <tbody>
                {
                  generalAnalysis.map((item, index) => (
                    <tr className={index % 2 === 0 ? " bg_white" : "bg_gray"}>
                      <th scope="row">{`${item.name}`}</th>
                      <td>{item.total_consumption_period}</td>
                      <td>{item.analyzed_period}</td>
                      <td>{item.supplemented_animals}</td>
                      <td>{parseFloat(item.average_weight_of_supplemented_animals.toFixed(2))}</td>
                      <td>{parseFloat(item.total_weight_of_supplemented_animals.toFixed(2))}</td>
                      <td>{parseFloat(item.uas_total_number_of_animals_supplemented.toFixed(2))}</td>
                      <td>{parseFloat(item.average_consumption_in_the_period_kg.toFixed(3))}</td>
                      <td>{parseFloat(item.average_consumption_in_the_period_ua.toFixed(3))}</td>
                      <td>{parseFloat(item.replacement_frequency.toFixed(2))}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        }

        {depositeInPasture &&
          <div className="tableDashboard" id={"estoque_pasto"}>
            <h2>Estoque Depósito no Pasto</h2>
            <table>
              <thead style={{
                color: "#fff"
              }}>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px",
                  textAlign: "left"
                }}>
                  Pasto
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  Tipo de Suplemento
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px",
                }} >
                  Quantidade no Depósito do Pasto
                  <br />
                  (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#808080",
                  width: "180px",
                  padding: "0 20px"
                }}>
                  Capacidade Total do Depósito *
                  <br />
                  (Kg)
                </th>
                <th scope="col" rowSpan={2} style={{
                  background: "#324440",
                  width: "180px"
                }}>
                  % da Capacidade Ocupada do Depósito *
                </th>
              </thead>

              <tbody>
                {
                  depositeInPasture?.map((item, index) => (
                    <tr className={index % 2 === 0 ? "bg_white" : "bg_gray"}>
                      <th scope="row">{item.initials}</th>
                      <td style={{
                        textAlign: "left"
                      }}>{item.name}</td>
                      <td>{item.stock_balance != null ? item.stock_balance : '-'}</td>
                      <td>{item.stock_capacity != null ? item.stock_capacity : '-'}</td>
                      <td style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%"
                      }}>
                        {item.stock_capacity != 0 ? IconMarq((item.stock_balance / item.stock_capacity), 30, 50) :
                          <div style={{
                            width: 20,
                            height: 20,
                          }} />
                        }
                        {item.stock_capacity ? item.stock_balance / item.stock_capacity : 0} %
                      </td>
                    </tr>
                  ))
                }
              </tbody>

              <tfoot
                style={{
                  background: "#324440",
                  color: "#fff"
                }}
              >
                <th scope="row" style={{
                  textAlign: "left"
                }}>Total geral</th>
                <td />
                <td>{depositeInPasture?.reduce((acc, item) => acc + item.stock_balance, 0)} kg</td>
                <td>{depositeInPasture?.reduce((acc, item) => acc + item.stock_capacity, 0)} kg</td>
                <td>{depositeInPasture?.reduce((acc, item) => acc + item.stock_capacity, 0) != 0 ?
                  <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}>
                    {IconMarq(29, 30, 50)}{depositeInPasture?.reduce((acc, item) => acc + item.stock_balance, 0) / depositeInPasture?.reduce((acc, item) => acc + item.stock_capacity, 0)} %
                  </div>
                  : <span>
                    0 %
                  </span>
                }
                </td>
              </tfoot>
              <span>*Valores serão demonstrados após a realização de uma avaliação de pastagem</span>

            </table>

            <div className="tableDashboard" id={"estoque_suple"}>
              <table>
                <thead style={{
                  color: "#fff"
                }}>
                  <th scope="col" rowSpan={2} style={{
                    background: "#324440",
                    width: "150px",
                    textAlign: "left"
                  }}>
                    Legenda
                  </th>

                  {[{
                    legenda: "<=30%"
                  },
                  {
                    legenda: ">=31% <=50%"
                  },
                  {
                    legenda: ">50%"
                  }].map((item) => (
                    <th scope="col" rowSpan={2} style={{
                      background: "#324440",
                      width: "150px",
                      textAlign: "left"
                    }}>
                      {item.legenda}
                    </th>
                  ))}
                </thead>

                <tbody>
                  <tr className="bg_white">
                    <th scope="row"></th>
                    <td style={{
                      textAlign: "left"
                    }}>{IconMarq(1, 30, 50)}</td>
                    <td style={{
                      textAlign: "left"
                    }}>{IconMarq(35, 30, 50)}</td>
                    <td style={{
                      textAlign: "left"
                    }}>{IconMarq(55, 30, 50)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    </div>
  );
}
