import { Form } from "@unform/web";
import { useRef, useState } from "react";
import { TextInputWithLabel } from "../../../components/Inputs/TextInputs/TextInputWithLabel/Index";
import { ButtonOnlyTitle } from "../../../components/Buttons/ButtonOnlyTitle/Index";
import "./styles.css";
import * as Yup from "yup";
import useEventListener from "@use-it/event-listener";
import { useNavigate } from "react-router-dom";
import axios from "../../../services/axios";
import Constants from "../../../constants/Index";
import { useYupOnlyVerify } from "../../../hooks/useYupValidate";
import { useAuth } from "../../../hooks/useAuth";

const schemaOnSubmit = Yup.object().shape({
  password: Yup.string()
    .required('Please Enter your password')
    .matches(
      /[0-9]{6,}/,
      "A senha deverá ser composta por no mínimo 6 caracteres, somente números."
    ),
  confirmpassword: Yup.string()
    .required("Repita sua senha")
    .oneOf([Yup.ref("password"), null], "*Senhas não conferem."),
});

const schemaOnChange = Yup.object().shape({
  password: Yup.string()
    .required(),
  confirmpassword: Yup.string()
    .required()
});

export function NewPassword() {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement | null>(null);
  const {user} = useAuth();

  async function handleFormSubmit() {
    const formData = formRef.current?.getData() || null;
    formRef.current?.setErrors({});
    const errors = {} as any;
    if (formData) {
      try {
        // Isso valida o formulário
        await schemaOnSubmit.validate(formData, { abortEarly: false });
        const json = {
          "password": formData["password"]
        }
        await axios
          .put("/auth/reset", json)
          .then(() =>  setNewPassword(false))
          .catch((error) => console.log(error));
      } catch (err) {
        // Se chegamos aqui existem erros no form
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((e) => {
            errors[e.path as string] = e.message;
          });
        }
      } finally {
        if (formRef && formRef.current) formRef.current.setErrors(errors);
      }
    }
  }

  useEventListener("keyup", (event: KeyboardEvent) => {
    const { key } = event;
    if (key === "Enter") handleFormSubmit();
  });

  const [newPassword, setNewPassword] = useState(true)
  const [disableAvancar, setDisableAvancar] = useState(true)

  async function handleChangesPassword() {
    const formData = formRef.current?.getData();
    //  Valida se os campos obrigatórios do formulário foram preenchidos
    const error = await useYupOnlyVerify(formRef, formData, schemaOnChange);
    if (error) {
      setDisableAvancar(true)
      const [err] = Object.values(error);
      if (err === Constants.MESSAGE_UNIQUE_PHONE) formRef.current?.setErrors(error);
    } else setDisableAvancar(false)
  }

  return (
    <>
      {newPassword ?
        <div className="newPassword__container">
          <h1 className="newPassword__container--title">Nova senha</h1>

          <Form ref={formRef as any} onSubmit={() => handleFormSubmit}>
            <div className="login__form--input">
              <TextInputWithLabel type="password" label="Senha" name="password" regex={/\d+/g} onAnywhereChanges={handleChangesPassword} id="password"/>
            </div>

            <div className="login__form--input">
              <TextInputWithLabel type="password" label="Confirmar Senha" name="confirmpassword" regex={/\d+/g} onAnywhereChanges={handleChangesPassword} id="confirmpassword"/>
            </div>

            <div className="d-flex button_container">
              <div className="button_voltar">
                <ButtonOnlyTitle onClick={() => navigate("/entrar")} title="Voltar" theme="light"  type="button" id="bt_voltar"/>
              </div>

              <div className="button_continuar">
                <ButtonOnlyTitle onClick={handleFormSubmit} title="Continuar" theme="success" disabled={disableAvancar} id="bt_continuar"/>
              </div>
            </div>
          </Form>
        </div>
        :
        <div className="newPassword__container">
          <h1 className="newPassword__container--title">Senha alterada com sucesso!</h1>
          <h4 className="newPassword__container--subtitle">Agora vamos juntos realizar seu cadastro!</h4>

          <div className="button_começar">
            <ButtonOnlyTitle onClick={() => {
              axios
                .get("/farms/user")
                .then(({data})=>{
                  if (data.length == 0 && user.role=='MASTER') navigate("/bemvindo/onboarding")
                  else navigate("/mapadepastos")
                })
              }} title="Começar" theme="success"  id="bt_comecar"/>
          </div>
        </div>
      }
    </>
  );
}
