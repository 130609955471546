import ReactDOM from "react-dom";
import { App } from "./App";
import "./global/global.css";

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById("root")
);
