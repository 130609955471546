import { ButtonOnlyTitle } from "../../../../Buttons/ButtonOnlyTitle/Index";
import { MutableRefObject, useEffect, useState } from "react";
import { FormArea, IFieldsNames } from "../FormArea/Index";
import AnimateHeight from "react-animate-height";
import { ReactComponent as EyeOpen } from "../../../../../assets/svg/icons/eyeClosed.svg";
import { ReactComponent as EyeClosed } from "../../../../../assets/svg/icons/eye.svg";
import { Scope } from "@unform/core";
import { IOptionsProps } from "../../../../../@types/reactSelect/IOption";
import * as yup from "yup";
import { IPositionProps, IPositionsProps } from "../../../../../@types/GoogleMaps/IPositionProps";
import { useGoogleMaps } from "../../../../../hooks/useGoogleMaps";
import { generateuuid } from "../../../../../utils/uuid/generate";
import { ICreateArea, ICreateAreas, IFarmArea } from "../Index";

type FarmProps = {
  formRef: MutableRefObject<HTMLFormElement | undefined>;
  farmid: number;
  areaSchema: any;
  isEdition?: boolean;
  farm: IFarmArea;
  disableEditFields: Array<IFieldsNames>;
  dataAreas: ICreateAreas;
  managersList: IOptionsProps;
  validateDataForm?: (data: any) => void;
};

export function FarmContainer({
  formRef,
  farmid,
  areaSchema,
  farm,
  isEdition = false,
  disableEditFields,
  dataAreas,
  validateDataForm,
  managersList,
}: FarmProps) {
  const { setExistingMaps } = useGoogleMaps();

  // referencia do formulário
  const [visibleFarm, setVisibleFarm] = useState<"auto" | 0>("auto");
  const [areas, setAreas] = useState<ICreateAreas>([]);
  const [mapAreas, setMapAreas] = useState<IPositionsProps>();

  const handleVisibleFarm = async () => {
    setVisibleFarm((prev) => (prev === 0 ? "auto" : 0));
  };

  const areaBase = {
    id: "",
    farmId: farm.id.toString(),
    name: "",
    initials: "",
    managerUser: null,
    coords: [],
    openForm: true,
    isNew: true,
    acreage: "",
    indoor_grazing_time: null,
    outdoor_grazing_time: null,
  } as ICreateArea;

  useEffect(() => {
    if (dataAreas.length == 0) setAreas(() => [{ ...areaBase, id: generateuuid() }]);
    else
      setAreas(() =>
        dataAreas
          .filter((f) => f.farmId === farm.id)
          .map((area, index) => ({ ...area, openForm: index === 0, isNew: false }))
      );
  }, []);

  async function isValidViewArea(areaToValidate: any) {
    const errors = {} as any;
    try {
      await areaSchema.validate({ areas: areaToValidate }, { abortEarly: false });
      return true;
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        err.inner.forEach((e, i) => {
          errors[(`farms[${farmid}].` + e.path) as string] = e.message;
        });
      }
    } finally { 
      if (formRef && formRef.current) formRef.current.setErrors(errors);
    }
    return false;
  }

  // Isso adiciona um novo retiro
  async function addNewArea() {
    const formData = formRef.current?.getData().farms || null;
    if (formData[farmid]) {
      if (await isValidViewArea(formData[farmid]?.areas)) {
        validateDataForm && validateDataForm(false);
        setAreas((values) => {
          return [...values, { ...areaBase, id: generateuuid() }];
        });
      }
    } else {
      setAreas((values) => {
        return [...values, { ...areaBase, id: generateuuid() }];
      });
    }
    setTimeout(async ()=>{
      validateDataForm && validateDataForm(true)
    }, 100)
  }

  // Isso remove um retiro
  async function removeArea(uuid: string) {
    formRef.current?.setErrors({});
    const areasFom = formRef.current?.getData().farms[farmid].areas;
    if (areasFom) {
      const filtered = areasFom
        .filter((a: any) => a.uuid !== uuid)
        .map((area: any) => ({
          id: area.uuid,
          farmId: area.farm_id,
          acreage: area.acreage,
          name: area.name,
          initials: area.initials,
          managerUser: area.manager_user_id,
          indoor_grazing_time: area.indoor_grazing_time,
          outdoor_grazing_time: area.outdoor_grazing_time,
          coords: area.map_coords.coords,
          openForm: true,
          isNew: area.isNew === "true",
        }));
      setAreas(() => filtered);
    }
    setTimeout(async ()=>{
      validateDataForm && validateDataForm(true)
    }, 100)
  }

  async function handleChangesAreas() {
    const formData = formRef.current?.getData().farms || null;
    if (formData && formData[farmid]) {
      validateDataForm && validateDataForm(true);
    }
  }

  function onHandleSuccess(polygon?: IPositionProps) {
    setMapAreas((areas) => {
      if (areas && polygon) {
        const filtered = areas.filter((area) => area.uuid !== polygon?.uuid);
        return [...filtered, polygon as IPositionProps];
      }
      return [polygon || ({} as IPositionProps)];
    });
  }

  useEffect(() => {
    setExistingMaps(() => []);
  }, [areas]);

  return (
    <>
      <div className="area__title--container">
        <h2>
          {farm.name} ({farm.initials})
        </h2>
        <button type="button" onClick={handleVisibleFarm} style={{ marginTop: 40 }}>
          {visibleFarm ? <EyeOpen /> : <EyeClosed />}
        </button>
      </div>

      <div className="area__divider" />

      <AnimateHeight height={visibleFarm}>
        {areas.map((area, index) => (
          <section key={index} className="dividercontainer">
            <Scope path={`areas[${index}]`}>
              <FormArea
                managers={managersList}
                farm={farm}
                farmId={farmid}
                areaId={index}
                formRef={formRef}
                isDeletable={isEdition || areas.length > 1 || index > 0}
                initData={area}
                handleChangesAreas={handleChangesAreas}
                onRemoveArea={removeArea}
                onHandleSuccess={onHandleSuccess}
                mapAreas={mapAreas}
                isEdition={isEdition}
                disableEditFields={disableEditFields}
                farmInitials={farm.initials}
              />
            </Scope>
            <div className="area__divider" />
          </section>
        ))}

        <div style={{ width: 448, marginLeft: 50, marginBottom: 20 }}>
          {!(disableEditFields.length == 5) && 
            <ButtonOnlyTitle
              theme="info"
              title="+ adicionar outro retiro"
              onClick={addNewArea}
              type={"button"}
            />
          }
        </div>
      </AnimateHeight>
    </>
  );
}
