import { ReactComponent as PositiveIcon } from "../../../assets/svg/icons/positive.svg";
import { ReactComponent as NegativeIcon } from "../../../assets/svg/icons/negative.svg";
import "./styles.css"

interface ButtonYesOrNoProps {
  optionSelected: "yes" | "no" | undefined;
  setOptionSelected?: (option: "yes" | "no") => void;
  onlyResult?: boolean;
}

export function ButtonYesOrNo({
  optionSelected,
  setOptionSelected,
  onlyResult = false
}: ButtonYesOrNoProps) {

  function optionSelect(button: "yes" | "no") {
    if (!optionSelected) return "unselected"
    if (button == "yes" && optionSelected == "yes") {
      return "yesPress"
    }
    if (button == "no" && optionSelected == "no") {
      return "noPress"
    }
    return "notPress"
  }

  return (
    <div className="containerbuttonYesOrNo">
      {optionSelected == "yes" &&
        <button className={`buttonYesOrNo ${optionSelect("yes")}`} onClick={() => {
          if (setOptionSelected) setOptionSelected("yes")
        }} disabled={onlyResult}>
          <PositiveIcon />
          <div className="contentYesOrNo" />
          <span className="textYesOrNo">Sim</span>
        </button>
      }

      {optionSelected == "no" &&
        <button className={`buttonYesOrNo ${optionSelect("no")}`} onClick={() => {
          if (setOptionSelected) setOptionSelected("no")
        }} disabled={onlyResult}>
          <NegativeIcon />
          <div className="contentYesOrNo" />
          <span className="textYesOrNo">Não</span>
        </button>
      }
    </div>
  );
};
