import { IMicroAreas } from "../../@types/API/IMicroArea";
import { IOptionsProps } from "../../@types/reactSelect/IOption";
import { sortBy } from "../arrays/sortBy";

export function getMicroAreasAsOptions(microAreasArray: IMicroAreas, areaId: string | undefined, label?: string) {
    let filtereds: IOptionsProps = [];
    if (areaId) {
      filtereds = microAreasArray
        .filter((m) => m.area_id === areaId)
        .map((m) => ({
          label: `${label ? label : ""}${m.initials}`,
          value: m.id,
        }));
    }
    return filtereds.sort(sortBy("label"));
  }