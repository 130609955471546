import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IOptionsProps } from '../../../../@types/reactSelect/IOption'
import { useEditOnboarding } from '../../../../hooks/useEditOnboarding'
import axios from '../../../../services/axios'
import { Input } from '../../components/Input'
import { InputIcon } from '../../components/InputIcon'
import { Dropdown } from '../../components/Select'
import { FooterEditOnboarding } from '../../Footer'
import './styles.css'
import { DrawOnMap } from '../../components/DrawOnMap/DrawOnMap'
import { ReactComponent as Available } from '../../../../assets/svg/icons/available.svg'
import { ReactComponent as Unvailable } from '../../../../assets/svg/icons/unavailable.svg'
import { ICoords } from '../../../../@types/GoogleMaps/ICoord'
import { Chips } from '../../../Chips'
import { AlertModal } from '../../../Modal/Alert/Index'
import { SimpleButtonClose } from '../../../Modal/Contents/SimpleButtonClose/Index'
import { TextModal } from '../../../Modal/Contents/TextModal/Index'
import { TitleModal } from '../../../Modal/Contents/TitleModal/Index'
import { ButtonRedirect } from '../../../Modal/Error/ButtonRedirect/Index'
import { moveCharLeft } from '../../../../utils/arrays/moveChar'
import { areaService } from '../../../../services/areaService'
import { microAreaService } from '../../../../services/microAreaService'

export function MicroArea() {
  const microAreaSchema = yup.object().shape({
    farm: yup
      .object({
        label: yup.string(),
        value: yup.string().required('Fazenda é necessária.'),
      })
      .nullable()
      .required('Fazenda é necessária.'),
    area: yup
      .object({
        label: yup.string(),
        value: yup.string().required('Retiro é necessário.'),
      })
      .nullable()
      .required('Retiro é necessário.'),
    initials: yup
      .string()
      .matches(/^\S+(\s+\S+)*$/, 'Esta sigla não é válida.')
      .required('Sigla é necessário.'),
    acreage: yup
      .string()
      .test('value', 'Campo obrigatório não informado. ', () => {
        if (Number(acreageInput?.replace(',', '.')) == 0) return false
        return true
      })
      .test(
        'value',
        'O espaço disponível neste retiro foi ultrapassado',
        () => {
          if (Number(remainingArea) >= 0) return true
          return false
        },
      )
      .required('Campo obrigatório não informado.'),
    map_coords: yup
      .array()
      .of(
        yup.object({
          lat: yup.number(),
          lng: yup.number(),
        }),
      )
      .test('is-null', 'Campo obrigatório não informado.', (value) =>
        value ? value.length > 0 : true,
      ),
  })

  const { setOptionForm, defaultValue } = useEditOnboarding()
  const [reasonOptions, setReasonOptions] = useState<IOptionsProps>()
  const [listFarms, setListFarms] = useState<IOptionsProps>()
  const [listAreas, setListAreas] = useState<IOptionsProps>()
  const [currentFarm, setCurrentFarm] = useState<any>()
  const [currentArea, setCurrentArea] = useState<any>()
  const [currentInitials, setCurrentInitials] = useState<string>('')
  const isNew = !defaultValue
  const [microAreaCoords, setMicroAreaCoords] = useState<ICoords>([])
  const [staticMicroAreas, setStaticMicroAreas] = useState<ICoords[]>([])
  const [remainingArea, setRemainingArea] = useState<string>('')
  const [acreageArea, setAcreageArea] = useState('')
  const [msgError, setMsgError] = useState<string>('')
  const [initialsOnlyRead, setInitialsOnlyRead] = useState<boolean>(true)
  const [lenghtMicroArea, setLenghtMicroArea] = useState(0)
  const [acreageMask, setAcreageMask] = useState<string | null>(null)
  const [acreageFormat, setAcreageFormat] = useState<boolean>(false)

  const contentStyleAlert = {
    width: '335px',
    height: '237px',
    borderRadius: '20px',
    padding: '20px',
  } as CSSProperties

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(microAreaSchema),
  })
  const acreageInput = watch('acreage')
  const initialsInput = watch('initials')

  const onSubmit = (data: any) => {
    var acreageFix = data?.acreage?.replace(',', '.')
    if (isNew) {
      var formNewMicroArea = [
        {
          initials: data?.initials,
          acreage: moveCharLeft(acreageFix, acreageFix.charAt(2)),
          area_id: data?.area?.value,
          map_coords: data?.map_coords,
          micro_area_reason_id: data?.reason?.value,
        },
      ]
      axios
        .post('/micro-areas', formNewMicroArea)
        .then(() => {
          setTimeout(() => setOptionForm(undefined), 1000)
        })
        .catch((error) => setMsgError(error.response.data.msg))
    } else {
      var formPathMicroArea = [
        {
          micro_area_id: defaultValue?.id,
          initials: data?.initials,
          acreage: moveCharLeft(acreageFix, acreageFix.charAt(2)),
        },
      ]
      axios
        .patch('/micro-areas', formPathMicroArea)
        .then(() => {
          setOptionForm(undefined)
        })
        .catch((error) => setMsgError(error.response.data.msg))
    }
  }

  useEffect(() => {
    axios.get('/micro-areas/reasons').then(({ data }) => {
      setReasonOptions(
        data.map((option: any) => ({
          value: option.id,
          label: option.description,
        })),
      )
    })

    axios.get('/farms/user').then(({ data }) => {
      setListFarms(
        data.map((farm: any) => ({
          label: farm.name,
          value: farm.id,
          info: farm,
        })),
      )
    })

    axios.get('/areas/user').then(({ data }) => {
      setListAreas(
        data.map((area: any) => ({
          label: area.name,
          value: area.id,
          info: area,
        })),
      )
    })
    setAcreageMask('9,99')
  }, [])

  useEffect(() => {
    if (currentFarm) {
      setCurrentInitials(`${currentFarm?.initials}`)
      setCurrentArea(undefined)
      setValue('area', undefined)
    }
  }, [currentFarm])

  useEffect(() => {
    if (currentArea) {
      setCurrentInitials(`${currentFarm?.initials}-${currentArea?.initials}`)
      setMicroAreaCoords([...currentArea.map_coords, currentArea.map_coords[0]])
      microAreaService
        .getMicroAreasByAreaId(currentArea.id)
        .then((data) =>
          setStaticMicroAreas(data.map((micro) => micro.map_coords)),
        )
    }
  }, [currentArea])

  useEffect(() => {
    setAcreageArea(defaultValue?.remaining_area)
  }, [defaultValue])

  useEffect(() => {
    if (listFarms) {
      setValue(
        'farm',
        listFarms.filter((farm) => farm?.value == defaultValue?.farm_id)[0],
      )
    }
  }, [listFarms, defaultValue])

  useEffect(() => {
    if (listAreas)
      setValue(
        'area',
        listAreas.filter((area) => area?.value == defaultValue?.area_id)[0],
      )
  }, [listAreas, defaultValue])

  useEffect(() => {
    if (reasonOptions)
      setValue(
        'reason',
        reasonOptions.filter(
          (reason) => reason?.value == defaultValue?.reason_id,
        )[0],
      )
  }, [reasonOptions, defaultValue])

  function handleMaskAcreage(text: string) {
    // switch (text.replace('.', '').length) {
    //   case 3:
    //     setAcreageMask('99.99999')
    //     break
    //   case 4:
    //     setAcreageMask('999.9999')
    //     break
    //   case 5:
    //     setAcreageMask('9999.9999')
    //     break
    //   case 6:
    //     setAcreageMask('99999.9999')
    //     break
    //   case 7:
    //     setAcreageMask('999999.99999')
    //     break
    //   case 8:
    //     setAcreageMask('9999999.99')
    //     break
    //   default:
    //     setAcreageMask('9999999.99')
    //     break
    // }
  }
  const handleMaskInput = (acreage: string) => {
    setAcreageMask(acreage)
    setAcreageFormat(true)
    if (!acreageFormat) {
      const [integerL = '', decimalL = ''] = acreage
        .toString()
        .replace('.', ',')
        .split(',')
      let final = integerL.length
      if (decimalL.length == 3) final++
      else if (decimalL.length === 0) final--
      if (final <= 10) {
        const intMask = Array.from(Array(final || 1).keys()).reduce(
          (acc, _) => acc + '9',
          '',
        )
        const decimalMask = Array.from(
          Array(final <= 3 ? decimalL.length + 1 : 2).keys(),
        ).reduce((acc, _) => acc + '9', '')
        const finalMask = `${intMask},${decimalMask}`
        // setAcreageDynamicInputWidth(finalMask.length)
        setAcreageMask(finalMask)
        setAcreageFormat(true)
      }
    } else {
      handleMaskAcreage(acreage)
    }
  }

  useEffect(() => {
    if (currentArea || defaultValue?.area_id) {
      if (acreageInput) {
        handleMaskInput(acreageInput)
        let acreage = acreageInput?.replace(',', '.')
        let currentAcreage = moveCharLeft(acreage, acreage.charAt(2))
        setRemainingArea(
          (Number(acreageArea) - Number(currentAcreage)).toFixed(2),
        )
      } else if (acreageInput === '') {
        setRemainingArea(Number(acreageArea).toFixed(2))
      }
    }
  }, [acreageInput, acreageArea, currentArea])

  const deleteMicroArea = () => {
    axios
      .delete(`/micro-areas/${defaultValue.id}`)
      .then(() => {
        setOptionForm(undefined)
      })
      .catch((err) => setMsgError(err.response.data.msg))
  }

  useEffect(() => {
    if (defaultValue) {
      setMicroAreaCoords(() => defaultValue.map_coords)
    } else setMicroAreaCoords(() => [])
  }, [defaultValue])

  useEffect(() => {
    if (initialsOnlyRead) {
      if (isNew)
        setValue(
          'initials',
          `${(lenghtMicroArea + 1).toString().padStart(3, '0')}`,
        )
      else setValue('initials', defaultValue?.initials)
    }
  }, [initialsOnlyRead, lenghtMicroArea])

  useEffect(() => {
    if (currentArea) {
      setCurrentInitials(`${currentFarm?.initials}-${currentArea?.initials}`)
      setMicroAreaCoords(currentArea?.map_coords)
      axios
        .get('/micro-areas', {
          params: { farm_id: currentFarm?.id, area_id: currentArea?.id },
        })
        .then(({ data }) => {
          const acregeUsed = data.reduce(
            (sum: number, current: any) => sum + current.acreage,
            0,
          )
          setLenghtMicroArea(data.length)
          setAcreageArea((currentArea?.acreage - acregeUsed).toFixed(2))
          setRemainingArea((currentArea?.acreage - acregeUsed).toFixed(2))
        })
    }
  }, [currentArea])

  useEffect(() => {
    if (!!initialsInput) setValue('initials', initialsInput.toLocaleUpperCase())
  }, [initialsInput])

  if (acreageMask === null) return <></>
  return (
    <>
      <AlertModal visible={!!msgError} contentStyle={contentStyleAlert}>
        <section className="EditAreas_modalalert-container">
          <SimpleButtonClose onClick={() => setMsgError('')} />
          <main className="EditAreas_modalalert-content">
            <TitleModal>Algo deu errado.</TitleModal>
            <TextModal>{msgError}</TextModal>
          </main>
          <ButtonRedirect onClick={() => setMsgError('')}>
            Voltar
          </ButtonRedirect>
        </section>
      </AlertModal>
      <main className={'body__formEditOnboarding'}>
        <div className="firstColumn">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Dropdown
              control={control}
              label={'Fazenda'}
              placeholderText={'Selecione...'}
              options={listFarms}
              isDisabled={!isNew}
              getSelectedData={(option: any) => {
                setCurrentFarm(option.info)
              }}
              closeMenuOnSelect={true}
              errorText={(errors?.farm as any)?.value?.message}
              name={'farm'}
            />
            <Dropdown
              control={control}
              label={'Retiro'}
              placeholderText={'Selecione...'}
              options={
                listAreas
                  ? listAreas.filter(
                      (area: any) => area?.info?.farm?.id == currentFarm?.id,
                    )
                  : []
              }
              getSelectedData={(option: any) => {
                setCurrentArea(option.info)
              }}
              isDisabled={!isNew}
              closeMenuOnSelect={true}
              errorText={(errors?.area as any)?.value?.message}
              name={'area'}
              noOptionsMessage={() => <span>Nenhum retiro disponível</span>}
            />

            <Input
              control={control}
              label={'Sigla'}
              theme={initialsOnlyRead ? 'read-only' : 'normal'}
              disabled={initialsOnlyRead}
              labelPrefix={`${
                isNew
                  ? currentInitials
                    ? `${currentInitials}-`
                    : ''
                  : `${defaultValue?.farm_initials}-${defaultValue?.area_initials}-`
              }`}
              overrideStyles={{
                input: {
                  width: 200,
                },
              }}
              maxLength={4}
              errorText={errors?.initials?.message as any}
              name={'initials'}
            />

            <label
              onClick={() => setInitialsOnlyRead(!initialsOnlyRead)}
              className="initial--readOnly"
            >
              {initialsOnlyRead ? 'Alterar sigla' : 'Usar sigla automática'}
            </label>

            <div>
              <label className="acreageLabel">
                Quantos hectares de área útil?
              </label>

              <div className="hectares__container">
                <Input
                  name={'acreage'}
                  theme={'normal'}
                  label={''}
                  type={'number'}
                  control={control}
                  disabled={false}
                  prefixPosition="right"
                  labelPrefix="ha"
                  overrideStyles={{
                    prefixLabel: {
                      color: '#999999',
                    },
                    input: {
                      width: 120,
                      maxWidth: 200,
                    },
                    contentInput: {
                      width: 200,
                      marginRight: 20,
                    },
                  }}
                  errorText={(errors?.acreage as any)?.message ? ' ' : ''}
                  onBlurCapture={() => {
                    // setValue('acreage', acreageInput.slice(0, 4))
                    // if (acreageInput || acreageInput == '') {
                    //   var acreage = acreageInput?.replace(',', '.')
                    //   acreage = moveCharLeft(acreage, acreage.length)
                    //   setValue(
                    //     'acreage',
                    //     acreage.length == 2
                    //       ? Number(acreage).toFixed(1)
                    //       : acreage,
                    //   )
                    // }
                  }}
                />

                <InputIcon
                  theme={'normal'}
                  control={control}
                  disabled={true}
                  className={'microarea__container__icon'}
                  labelPrefix={`${remainingArea} disponíveis`}
                  Icon={Number(remainingArea) >= 0 ? Available : Unvailable}
                  prefixPosition={'right'}
                  name={'remainingArea'}
                  overrideStyles={{
                    prefixLabel: {
                      maxWidth: 200,
                      minWidth: 200,
                    },
                    input: {
                      width: 10,
                    },
                    container: {
                      width: 280,
                    },
                  }}
                />
              </div>
            </div>

            {errors?.acreage ? (
              <small className="textInput--small-error animation__fadeIn">
                {(errors?.acreage as any)?.message}
              </small>
            ) : (
              ''
            )}

            {isNew || defaultValue?.reason_id ? (
              <Dropdown
                label={'Motivo para não utilizar o pasto'}
                closeMenuOnSelect={true}
                control={control}
                options={reasonOptions}
                isDisabled={!isNew}
                isSearchable={isNew}
                errorText={(errors?.reason as any)?.value?.message}
                name={'reason'}
                placeholderText={'Selecione...'}
              />
            ) : (
              ''
            )}
          </form>
        </div>
        <div className="secondColumn">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawOnMap
              staticsElements={staticMicroAreas}
              style={{ width: '400px', height: '300px' }}
              mainPolygon={[]}
              initialsLabel={`${currentInitials}${initialsInput || ''}`}
              control={control}
              fitCoords={microAreaCoords}
              name={'map_coords'}
              editable={isNew && currentArea}
            />
          </form>
          {defaultValue?.batch_initials ? (
            <div className="label_batch">
              <Chips.Pressable
                variationStyles={{ container: { width: '100%' } }}
                theme={'link-light'}
                label={`${defaultValue?.farm_initials}-${defaultValue?.area_initials}-${defaultValue?.batch_initials}`}
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </main>

      <form onSubmit={handleSubmit(onSubmit)}>
        <FooterEditOnboarding
          undoFunction={() => setOptionForm(undefined)}
          saveFunction={() => console.log('Save')}
          deleteFunction={() => deleteMicroArea()}
          isNew={isNew}
        />
      </form>
    </>
  )
}
