import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as BackIcon } from "../../../../assets/svg/icons/back.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as GrassIcon } from "../../../../assets/svg/icons/grass.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";

interface GrazingRouteProps {
  task: ITaskGrazingRoute | undefined;
}

export default function InfoGrazingRoute(task: GrazingRouteProps) {
  const [taskGrazingRoute, setTaskGrazingRoute] = useState<ITaskGrazingRoute>();
  useEffect(() => {
    setTaskGrazingRoute(task.task)
  }, []);

  const mapText = new Map();
  mapText.set("Adicionar", "Incluir")
  mapText.set("Remover", "Excluir")

  return (
    <>
      <div >
        <span>Do retiro</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <AreaIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskGrazingRoute?.area_initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <div >
        <span>Do lote</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <AnimalsIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskGrazingRoute?.batch_initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <MessageBalloon text={`Nesta rota estão os pastos ${taskGrazingRoute?.micro_area_route.map(el => el.initials).join(", ")}.`} pointed />

      <div className="containerQuestion">
        <MessageBalloon text={`Deseja incluir ou excluir um pasto na rota?`} pointed />
        <ButtonPressed onlyResult optionSelected={1} value={1} text={`${mapText.get(taskGrazingRoute?.task_update_route_type)}`} />
      </div>


      <div>
        <span>Pasto:</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <span>{taskGrazingRoute?.micro_area_full_initials}</span>
          <div className="labelInitialTaskInfo">
            <PointIcon width={15} />
          </div>
        </div>
      </div>
    </>
  )
}