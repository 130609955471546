import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";
import { ButtonYesOrNo } from "../../../Buttons/ButtonYesOrNo";

interface InfoEvolutionProps {
  task: ITaskEvolution | undefined;
}


export default function InfoEvolution(task: InfoEvolutionProps) {
  const [taskEvolution, setTaskEvolution] = useState<ITaskEvolution>();
  useEffect(() => {
    setTaskEvolution(task.task)
  }, []);


  return (
    <>
      <div >
        <span>No lote</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <AnimalsIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskEvolution?.batch_initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <div className="containerQuestion">
        <span>Evoluído de</span>
        <div style={{ display: "flex", gap: 10 }}>
          <MessageBalloon text={`${taskEvolution?.previous_category.quantity}`} />
          <MessageBalloon text={`${taskEvolution?.previous_category.category_description.name} ${taskEvolution?.previous_category.category_description.age_description}`} />
        </div>
      </div>

      <div className="containerQuestion">
        <span>Evoluído para</span>
        <div style={{ display: "flex", gap: 10 }}>
          <MessageBalloon text={`${taskEvolution?.current_category.quantity}`} />
          <MessageBalloon text={`${taskEvolution?.current_category.category_description.name} ${taskEvolution?.current_category.category_description.age_description}`} />
        </div>
      </div>

    </>
  )
}