import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "../styles.css"

interface Props {
    label: string;
    data: any;
    fullWidth?: boolean;
}

const ChartBar: React.FC<Props> = ({ label, data, fullWidth }) => {
    Chart.register(ChartDataLabels);

    const chartData = {
        labels: data?Array.from(data, (el: any) => el?.label):[],
        datasets: [
            {
                label,
                data: data?data.map((el: any) => Number(el?.value.toFixed(2))):[],
                backgroundColor: [
                    "#57CE71",
                    "#5AC8FA",
                    "#FF9501",
                    "#4A90E2",
                    "#FF3B30",
                    "#5AC8FA",
                    "#FFCC00",
                    "#5856D6",
                    "#34C759",
                    "#FF2D55",
                    "#007AFF",
                    "#FF2D55",
                    "#007AFF",
                    "#FFCC00",
                    "#5856D6",
                    "#34C759",
                    "#2737FA",
                    "#8A2BE2",
                ],
                borderWidth: 1,
            },
        ],

    }

    Chart.defaults.font.size = 10;
    if(!data) return <></>

    return (
        <div>
            <span className='titleGraph'>{label}</span>
            <div className={`contentGraphBorder ${fullWidth ? "containerGraphFull" : ""}`}>
                {data.every((el: any) => el?.value === 0) ?
                    <div className='noData'>
                        <span>Sem dados</span>
                    </div>
                    :
                    <>
                        <Bar
                            className={`${fullWidth ? "fullWidth" : ""}`}
                            data={chartData}
                            options={{
                                responsive: true,
                                maintainAspectRatio: true,
                                aspectRatio: fullWidth ? 2 : 1,
                                devicePixelRatio: 4,
                                animation: {
                                    duration: 0
                                },
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    datalabels: {
                                        display: true,
                                        anchor: "end",
                                        align: "start",
                                        offset: -20,
                                        font: {
                                            size: 10,
                                            weight: "bold"
                                        },
                                    },
                                },
                                layout: {
                                    padding: {
                                        top: 30,
                                        right: 2
                                    }
                                },
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                        }
                                    }
                                }
                            }}
                            width={fullWidth ? 1300 : 285}
                            height={fullWidth ? 500 : 285}
                        />
                    </>
                }
            </div>
        </div>
    )
};

export default ChartBar;