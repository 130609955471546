import { ButtonHTMLAttributes } from "react";
import "./styles.css";

interface ButtonOnlyTitleProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  theme?: "dark" | "info" | "light" | "success" | "urgent";
}

export function ButtonOnlyTitle({ title, theme, id, ...rest }: ButtonOnlyTitleProps) {
  return (
    <button className={`buttononlytitle buttononlytitle--${theme}`} id={id} type="button" {...rest}>
      <h6>{title}</h6>
    </button>
  );
}
