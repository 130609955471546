import { GetHandleProps, SliderItem } from "react-compound-slider";

interface IHandleProps {
  domain: number[];
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  disable?: boolean;
}

export const Handle = ({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
  disable = false,
}: IHandleProps) => (
  <div
    role="slider"
    aria-valuemin={min}
    aria-valuemax={max}
    aria-valuenow={value}
    style={{
      left: `${percent}%`,
      cursor: disable ? "default" : "pointer",
    }}
    {...(!disable && getHandleProps(id))}
    className={`indicadorTempo ${
      id == "$$-0" ? "pontoInicioChuva" : "pontoInicioSeca"
    }`}
  >
    <div
      className={`tagPonto ${
        id == "$$-0" ? "avisoInicioChuva" : "avisoInicioSeca"
      }`}
    >
      <h2 className="textSlider">
        {id == "$$-0" ? "Início da chuva" : "Início da seca"}
      </h2>
    </div>
  </div>
);
