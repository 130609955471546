import { ElementType } from "react";
import "./styles.css";
type LabelIconProps = {
  Icon: ElementType;
  label?: string;
};

export function LabelIcon({ label, Icon }: LabelIconProps) {
  return (
    <section className="drawfarm__label--container">
      <Icon />
      <h3>{label}</h3>
    </section>
  );
}
