import { useEffect, useState } from "react";
import { PDFEvaluationGlobal } from "../../components/Pdf/global";
import { PDFEvaluation } from "../../components/Pdf/microAreas";
import { RoundedCloseButton } from "../../components/Buttons/RoundedCloseButton/Index";
import { IOptionProps, IOptionsProps } from "../../@types/reactSelect/IOption";
import { useForm } from "react-hook-form";
import axios from "../../services/axios"
import { ButtonOnlyTitle } from "../../components/Buttons/ButtonOnlyTitle/Index";
import { useNavigate } from "react-router-dom";
import Constants from "../../constants/Index";
import { Dropdown } from "../../components/EditOnboarding/components/Select";
import "./styles.css"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Loading } from "../../components/Loading/Index";

const schemaReport = yup.object().shape({
    farm: yup
        .object({
            label: yup.string(),
            value: yup.string().required("Fazenda é necessária."),
        })
        .nullable()
        .required("Fazenda é necessária."),

    areas: yup
        .array()
        .nullable()
        .min(1, "Retiros são necessários.")
        .required("Retiros são necessários."),
    microareas: yup
        .array()
        .nullable()
        .min(1, "Pastos são necessários.")
        .required("Pastos são necessários."),
    model: yup
        .object({
            label: yup.string(),
            value: yup.string().required("Modelo é necessário."),
        })
        .nullable()
        .required("Modelo é necessário."),
});


export function PdfPage() {
    const [optionsFarm, setOptionsFarm] = useState<IOptionsProps>([]);
    const [optionsFarmAvailable, setOptionsFarmAvailable] = useState<Number[]>([]);
    const [optionsArea, setOptionsArea] = useState<IOptionsProps>([]);
    const [optionsAreaAvailable, setOptionsAreaAvailable] = useState<Number[]>([]);
    const [optionsMicroArea, setOptionsMicroArea] = useState<IOptionsProps>([]);
    const [optionsMicroAreasAvailable, setOptionsMicroAreasAvailable] = useState<Number[]>([]);
    const [dataReport, setDataReport] = useState<any>();
    const [loadingState, setLoadingState] = useState<boolean>(false);

    const {
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schemaReport),
    });

    const farm = watch("farm")
    const areas = watch("areas")
    const microAreas = watch("microareas")
    const model = watch("model")
    const navigate = useNavigate();

    useEffect(() => {
        axios.get("/farms/user").then((response) => {
            setOptionsFarm(response.data.map((farm: any) => ({ value: farm.id, label: farm.name, info: farm })))
        })
        axios.get("/areas/user").then((response) => {
            setOptionsArea(response.data.map((area: any) => ({ value: area.id, label: area.name, info: area })));
        })
        axios.get("/tasks/pasture-evaluation/micro-areas").then(({ data }) => {
            setOptionsAreaAvailable(data.map((el: any) => el?.area_id))
            setOptionsFarmAvailable(data.map((el: any) => el?.farm_id))
            setOptionsMicroAreasAvailable(data.map((el: any) => el?.id))
            setOptionsMicroArea(data.map((el: any) => ({
                label: `${el?.farm_initials}-${el?.area_initials}-${el?.initials}`,
                value: el?.id,
                info: {
                    farm_id: el?.farm_id,
                    area_id: el?.area_id,
                }
            })))
        })
        document.title = Constants.TITLE_PASTURE_EVALUATION
    }, []);

    useEffect(() => {
        if (areas) {
            setValue("microareas",
                optionsMicroArea?.filter((microArea: any) => areas?.map((el: any) => el?.value).includes(`${microArea?.info.area_id}`))
                    .sort((a, b) => (a.value).toString().localeCompare((b.value).toString()))
            )
        }
    }, [areas]);

    useEffect(() => {
        setDataReport(undefined)
    }, [farm, areas, microAreas, model]);

    useEffect(() => {
        if (model)
            document.title = model.label
    }, [model]);

    function requestReports() {
        setDataReport(undefined)
        setLoadingState(true)

        const queryString = `${model.value == 1 ? `farm_id=${farm?.value}&` : ""}${microAreas.map((el: any) => `micro_area_ids=${el.value}`).join('&')}`;

        axios.get(`tasks/pasture-evaluation/report?${queryString}`)
            .then((response) => {
                setDataReport(response.data)
            })
            .finally(() => {
                setLoadingState(false)
            })
    }

    return (
        <div style={{ display: "flex", width: "100%" }}>
            <Loading visible={loadingState} />

            <form onSubmit={handleSubmit(requestReports)} className="formPDFContainer no-print">
                <section>
                    <header className="headerCreateEvaluator">
                        <h1 className="titleCreateEvaluator">Baixar avaliação</h1>
                        <div>
                            <RoundedCloseButton
                                onClick={() => {
                                    navigate(Constants.PADDOCKS_MAP)
                                }}
                            />
                        </div>
                    </header>
                    <main className="formCreateEvaluator">
                        <div>
                            <span className="labelCreateEvaluator">Fazenda</span>
                            <div className="filters-dropdown filters-dropdown__full-width">
                                <Dropdown
                                    name={"farm"}
                                    options={optionsFarm
                                        .filter(farm => optionsFarmAvailable.includes(farm.value))
                                    }
                                    placeholderText="Selecionar fazenda..."
                                    control={control}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => <span>Nenhum fazenda disponível</span>}
                                    getSelectedData={() => {
                                        if (optionsFarm) {
                                            setValue("areas", null)
                                            setValue("microareas", null)
                                        }
                                    }}
                                    errorText={(errors?.farm as any)?.value?.message}
                                />
                            </div>
                        </div>

                        <div>
                            <span className="labelCreateEvaluator">Retiros</span>
                            <div className="filters-dropdown filters-dropdown__full-width">
                                <Dropdown
                                    name={"areas"}
                                    options={optionsArea
                                        .filter(area => (area.info as any).farm.id == farm?.value)
                                        .filter(area => optionsAreaAvailable.includes(area.value))
                                    }
                                    placeholderText="Selecionar retiros..."
                                    control={control}
                                    noOptionsMessage={() => <span>Nenhum retiro disponível</span>}
                                    isMulti={true}
                                    errorText={(errors?.areas as any)?.value?.message}
                                />
                            </div>
                        </div>

                        <div>
                            <span className="labelCreateEvaluator">Pastos</span>
                            <div className="filters-dropdown filters-dropdown__full-width">
                                <Dropdown
                                    name={"microareas"}
                                    options={
                                        optionsMicroArea
                                            .filter(microarea => areas?.map((el: any) => el?.value).includes(`${microarea?.info.area_id}`))
                                            .filter(microarea => optionsMicroAreasAvailable.includes(microarea.value))
                                            .filter(el => !microAreas?.map((microArea: IOptionProps) => microArea?.value).includes(el?.value))
                                            .sort((a, b) => (a.value).toString().localeCompare((b.value).toString()))
                                    }
                                    placeholderText="Selecionar pastos..."
                                    control={control}
                                    noOptionsMessage={() => <span>Nenhum pasto disponível</span>}
                                    isMulti={true}
                                    errorText={(errors?.microareas as any)?.value?.message}
                                />
                            </div>
                        </div>

                        <div>
                            <span className="labelCreateEvaluator">Modelo</span>
                            <div className="filters-dropdown filters-dropdown__full-width">
                                <Dropdown
                                    name={"model"}
                                    options={[
                                        {
                                            value: 1,
                                            label: "Relatório global"
                                        },
                                        {
                                            value: 2,
                                            label: "Relatório por pasto"
                                        }
                                    ]}
                                    placeholderText="Selecionar modelo..."
                                    control={control}
                                    closeMenuOnSelect={true}
                                    noOptionsMessage={() => <span>Nenhum modelo disponível</span>}
                                    errorText={(errors?.model as any)?.value?.message}
                                />
                            </div>
                        </div>

                    </main>
                </section>

                <footer className="footerCreateEvaluator">
                    <ButtonOnlyTitle
                        title="Cancelar"
                        theme="light"
                        type="button"
                        onClick={() =>
                            navigate(Constants.PADDOCKS_MAP)
                        }
                    />
                    <ButtonOnlyTitle
                        title="Baixar"
                        theme="info"
                        type="submit"
                    />
                </footer>
            </form>

            {dataReport &&
                <div style={{ height: 1, width: "50vw" }}>
                    {model.value == 1 ? <PDFEvaluationGlobal
                        farm={farm} areas={areas}
                        dataReport={dataReport as IReportGlobalPastureEvaluator}
                    /> : ""}
                    {model.value == 2 ? <PDFEvaluation
                        farm={farm} areas={areas}
                        dataReport={dataReport as IReportMicroAreaPastureEvaluator}
                    /> : ""}
                </div>
            }
        </div>
    );
}
