import React, { useEffect } from 'react';
import { Chart as ElementChart } from 'react-chartjs-2';
import Chart, { ChartData } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "../styles.css"

interface Props {
    label: string;
    labelOptions: string[];
    optionsColumn: LabelElement[];
    optionsLine: LabelElement[];
    fullWidth?: boolean;
}

interface LabelElement {
    label: string[] | string;
    value: number;
}

const ChartBarLine: React.FC<Props> = ({ label, labelOptions, optionsColumn, optionsLine, fullWidth }) => {
    Chart.register(ChartDataLabels);

    const charData: ChartData = {
        labels: optionsColumn ? optionsColumn.map((el) => el.label) : [],
        datasets: [
            {
                type: 'bar',
                label: labelOptions[0],
                backgroundColor: "#57CE71",
                data: optionsColumn ? optionsColumn.map((el) => Number((typeof (el.value) == "number" ? el.value : 0).toFixed(2))) : [],
                borderWidth: 1,
                yAxisID: 'y1',
                order: 1
            },
            {
                type: 'line',
                label: labelOptions[1],
                backgroundColor: "#FF9501",
                borderColor: "#FF9501",
                data: optionsLine ? optionsLine.map((el) => Number((typeof (el.value) == "number" ? el.value : 0).toFixed(2))) : [],
                borderWidth: 2,
                yAxisID: 'y2',
                order: 0
            }
        ],
    };


    return (
        <div>
            <span className='titleGraph'>{label}</span>
            <div className={`contentGraphBorder ${fullWidth ? "containerGraphFull" : ""}`}>
                {optionsColumn && optionsColumn.every((el: any) => el?.value === 0) && optionsLine.every((el: any) => el?.value === 0) ?
                    <div className='noData'>
                        <span>Sem dados</span>
                    </div>
                    :
                    <>
                        <ElementChart
                            className={` ${fullWidth ? "fullWidth" : ""}`}
                            type='bar'
                            data={charData}
                            options={{
                                devicePixelRatio: 4,
                                animation: {
                                    duration: 0
                                },
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                        labels: {
                                            boxWidth: 10,
                                        },
                                    },
                                    datalabels: {
                                        display: true,
                                        anchor: "end",
                                        align: "start",
                                        offset: -20,
                                        font: {
                                            size: 10,
                                            weight: "bold"
                                        },
                                    },
                                },
                                layout: {
                                    padding: {
                                        top: 10,
                                    }
                                },
                                scales: {
                                    x: {
                                        grid: {
                                            display: false,
                                        }
                                    },
                                    y1: {
                                        type: 'linear',
                                        display: true,
                                        position: 'left',
                                    },
                                    y2: {
                                        type: 'linear',
                                        display: true,
                                        position: 'right',
                                        grid: {
                                            drawOnChartArea: false,
                                        },
                                    }
                                },
                            }}
                            width={1360}
                            height={500}
                        />
                    </>
                }
            </div>
        </div>
    )
};

export default ChartBarLine;