import "./styles.css";
import { ReactComponent as CloseIcon } from "../../../../assets/svg/icons/close.svg";

type HeaderCloseProps = {
  onClick: () => void;
};

export function SimpleButtonClose({ onClick: eventClick }: HeaderCloseProps) {
  return (
    <div className="headerClose__button--error">
      <button onClick={eventClick} type="button">
        <CloseIcon />
      </button>
    </div>
  );
}
