import { useCallback, useState } from "react";
import AnimateHeight from "react-animate-height";
import "./styles.css";
import { ReactComponent as EyeOpenIcon } from "../../../../assets/svg/icons/eye.svg";
import { ReactComponent as EyeClosedIcon } from "../../../../assets/svg/icons/eyeClosed.svg";
import { ButtonOnlyTitle } from "../../../Buttons/ButtonOnlyTitle/Index";
import { Select } from "../../../Inputs/Select/Index";
import { TextInput } from "../../../Inputs/TextInputs/TextInput/Index";
import { IOptionsProps } from "../../../../@types/reactSelect/IOption";
import { HandleUUID } from "../../../Inputs/HandleUUID/Index";

type CreateProfileProps = {
  uuid: number;
  profilesList: IOptionsProps;
  isVisibleBtnRemoveProfile: boolean;
  onAnywhereChanges?: (input?: HTMLInputElement) => void;
  onRemoveProfile?: (uuid: number) => void;
  onlyRead?: boolean;
};

export function CreateProfile({
  onAnywhereChanges,
  onRemoveProfile,
  profilesList,
  uuid,
  isVisibleBtnRemoveProfile,
  onlyRead = false
}: CreateProfileProps) {
  const [statusProfile, setStatusProfile] = useState<0 | "auto">("auto");

  // Isso captura qualquer tipo de alteração nos Inputs repassados
  const onChangeAnywhereInput = useCallback(
    (input?: HTMLInputElement) => {
      if (onAnywhereChanges) onAnywhereChanges(input);
    },
    [onAnywhereChanges]
  );

  const toggleProfile = () => {
    setStatusProfile((prev) => (prev === 0 ? "auto" : 0));
  };

  return (
    <section className="createuser__content" id={`profile${uuid}`}>
      <header className="createuser__header">
        <h3>Perfil {uuid + 1}</h3>
        <button onClick={toggleProfile} type="button">
          {statusProfile === "auto" ? <EyeClosedIcon /> : <EyeOpenIcon />}
        </button>
      </header>
      <AnimateHeight height={statusProfile}>
        <div className="container__input">
          <HandleUUID name="uuid" />
          <label>Cargo/Função *</label>
          <Select
            components={{
              NoOptionsMessage: () => <span>Não foram encontrados cargos/funções</span>,
            }}
            id={`createuser-profile_id-${uuid}`}
            closeMenuOnSelect={true}
            placeholder="Selecione"
            options={profilesList}
            isSearchable={false}
            name="profile"
            handleAnywhereChanges={onChangeAnywhereInput}
            isDisabled={onlyRead}
          />
        </div>
        <TextInput
          hidden={true}
          value={!onlyRead + ""}
          name="isNew"
        />
        <div className="container__input">
          <label>Nome de Usuário *</label>
          <TextInput
            id={`createuser-name${uuid}`}
            autoComplete="off"
            name="name"
            handleAnywhereChanges={onChangeAnywhereInput}
            disabled={onlyRead}
          />
        </div>
        <div className="container__input">
          <label>Telefone Celular *</label>
          <TextInput
            id={`createuser-phone${uuid}`}
            mask={'(99) 99999-9999'}
            autoComplete="off"
            name="phone"
            returnUnmasked
            handleAnywhereChanges={onChangeAnywhereInput}
            disabled={onlyRead}
          />
        </div>

        <span className="createuser__delimiter" />
        {isVisibleBtnRemoveProfile && (
          <div className="createuser__container--removeprofile">
            <ButtonOnlyTitle
              onClick={() => {
                onRemoveProfile && onRemoveProfile(uuid);
              }}
              title="- Remover esse perfil"
              theme="light"
            />
          </div>
        )}
      </AnimateHeight>
      {statusProfile === 0 && <span className="createuser__delimiter" />}
    </section>
  );
}
