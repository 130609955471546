import { CSSProperties, useState } from "react";
import { Rail, Handles, Ticks, Tracks, Slider } from "react-compound-slider";
import { Handle } from "./Handle";
import { Tick } from "./Tick";
import { Track } from "./Track";

interface SliderProps {
  listOptions: object;
  rain_initial: number;
  setRainInitial: (value: number) => void;
  dry_initial: number;
  setDryInitial: (value: number) => void;
  isDisabled?: boolean;
}

const sliderStyle = {
  position: "relative",
  width: "100%",
} as CSSProperties;

export const SliderComponent = ({
  listOptions,
  rain_initial,
  dry_initial,
  setRainInitial,
  setDryInitial,
  isDisabled = false,
}: SliderProps) => {
  const [boolSecaAntesDaChuva, setboolSecaAntesDaChuva] = useState<boolean>(false);
  const [values] = useState<any>([rain_initial, dry_initial]);
  const domain: number[] = [0, 11];
  const valMap: any = listOptions;

  return (
    <Slider
      mode={1}
      step={1}
      domain={domain}
      rootStyle={sliderStyle}
      values={values}
    >
      {/* Tracejado na barra indicando os meses */}
      <Rail>
        {({ getRailProps }) => (
          <div
            className={boolSecaAntesDaChuva ? "lineBlue" : "lineGray"}
            style={{
              cursor: isDisabled ? "default" : "pointer",
            }}
            {...(!isDisabled && getRailProps())}
          />
        )}
      </Rail>
      {/* Marcação do inicio da chuva e inico da seca */}
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map((handle) => (
              <Handle
                key={handle.id}
                handle={handle}
                domain={domain}
                getHandleProps={getHandleProps}
                disable={isDisabled}
              />
            ))}
          </div>
        )}
      </Handles>
      {/* Define a cor da barra indicando onde começa e termina a chuva e a seca respectivamente*/}
      <Tracks right={false} left={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(
              ({ id, source, target }) => (
                boolSecaAntesDaChuva
                  ? (setRainInitial(target.value), setDryInitial(source.value))
                  : (setRainInitial(source.value), setDryInitial(target.value)),
                target.id == "$$-0"
                  ? setboolSecaAntesDaChuva(true)
                  : setboolSecaAntesDaChuva(false),
                (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                    boolColor={boolSecaAntesDaChuva}
                    disable={isDisabled}
                  />
                )
              )
            )}
          </div>
        )}
      </Tracks>
      {/* Demarcação dos meses na barra */}
      <Ticks count={11}>
        {({ ticks }) => (
          <div className="slider-ticks">
            {ticks.map((tick) => (
              <Tick
                key={tick.id}
                format={(id: number) => valMap[id]}
                tick={tick}
                count={ticks.length}
                disable={isDisabled}
              />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  );
};
