import { CSSProperties, ReactNode, useEffect, useState } from "react";
//@ts-ignore
import Rodal from "rodal";
import "rodal/lib/rodal.css";

type AnimationModal =
  | "zoom"
  | "fade"
  | "flip"
  | "door"
  | "rotate"
  | "slideUp"
  | "slideDown"
  | "slideLeft"
  | "slideRight";

type ModalComponentProps = {
  children: ReactNode | ReactNode[];
  visible: boolean;
  contentStyle?: CSSProperties;
  animation?: AnimationModal;
  onCloseFunction?: any;
};

export function AlertModal({
  animation = "zoom",
  contentStyle,
  children,
  visible,
  onCloseFunction = () => {},
}: ModalComponentProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  useEffect(() => setShowModal(visible), [visible]);

  return (
    <Rodal
      customStyles={contentStyle}
      animation={animation}
      visible={showModal}
      onClose={onCloseFunction}
      showCloseButton={false}
    >
      {children}
    </Rodal>
  );
}
