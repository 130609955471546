import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ReactComponent as GrassIcon } from "../../../../assets/svg/icons/grass.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";
import { ButtonYesOrNo } from "../../../Buttons/ButtonYesOrNo";
import { format } from "date-fns";

interface InfoProductDispatchProps {
  task: ITaskProductDispatch | undefined;
}


export default function InfoProductDispatch(task: InfoProductDispatchProps) {
  const [taskProductDispatch, setTaskProductDispatch] = useState<ITaskProductDispatch>();
  useEffect(() => {
    setTaskProductDispatch(task.task)
  }, []);


  const mapText = new Map();
  mapText.set("FULL", "Cheio")
  mapText.set("EMPTY", "Vazio")
  mapText.set("MEDIUM", "Médio")

  return (
    <>
      <div >
        <span>No pasto</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <GrassIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskProductDispatch?.initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <div className="containerQuestion">
        <MessageBalloon text={`Esse produto está saindo de um depósito central ou do depósito desse pasto?`} pointed />
        <ButtonPressed onlyResult optionSelected={1} value={1} text={`Depósito Central`} />
      </div>

      <div className="containerQuestion">
        <MessageBalloon text={`O destino será para um cocho ou para o depósito desse pasto?`} pointed />
        <ButtonPressed onlyResult optionSelected={1} value={1} text={`${taskProductDispatch?.dispatch_type}`} />
      </div>

      <div className="containerQuestion">
        <MessageBalloon text={`Proteico`} pointed />
        <CounterInputEdit isDisabled defaultValue={taskProductDispatch?.quantity_estimated_loss || 0} control={undefined} isInline label="kg" />
      </div>

      {taskProductDispatch?.dispatch_type == "COCHO" ?
        <>
          <div className="containerQuestion">
            <MessageBalloon text={`Como está o cocho?`} pointed />
            <ButtonPressed onlyResult optionSelected={1} value={1} text={`${mapText.get(taskProductDispatch?.trough_status)}`} />
          </div>

          <div className="containerQuestion">
            <MessageBalloon text={`Houve perda de produto?`} pointed />
            <ButtonPressed onlyResult optionSelected={1} value={1} text={`${taskProductDispatch?.quantity_estimated_loss != 0 ? "Sim" : "Não"}`} />
          </div>

          {taskProductDispatch?.quantity_estimated_loss != 0 ?
            <>
              <CounterInputEdit isDisabled defaultValue={taskProductDispatch?.quantity_estimated_loss} control={undefined} isInline label="kg" />
            </>
            :
            <>
            </>
          }
        </>
        :
        <>
        </>
      }
    </>
  )
}