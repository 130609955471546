import { CgClose } from "react-icons/cg";
import "./styles.css";

type HeaderContentModalProps = {
  onEventClose: () => void;
};

export function HeaderContentModal({ onEventClose }: HeaderContentModalProps) {
  return (
    <div className="headerContentModal__container">
      <button
        onClick={() => {
          onEventClose && onEventClose();
        }}
      >
        <CgClose />
      </button>
    </div>
  );
}
