import { ICoords } from "../../@types/GoogleMaps/ICoord";

export function getCenterCoords(coords: ICoords) {
  var bounds = new google.maps.LatLngBounds();
  coords.forEach((cood) => {
    bounds.extend(cood);
  });

  const { lat, lng } = bounds.getCenter();

  return { lat: lat(), lng: lng() };
}
