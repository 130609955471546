import { Children, ReactElement, ReactNode, useEffect, useRef, useState } from "react";

import { FlyToBounds, FlyToBoundsRefProps, IAnimationState, IZoomState } from "../FlyToBounds/Index";
import { ICoords } from "../../../@types/GoogleMaps/ICoord";
import Constants from "../../../constants/Index";

export type IAnimatedZoomRefProps = {
  setStateStateAnimation: (state: IAnimationState) => void;
};

type AnimatedZoomProps = {
  children: ReactNode;
  coordsToBoundZoom: ICoords;
  updateZoomVisualization?: IAnimationState;
};
export function AnimatedZoom(props: AnimatedZoomProps) {
  const { children, coordsToBoundZoom, updateZoomVisualization } = props;
  const flytoBoundsRef = useRef<FlyToBoundsRefProps>();

  const [currentZoomMap, setCurrentZoomMap] = useState<IZoomState>("1X");

  const [elements1x, setElements1x] = useState<ReactElement | null>();

  const [maxZoom, setMaxZoom] = useState<number>(0);

  const [elements2x, setElements2x] = useState<ReactElement | null>();

  const [elements3x, setElements3x] = useState<ReactElement | null>();

  const [elements4x, setElements4x] = useState<ReactElement | null>();

  const [stateOfAnimation, setStateOfAnimation] = useState<IAnimationState>("start");

  function handleTypeVisualization(currentZoom: number) {
    switch (true) {
      case currentZoom <= maxZoom - Constants.LEAFLET_ZOOM_BOUNDS_DIFFERENCE_1X:
        setCurrentZoomMap("1X");
        break;
      case currentZoom <= maxZoom - Constants.LEAFLET_ZOOM_BOUNDS_DIFFERENCE_2X:
        setCurrentZoomMap("2X");
        break;
      case currentZoom <= maxZoom - Constants.LEAFLET_ZOOM_BOUNDS_DIFFERENCE_3X:
        setCurrentZoomMap("3X");
        break;
      case currentZoom <= maxZoom - Constants.LEAFLET_ZOOM_BOUNDS_DIFFERENCE_4X:
        setCurrentZoomMap("4X");
        break;
    }
  }

  useEffect(() => {
    Children.forEach(children, (child: any) => {
      if (child.type.displayName == Constants.COMPONENT_1X_NAME) setElements1x(child);
      if (child.type.displayName == Constants.COMPONENT_2X_NAME) setElements2x(child);
      if (child.type.displayName == Constants.COMPONENT_3X_NAME) setElements3x(child);
      if (child.type.displayName == Constants.COMPONENT_4X_NAME) setElements4x(child);
    });
  }, [children]);
  useEffect(() => {
    if (updateZoomVisualization) setStateOfAnimation(updateZoomVisualization);
  }, [updateZoomVisualization]);

  useEffect(() => {
    if (flytoBoundsRef && flytoBoundsRef.current && flytoBoundsRef.current.getBoundsZoom) {
      const maxZoom: number = flytoBoundsRef.current.getBoundsZoom(coordsToBoundZoom);
      setMaxZoom(maxZoom ? maxZoom : 0);
    }
  }, [flytoBoundsRef, coordsToBoundZoom]);

  return (
    <FlyToBounds ref={flytoBoundsRef} onZoomChanged={handleTypeVisualization}>
      {stateOfAnimation !== "start" ? (
        <>
          {currentZoomMap === "1X" ? <>{elements1x}</> : null}
          {currentZoomMap === "2X" ? <>{elements2x}</> : null}
          {currentZoomMap === "3X" ? <>{elements3x}</> : null}
          {currentZoomMap === "4X" ? <>{elements4x}</> : null}
        </>
      ) : (
        <>{elements1x}</>
      )}
    </FlyToBounds>
  );
}
