import { useState } from "react";
import { ReactComponent as PenIcon } from "../../../../assets/svg/icons/penEdit.svg";
import "./styles.css";
import { useEditOnboarding } from "../../../../hooks/useEditOnboarding";

type TableVisualizationRowProps = {
  product: IProducts;
};

export default function TableVisualizationRow({
  product,
}: TableVisualizationRowProps) {
  const {
    setOptionForm,
    setDefaultValue
  } = useEditOnboarding();

  return (
    <div className="tableRow">
      <div>{product.classification}</div>
      <div>{product.trade_name}</div>
      <div>{product.manufacturer}</div>
      <div className="infoButton">
        <PenIcon
          onClick={() => {
            setOptionForm("create_products")
            setDefaultValue(product)
          }}
        />
      </div>
    </div>
  );
}
