import { GetTrackProps, SliderItem } from "react-compound-slider";

interface ITrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  boolColor: boolean;
  disable?: boolean;
}

export const Track = ({
  source,
  target,
  getTrackProps,
  boolColor,
  disable = false,
}: ITrackProps) => (
  <div
    style={{
      position: "absolute",
      height: 5,
      zIndex: 1,
      borderRadius: 7,
      cursor: disable ? "default" : "pointer",
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`,
    }}
    {...(!disable && getTrackProps())}
    className={`${boolColor ? "barAposInicioSeca" : "barAposInicioChuva"}`}
  />
);
