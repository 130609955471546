import "./styles.css"
import { useEffect, useState } from "react";
import { IOptionsEvaluation } from "../../ViewTables/Index";
import TableVisualizationRowTask from "./TableVisualizationRow/Index";
import ViewTasks from "../../Tasks/ViewTasks";
import { Loading } from "../../Loading/Index";

interface TableProductsProps {
  listTable: ITaskEvaluation[];
  filter: IOptionsEvaluation | undefined;
  isLoading: boolean;
}

export default function TableTaskEvaluation({ listTable, isLoading }: TableProductsProps) {
  const [selectedId, setSelectedId] = useState(0);

  return (
    <>
      <>
        {listTable && listTable.length > 0 ?
          <>
            <section className="tableHeaderColumnTaskEvaluation">
              <div>Usuário</div>
              <div>Tarefa</div>
              <div>Pasto/Lote</div>
              <div>Status</div>
              <div>Data Criação</div>
              <div>Data Execução</div>
              <div />
            </section>

            <section
              className="tableViewRows"
              style={{
                position: "relative",
              }}
            >
              <Loading visible={isLoading} />

              {listTable.map(evaluation =>
                <TableVisualizationRowTask evaluation={evaluation} setSelectedId={setSelectedId} />
              )}
            </section>
          </>
          :
          <div className="emptyTable_container">
            <span> Nenhum resultado encontrado.</span>
          </div>
        }
      </>

      <ViewTasks
        selected={selectedId}
        setSelectedId={setSelectedId}
      />
    </>
  )
}