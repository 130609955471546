import { ReactComponent as HighIcon } from "../../../assets/svg/icons/high.svg";
import { ReactComponent as MidIcon } from "../../../assets/svg/icons/mid.svg";
import { ReactComponent as LowIcon } from "../../../assets/svg/icons/low.svg";
import { ReactComponent as UltraLowIcon } from "../../../assets/svg/icons/ultralow.svg";
import "./styles.css"

interface ButtonHeightPastureProps {
  optionSelected: "ALTO" | "IDEAL" | "BAIXO" | "MUITO BAIXO" | undefined;
  setOptionSelected?: (option: "ALTO" | "IDEAL" | "BAIXO" | "MUITO BAIXO") => void;
  onlyResult?: boolean;
}

export function ButtonHeightPasture({
  optionSelected,
  setOptionSelected,
  onlyResult = false
}: ButtonHeightPastureProps) {

  function optionSelect(selected: "ALTO" | "IDEAL" | "BAIXO" | "MUITO BAIXO") {
    if (!optionSelected) return "unselected"
    if (selected == optionSelected) {
      return "heightPress"
    }
    return "heightNotPress"
  }

  return (
    <div className="containerHeightPasture">
      {optionSelected == "ALTO" ?
        <button className={`buttonHeight ${optionSelect("ALTO")}`} disabled={onlyResult}>
          <HighIcon />
          <span className="textHeight">Alto</span>
        </button>
        : <></>
      }

      {optionSelected == "IDEAL" ?
        <button className={`buttonHeight ${optionSelect("IDEAL")}`} disabled={onlyResult}>
          <MidIcon />
          <span className="textHeight">Ideal</span>
        </button>
        : <></>
      }

      {optionSelected == "BAIXO" ?
        <button className={`buttonHeight ${optionSelect("BAIXO")}`} disabled={onlyResult}>
          <LowIcon />
          <span className="textHeight">Baixo</span>
        </button>
        : <></>
      }

      {optionSelected == "MUITO BAIXO" ?
        <button className={`buttonHeight ${optionSelect("MUITO BAIXO")}`} disabled={onlyResult}>
          <UltraLowIcon />
          <span className="textHeight">Muito baixo</span>
        </button>
        : <></>
      }
    </div>
  );
};
