import { ElementType, useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";

import "./styles.css";

type InputText = JSX.IntrinsicElements["input"] & {
  name: string;
  getMessageError?: (error: string) => void;
  Icon: ElementType;
  disabled?: boolean;
};

export function TextInputWithIcon({
  getMessageError,
  className,
  Icon,
  name,
  disabled = false,
  ...rest
}: InputText) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [errorField, setErrorField] = useState<string>("");

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef as any,
      getValue: (ref) => {
        const value = ref.current.value;
        return value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (error != " ")
      if (getMessageError) {
        getMessageError(error ? error : "");
      } else setErrorField(() => (error ? error : ""));
  }, [error]);

  return (
    <>
      <div className={`textInputIcon-label__container${disabled?"-disabled":""}`}>
        <input
          defaultValue={defaultValue}
          className={`textInputIcon`}
          ref={inputRef as any}
          disabled={disabled}
          {...rest}
        />
        <Icon className="textInputIcon-icon" />
      </div>
      {errorField && (
        <small
          className="textInputwithlabel__error animation__fadeIn"
          id={`error-${name}`}
        >
          {errorField}
        </small>
      )}
    </>
  );
}
