
import ChipBaseComponent, { DEFAULT_CHIP_COLORS, DEFAULT_THEMES_CHIP_CONTAINER, DEFAULT_THEMES_CHIP_LABEL } from '../Base/ComponentChipBase/Index'

type IChipOnlyViewThemes = IComponentChipThemes | 'dark-highlight'

export const ONLYVIEW_CONTAINER_THEMES = {
    light: DEFAULT_THEMES_CHIP_CONTAINER.light,
    dark: DEFAULT_THEMES_CHIP_CONTAINER.dark,
    'dark-highlight': {
        ...DEFAULT_THEMES_CHIP_CONTAINER.dark,
        border: `2px solid ${DEFAULT_CHIP_COLORS.GRAY_600}`
    }
}

export const ONLYVIEW_LABEL_THEMES = {
    light: DEFAULT_THEMES_CHIP_LABEL.light,
    dark: DEFAULT_THEMES_CHIP_LABEL.dark,
    'dark-highlight': {
        ...DEFAULT_THEMES_CHIP_LABEL.dark,
        color: DEFAULT_CHIP_COLORS.WHITE_900
    }
}

const ONLYVIEW_THEME_VARIATION = {
    label: {
        'font-family': 'Fira Code',
        'font-size': '18px'
    }
} as IChipComponentVariationStyles

interface ChipPressableComponentProps extends IComponentChipBase<IChipOnlyViewThemes> { }

export default function ComponentChipOnlyView({ variationStyles, ...rest }: ChipPressableComponentProps) {
    return <>
        <ChipBaseComponent<IChipOnlyViewThemes>
            variationStyles={{ ...ONLYVIEW_THEME_VARIATION, ...variationStyles }}
            overrideContainerTheme={ONLYVIEW_CONTAINER_THEMES}
            overrideLabelTheme={ONLYVIEW_LABEL_THEMES}
            {...rest}
        />
    </>
}