import { CSSProperties, useEffect, useState } from "react";
import { Modal } from "../../../../Modal/Default/Index";
import { RoundedCloseButton } from "../../../../Buttons/RoundedCloseButton/Index";
import "./styles.css"
import { ButtonOnlyTitle } from "../../../../Buttons/ButtonOnlyTitle/Index";
import { IOptionsProps } from "../../../../../@types/reactSelect/IOption";
import { useEditOnboarding } from "../../../../../hooks/useEditOnboarding";
import axios from "../../../../../services/axios"
import { useTaskCreation } from "../../../../../hooks/useTaskCreation";
import { useForm } from "react-hook-form";
import { Dropdown } from "../../../../EditOnboarding/components/Select";
import { Input } from "../../../../EditOnboarding/components/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup'

const createEvaluatorModalStyle = {
  padding: "50px",
  width: "600px",
  height: "fit-content",
  borderRadius: 20,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
} as CSSProperties;

const evaluatorSchema = yup.object()
.shape({
  evaluator: yup
    .object()
    .nullable()
    .required('Responsável é necessário.'),
  area: yup
    .object()
    .nullable()
    .required('Retiro é necessário.'),
})


export default function CreateEvaluator() {
  const [allResponsibles, setAllResponsibles] = useState<IOptionsProps>();
  const [optionsArea, setOptionsArea] = useState<IOptionsProps>([]);
  const { modalCreateEvaluator, setModalCreateEvaluator } = useTaskCreation();
  const {
    defaultValue,
    idSelected,
    setIdSelected
  } = useEditOnboarding();

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({ 
    defaultValues: defaultValue,
    resolver: yupResolver(evaluatorSchema),
  });

  useEffect(() => {
    axios
      .get("areas")
      .then((response) => {
        setAllResponsibles(response.data.map((area: any) => ({
          label: area.manager_user?.name,
          value: area.manager_user?.id,
          info: area
        })) as IOptionsProps);
      });
  }, []);

  function onSubmit(data: any) {
    const body = {
      "user_id": Number(data?.evaluator?.value),
      "area_ids": [Number(data?.area?.value)]
    }
    if (idSelected) {
      axios.patch("users/user/evaluator", body)
        .then(() => {
          setModalCreateEvaluator(false)
        })
        .catch(err => console.error(err))
    } else {
      axios.post("users/user/evaluator", body)
        .then(() => {
          setModalCreateEvaluator(false)
        })
        .catch(err => console.error(err))
    }
  }

  function deleteEvaluator(id: number) {
    axios.delete(`users/user/evaluator/${id}`)
      .then(() => {
        setModalCreateEvaluator(false)
        setIdSelected(0)
      })
      .catch(err => console.error(err))
  }

  useEffect(() => {
    if (defaultValue) {
      setValue("evaluator", {
        label: defaultValue?.area?.manager_user?.name,
        value: defaultValue?.area?.manager_user?.id
      })
      if (defaultValue?.area?.farm)
        setValue("farm", `${defaultValue?.area?.farm?.name} (${defaultValue?.area?.farm?.initials})`)
      setValue("area", {
        label: defaultValue?.area?.name,
        value: defaultValue?.area?.id
      })
      setOptionsArea([{
        label: defaultValue?.area?.name,
        value: defaultValue?.area?.id
      }]);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (!modalCreateEvaluator) {
      reset({
        farm: "",
        area: "",
        evaluator: ""
      })
    }
  }, [modalCreateEvaluator]);
  const responsible = watch("evaluator")
  const area = watch("area")

  return (
    <Modal
      visible={modalCreateEvaluator}
      contentStyle={createEvaluatorModalStyle}
    >
      <form onSubmit={() => handleSubmit(onSubmit)}>
        <section>
          <header className="headerCreateEvaluator">
            <h2>{idSelected ? "Editar avaliador" : "Adicionar avaliador"}</h2>
            <div>
              <RoundedCloseButton
                onClick={() => {
                  setModalCreateEvaluator(false)
                }}
              />
            </div>
          </header>
          <main className="formCreateEvaluator">
            <div>
              <span className="labelCreateEvaluator">Responsável</span>
              <div className="filters-dropdown filters-dropdown__full-width">
                <Dropdown
                  options={allResponsibles?.filter(el=>el?.value!=responsible?.value) ?? []}
                  placeholderText="Selecionar responsável..."
                  getSelectedData={(el) => {
                    if (allResponsibles) {
                      setOptionsArea([{
                        label: (allResponsibles.filter(resp => resp.value == el.value)[0]?.info as any)?.name,
                        value: (allResponsibles.filter(resp => resp.value == el.value)[0]?.info as any)?.id
                      }]);
                      const farm = el.info?.farm
                      setValue("farm", `${farm?.name} (${farm?.initials})`)
                      setValue("area", null)
                    }
                  }}
                  name={"evaluator"}
                  control={control}
                  closeMenuOnSelect={true}
                  isDisabled={idSelected != 0}
                  noOptionsMessage={() => <span>Nenhum responsável disponível</span>}
                />
              </div>
            </div>

            <div>
              <span className="labelCreateEvaluator">Fazenda</span>
              <Input
                control={control}
                theme={"read-only"}
                name={"farm"}
                errorText={errors.evaluator?.message?" ":""}
                disabled
              />
            </div>

            <div>
              <span className="labelCreateEvaluator">Retiro</span>
              <div className="filters-dropdown filters-dropdown__full-width">
                <Dropdown
                  options={optionsArea?.filter(el=>el?.value!=area?.value) ?? []}
                  placeholderText="Selecionar retiro..."
                  name={"area"}
                  control={control}
                  closeMenuOnSelect={true}
                  noOptionsMessage={() => <span>Nenhum retiro disponível</span>}
                />
              </div>
            </div>
            <ButtonOnlyTitle
              title="Remover avaliador"
              theme="urgent"
              type="button"
              onClick={() => deleteEvaluator(idSelected)}
              hidden={!idSelected}
            />
          </main>
        </section>

        <footer className="footerCreateEvaluator">
          <ButtonOnlyTitle
            title="Cancelar"
            theme="light"
            type="button"
            onClick={() => {
              setModalCreateEvaluator(false)
            }}
          />
          <ButtonOnlyTitle
            title={idSelected ? "Confirmar" : "Adicionar"}
            theme="info"
            type="button"
            onClick={handleSubmit(onSubmit)}
            disabled={Object.keys(errors).length !== 0}
          />
        </footer>
      </form>
    </Modal>
  )
}