import { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import "./styles.css";

type InputText = JSX.IntrinsicElements["input"] & {
  name: string;
};

/*
  * Este componente só deve ser usado para renderiação de IDS
  * Deve ser usado com ateção!!!
*/

export function HandleUUID({ name, className, ...rest }: InputText) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        const value = ref?.current?.value;
        if (rest && rest?.type === "number" && value) return value !== "" ? Number(value) : undefined;
        return value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="handleuuid__container">
      <input
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      />
    </div>
  );
}
