import { ReactNode } from "react";
import { LeafletMap } from "../LeafletMap/Index";

type LeafletStaticMapProps = {
  children: ReactNode;
};

export function LeafletStaticMap({ children }: LeafletStaticMapProps) {
  const interactionOptions = {
    zoomControl: false,
    doubleClickZoom: false,
    closePopupOnClick: false,
    dragging: false,
    trackResize: false,
    touchZoom: false,
    scrollWheelZoom: false,
  };
  return <LeafletMap interactionOptions={interactionOptions}>{children}</LeafletMap>;
}
