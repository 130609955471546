import * as yup from "yup";

export const useYupValidate = async (formRef: any, formData: any, schemaValidate: any) => {
  const errors = {} as any;
  if (formRef && formRef.current) formRef.current.setErrors(errors);
  try {
    await schemaValidate.validate(formData, { abortEarly: false });
    return true; // se tudo estiver ok, devolvemos true
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      err.inner.forEach((e) => {
        errors[e.path as string] = e.message;
      });
      return false; // se existir algum erro, devolvemos falso
    }
  } finally {
    // atualizamos o objeto de erros do form
    if (formRef && formRef.current) formRef.current.setErrors(errors);
  }
  return false;
};

export const useYupOnlyVerify = async (formRef: any, formData: any, schemaValidate: any) => {
  formRef.current?.setErrors({});
  try {
    await schemaValidate.validate(formData, { abortEarly: true });
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      return { [err.path as string]: err.message };
    }
  }
  return null;
};
