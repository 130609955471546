import { useEffect, useRef, useState } from "react";
import "./styles.css";
import { Polygon } from "@react-google-maps/api";
import { IKmlUpload } from "../../../@types/app/IKmlUpload";
import { ICoords } from "../../../@types/GoogleMaps/ICoord";
import {
  CustomPolygonProps
} from "../../../contexts/GoogleMapsContext";
import { useGoogleMaps } from "../../../hooks/useGoogleMaps";

type PasturesProps = {
  farmProps: IKmlUpload;
  editable?: boolean;
  noClickable?: boolean;
  handleClick?: (id: string) => void;
  polygonStyle?: google.maps.PolygonOptions;
  onFarmEdit?: (coords: ICoords) => void;
};

export function FarmPolygon({
  farmProps,
  editable = false,
  noClickable = false,
  handleClick,
  polygonStyle,
  onFarmEdit,
}: PasturesProps) {
  const { onEditPoint } = useGoogleMaps();

  const [options, setOptions] = useState({
    strokeColor: "#ededed",
    fillColor: "#F00",
    strokeOpacity: 1,
    fillOpacity: 1,
    strokeWeight: 3,
  });

  // Isto armazena o pasto em si
  const [farm, setFarm] = useState<IKmlUpload>();

  // Isto define se o pasto atual deve estar ativo
  const [isActive, setIsActive] = useState<boolean>(false);

  // Referência do componente
  const polygonRef = useRef<CustomPolygonProps | null>(null);

  // Armazena o componente para depois ser repassado para a referência
  const [polygonComponet, setPolygonComponet] = useState<CustomPolygonProps | null>(null);

  // Isto define as configurações iniciais do polígono e a referência
  useEffect(() => {
    if (polygonComponet && polygonRef) {
      polygonRef.current = polygonComponet;
    }
  }, [polygonComponet, polygonRef]);

  useEffect(() => {
    setFarm(() => farmProps);
  }, [farmProps]);

  return (
    <>
      {farm && (
        <>
          <Polygon
            editable={editable}
            path={farm.coords}
            options={polygonStyle || options}
            onMouseUp={() => {
              if (onFarmEdit) {
                onFarmEdit(onEditPoint(polygonRef));
              }
            }}
            onLoad={(polygon) => setPolygonComponet(polygon)}
          ></Polygon>
        </>
      )}
    </>
  );
}
