import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";
import "./style.css";
import axios from "../../../services/axios";
import { MessageBalloon } from "../../MessageBalloon/Index";
import { ButtonOnlyTitle } from "../../Buttons/ButtonOnlyTitle/Index";
import { useTaskCreation } from "../../../hooks/useTaskCreation";
import { DropDownSelect } from "../../Filters/DropDown/Index";
import { MultipleSelect } from "../../MultipleSelect/Index";
import { ButtonWithChildren } from "../../Buttons/ButtonWithChildren/Index";
import { ReactComponent as Pen } from "../../../assets/svg/icons/penEdit.svg";
import { CounterInputEdit } from "../../EditOnboarding/components/Input/CounterInput/Index";
import { useForm } from "react-hook-form";
import { IOptionProps, IOptionsProps } from "../../../@types/reactSelect/IOption";
import { sortBy } from "../../../utils/arrays/sortBy";

interface FencingProps {}

type FencingTasks = "REPAIR" | "REMOVE" | "BUILD" | undefined;

const SUBTASKS = {
  REPAIR: "REPAIR" as "REPAIR",
  REMOVE: "REMOVE" as "REMOVE",
  BUILD: "BUILD" as "BUILD",
};

export const Fencing: React.FC<FencingProps> = () => {
  const { control } = useForm();
  const {
    setAllowTaskCreation,
    responsibleId,
    microAreaId,
    payload,
    setPayload,
    contextMicroArea,
    setShowMicroAreaUnavailableModal,
    setShowDrawOnMapModal,
    allMicroAreas,
    setFilterMicroAreas,
    setCurrentCoords,
    setEditFences
  } = useTaskCreation();

  const [specificTask, setSpecificTask] = useState<FencingTasks>();

  const [showWireAmount, setShowWireAmount] = useState<boolean>(false);
  const [showMapDrawButton, setShowMapDrawButton] = useState<boolean>(false);
  const [showPoleMaterialChooser, setShowPoleMaterialChooser] =
    useState<boolean>(false);

  const [fenceMaterialOptions, setFenceMaterialOptions] =
    useState<IOptionsProps>([]);
  const [fencePoleMaterialOptions, setFencePoleMaterialOptions] =
    useState<IOptionsProps>([]);

  const [changes, setChanges] = useState<any>({});
  const [wireAmount, setWireAmount] = useState<number>(0);
  const [selectedMicroAreas, setSelectedMicroAreas] = useState<number[]>([]);

  useEffect(() => {
    setAllowTaskCreation(false);
  }, []);

  useEffect(() => {
    if (specificTask && specificTask != "REPAIR" && thereIsBatch()) {
      setShowMicroAreaUnavailableModal(true);
    } 
    if (specificTask != "REMOVE") {
      setCurrentCoords(contextMicroArea?.map_coords || []);
    } else {
      setCurrentCoords([])
    }
  }, [specificTask]);

  function thereIsBatch(): boolean {
    if (!contextMicroArea) return false;
    if (!contextMicroArea.micro_area_route) return false;

    const thereIsBatch = contextMicroArea.micro_area_route.current_micro_area;
    return thereIsBatch;
  }

  function getButtonThemes(task: FencingTasks) {
    if (!specificTask) return "dark";

    if (specificTask === task) return "success";
    else return "light";
  }

  function translateSubtask(
    task: FencingTasks
  ): "REPARAR" | "CONSTRUIR" | "REMOVER" {
    switch (task) {
      case SUBTASKS.REPAIR:
        return "REPARAR";

      case SUBTASKS.BUILD:
        return "CONSTRUIR";

      default:
        return "REMOVER";
    }
  }

  const commonForms = (task: FencingTasks) => {
    const currentChanges = {
      micro_area_id: microAreaId,
      assigned_user_id: responsibleId,
      activity: translateSubtask(task),
    };

    setChanges({ ...changes, ...currentChanges });
  };

  const handleFencingTaskChoice = async (task: FencingTasks) => {
    resetSubtasks();
    setSpecificTask(task);
    commonForms(task)
    if (task === SUBTASKS.BUILD) {
      await getFencePoleMaterial();
      await getFenceMaterials();
      setAllowTaskCreation(false)
      setEditFences(true)
    }
    else if (task == SUBTASKS.REMOVE) {
      setAllowTaskCreation(false)
      setEditFences(false)
    }
    else {
      setEditFences(false)
      setAllowTaskCreation(true);
    }
  };

  const handleFenceMaterialChoice = (id: any) => {
    if (!id) return;
    setShowWireAmount(true);
    setChanges({ ...changes, construction_material_id: id });
  };

  useEffect(() => {
    if (wireAmount === 0) return;

    setChanges({ ...changes, wire_quantity: wireAmount });
    setShowPoleMaterialChooser(true);
  }, [wireAmount]);

  useEffect(() => {
    const reqPayload = {
      ...payload,
      changes: [changes]
    };
    setPayload(reqPayload);
  }, [changes]);

  const handleFencePoleMaterialChoice = (id: any) => {
    setShowMapDrawButton(true);
    setChanges({ ...changes, structure_id: id });
  };

  useEffect(() => {
    if (allMicroAreas && setFilterMicroAreas) {
      setFilterMicroAreas(
        allMicroAreas
          .filter(microArea => selectedMicroAreas.includes(Number(microArea.id)))
      )
    }
  }, [selectedMicroAreas]);

  function setSubtaskPage(): ReactNode {
    if (!specificTask) return <></>;
    switch (specificTask) {
      case SUBTASKS.BUILD:
        return (
          <>
            <DropDownSelect
              options={fenceMaterialOptions}
              placeholder="Selecionar tipo de cerca..."
              onChange={(ev: any) => handleFenceMaterialChoice(ev)}
            />
            {showWireAmount && (
              <>
                <div className="balloon-container">
                  <MessageBalloon text="Qual a quantidade de fios?" pointed />
                </div>
                <CounterInputEdit
                  control={control}
                  defaultValue={0}
                  isInline
                  onChangevalue={(ev) => setWireAmount(+ev)}
                  onChange={(ev) => {
                    setWireAmount(+ev.target.value);
                  }}
                  max={9999}
                />
                {showPoleMaterialChooser && wireAmount > 0 && (
                  <>
                    <div className="balloon-container">
                      <MessageBalloon
                        text="Você pode cadastrar mais opções de material
                      em ajustes especiais"
                        pointed
                      />
                    </div>
                    <DropDownSelect
                      options={fencePoleMaterialOptions}
                      placeholder="Selecionar material..."
                      onChange={(ev) => handleFencePoleMaterialChoice(ev)}
                    />
                    {showMapDrawButton && (
                      <>
                        <ButtonWithChildren
                          theme={"dark"}
                          onClick={() => setShowDrawOnMapModal(true)}
                        >
                          <div className="buttonWithChildren--content-inline button__createTask">
                            <Pen width={18} height={18} fill="#fff" />
                            <h6>Desenhe a nova cerca no mapa</h6>
                          </div>
                        </ButtonWithChildren>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        );
      case SUBTASKS.REMOVE:
        return (
          <div style={{ width: "100%" }}>
            <ButtonWithChildren
              theme={"dark"}
              onClick={() => setShowDrawOnMapModal(true)}
            >
              <div className="buttonWithChildren--content-inline button__createTask">
                <h6>Selecionar pasto(s) no mapa</h6>
              </div>
            </ButtonWithChildren>
          </div>
        );
      case SUBTASKS.REPAIR:
        return <></>;
    }
  }

  const getFenceMaterials = async () => {
    axios.get("/tasks/fencing/construction-materials").then((response) => {
      if (response.data) {
        setFenceMaterialOptions(
          response.data.map((material: any) => ({
            label: material.name,
            value: material.id,
          }))
        );
      }
    });
  };

  const resetSubtasks = () => {
    setChanges({});
    setShowWireAmount(false);
  };

  const getFencePoleMaterial = async () => {
    axios.get("/tasks/fencing/structures").then((response) => {
      if (response.data) {
        setFencePoleMaterialOptions(
          response.data.map((material: any) => ({
            label: material.name,
            value: material.id,
          }))
        );
      }
    });
  };

  return (
    <>
      <div className="balloon-container">
        <MessageBalloon text="Qual atividade será realizada?" pointed />
      </div>
      <div className="task-button-container">
        <ButtonOnlyTitle
          title="Retirar cerca"
          theme={getButtonThemes(SUBTASKS.REMOVE)}
          onClick={() => {
            setCurrentCoords([]);
            handleFencingTaskChoice(SUBTASKS.REMOVE)
          }}
        />
      </div>
      <div className="task-button-container">
        <ButtonOnlyTitle
          title="Reparar cerca"
          theme={getButtonThemes(SUBTASKS.REPAIR)}
          onClick={() => handleFencingTaskChoice(SUBTASKS.REPAIR)}
        />
      </div>
      <div className="task-button-container">
        <ButtonOnlyTitle
          title="Construir cerca"
          theme={getButtonThemes(SUBTASKS.BUILD)}
          onClick={() => {
            setCurrentCoords(contextMicroArea?.map_coords || [])
            handleFencingTaskChoice(SUBTASKS.BUILD)
          }}
        />
      </div>
      {specificTask && (
        <div className="subtask-container">{setSubtaskPage()}</div>
      )}
    </>
  );
};
