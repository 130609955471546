import { useEffect, useState } from "react";
import { IAnimalCategory } from "../../../../../@types/app/AnimalCategory/ICategory";
import { IAnimalMonth } from "../../../../../@types/app/AnimalCategory/IMonths";
import { randomString } from "../../../../../utils/uuid/radomString";
import { TextInput } from "../../../../Inputs/TextInputs/TextInput/Index";
import "./styles.css";

type TextInputEditWeight = {
  monthProps: IAnimalMonth;
  evolutionName: string;
  category: IAnimalCategory;
  handleInputChanges?: (data?: HTMLInputElement) => void;
  onInputError?: (err?: string) => void;
};

export function InputWeight({
  monthProps,
  category,
  evolutionName,
  handleInputChanges,
  onInputError,
}: TextInputEditWeight) {
  const [error, setError] = useState<string>("");
  const [uuidInput, setUuidInput] = useState<string>("");

  function handleInputs(data?: HTMLInputElement) {
    if (data && handleInputChanges) {
      handleInputChanges(data);
    }
  }

  function handleErrorInput(err: string) {
    onInputError && onInputError(err);
    setError(err);
  }

  useEffect(() => {
    setUuidInput(`${evolutionName}-${category.name}-${randomString(4)}`.toLocaleLowerCase());
  }, [monthProps]);

  return (
    <label htmlFor={uuidInput} className={`tableItemEdit__label ${error ? "labelNotValid" : ""}`}>
      <TextInput
        id={uuidInput}
        type="number"
        name="value"
        defaultValue={monthProps.weight.value}
        handleAnywhereChanges={handleInputs}
        getMessageError={handleErrorInput}
        onWheel={(event) => event.currentTarget.blur()}
      />
      <span>{monthProps.weight.unit}</span>
    </label>
  );
}
