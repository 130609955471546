import { ReactNode } from "react";
import "./styles.css";

type ButtonRedirectProps = {
  children: ReactNode;
  onClick: () => void;
};

export function ButtonRedirect({ children, onClick: eventClick }: ButtonRedirectProps) {
  return (
    <div className="modalerror__button--redirect">
      <button onClick={eventClick}>{children}</button>
    </div>
  );
}
