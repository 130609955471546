import React, { useEffect, useState } from "react";
import axios from "../../../services/axios";
import { DropDownSelect } from "../../Filters/DropDown/Index";
import { IOptionsProps } from "../../../@types/reactSelect/IOption";
import { MessageBalloon } from "../../MessageBalloon/Index";
import { useTaskCreation } from "../../../hooks/useTaskCreation";

interface SoilPreparationProps {}

export const SoilPreparation: React.FC<SoilPreparationProps> = () => {
  const [machineTypeOptions, setMachineTypeOptions] = useState<IOptionsProps>(
    []
  );

  const {
    payload,
    setPayload,
    setAllowTaskCreation,
    responsibleId,
    microAreaId,
  } = useTaskCreation();

  useEffect(() => {
    axios
      .get("/tasks/soil-preparation/machine-types")
      .then((response) => {
        setMachineTypeOptions(
          response.data.map((machineType: { name: string; id: number }) => ({
            label: machineType.name,
            value: machineType.id,
          }))
        );
      })
      .catch((error) => console.log(error));
  }, []);

  const handleMachineTypeChoice = (machineId: any) => {
    const changes = {
      micro_area_id: microAreaId,
      assigned_user_id: responsibleId,
      machine_type_id: machineId,
    };
    setPayload({ ...payload, changes: [changes] });
    setAllowTaskCreation(true);
  };

  return (
    <>
      {/* <div className="balloon-container">
        <MessageBalloon
          text="Você pode cadastrar mais opções de gradagem
          em ajustes especiais"
          pointed
        />
      </div> */}
      <DropDownSelect
        options={machineTypeOptions}
        placeholder="Selecionar implemento a ser feito no solo..."
        onChange={(ev) => handleMachineTypeChoice(ev)}
      />
    </>
  );
};
