import { ReactNode } from "react";
import { Popup } from "react-leaflet";
import "./styles.css";

import L from "leaflet";

type CustomPopPupProps = {
  children?: ReactNode;
  position: any;
  offset?: L.PointExpression;
};

export function CustomPopup({ children, position, offset }: CustomPopPupProps) {
  return (
    <Popup
      position={position as any}
      className="customPopup__container"
      closeButton={false}
      autoPan={false}
      children={children}
      offset={offset}
      autoClose={false}
      closeOnEscapeKey={false}
      closeOnClick={false}
      keepInView
    />
  );
}
//L.point(20, 200)
