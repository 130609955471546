import axios from './axios'

const getAreabyId = <T = IArea>(areaId: number, formatDatacallBack?: (data: IArea) => any): Promise<T> => {
    return axios.get<IArea>(`/areas/${areaId}`).then(({ data }) => {
        if (formatDatacallBack) return formatDatacallBack(data)
        return data
    })
}

const getAreabyFarmId = <T = IArea[]>(farmId: number, formatDatacallBack?: (data: IArea[]) => any): Promise<T> => {
    return axios.get<IArea[]>(`/areas?farm_id=${farmId}`).then(({ data }) => {
        if (formatDatacallBack) return formatDatacallBack(data)
        return data
    })
}

const createNewArea = (body: ICreateArea[]): Promise<any> => {
    return axios.post(`/areas`, body).then(({ data }) => {
        return data
    })
}

const editArea = (data: IEditArea[]): Promise<IEditArea> => {
    return axios.patch('/areas', data)
}

const deleteArea = (areaId: number): Promise<any> => {
    return axios.delete(`/areas/${areaId}`)
}

export const areaService = {
    getAreabyId,
    editArea,
    deleteArea,
    createNewArea,
    getAreabyFarmId
}