import { BrowserRouter } from "react-router-dom";
import { useJsApiLoader } from "@react-google-maps/api";
import { AuthProvider } from "./contexts/AuthContext";
import { AuthRoutes } from "./routes/auth.routes";
import { MainRoutes } from "./routes/main.routes";
import { GoogleMapsProvider } from "./contexts/GoogleMapsContext";

export function App() {
  // Isto carrega a API de mapa
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API as string,
  });

  if (!isLoaded) return <></>;

  return (
    <BrowserRouter>
      <AuthProvider>
        <GoogleMapsProvider value={{ mapLoad: isLoaded }}>
          <MainRoutes />
          <AuthRoutes mapLoad={isLoaded} />
        </GoogleMapsProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
