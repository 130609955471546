import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as EyeOpen } from "../../../../../assets/svg/icons/eyeClosed.svg";
import { ReactComponent as EyeClosed } from "../../../../../assets/svg/icons/eye.svg";
import { ReactComponent as Circleicon } from "../../../../../assets/svg/icons/ellipse.svg";
import AnimateHeight from "react-animate-height";
import { IAreas } from "../../../../../@types/API/IArea";
import { useOnboarding } from "../../../../../hooks/useOnboarding";
import { Scope } from "@unform/core";
import axios from "../../../../../services/axios";
import { AreaLots } from "./Area";
import {
  FlyToBounds,
  FlyToBoundsRefProps,
} from "../../../../Leaflet/FlyToBounds/Index";
import { IFieldsNames } from "../../CreateArea/FormArea/Index";
import { ICreateArea } from "../../CreateArea/Index";
import { ReactComponent as GrassIcon } from "../../../../../assets/svg/icons/grass.svg";
import { ReactComponent as Animais } from "../../../../../assets/svg/icons/animais.svg";
import { LeafletStaticMap } from "../../../../Leaflet/LeafletStaticMap/Index";
import { Polygon } from "react-leaflet";
import Constants from "../../../../../constants/Index";
import "./../styles.css"

type FarmProps = {
  formRef: MutableRefObject<HTMLFormElement | undefined>;
  farm: any;
  keyFarm: number;
  initValues: any;
  onlyRead?: boolean;
  onBoarding: boolean;
  handleAnyChanges: (index?: number) => void;
};

export function FarmLots({
  farm,
  formRef,
  keyFarm,
  initValues,
  onlyRead = false,
  onBoarding = false,
  handleAnyChanges,
}: FarmProps) {
  const [visibleFarm, setVisibleFarm] = useState<"auto" | 0>("auto");
  const [selectBatch, setSelectBatch] = useState<number>(0);
  const [numOfMicroAreas, setNumOfMicroAreas] = useState<number>(0);
  const [listBatches, setListBatches] = useState<any>();
  const [listCoords, setListCoords] = useState<any>();
  const [areas, setAreas] = useState<IAreas>([]);
  const { payload } = useOnboarding();
  const flytoBoundsRef = useRef<FlyToBoundsRefProps>();

  const handleVisibleFarm = async () => {
    setVisibleFarm((prev) => (prev === 0 ? "auto" : 0));
  };

  useEffect(() => {
    if (!onlyRead && payload && payload.areas) {
      var listAreas = payload.areas;
      setAreas(listAreas.filter((area) => area.farm_id == farm.uuid) as IAreas);
    } else if (initValues) {
      axios.get("/areas", { params: { farm_id: farm.id } }).then(({ data }) => {
        const distinctArea = data.map((obj: any) => {
          var areaForm = {
            ...obj,
            map_coords: {
              coords: obj.map_coords,
            },
          };
          return areaForm;
        });
        setAreas(distinctArea);
      });
    }
  }, [initValues]);

  const handleChanges = useCallback(() => {
    setTimeout(() => {
      handleAnyChanges && handleAnyChanges();
      refreshBatches();
    }, 100);
  }, [handleAnyChanges]);

  function getBatches(formData: any, index: number) {
    return {
      farmKey: formData[index].keyFarm,
      areaKey: formData[index].keyArea,
      batchKey: formData[index].keyBatch,
      micro_area_route_ids: formData[index].micro_area_route_ids,
      currentMicroArea: formData[index].current_micro_area?.value,
      initials:
        formData[index].farmAreaInitials + "-" + formData[index].initials,
      numberPastures: formData[index].micro_area_route_ids?.length,
      numberAnimals: formData[index].categories?.reduce(
        (sum: number, current: any) => sum + current.quantity,
        0
      ),
    };
  }

  async function refreshBatches() {
    const formData = formRef.current
      ?.getData()
      .farms?.map((farm: any) =>
        farm.areas
          .map((area: any) => area.lots.map((lot: any) => lot).flat())
          .flat()
      )
      .flat()
      .filter((value: any) => value.farm_id == farm.uuid);
    if (formData) {
      setListBatches(
        await formData.map((_: any, index: number) =>
          getBatches(formData, index)
        )
      );
    }
  }

  function getNumberPastures(batches: any) {
    if (batches && areas && areas[Number(batches[selectBatch]?.areaKey)])
      setNumOfMicroAreas(
        payload.micro_areas.filter(
          (microArea) =>
            microArea.area_id ==
            (areas[Number(batches[selectBatch].areaKey)] as any).uuid
        ).length
      );
  }

  useEffect(() => {
    getCoordsMicroAreaRoute(listBatches, selectBatch);
    getNumberPastures(listBatches);
  }, [listBatches]);

  async function getCoordsMicroAreaRoute(routeIDs: any, index: number) {
    const batchMicroArea = routeIDs?.map(
      (microArea: any) => microArea.micro_area_route_ids
    );
    if (batchMicroArea) {
      const coordsMicroArea = payload.micro_areas.filter((microArea) =>
        batchMicroArea[index]?.includes(microArea.uuid)
      );
      setListCoords(coordsMicroArea);
    }
  }

  useEffect(() => {
    getCoordsMicroAreaRoute(listBatches, selectBatch);
    getNumberPastures(listBatches);
  }, [selectBatch]);

  return (
    <>
      <div className="area__title--container">
        <h2>{farm.name}</h2>
        <button
          type="button"
          onClick={handleVisibleFarm}
          style={{ marginTop: 40 }}
        >
          {visibleFarm ? <EyeOpen /> : <EyeClosed />}
        </button>
      </div>

      <AnimateHeight height={visibleFarm}>
        <div className="d-flex">
          <div className="batch_column">
            {areas.map((area, key) => (
              <Scope key={key} path={`areas[${key}]`}>
                <AreaLots
                  formRef={formRef}
                  farm={farm}
                  area={area}
                  keyFarm={keyFarm}
                  keyArea={key}
                  onBoarding={onBoarding}
                  handleAnyChanges={handleChanges}
                  refreshBatches={refreshBatches}
                  batchSelected={(value) => setSelectBatch(value)}
                />
                <div className="microarea__divider" />
              </Scope>
            ))}
          </div>

          <div
            className="batch_column_mapa"
            style={{ height: 550, marginBottom: 100 }}
          >
            <div
              className={`area__drawarea--container`}
              style={{ height: 400 }}
            >
              <LeafletStaticMap>
                <FlyToBounds ref={flytoBoundsRef} fitCoords={farm?.map_coords}>
                  <div className="FlytoBounds_container">
                    <div className="subtitle__animals">
                      <Animais />
                      <span id={`numberAnimals-${keyFarm}`}>
                        {listBatches && listBatches[selectBatch]?.numberAnimals || 0}
                      </span>
                    </div>
                    <div className="subtitle__grass ">
                      <GrassIcon />
                      <span
                        style={{ color: "white" }}
                        id={`numberPastures-${keyFarm}`}
                      >
                        {listBatches
                          ? listBatches
                              .filter(
                                (batch: any) =>
                                  batch?.areaKey ==
                                  listBatches[selectBatch]?.areaKey
                              )
                              .reduce(
                                (sum: number, current: any) =>
                                  sum + current?.numberPastures,
                                0
                              )
                          : 0}
                        /{numOfMicroAreas}
                      </span>
                    </div>
                  </div>
                  {/* area */}
                  {areas.map((area: any) => (
                    <Polygon
                      positions={area.map_coords.coords || []}
                      color={Constants.LEAFLET_UNFOCUS.color}
                    />
                  ))}
                  {/* batch */}
                  {listCoords &&
                    listCoords.map((micro: any) => (
                      <Polygon
                        positions={micro.map_coords.coords || []}
                        color={Constants.LEAFLET_UNFOCUS_TRANSLUCENT.color}
                        fillColor={Constants.LEAFLET_NORMAL_BLUE.fillColor}
                        fillOpacity={
                          Constants.LEAFLET_UNFOCUS_TRANSLUCENT.fillOpacity
                        }
                      />
                    ))}
                </FlyToBounds>
              </LeafletStaticMap>
            </div>
            <div className="grid_batches">
              {listBatches &&
                listBatches.map((values: any, index: number) => (
                  <a
                    href={`#batch-${values.farmKey}-${values.areaKey}-${values.batchKey}`}
                    className="cardBatch"
                    onClick={() => setSelectBatch(index)}
                  >
                    <span className="initials">{values.initials || ""}</span>
                    <span className="info">
                      {values.numberPastures || 0} pastos <Circleicon />{" "}
                      {values.numberAnimals || 0} animais
                    </span>
                  </a>
                ))}
            </div>
          </div>
        </div>
      </AnimateHeight>
    </>
  );
}
