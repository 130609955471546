import React, { useEffect, useState } from "react";
import { DropDownSelect } from "../../Filters/DropDown/Index";
import "./style.css";
import { ButtonOnlyTitle } from "../../Buttons/ButtonOnlyTitle/Index";
import { MessageBalloon } from "../../MessageBalloon/Index";
import axios from "../../../services/axios";
import {
  IOptionProps,
  IOptionsProps,
} from "../../../@types/reactSelect/IOption";
import { CounterInputEdit } from "../../EditOnboarding/components/Input/CounterInput/Index";
import { useForm } from "react-hook-form";
import { useTaskCreation } from "../../../hooks/useTaskCreation";

interface WaterTroughProps {}

type TSpecificTask = "INSTALL" | "REPAIR" | "REMOVE" | undefined;

type TResponse = { id: string; name: string };

export const WaterTroughs: React.FC<WaterTroughProps> = () => {
  const [specificTask, setSpecificTask] = useState<TSpecificTask>();
  const [createTask, setCreateTask] = useState<boolean>(false);

  const [showMaterials, setShowMaterials] = useState<boolean>(false);
  const [showSizeChooser, setShowSizeChooser] = useState<boolean>(false);

  const [changes, setChanges] = useState<any>();

  const [waterTroughs, setWaterTroughs] = useState<{
    micro_area_resource_id: number;
    resource_name: string;
  }>();
  const [shapeOptions, setShapeOptions] = useState<IOptionsProps>([]);
  const [materialOptions, setMaterialOptions] = useState<IOptionsProps>([]);

  const { control } = useForm();
  const {
    payload,
    setPayload,
    setAllowTaskCreation,
    responsibleId,
    microAreaId,
  } = useTaskCreation();

  function getButtonThemes(task: TSpecificTask) {
    if (!specificTask) return "dark";

    if (specificTask === task) return "success";
    else return "light";
  }

  const getOptions = (data: Array<TResponse>): IOptionsProps => {
    if (!data) return [];

    return data.map((value) => ({ label: value.name, value: value.id }));
  };

  useEffect(() => {
    getWaterTroughs();
  }, []);

  useEffect(() => {
    if (!specificTask || specificTask !== "INSTALL") return;

    getShapes();
    getMaterials();
  }, [specificTask]);

  const getShapes = () => {
    axios
      .get<Array<TResponse>>("/tasks/water-trough/shapes")
      .then((response) => {
        setShapeOptions(getOptions(response.data));
      });
  };

  const getMaterials = () => {
    axios
      .get<Array<TResponse>>("/tasks/water-trough/materials")
      .then((response) => {
        setMaterialOptions(getOptions(response.data));
      });
  };

  const getWaterTroughs = () => {
    axios
      .get("/micro-areas/resources", {
        params: {
          micro_area_id: microAreaId,
        },
      })
      .then(
        (response: {
          data: [{ micro_area_resource_id: number; resource_name: string }];
        }) => {
          if (!response.data) return;
          setWaterTroughs(
            response.data.find(
              (resource) => resource.resource_name === "Bebedouro"
            )
          );
        }
      );
  };

  function translateActivity(activity: TSpecificTask): string {
    switch (activity) {
      case "INSTALL":
        return "INSTALAR";

      case "REMOVE":
        return "REMOVER";

      case "REPAIR":
        return "REPARAR";

      default:
        return "";
    }
  }

  const handleActivityChoice = (activity: TSpecificTask) => {
    setSpecificTask(activity);
    if (activity !== "INSTALL") setCreateTask(true);
    else setCreateTask(false);

    let changes: any = {
      water_trough_activity: translateActivity(activity),
      micro_area_id: microAreaId,
      assigned_user_id: responsibleId,
    };

    if (waterTroughs && (activity === "REMOVE" || activity === "REPAIR"))
      changes = {
        ...changes,
        micro_area_aditional_resource_id: waterTroughs.micro_area_resource_id,
      };

    setChanges(changes);
  };

  const handleShapeChoice = (shapeId: any) => {
    setShowMaterials(true);
    setChanges((prev: any) => ({
      ...prev,
      water_trough_shape_id: shapeId,
    }));
  };

  const handleMaterialChoice = (materialId: any) => {
    setShowSizeChooser(true);
    setChanges((prev: any) => ({
      ...prev,
      water_trough_material_id: materialId,
    }));
  };

  const handleSizeInput = (size: any) => {
    setChanges((prev: any) => ({
      ...prev,
      access_area_meters: +size,
    }));

    if (size === 0) setCreateTask(false);
    else setCreateTask(true);
  };

  useEffect(() => {
    if (!createTask) return setAllowTaskCreation(false);

    setPayload({ ...payload, changes: [changes] });
    setAllowTaskCreation(true);
  }, [changes]);

  return (
    <>
      {(!specificTask || specificTask !== "INSTALL") && (
        <>
          <div className="balloon-container">
            <MessageBalloon
              text="Qual atividade será realizada?"
              pointed
            />
          </div>
          {/* <div className="balloon-container">
            <MessageBalloon text={`${waterTroughs ? 1 : 0}`} />
            <MessageBalloon
              text={
                waterTroughs ? "Bebedouro artificial" : "Bebedouros artificiais"
              }
            />
          </div> */}

          <div
            className="task-button-container"
            title={
              !waterTroughs
                ? "Ainda não há bebedouros no pasto selecionado"
                : ""
            }
          >
            <ButtonOnlyTitle
              title="Remover bebedouro"
              theme={getButtonThemes("REMOVE")}
              onClick={() => handleActivityChoice("REMOVE")}
              // disabled={!waterTroughs}
            />
          </div>
          <div
            className="task-button-container"
            title={
              !waterTroughs
                ? "Ainda não há bebedouros no pasto selecionado"
                : ""
            }
          >
            <ButtonOnlyTitle
              title="Reparar bebedouro"
              theme={getButtonThemes("REPAIR")}
              onClick={() => handleActivityChoice("REPAIR")}
              // disabled={!waterTroughs}
            />
          </div>
          <div
            className="task-button-container"
            title={
              waterTroughs
                ? "Ja há bebedouros no pasto selecionado"
                : ""
            }
          >
            <div className="task-button-container">
              <ButtonOnlyTitle
                title="Instalar novo bebedouro"
                theme={getButtonThemes("INSTALL")}
                onClick={() => handleActivityChoice("INSTALL")}
                // disabled={!!waterTroughs}
              />
            </div>
          </div>
        </>
      )}
      {specificTask && specificTask === "INSTALL" && (
        <>
          <DropDownSelect
            options={shapeOptions}
            placeholder="Selecionar formato... "
            onChange={(ev) => handleShapeChoice(ev)}
          />
          {showMaterials && (
            <>
              <div className="task-question-container">
                <div className="balloon-container">
                  <MessageBalloon
                    text="Você pode cadastrar mais opções de material
                  em ajustes especiais"
                    pointed
                  />
                </div>
                <DropDownSelect
                  options={materialOptions}
                  placeholder="Selecionar material..."
                  onChange={(ev) => handleMaterialChoice(ev)}
                />
              </div>
              {showSizeChooser && (
                <div className="task-question-container">
                  <div className="balloon-container">
                    <MessageBalloon
                      text="Qual o tamanho da área de acesso?"
                      pointed
                    />
                  </div>
                  <CounterInputEdit
                    defaultValue={0}
                    control={control}
                    isInline
                    label="m"
                    onChangevalue={(ev) => handleSizeInput(ev)}
                    max={9999}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
