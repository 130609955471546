import {
  SetStateAction,
  createContext,
  useCallback,
  RefObject,
  ReactNode,
  Dispatch,
  useState,
  useEffect,
} from "react";

import { ICoord, ICoords } from "../@types/GoogleMaps/ICoord";
import { IExistingMaps } from "../@types/GoogleMaps/IExistingMaps";
import { IPositionProps, IPositionsProps } from "../@types/GoogleMaps/IPositionProps";
import { IToolsMode } from "../@types/GoogleMaps/ToolsMode";

export type CustomUseStateProps<T> = [generic: T[], setGeneric: Dispatch<SetStateAction<T[]>>];

export type CustomSetStateProps<T> = [setGeneric: Dispatch<SetStateAction<T[]>>];

export type PositionProps = {
  uuid: string;
  coords: ICoords;
};

export type PastureProps = {
  uuid: string;
  coords: ICoords;
  amountMobs: number;
  subsuper: string;
};

export type ListMarkersProps = {
  uuid: string;
  coords: ICoord;
};

export type PolygonPropsCoord = {
  uuid: string;
  coords: ICoord;
};

export type ReferencesProps = {
  uuid: string;
  component: RefObject<google.maps.Polygon>;
};

export type CustomPolygonProps = google.maps.Polygon;

export type CustomPolylineProps = google.maps.Polyline;

export type ViewMap = "all" | "farm" | "retreat" | "pasture";

export type PendingTasksProps = {
  coords: ICoords;
  uuid: string;
};

type GoogleMapsProps = {
  children: ReactNode;
  value: {
    mapLoad: boolean;
  };
};

export type ManagerViewMap = {
  zoom: number;
  center: ICoord;
};

type GoogleMapsContextData = {
  // Diz qual o zoom atual do mapa
  currentZoom: number;
  // Define o zoom atual do mapa
  setCurrentZoom: Dispatch<SetStateAction<number>>;

  // Diz se o mapa foi ou não carregado
  mapLoaded: boolean;
  // Define se o mapa foi ou não carregado
  setMapLoaded: Dispatch<SetStateAction<boolean>>;

  // Diz qual a posição que o mapa deve exibir
  currentPosition: ICoord;
  // Define qual a posição que o mapa deve exibir
  setCurrentPosition: Dispatch<SetStateAction<ICoord>>;

  // Isso tem a referência do componente de Mapa
  mapRef: google.maps.Map | null;
  // Isso define a referência do componente de Mapa
  setMapRef: Dispatch<SetStateAction<google.maps.Map | null>>;

  // Diz qual o modo de ferramenta ativo no momento
  toolMode: IToolsMode;
  // Isto define o modo de ferramenta ativo no momento
  setToolMode: Dispatch<SetStateAction<IToolsMode>>;

  // Diz as cordenadas de um novo polígono
  newPolygon: IPositionProps;
  // Isto define as cordenadas de um novo polígono
  setNewPolygon: Dispatch<SetStateAction<IPositionProps>>;

  existingMaps: IExistingMaps;
  // Isto define as cordenadas de um novo polígono
  setExistingMaps: Dispatch<SetStateAction<IExistingMaps>>;

  polygonSelected: string;

  listMarkers: Array<ListMarkersProps>;

  pointSelected: PolygonPropsCoord | undefined;

  managerTypeViewMap: string;

  visibilityAreas: Array<boolean>;

  optionsSelected: object;

  managerViewMap: ManagerViewMap;
  // Event é o evento disparado pelo mouse
  getGeoposition: (event: google.maps.MapMouseEvent) => ICoord;

  setPolygonSelected: Dispatch<SetStateAction<string>>;

  setlistMarkers: Dispatch<SetStateAction<Array<ListMarkersProps>>>;

  setPointSelected: Dispatch<SetStateAction<PolygonPropsCoord | undefined>>;
  setManagerViewMap: Dispatch<SetStateAction<ManagerViewMap>>;
  setManagerTypeViewMap: Dispatch<SetStateAction<ViewMap>>;
  setVisibilityAreas: Dispatch<SetStateAction<Array<boolean>>>;

  // Isto edita um polygono quando passada a referência dele
  onEditPoint: (polygon: RefObject<google.maps.Polygon>) => ICoords;

  onCreatePoint: <T>(
    argsGeneric: CustomSetStateProps<T>,
    //state a ser modificado
    coords: ICoord
    //coordenadas a serem adicionadas
  ) => void;

  onRemovePoint: <T>(
    arrayCoords: ICoords,
    //state a ser modificado
    coords: ICoord
    //coordenadas a serem removidas
  ) => ICoords;

  // Isto pega o centro de um poligono
  getCenterPolygon: (coords: ICoords) => ICoord;
  // Isto pega um array de coordenadas e centraliza na tela
  fitBoundsPolygon: (coords: ICoords) => ICoord;
  getBounds: (coords: ICoords) => google.maps.LatLngBounds;
};

const GoogleMapsContext = createContext({} as GoogleMapsContextData);

function GoogleMapsProvider({ children, value }: GoogleMapsProps) {
  // Diz qual o zoom atual do mapa
  const [currentZoom, setCurrentZoom] = useState(14);
  // Diz qual a posição atual do mapa
  const [currentPosition, setCurrentPosition] = useState<ICoord>();
  // Diz se o Mapa ou componentes do Mapa estão prontos para serem exibidos
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);

  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);

  // Define a funcionalidade que esta ativa no momento
  const [toolSelected, setToolSelected] = useState<IToolsMode>("explore");

  // Isto armazena um novo polígono caso criado
  const [newPolygon, setNewPolygon] = useState<PositionProps>({ uuid: "", coords: [] });

  const [existingMaps, setExistingMaps] = useState<IExistingMaps>([]);

  const [polygonSelected, setPolygonSelected] = useState<string>("");

  const [listMarkers, setlistMarkers] = useState<Array<ListMarkersProps>>([]);

  const [managerViewMap, setManagerViewMap] = useState<ManagerViewMap>({} as ManagerViewMap);

  const [managerTypeViewMap, setManagerTypeViewMap] = useState<ViewMap>("all");

  const [visibilityAreas, setVisibilityAreas] = useState<Array<boolean>>([true, true, true]);

  const [pointSelected, setPointSelected] = useState<PolygonPropsCoord>({
    uuid: "",
    coords: {} as ICoord,
  });

  const [optionsSelected] = useState({
    strokeColor: "#FF9900",
    fillColor: "#FF9900",
    strokeOpacity: 1,
    fillOpacity: 0.5,
    strokeWeight: 4,
  });

  // Função que adiciona um novo ponto no state passado
  const onCreatePoint = useCallback(
    <T extends ICoord>(argsGeneric: CustomSetStateProps<T>, coords: ICoord) => {
      const [setGenericState] = argsGeneric;
      setGenericState((prevState) => [...prevState, coords] as T[]);
    },
    []
  );

  // Função que remove um ponto no state passado
  const onRemovePoint = useCallback((arrayCoords: ICoords, coords: ICoord) => {
    const response = arrayCoords.filter(
      (coord) => coord.lat != coords.lat && coord.lng != coords.lng
    );
    return response;
  }, []);

  // Função que edita um poligono existente
  const onEditPoint = useCallback(<T extends ICoord>(polygon: RefObject<google.maps.Polygon>) => {
    const polygonRef = polygon;
    if (polygonRef.current) {
      return polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
    }
  }, []);

  //Função que devolve as coordenadas de um clique
  const getGeoposition = useCallback((event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();
    return { lat, lng } as ICoord;
  }, []);

  // Função que devolve o centro de um poligono
  function getCenterPolygon(coords: ICoords) {
    var bounds = new google.maps.LatLngBounds();
    coords.forEach((cood) => {
      bounds.extend(cood);
    });
    const { lat, lng } = bounds.getCenter();

    return { lat: lat(), lng: lng() };
  }

  function getBounds(coords: ICoords) {
    var bounds = new google.maps.LatLngBounds();
    coords.forEach((cood) => {
      bounds.extend(cood);
    });
    return bounds;
  }

  // Função que devolve o centro de um poligono
  function fitBoundsPolygon(coords: ICoords) {
    var bounds = new google.maps.LatLngBounds();
    coords.forEach((cood) => {
      bounds.extend(cood);
    });
    const { lat, lng } = bounds.getCenter();
    mapRef?.fitBounds(bounds);
    return { lat: lat(), lng: lng() };
  }

  // Isto monitora os valores passados como default para o contexto
  useEffect(() => {
    setMapLoaded(value.mapLoad);
  }, [value]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(({ coords }) =>
      setCurrentPosition(() => ({ lat: coords.latitude, lng: coords.longitude }))
    );
  }, []);

  return (
    <GoogleMapsContext.Provider
      value={
        {
          setManagerTypeViewMap,
          managerTypeViewMap,
          setPolygonSelected,
          setVisibilityAreas,
          setCurrentPosition,
          setManagerViewMap,
          setPointSelected,
          fitBoundsPolygon,
          getCenterPolygon,
          polygonSelected,
          currentPosition,
          setToolMode: setToolSelected,
          visibilityAreas,
          optionsSelected,
          setlistMarkers,
          getGeoposition,
          setCurrentZoom,
          managerViewMap,
          onRemovePoint,
          onCreatePoint,
          setNewPolygon,
          pointSelected,
          toolMode: toolSelected,
          setMapLoaded,
          listMarkers,
          onEditPoint,
          currentZoom,
          newPolygon,
          setMapRef,
          mapLoaded,
          mapRef,
          getBounds,
          existingMaps,
          setExistingMaps,
        } as GoogleMapsContextData
      }
    >
      {children}
    </GoogleMapsContext.Provider>
  );
}

export { GoogleMapsProvider, GoogleMapsContext };
