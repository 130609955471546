import React from "react";
import { Modal } from "../Default/Index";
import { RoundedCloseButton } from "../../Buttons/RoundedCloseButton/Index";
import { ButtonOnlyTitle } from "../../Buttons/ButtonOnlyTitle/Index";
import { ReactComponent as Check } from "../../../assets/svg/icons/CheckFull.svg";

interface SuccessModalCreateProductProps {
  visible: boolean;
  onClose: () => void;
  backScreen: () => void;
  newProduct: () => void;
}

export const SuccessModalCreateProduct: React.FC<SuccessModalCreateProductProps> = ({
  visible,
  onClose,
  backScreen,
  newProduct
}) => {
  return (
    <div style={{ zIndex: 910 }}>
      <Modal
        visible={visible}
        contentStyle={{
          padding: "60px",
          width: "600px",
          height: "405px",
          borderRadius: 25,
          position: "relative",
        }}
      >
        <header className="success__modal-header">
          <RoundedCloseButton onClick={() => onClose()} />
        </header>
        <main className="success__modal-main">
          <Check width={40} height={40} />
          <div className="success__modal-message">
            <h2>Concluído!</h2>
            <span>Você cadastrou esse produto com sucesso!</span>
          </div>
        </main>
        <footer className="success__modal-button-container">
          <ButtonOnlyTitle title="Voltar para tela principal" theme="light" onClick={() => backScreen()}/>
          <ButtonOnlyTitle title="Cadastrar outro produto" theme="info" onClick={() => newProduct()}/>
        </footer>
      </Modal>
    </div>
  );
};
