import { useCallback, useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import Password from "react-better-password";
import { ReactComponent as EyeClosedIcon } from "../../../../assets/svg/icons/eyeClosed.svg";
import { ReactComponent as EyeOpenIcon } from "../../../../assets/svg/icons/eye.svg";
import InputMask from "react-input-mask";

import "./styles.css";

type StateProps = "Success" | "Error" | "Normal";

type InputText = JSX.IntrinsicElements["input"] & {
  name: string;
  getMessageError?: (error: string) => void;
  label: string;
  state?: StateProps;
  mask?: string | (string | RegExp)[];
  onAnywhereChanges?: () => void;
  regex?: RegExp
};

export function TextInputWithLabel({
  state = "Normal",
  getMessageError,
  className,
  label,
  name,
  type,
  mask,
  onAnywhereChanges,
  regex,
  ...rest
}: InputText) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [errorField, setErrorField] = useState<string>("");
  const [errorState, setErrorState] = useState<StateProps>(state);
  const [show, setShow] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string>("");
  
  const onChangeAnywhereInput = useCallback(() => {
    if (onAnywhereChanges) onAnywhereChanges();
  }, [onAnywhereChanges]);

  function handlePasshowd() {
    //@ts-ignore
    setCurrentValue(inputRef.current?.state.unmasked);
    setShow((prevState) => !prevState);
  }

  useEffect(() => setErrorState(state), [state]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef as any,
      getValue: (ref) => {
        //Usamos uma lib de senha pois nativamente não podemos usar "*" na máscara
        if (type === "password") {
          return ref.current.state.unmasked;
        } else {
          const value = ref.current.value;
          if (type === "number") return value !== "" ? Number(value) : undefined;
          return value;
        }
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setErrorState(() => (error ? "Error" : "Normal"));
    if(error != " ")
      if (getMessageError) {
        getMessageError(error ? error : "");
      } else setErrorField(() => (error ? error : ""));
  }, [error]);

  const handleChange = (password: string) => {
    setCurrentValue(regex ? password.match(regex)?.join('') || '' : password);
    onChangeAnywhereInput();
  };

  return (
    <>
      <div className={`textInput-label__container inputStatus${errorState}`}>
        <div className="textInput-label__content">
          <label>{label}</label>
          {/* Usamos uma lib de senha pois nativamente não podemos usar "*" na máscara */}
          {type === "password" ? (
            <Password
              {...rest}
              className="inputpassword"
              value={defaultValue || currentValue}
              ref={inputRef as any}
              show={show}
              mask="*"
              onChange={handleChange}
            />
          ) : type === "tel" ? (
            <InputMask
              {...rest}
              ref={inputRef as any}
              mask={mask as string | (string | RegExp)[]}
              alwaysShowMask={false}
              //@ts-ignore
              maskChar={null}
            />
          ) : (
            <input
              defaultValue={defaultValue}
              className={`textInput`}
              ref={inputRef as any}
              {...rest}
            />
          )}
        </div>
        {type === "password" && (
          <button type="button" onClick={handlePasshowd} id={`toggleHide-${name}`}>
            {show ? <EyeOpenIcon /> : <EyeClosedIcon />}
          </button>
        )}
      </div>
      {errorField && (
        <small className="textInputwithlabel__error animation__fadeIn" id={`error-${name}`}>{errorField}</small>
      )}
    </>
  );
}
