import { createContext, ReactNode, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

type EditValues = {
  formRefValue: any;
  setFormRefValue: (s: HTMLFormElement | undefined) => void;
  blockSave: boolean;
  setBlockSave: (t: boolean) => void;
  refreshValues: boolean;
  setRefreshValues: (t: boolean) => void;
  permissionMaster: boolean;
};

type EditValuesProps = {
  children: ReactNode;
};

const EditValuesContext = createContext({} as EditValues);

function EditValuesProvider({ children }: EditValuesProps) {
  const [formRefValue, setFormRefValue] = useState<HTMLFormElement | undefined>(undefined)
  const [blockSave, setBlockSave] = useState<boolean>(false);
  const [refreshValues, setRefreshValues] = useState<boolean>(false);
  const [permissionMaster, setPermissionMaster] = useState<boolean>(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token")
  let decode: any;

  try {
    decode = jwt_decode(token || " ");
  } catch (error) {
    console.error("Failed to decode token: ", error);
    navigate("/entrar")
  }

  useEffect(() => {
    setPermissionMaster(decode.ROLE == "MASTER")
  }, [])

  return (
    <EditValuesContext.Provider
      value={{
        formRefValue, setFormRefValue, blockSave, setBlockSave, refreshValues,
        setRefreshValues, permissionMaster
      } as EditValues}
    >
      {children}
    </EditValuesContext.Provider>
  );
}

export { EditValuesContext, EditValuesProvider };
