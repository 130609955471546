import {
  ChangeEvent,
  HTMLAttributes,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { useField } from "@unform/core";
import "./styles.css";
import ReactInputMask from "react-input-mask";

type InputText = InputHTMLAttributes<HTMLInputElement> & {
  getMessageError?: (error: string) => void;
  handleAnywhereChanges?: (data?: HTMLInputElement) => void;
  returnAsNumber?: boolean;
  updateValue?: string | number;
  returnUnmasked?: boolean;
  mask?: string;
  isInvalid?: boolean;
};

export function TextInput({
  handleAnywhereChanges,
  returnAsNumber,
  getMessageError,
  returnUnmasked,
  updateValue,
  className,
  mask,
  isInvalid = false,
  ...rest
}: InputText) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(rest.name || "");

  const [errorField, setErrorField] = useState<string>("");

  const [inputValue, setInputValue] = useState<string>("");
  const [timer, setTimer] = useState<any>(null);

  const inputChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      if (inputRef.current && handleAnywhereChanges) handleAnywhereChanges(inputRef.current);
    }, 100);

    setTimer(newTimer);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        const value = ref?.current?.value;
        if (rest && rest?.type === "number" && value) return value !== "" ? Number(value) : undefined;
        if (returnAsNumber && value) return value !== "" ? value.replace(",", ".") : undefined;
        if (returnUnmasked  && value) return value?.replace(/[^0-9a-z]/gi, "");
        else return value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (getMessageError) getMessageError(error ? error : "");
    else setErrorField(() => (error ? error : ""));
  }, [error]);

  return (
    <div className="textInput__container">
      {mask ? (
        <ReactInputMask
          {...rest}
          ref={inputRef as any}
          mask={mask}
          alwaysShowMask={false}
          defaultValue={defaultValue || rest.defaultValue}
          //@ts-ignore
          maskChar={null}
          className={errorField ? "isInvalid" : ""}
          onChange={(event) => {
            inputChanged(event);
            rest.onChange && rest.onChange(event);
          }}
          autoComplete="off"
        />
      ) : (
        <input
          {...rest}
          className={`textInput ${errorField || isInvalid ? "isInvalid" : ""}`}
          ref={inputRef}
          onChange={(event) => {
            inputChanged(event);
            rest.onChange && rest.onChange(event);
          }}
          autoComplete="off"
          defaultValue={defaultValue || rest.defaultValue}
        />
      )}
      {errorField && (
        <small className="textInput--small-error animation__fadeIn">{errorField}</small>
      )}
    </div>
  );
}
