import { IPayloadProps } from "../@types/Onboarding/IPayload";
import Constants from "../constants/Index";

export function exportProfilesOnFormatOfAPI(payload: IPayloadProps) {
  if (payload && payload.users)
    return payload.users.map((user) => ({
      uuid: user.uuid,
      profile_id: user.profile_id,
      name: user.name,
      phone: user.phone,
      password: user.password || Constants.DEFAULT_PASSWORD,
    }));
  return null;
}

export function exportAssortmentsOnFormatOfAPI(payload: IPayloadProps) {
  if (!payload.assortment || payload.assortment.whoiam?.value === "fazenda") return null;
  return {
    name: payload.assortment.name,
    document: payload.assortment.document,
    state_registration: payload.assortment.state_registration || "",
    phone: payload.assortment.phone || "",
    address: payload.assortment.address,
  };
}

export function exportFarmsOnFormatOfAPI(payload: IPayloadProps) {
  if (payload && payload.farms)
    return payload.farms.map((farm) => ({
      name: farm.name,
      uuid: farm.uuid,
      initials: farm.initials,
      document: farm.document,
      state_registration: farm.state_registration,
      owner_user_id: farm.manager_user_id,
      address: farm.address,
      properties: farm.properties,
      map_coords: farm.map_coords,
    }));
  return null;
}

export function exportAreasOnFormatOfAPI(payload: IPayloadProps) {
  if (payload && payload.areas)
    return payload.areas.map((area) => ({
      name: area.name,
      uuid: area.uuid,
      initials: area.initials,
      farm_id: area.farm_id,
      owner_user_id: area.manager_user_id?.value,
      map_coords: area.map_coords?.coords,
      acreage: area.acreage,
      indoor_grazing_time: area.indoor_grazing_time?.value,
      outdoor_grazing_time: area.outdoor_grazing_time?.value,
    }));
  return null;
}

export function exportMicroAreasOnFormatOfAPI(payload: IPayloadProps) {
  var reasonsList = new Map();
  if (payload && payload.reason) {
    reasonsList = new Map(
      payload.reason.map((microArea: any) => {
        return [microArea.micro_area_id, microArea.reason_id?.value];
      })
    ) as Map<string, string>;
  }

  if (payload && payload.micro_areas)
    return payload.micro_areas.map((micro) => ({
      uuid: micro.uuid,
      area_id: micro.area_id,
      initials: micro.initials,
      acreage: micro.acreage,
      map_coords: micro.map_coords.coords,
      micro_area_reason_id: reasonsList.get(micro.uuid) || null,
    }));
  return null;
}

export function exportEvolutionsOnFormatOfAPI(payload: IPayloadProps) {
  if (payload && payload.evolutions) return payload.evolutions;
  return [];
}

export function exportBatchesOnFormatOfAPI(payload: IPayloadProps) {
  if (payload && payload.batches)
    return payload.batches.map((batch) => ({
      area_id: batch.area_id,
      initials: batch.initials,
      categories: batch.categories
        .filter((category) => category !== null)
        .map((category) => ({
          id: category.id,
          month: category.month,
          quantity: category.quantity,
        })),
      current_micro_area_id: batch.current_micro_area_id,
      micro_area_route_ids: batch.micro_area_route_ids,
      batch_capacity_rate: batch.batch_capacity_rate,
    }));
  return null;
}


export function exportGoalsOnFormatOfAPI(payload: IPayloadProps) {
  if (payload && payload.goals)
    return {
      beginning: payload.goals.beginning || [],
      end: payload.goals.end || [],
    };
  return null;
}
