/*
  * Este componente compoe o componente AnimatedZoom, 
    os filhos inseridos nele só serão exibidos na visão 4X
*/

import { ReactNode } from "react";
import Constants from "../../../constants/Index";

type ContainerZoom4XProps = {
  children: ReactNode | Array<ReactNode>;
};

export const ContainerZoom4X = ({ children }: ContainerZoom4XProps) => <>{children}</>;

ContainerZoom4X.displayName = Constants.COMPONENT_4X_NAME;

export default ContainerZoom4X;
