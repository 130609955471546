import {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { IAssortment } from "../@types/app/IAssortment";
import { IProfiles } from "../@types/app/IProfile";
import { IPayloadProps } from "../@types/Onboarding/IPayload";
import { IOptionProps } from "../@types/reactSelect/IOption";
import Constants from "../constants/Index";

type OnboardingContextData = {
  screenNumber: number;
  subScreenNumber: number;
  userProgress: Array<UserProgressProps>;
  screenComponent: ReactNode;
  blockNextStep: boolean;
  blockALLStep: boolean;
  modalError: ModalError;
  payload: IPayloadProps;

  setPayload: Dispatch<SetStateAction<IPayloadProps>>;
  setModalError: Dispatch<SetStateAction<ModalError>>;

  updateUserProgress: (t: UserProgressProps) => void;
  setSubScreenNumber: (t: number) => void;
  setScreenNumber: (t: number) => void;
  setShowScreen: (progress: Array<UserProgressProps>, section: number, subSection: number) => void;
  unMountUserProgress: () => void;
  advanceStep: (prog: Array<UserProgressProps>, section: number, subSection: number) => void;
  backStep: (prog: Array<UserProgressProps>, section: number, subSection: number) => void;
  updateStateSubSection: (
    section: UserProgressProps,
    subSec: number,
    state: UserProgressScreensProps
  ) => UserProgressProps;
  skipStep: (prog: Array<UserProgressProps>, atualSubScreen: number, atualScreen: number) => void;
  setAndRederTopScreen: (
    prog: Array<UserProgressProps>,
    currentSec: number,
    currentSub: number
  ) => void;
  setBlockNextStep: (t: boolean) => void;
  updateStateManually: (
    prog: Array<UserProgressProps>,
    sec: number,
    sub: number,
    state: UserProgressScreensProps
  ) => void;
};

export type UserProgressScreensProps =
  | "nothing"
  | "advanced"
  | "visualized"
  | "unnecessary"
  | "skiped";

type OnboardingContextProps = {
  children: ReactNode;
};

export type ScreensProps = {
  name: string;
  element: ReactNode;
  type: UserProgressScreensProps;
  required?: boolean;
};

export type UserProgressProps = {
  section: number;
  screens: Array<ScreensProps> | undefined;
};

type ModalError = {
  hasError: boolean;
  title: string;
  body: string;
  buttonText: string;
};

const OnboardingContext = createContext({} as OnboardingContextData);

function OnboardingProvider({ children }: OnboardingContextProps) {
  // Define qual section esta ativa no momento
  const [screenNumber, setScreenNumber] = useState<number>(1);

  // Define qual a tela da subsessão esta sendo exibida
  const [subScreenNumber, setSubScreenNumber] = useState<number>(0);

  // Bloqueia o avanço entre passos
  const [blockNextStep, setBlockNextStep] = useState<boolean>(false);

  // Bloqueia o avanço entre passos e a pulo também
  const [blockALLStep, setBlockALLStep] = useState<boolean>(false);

  // Diz ao app Onboarding qual tela deve ser mostrada
  const [screenComponent, setScreenComponent] = useState<ReactNode>(null);

  // Gerencia o progresso do usuário
  const [userProgress, setUserProgress] = useState<Array<UserProgressProps>>([]);

  // Isso é o payload que no fim do fluxo será enviado
  const [payload, setPayload] = useState<IPayloadProps>();

  // Diz ao Template se existem erros e qual erro exibir
  const [modalError, setModalError] = useState<ModalError>({
    title: "Algo deu errado!",
    hasError: false,
    buttonText: "",
    body: "",
  } as ModalError);

  // Devolve o tamnho de uma seção
  const getLengthSection = (
    progress: Array<UserProgressProps> | undefined,
    sectionNumb: number
  ) => {
    if (!progress) return 0;
    const section = progress.find((section) => section.section === sectionNumb);
    if (section && section.screens) return section.screens.length;
    return 0;
  };

  // Isto devolve o estado de uma tela
  const getStateScreen = (
    progress: Array<UserProgressProps> | undefined,
    sectionNumb: number,
    subSectionNumb: number
  ) => {
    if (!progress) return undefined;
    const section = progress.find((section) => section.section === sectionNumb);
    if (section && section.screens) {
      const subSection = section.screens[subSectionNumb];
      return subSection.type;
    }
    return undefined;
  };

  // Isto atualiza uma subseção com o valor do tipo passado
  const updateStateSubSection = (
    section: Array<ScreensProps> | undefined,
    subSec: number,
    state: UserProgressScreensProps
  ) => {
    return section?.map((e, i) => {
      if (e.type === "visualized") e.type = "nothing";
      if (state === "visualized" && e.type != "nothing") return e;
      return i === subSec ? ({ ...e, type: state } as ScreensProps) : e;
    }) as Array<ScreensProps>;
  };

  // Isto atualiza o progresso do usuário
  const updateSectionOnProgress = (newSubSection: Array<ScreensProps>, section: number) => {
    setUserProgress((prevState) =>
      prevState.map((e) => (e.section === section ? { ...e, screens: newSubSection } : e))
    );
  };

  // Isto faz os cálculos e devolve a proxima seção e tela a ser renderizada
  const handleSectionsAndSubSections = (
    progress: Array<UserProgressProps>,
    sec: number,
    sub: number
  ) => {
    const foundSection = progress.find((e) => e.section === sec);
    if (foundSection && foundSection.screens && sec <= progress.length) {
      sub++;
      if (sub === foundSection.screens.length) {
        sec++;
        sub = 0;
        setScreenNumber(sec);
      }
      setSubScreenNumber(sub);
    }
    return [sec, sub];
  };

  // Isto procura dentro das telas a qual será renderizada
  const handleScreenComponent = (progress: Array<UserProgressProps>, sec: number, sub: number) => {
    const section = progress.find((e) => e.section === sec);
    if (section && section.screens && section.screens[sub])
      setScreenComponent(section.screens[sub].element);
  };

  // Isto encontra a última tela que ainda não houve iteração
  const findLastScreen = (section: UserProgressProps) => {
    const screens = section.screens;
    if (screens) {
      const index = screens.findIndex(
        (screen) => screen.type != "unnecessary" && screen.type != "advanced"
      );
      setSubScreenNumber(index);
      return screens[index];
    }
  };

  // Isto cria uma nova tela e coloca no estate
  const updateUserProgress = useCallback((progress: UserProgressProps) => {
    setUserProgress((prevState) => [...prevState, progress]);
  }, []);

  // Isto define manualmente o estado de uma tela e salta para a próxima
  const updateStateManually = useCallback(
    (
      prog: Array<UserProgressProps>,
      currentSec: number,
      currentSubSec: number,
      state: UserProgressScreensProps
    ) => {
      const findSection = prog.find((e) => e.section === currentSec);
      if (findSection && findSection.screens && findSection.screens[currentSubSec]) {
        let newSubSec = updateStateSubSection(findSection.screens, currentSubSec, state);
        newSubSec = updateStateSubSection(newSubSec, currentSubSec + 1, "visualized");
        updateSectionOnProgress(newSubSec, currentSec);
        const [sec, sub] = handleSectionsAndSubSections(prog, currentSec, currentSubSec);
        handleScreenComponent(prog, sec, sub);
      }
    },
    []
  );

  // Isto remove as configs de progresso caso o componente seja desmontado
  const unMountUserProgress = useCallback(() => setUserProgress(() => []), []);

  // Isto faz a troca entre passos e define qual componente exibir
  const advanceStep = useCallback(
    (progress: Array<UserProgressProps>, currentSection: number, currentSub: number) => {
      if (blockNextStep) return;

      const foundSection = progress.find((e) => e.section === currentSection);
      // Se temos uma seção atualizamos
      if (foundSection && foundSection.screens) {
        // Avançamos a anterior e visualizamos a próxima tela
        let stateScreens = updateStateSubSection(foundSection.screens, currentSub, "advanced");
        // Definimos qual tela mostrar
        const [sec, sub] = handleSectionsAndSubSections(progress, currentSection, currentSub);
        stateScreens = updateStateSubSection(stateScreens, currentSub + 1, "visualized");
        updateSectionOnProgress(stateScreens, currentSection);
        // Se trocamos de seção colocamos a primeira como visualizada
        if (sec !== currentSection) {
          const foundSection = progress.find((e) => e.section === sec);
          stateScreens = updateStateSubSection(foundSection?.screens, sub, "visualized");
          updateSectionOnProgress(stateScreens, sec);
        }
        handleScreenComponent(progress, sec, sub);
      }
    },
    []
  );

  const backStep = useCallback((progress: Array<UserProgressProps>, currentSection: number, currentSub: number) => {
    if (blockNextStep) return;

    const foundSection = progress.find((e) => e.section === currentSection);
    // Se temos uma seção atualizamos
    if (foundSection && foundSection.screens) {
      // Marcamos a atual tela como visualizada
      const state = getStateScreen(progress, currentSection, currentSub);

      // Somente se o estado de uma tela for "nothing" que alteramos
      if (state && state === "nothing") {
        let stateScreens = updateStateSubSection(foundSection.screens, currentSub, "visualized");
        updateSectionOnProgress(stateScreens, currentSection);
      }

      // Se a Subsessão for maior que zero, podemos voltar sem problemas
      if (currentSub > 0) {
        setSubScreenNumber(() => currentSub - 1);
        handleScreenComponent(progress, currentSection, currentSub - 1);
        // Agora se ja estamos na primeira tela da seção vamos tentar voltar a seção
      } else if (currentSection - 1 > 0) {
        setScreenNumber(() => currentSection - 1);
        const subSectionUpdated = getLengthSection(progress, currentSection - 1) - 1;
        setSubScreenNumber(() => subSectionUpdated);
        handleScreenComponent(progress, currentSection - 1, subSectionUpdated);
      }
    }
  }, []);

  // Isto pula passos e decide quantos passos devem ser pulados
  const skipStep = useCallback((prog: Array<UserProgressProps>, currentSec: number, currentSub: number) => {
    if (blockNextStep) return;
    const section = prog.find((e) => e.section === currentSec);
    if (section && section.screens) {
      // Avançamos a anterior e visualizamos a próxima tela
      let stateScreens = updateStateSubSection(section.screens, currentSub, "skiped");
      let auxCurrentSub = currentSub; // Para não manipular errado usamos uma auxiliar
      if (stateScreens && stateScreens[auxCurrentSub]) {
        let isRequired = stateScreens[auxCurrentSub].required;
        // Enquanto existirem telas requeridas pulamos
        while (isRequired) {
          auxCurrentSub++;
          stateScreens = updateStateSubSection(stateScreens, auxCurrentSub, "skiped");
          if (!stateScreens[auxCurrentSub + 1]) isRequired = false;
          else isRequired = stateScreens[auxCurrentSub + 1].required;
        }
        // Quando chegamos aqui definimos a próxima tela como visualizada
        const [sec, sub] = handleSectionsAndSubSections(prog, currentSec, auxCurrentSub);
        stateScreens = updateStateSubSection(stateScreens, sub, "visualized");
        updateSectionOnProgress(stateScreens, currentSec);
        // Se trocamos de seção colocamos a primeira como visualizada
        if (sec !== currentSec) {
          const foundSection = prog.find((e) => e.section === sec);
          stateScreens = updateStateSubSection(foundSection?.screens, sub, "visualized");
          updateSectionOnProgress(stateScreens, sec);
        }
        // Depois de feitos os Saltos necessários renderizamos a tela certa
        handleScreenComponent(prog, sec, sub);
      }
    }
  }, []);

  // Isto define manualmente que em uma seção deve mostrar a primeira tela
  const setAndRederTopScreen = useCallback(
    (prog: Array<UserProgressProps>, currentSec: number, currentSub: number) => {
      if (blockNextStep || blockALLStep) return;

      const findSection = prog.find((e) => e.section === currentSec) as UserProgressProps;
      // tentamos pegar a última seção, se for a primeira seção pegamos ela
      const lastSecPosition = currentSec === 1 ? currentSec : currentSec - 1;
      const lastSection = prog.find((e) => e.section === lastSecPosition);
      if (lastSection && lastSection.screens && findSection.screens) {
        const lastScreen = lastSection.screens[lastSection.screens.length - 1];
        // Se a ultima seção foi finalizada ou se é a seção 1, entramos
        if (currentSec === 1 || lastScreen.type !== "nothing") {
          // Isto garante que se nao houver tela pegamos a primeira
          const auxSub = currentSub > 0 ? currentSub : 0;
          const updateSub = updateStateSubSection(findSection.screens, auxSub, "visualized");
          updateSectionOnProgress(updateSub, currentSec);
          const screen = findLastScreen(findSection);
          if (screen) setScreenComponent(screen?.element);
          setBlockALLStep(false);
        } else {
          // Se chegamos aqui, então a seção anterior não foi completada
          setBlockALLStep(true);
        }
      }
    },
    []
  );

  // Isto define manualmente uma Tela a ser mostrada
  const setShowScreen = useCallback(
    (progress: Array<UserProgressProps>, section: number, subSection: number) => {
      const arrSection = progress.find((pr) => pr.section === section);
      if (arrSection?.screens) setScreenComponent(arrSection.screens[subSection].element);
      if (arrSection && arrSection.screens) {
        // Se tenho uma tela marco como visualizada
        const updatedSection = updateStateSubSection(arrSection.screens, subSection, "visualized");
        updateSectionOnProgress(updatedSection, section);
      }
      setScreenNumber(section);
      setSubScreenNumber(subSection);
    },
    []
  );

  useEffect(() => {
    const payloadData = localStorage.getItem(Constants.LOCALSTORAGE_PAYLOAD);
    if (payloadData) setPayload(() => JSON.parse(payloadData));
  }, []);

  return (
    <OnboardingContext.Provider
      value={
        {
          setAndRederTopScreen,
          unMountUserProgress,
          updateStateManually,
          updateUserProgress,
          setSubScreenNumber,
          setBlockNextStep,
          setScreenNumber,
          subScreenNumber,
          screenComponent,
          setModalError,
          setShowScreen,
          blockNextStep,
          userProgress,
          screenNumber,
          blockALLStep,
          advanceStep,
          setPayload,
          modalError,
          skipStep,
          backStep,
          payload,
        } as OnboardingContextData
      }
    >
      {children}
    </OnboardingContext.Provider>
  );
}

export { OnboardingProvider, OnboardingContext };
