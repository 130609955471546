import { useField } from "@unform/core";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Rail, Handles, Ticks, Tracks, Slider } from "react-compound-slider";
import { Handle } from "./Handle";
import "./style.css";
import { Tick } from "./Tick";
import { Track } from "./Track";

interface SliderProps {
  listOptions: object;
  name: string;
  rain_initial: number;
  dry_initial: number;
  disable?: boolean;
}

const sliderStyle = {
  position: "relative",
  width: "100%",
} as CSSProperties;

export const SliderComponent = ({
  name,
  listOptions,
  rain_initial,
  dry_initial,
  disable = false,
}: SliderProps) => {
  const [boolSecaAntesDaChuva, setboolSecaAntesDaChuva] =
    useState<boolean>(false);
  const [values, setValues] = useState<any>([rain_initial, dry_initial]);
  const [inicioChuva, setinicioChuva] = useState(3);
  const [inicioSeca, setinicioSeca] = useState(8);
  const { fieldName, registerField } = useField(name);
  const selectRef = useRef<any>(null);
  const domain: number[] = [0, 11];
  const valMap: any = listOptions;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: () => {
        return { rain_initial: inicioChuva, dry_initial: inicioSeca };
      },
      setValue: (_, value: any) => {
        if (value) setValues(() => [value.rain_initial, value.dry_initial]);
      },
    });
  }, [fieldName, registerField, inicioChuva, inicioSeca]);

  return (
    <Slider
      mode={1}
      step={1}
      domain={domain}
      rootStyle={sliderStyle}
      values={values}
    >
      {/* Tracejado na barra indicando os meses */}
      <Rail>
        {({ getRailProps }) => (
          <div
            className={boolSecaAntesDaChuva ? "lineBlue" : "lineGray"}
            style={{
              cursor: disable ? "default" : "pointer",
            }}
            {...(!disable && getRailProps())}
          />
        )}
      </Rail>
      {/* Marcação do inicio da chuva e inico da seca */}
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map((handle) => (
              <Handle
                key={handle.id}
                handle={handle}
                domain={domain}
                getHandleProps={getHandleProps}
                disable={disable}
              />
            ))}
          </div>
        )}
      </Handles>
      {/* Define a cor da barra indicando onde começa e termina a chuva e a seca respectivamente*/}
      <Tracks right={false} left={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(
              ({ id, source, target }) => (
                boolSecaAntesDaChuva
                  ? (setinicioChuva(target.value), setinicioSeca(source.value))
                  : (setinicioChuva(source.value), setinicioSeca(target.value)),
                target.id == "$$-0"
                  ? setboolSecaAntesDaChuva(true)
                  : setboolSecaAntesDaChuva(false),
                (
                  <Track
                    key={id}
                    source={source}
                    target={target}
                    getTrackProps={getTrackProps}
                    boolColor={boolSecaAntesDaChuva}
                    disable={disable}
                  />
                )
              )
            )}
          </div>
        )}
      </Tracks>
      {/* Demarcação dos meses na barra */}
      <Ticks count={11}>
        {({ ticks }) => (
          <div className="slider-ticks">
            {ticks.map((tick) => (
              <Tick
                key={tick.id}
                format={(id: number) => valMap[id]}
                tick={tick}
                count={ticks.length}
                disable={disable}
              />
            ))}
          </div>
        )}
      </Ticks>
    </Slider>
  );
};
