import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as BackIcon } from "../../../../assets/svg/icons/back.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ReactComponent as GrassIcon } from "../../../../assets/svg/icons/grass.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";

interface InfoMoveBatchProps {
  task: ITaskMoveBatch | undefined;
}

export default function InfoMoveBatch(task: InfoMoveBatchProps) {
  const [taskMoveBatch, setTaskMoveBatch] = useState<ITaskMoveBatch>();
  useEffect(() => {
    setTaskMoveBatch(task.task)
  }, []);


  return (
    <>
      <div >
        <span>No lote</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <AnimalsIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskMoveBatch?.batch_initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <div >
        <span>Do pasto</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <span>{taskMoveBatch?.source_micro_area_full_initials}</span>
          <div className="labelInitialTaskInfo">
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
        <BackIcon width={30} style={{ transform: "rotate(270deg)" }} />
        <GrassIcon width={30} />
      </div>

      <div >
        <span>Para o pasto</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <span>{taskMoveBatch?.destination_micro_area_full_initials}</span>
          <div className="labelInitialTaskInfo">
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      {taskMoveBatch?.categories.map((category, index) => (
        <div key={index}>
          <div className="lot__title--category" style={{ marginTop: 0 }}>{category.category_description.name}</div>
          <div className="lot__subtitle--category">{category.category_description.age_description}</div>
          <CounterInputEdit isDisabled defaultValue={category.quantity} control={undefined} isInline />
        </div>
      ))}
    </>
  )
}