import { CSSProperties, InputHTMLAttributes, useEffect, useId, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import "./styles.css";

import ReactInputMask from "react-input-mask";
import React from "react";

type THEME_BUTTON = "normal" | "none" | "read-only";

interface ITextComponent extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  control: any;
  theme: THEME_BUTTON;
  labelPrefix?: string;
  prefixPosition?: "left" | "right";
  mask?: string | (string | RegExp)[];
  overrideStyles?: {
    container?: CSSProperties;
    contentInput?: CSSProperties;
    prefixLabel?: CSSProperties;
    input?: CSSProperties;
  }
  errorText?: any;
}

export function Input({ labelPrefix, label, control, name, theme, mask, errorText, overrideStyles, prefixPosition = 'left', ...props }: ITextComponent) {
  const inputUniqueId = useId();
  const [inputContentTheme, setInputContentTheme] = useState<THEME_BUTTON>('none');
  const [fieldError, setFieldError] = useState<string | null>(null);
  useEffect(() => setInputContentTheme(() => theme), [theme])
  return (
    <>
      <div className="rhf__container--input" style={overrideStyles?.container}>
        <label
          htmlFor={`${inputUniqueId}-rhf-text-input`}
          className="rhf__header--label">{label}</label>
        <div className={`rhf__input--content${(!!fieldError || errorText) ? "Error" : ""} rhf__input--theme-${inputContentTheme} rhf__prefix-on-${prefixPosition}`} style={overrideStyles?.contentInput}>
          <label htmlFor={`${inputUniqueId}-rhf-text-input`}
            className='rhf__prefix--label' style={overrideStyles?.prefixLabel}>{labelPrefix}</label>
          <Controller
            name={name as string}
            control={control}
            render={({ field: { name, onChange, value, onBlur }, fieldState: { }, formState: { errors } }) => {
              setFieldError(!!errors[name] ? errors[name]?.message as string : null)
              return (
                <ReactInputMask
                  mask={mask || ''}
                  //@ts-ignore
                  maskChar={null}
                  id={`${inputUniqueId}-rhf-text-input`}
                  style={overrideStyles?.input}
                  autoComplete='off'
                  {...props}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                />
              )
            }}
          />
        </div>
      </div>
      {(errorText || !!fieldError) && (
        <small className="textInput--small-error animation__fadeIn">
          {errorText || fieldError}
        </small>
      )}
    </>
  );
}
