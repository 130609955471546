import { CSSProperties, useEffect, useRef, useState } from 'react'

import { FooterControlls } from '../../../../components/Onboarding/Template/FooterControlls/Index'
import { useOnboarding } from '../../../../hooks/useOnboarding'
import { Select } from '../../../../components/Inputs/Select/Index'
import { Form } from '@unform/web'
import { Scope } from '@unform/core'
import './styles.css'
import * as yup from 'yup'
import { AlertModal } from '../../../../components/Modal/Alert/Index'
import { SimpleButtonClose } from '../../../../components/Modal/Contents/SimpleButtonClose/Index'
import { TitleModal } from '../../../../components/Modal/Contents/TitleModal/Index'
import { TextModal } from '../../../../components/Modal/Contents/TextModal/Index'
import { ButtonRedirect } from '../../../../components/Modal/Error/ButtonRedirect/Index'
import { PastureUnavailable } from '../../../../components/Onboarding/Register/PasturesUnavailable/Index'
import Constants from '../../../../constants/Index'
import { IMicroAreas } from '../../../../@types/app/IMicroArea'
import { IPayloadProps } from '../../../../@types/Onboarding/IPayload'
import axios from '../../../../services/axios'

const microAreaUnavailableSchema = yup.object().shape({
  microAreaUnavailable: yup.array().of(
    yup.object().shape({
      reason: yup
        .object({
          label: yup.string(),
          value: yup
            .string()
            .required('É necessário um motivo para não utilizar o pasto.'),
        })
        .required('É necessário um motivo para não utilizar o pasto.')
        .nullable(),
    }),
  ),
})

const contentStyleAlert = {
  width: '335px',
  height: '237px',
  borderRadius: '20px',
} as CSSProperties

interface PasturesUnavailableProps {
  onlyRead?: boolean
}

export function PasturesUnavailable({
  onlyRead = false,
}: PasturesUnavailableProps) {
  const formRef = useRef<HTMLFormElement>()
  const mainRef = useRef<HTMLElement>(null)
  const { payload, setPayload, setBlockNextStep } = useOnboarding()
  const [farms, setFarms] = useState<any>([])
  const [areas, setAreas] = useState<any>([])
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [listMicroAreasUnavailabe, setListMicroAreasUnavailabe] =
    useState<IMicroAreas>([])
  const [options, setOptions] = useState([])

  async function getMicroAreasId(payloadObj: any) {
    const listMicro = await payloadObj.micro_areas
      .map((micro: any) => micro.uuid)
      .flat()
    const listUsedMicroAreas = await payloadObj.batches
      .map((batch: any) => batch.micro_area_route_ids)
      .flat()
    const availableMicroAreas = await listMicro.filter(
      (list: any) => !listUsedMicroAreas.includes(list),
    )
    return availableMicroAreas
  }

  async function getListMicroAreas() {
    const listMicroAreas = await payload.micro_areas
      .map((micro: any) => micro)
      .flat()
    const listIdsMicroArea = await getMicroAreasId(payload)
    const listMicro = await listMicroAreas.filter((micro) =>
      listIdsMicroArea.includes(micro.uuid),
    )
    setListMicroAreasUnavailabe(listMicro)
  }

  async function validateForm(showModal: boolean) {
    const formData = formRef.current?.getData() || null
    formRef.current?.setErrors({})
    const errors = {} as any
    let isValide = false
    if (formData) {
      try {
        // Isso valida o formulário
        await microAreaUnavailableSchema.validate(formData, {
          abortEarly: false,
        })
        isValide = true
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach((e) => {
            errors[(e.path as string).replace('.value', '')] = e.message
          })
        }
        if (showModal) setModalVisible(true)
      } finally {
        if (formRef && formRef.current) {
          formRef.current.setErrors(errors)
        }
        setBlockNextStep(!isValide)
        return isValide
      }
    }
    return false
  }

  async function savePayload() {
    const formData = formRef.current?.getData()?.microAreaUnavailable
    if (formData) {
      const data = formData.map((microArea: any) => {
        return {
          micro_area_id: microArea?.microAreaId,
          reason_id: microArea?.reason,
        }
      })
      setPayload((payload) => {
        const response = { ...payload, reason: data }
        localStorage.setItem(
          Constants.LOCALSTORAGE_PAYLOAD,
          JSON.stringify(response),
        )
        return response
      })
    }
  }

  useEffect(() => {
    if (payload && payload.reason) {
      listMicroAreasUnavailabe.map((microArea) => microArea.uuid)
      const mapUuid = new Map(
        payload.reason.map((value) => {
          return [value.micro_area_id, value.reason_id]
        }),
      )

      formRef.current?.setData({
        microAreaUnavailable: listMicroAreasUnavailabe.map((value) => {
          return { reason: mapUuid.get(value.uuid) || null }
        }),
      })
    }
  }, [listMicroAreasUnavailabe])

  async function handleForm() {
    if (await validateForm(true)) {
      savePayload()
      return true
    }
    return false
  }

  async function getMicroAreasReasons() {
    return await axios.get('/micro-areas/reasons').then(({ data }) => {
      setOptions(
        data.map((option: any) => ({
          value: option.id,
          label: option.description,
        })),
      )
    })
  }

  useEffect(() => {
    getListMicroAreas()
    getMicroAreasReasons()
    setFarms(payload.farms)
    setAreas(payload.areas)
    setTimeout(() => validateForm(false), 200)
  }, [])

  return (
    <>
      <main ref={mainRef as any}>
        <section className="pasturesUnavailable__container">
          <h1 id="pageStart">Pastos não utilizados</h1>
          <Form ref={formRef as any} onSubmit={() => {}}>
            {listMicroAreasUnavailabe.length > 0 ? (
              listMicroAreasUnavailabe.map((microArea, key) => {
                const area = areas.filter(
                  (area: any) => area.uuid == microArea.area_id,
                )
                const farm = farms.filter(
                  (farm: any) => farm.uuid == area[0].farm_id,
                )
                const initial =
                  farm[0].initials +
                  '-' +
                  area[0].initials +
                  '-' +
                  microArea.initials

                return (
                  <Scope key={key} path={`microAreaUnavailable[${key}]`}>
                    <PastureUnavailable
                      initial={initial}
                      indexComponent={key}
                      onAnywhereChanges={() => validateForm(false)}
                      microAreaId={microArea.uuid}
                      microAreasReasons={options}
                    />
                  </Scope>
                )
              })
            ) : (
              <div>
                <span>Todos os pastos estão sendo utilizados</span>
              </div>
            )}
          </Form>
        </section>
      </main>

      <AlertModal visible={modalVisible} contentStyle={contentStyleAlert}>
        <section className="modalEdit__alert">
          <SimpleButtonClose
            onClick={() => {
              setModalVisible(!modalVisible)
            }}
          />
          <TitleModal>Pastos não utilizados!</TitleModal>
          <TextModal>
            Alguns pastos ficaram de fora, selecione um motivo para cada um
            deles.
          </TextModal>
          <ButtonRedirect onClick={() => setModalVisible(!modalVisible)}>
            VOLTAR
          </ButtonRedirect>
        </section>
      </AlertModal>

      {!onlyRead && (
        <footer>
          <FooterControlls nextStepEvent={handleForm} />
        </footer>
      )}
    </>
  )
}
