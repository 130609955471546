const BASE_URL = 'https://servicodados.ibge.gov.br/api/v1'

import axios from 'axios'

const api = axios.create({
  baseURL: 'https://6bcx0qh41j.execute-api.us-east-1.amazonaws.com/dev',
})

export const fetchStatesUF = () => {
  const url = `${BASE_URL}/localidades/estados`
  return api(url).then(({ data }) => {
    const response = data.map((e: any) => e.sigla)
    return response.sort()
  })
}

export const fetchStateCities = (state: string) => {
  const url = `${BASE_URL}/localidades/estados/${state}/municipios`
  return api(url).then(({ data }) => {
    const response = data.map((e: any) => ({ id: e.id, nome: e.nome }))
    return response.sort()
  })
}
