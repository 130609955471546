import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as LogoPastejoIcon } from "../../../assets/svg/logo/logoPastejo.svg";
import "../styles.css";
import ChartBar from "../../Graph/Bar";
import ChartPie from "../../Graph/Pie";
import ChartBarLine from "../../Graph/BarLine";
import ChartBarDouble from "../../Graph/BarDouble";
import { GoogleMap, Polygon, Polyline } from "@react-google-maps/api";
import { ReactComponent as FarmIcon } from "../../../assets/svg/icons/farmIcon.svg";
import { ReactComponent as LocateIcon } from "../../../assets/svg/icons/point.svg";
import { useGoogleMaps } from "../../../hooks/useGoogleMaps";
import Constants from "../../../constants/Index";
import { IOptionProps } from "../../../@types/reactSelect/IOption";
import { splitArray } from "../../../utils/arrays/split";

interface PDFEvaluationProps {
    farm: IOptionProps;
    areas: IOptionProps[];
    dataReport: IReportGlobalPastureEvaluator;
}

export const PDFEvaluationGlobal = ({
    farm,
    areas,
    dataReport,
}: PDFEvaluationProps) => {
    const [mapPages, setMapPages] = useState(new Map<string, number>());
    const [summaryPages, setSummaryPages] = useState(0);
    const pageRef = useRef(null);
    const [pageHeight, setPageHeight] = useState(0);

    useEffect(() => {
        if (pageRef.current) {
            setPageHeight(1440);
        }
    }, [pageRef]);

    useEffect(() => {
        setSummaryPages(splitArray(Array.from(mapPages.keys()), 30).length)
    }, [mapPages]);

    const FooterPDF = ({
        pageSugest,
        light = false,
    }: any) => {
        const [page, setPage] = useState(1);
        const footerRef = useRef(null);

        useEffect(() => {
            if (footerRef.current && !pageSugest) {
                const componentTop = (footerRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setPage(currentPage);
            } else {
                setPage(pageSugest);
            }
        }, [footerRef, pageHeight]);

        return (
            <div className="pdfFooter" id="footer" ref={footerRef}>
                <div className="pdfLogoPastejo">
                    <LogoPastejoIcon />
                </div>
                <span style={{ color: light ? "#FFF" : "#000" }}>
                    {pageSugest ? pageSugest : page}
                </span>
            </div>
        );
    }

    const MicroAreaRelatorioPDFForragem1 = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`2 Forragem`, currentPage - 1)))
                setMapPages(new Map(mapPages.set(`2.1 Forragem primária`, currentPage - 1)))
            }
        }, [, pageHeight]);

        return (
            <>
                <div className="pdfTopicContainer" ref={resultadosRef}>
                    <h2 className="pdfMicroArea" style={{ width: 45 }}>2</h2>
                    <h2 className="pdfMicroArea">Forragem</h2>
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfTopicId">2.1</h2>
                    <h2 className="pdfTopic">Forragem primária</h2>
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"N° de pastos com e sem forragem avaliados"}
                        data={dataReport?.fodder_chart?.primary_fodder_details?.micro_areas_evaluated_quantity || []}
                    />
                    <ChartPie
                        label={"N° de pastos com e sem forragem avaliados"}
                        data={dataReport?.fodder_chart?.primary_fodder_details?.micro_areas_evaluated_percentage || []}
                    />
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"Avaliação de área útil"}
                        data={dataReport?.fodder_chart?.primary_fodder_details?.useful_area || []}
                    />
                    <ChartPie
                        label={"% de área útil com e sem forragem"}
                        data={dataReport?.fodder_chart?.primary_fodder_details?.usefulAreaPercentage || []}
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFForragem2 = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`2.2 Forragem secundária`, currentPage - 1)))
            }
        }, [pageHeight]);

        return (
            <>
                <div className="pdfTopicContainer" ref={resultadosRef}>
                    <h2 className="pdfTopicId">2.2</h2>
                    <h2 className="pdfTopic">Forragem secundária</h2>
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"N° de pastos com e sem forragem avaliados"}
                        data={dataReport?.fodder_chart?.secondary_fodder_details?.micro_areas_evaluated_quantity || []}
                    />
                    <ChartPie
                        label={"N° de pastos com e sem forragem avaliados"}
                        data={dataReport?.fodder_chart?.secondary_fodder_details?.micro_areas_evaluated_percentage || []}
                    />
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"Avaliação de área útil"}
                        data={dataReport?.fodder_chart?.secondary_fodder_details?.useful_area || []}
                    />
                    <ChartPie
                        label={"% de área útil com e sem forragem"}
                        data={dataReport?.fodder_chart?.secondary_fodder_details?.usefulAreaPercentage || []}
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFForragem3 = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`2.3 Forragem terciaria`, currentPage - 1)))
            }
        }, [pageHeight]);

        return (
            <>
                <div className="pdfTopicContainer" ref={resultadosRef}>
                    <h2 className="pdfTopicId">2.3</h2>
                    <h2 className="pdfTopic">Forragem terciaria</h2>
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"N° de pastos com e sem forragem avaliados"}
                        data={dataReport?.fodder_chart?.tertiary_fodder_details?.micro_areas_evaluated_quantity || []}
                    />
                    <ChartPie
                        label={"N° de pastos com e sem forragem avaliados"}
                        data={dataReport?.fodder_chart?.tertiary_fodder_details?.micro_areas_evaluated_percentage || []}
                    />
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"Avaliação de área útil"}
                        data={dataReport?.fodder_chart?.tertiary_fodder_details?.useful_area || []}
                    />
                    <ChartPie
                        label={"% de área útil com e sem forragem"}
                        data={dataReport?.fodder_chart?.tertiary_fodder_details?.usefulAreaPercentage || []}
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg2 = () => {
        return (
            <>
                <div className="containerGraph">
                    <ChartBar
                        label={"Número de tipos de forrageira por pastos"}
                        data={dataReport?.fodder_chart?.quantity_fodder || []}
                        fullWidth
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg3 = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`3 Gênero`, currentPage - 1)))
                setMapPages(new Map(mapPages.set(`4 Espécie`, currentPage - 1)))
            }
        }, [, pageHeight]);

        return (
            <>
                <div className="pdfTopicContainer" ref={resultadosRef}>
                    <h2 className="pdfMicroArea" style={{ width: 45 }}>3</h2>
                    <h2 className="pdfMicroArea">Gênero</h2>
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"Composição de área efetiva por gênero"}
                        data={dataReport?.fodder_genus_chart?.area_composition || []}
                    />
                    <ChartPie
                        label={"% de gênero de área efetiva"}
                        data={dataReport?.fodder_genus_chart?.genus_composition || []}
                    />
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfMicroArea" style={{ width: 45 }}>4</h2>
                    <h2 className="pdfMicroArea">Espécie</h2>
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"Composição de área efetiva por espécie"}
                        data={dataReport?.fodder_species_chart?.area_composition_by_species || []}
                        fullWidth
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg4 = () => {
        return (
            <>
                <div className="containerGraph">
                    <ChartPie
                        label={"% de espécies por área útil"}
                        data={dataReport?.fodder_species_chart?.species_percentage_by_useful_area || []}
                        fullWidth
                    />
                </div>

                <div className="containerGraph" >
                    <ChartPie
                        label={"% de espécie por área efetiva"}
                        data={dataReport?.fodder_species_chart?.effective_area_percentage_by_species || []}
                        fullWidth
                    />
                </div>
            </>
        )
    }

    const MicroAreaRelatorioPDFpg5 = () => {
        return (
            <>
                <div className="containerGraph" >
                    <ChartPie
                        label={"% de altura do capim por gênero Andropogon"}
                        data={dataReport?.fodder_species_chart?.height_percentage_by_andropogon}
                    />
                    <ChartPie
                        label={"% de altura do capim por gênero Brachiaria"}
                        data={dataReport?.fodder_species_chart?.height_percentage_by_brachiaria}
                    />
                </div>
                <div className="containerGraph" >
                    <ChartPie
                        label={"% de altura do capim por gênero Cynodon"}
                        data={dataReport?.fodder_species_chart?.height_percentage_by_cynodon}
                    />
                    <ChartPie
                        label={"% de altura do capim por gênero Panicum"}
                        data={dataReport?.fodder_species_chart?.height_percentage_by_panicum}
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg51 = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`5 Invasoras`, currentPage - 1)))
            }
        }, [, pageHeight]);

        return (
            <>
                <div className="containerGraph" >
                    <ChartPie
                        label={"% de altura do capim por gênero Pennisetum"}
                        data={dataReport?.fodder_species_chart?.height_percentage_by_pennisetum}
                    />
                </div>
                <div>
                    <div className="pdfTopicContainer" ref={resultadosRef}>
                        <h2 className="pdfMicroArea" style={{ width: 45 }}>5</h2>
                        <h2 className="pdfMicroArea">Invasoras</h2>
                    </div>

                    <div className="containerGraph">
                        <ChartBar
                            label={"Presença de invasora por área útil (ha)"}
                            data={dataReport?.invasive_chart?.presence_by_useful_area || []}
                        />
                        <ChartPie
                            label={"% de sem e com invasoras"}
                            data={dataReport?.invasive_chart?.invasive_percentage || []}
                        />
                    </div>
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg6 = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`6 Folha larga`, currentPage - 1)))
            }
        }, [, pageHeight]);

        return (
            <>
                <div className="containerGraph">
                    <ChartBar
                        label={"Presença de folha por área útil (ha)"}
                        data={dataReport?.invasive_chart?.leaf_presence_by_useful_area || []}
                    />

                    <ChartPie
                        label={"% de tipo de folha"}
                        data={dataReport?.invasive_chart?.leaf_type_percentage || []}
                    />
                </div>

                <div>
                    <div className="pdfTopicContainer" ref={resultadosRef}>
                        <h2 className="pdfMicroArea" style={{ width: 45 }}>6</h2>
                        <h2 className="pdfMicroArea">Folha larga</h2>
                    </div>

                    <div className="containerGraph">
                        <ChartBar
                            label={"Tipo de invasora por área útil (ha)"}
                            data={dataReport?.large_leaf_chart?.leaf_type_by_useful_area || []}
                        />
                        <ChartPie
                            label={"% de tipo de invasoras"}
                            data={dataReport?.large_leaf_chart?.invasive_percentage || []}
                        />
                    </div>
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFFolhaEstreita1 = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`7 Folha estreita`, currentPage - 1)))
            }
        }, [, pageHeight]);

        return (
            <>
                <div className="containerGraph">
                    <ChartBarLine
                        label={"% de cobertura da invasora por área útil (ha)"}
                        labelOptions={["Área útil (ha)", "% de cobertura de invasora"]}
                        optionsColumn={dataReport?.large_leaf_chart?.covering_percentage_by_useful_area
                            .filter((_: any, index: number) => index % 2 == 0)}
                        optionsLine={dataReport?.large_leaf_chart?.covering_percentage_by_useful_area
                            .filter((_: any, index: number) => index % 2 == 1)}
                        fullWidth
                    />
                </div>

                <div>
                    <div className="pdfTopicContainer" ref={resultadosRef}>
                        <h2 className="pdfMicroArea" style={{ width: 45 }}>7</h2>
                        <h2 className="pdfMicroArea">Folha estreita</h2>
                    </div>

                    <div className="containerGraph">
                        <ChartBar
                            label={"Nota de folha estreita por área útil (ha)"}
                            data={dataReport?.small_leaf_chart?.leaf_rating_by_useful_area || []}
                        />
                        <ChartPie
                            label={"% de nota de folha estreita"}
                            data={dataReport?.small_leaf_chart?.small_leaf_percentage_rating || []}
                        />
                    </div>
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFFolhaEstreita2 = () => {

        return (
            <>
                <div>
                    <div className="containerGraph">
                        <ChartBar
                            label={"Número de pastos x quantidade de tipos invasora"}
                            data={dataReport?.small_leaf_chart?.small_leaf_quantity || []}
                            fullWidth
                        />
                    </div>
                </div>
            </>
        );
    }


    const MicroAreaRelatorioPDFPragas = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`8 Pragas ou doenças`, currentPage - 1)))
            }
        }, [pageHeight]);

        return (
            <>
                <div className="pdfTopicContainer" ref={resultadosRef}>
                    <h2 className="pdfMicroArea" style={{ width: 45 }}>8</h2>
                    <h2 className="pdfMicroArea">Pragas ou doenças</h2>
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"Presença de pragas e doenças por área útil (ha)"}
                        data={dataReport?.plague_chart?.presence_by_useful_area || []}
                    />
                    <ChartPie
                        label={"% de presença de pragas e doenças"}
                        data={dataReport?.plague_chart?.presence_percentage || []}
                    />
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"Pragas e doenças por área útil (ha)"}
                        data={dataReport?.plague_chart?.plague_types_by_useful_area || []}
                    />
                    <ChartPie
                        label={"% de pragas e doenças"}
                        data={dataReport?.plague_chart?.plague_percentage_by_types || []}
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg9 = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`9 Aguada`, currentPage - 1)))
            }
        }, [, pageHeight]);

        return (
            <>
                <div className="pdfTopicContainer" ref={resultadosRef}>
                    <h2 className="pdfMicroArea" style={{ width: 45 }}>9</h2>
                    <h2 className="pdfMicroArea">Aguada</h2>
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"N° de pastos com e sem aguada"}
                        data={dataReport?.waterhole_chart?.micro_areas_waterhole_quantity || []}
                    />
                    <ChartPie
                        label={"% de com e sem aguada"}
                        data={dataReport?.waterhole_chart?.micro_areas_waterhole_percentage || []}
                    />
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"N° de pastos por tipo de aguada"}
                        data={dataReport?.waterhole_chart?.micro_areas_quantity_by_waterhole_type || []}
                    />
                    <ChartPie
                        label={"% de tipo de aguada"}
                        data={dataReport?.waterhole_chart?.micro_areas_quantity_by_waterhole_type_percentage || []}
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg10 = () => {
        return (
            <>
                <div className="containerGraph">
                    <ChartBar
                        label={"Nível de acesso bebedouro artificial"}
                        data={dataReport?.waterhole_chart?.access_level_bebedouro_artificial || []}
                    />
                    <ChartPie
                        label={"% de nível de acesso bebedouro artificial"}
                        data={dataReport?.waterhole_chart?.access_level_bebedouro_artificial_percentage || []}
                    />
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"Nível de acesso das cacimbas"}
                        data={dataReport?.waterhole_chart?.access_level_cacimba || []}
                    />
                    <ChartPie
                        label={"% de nível de acesso das cacimbas"}
                        data={dataReport?.waterhole_chart?.access_level_cacimba_percentage || []}
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg11 = () => {
        return (
            <>
                <div className="containerGraph">
                    <ChartBar
                        label={"Nível de acesso dos córregos"}
                        data={dataReport?.waterhole_chart?.access_level_corregos || []}
                    />
                    <ChartPie
                        label={"% de nível de acesso dos córregos"}
                        data={dataReport?.waterhole_chart?.access_level_corregos_percentage || []}
                    />
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"Nível de acesso das represas"}
                        data={dataReport?.waterhole_chart?.access_level_represas || []}
                    />
                    <ChartPie
                        label={"% de nível de acesso das represas"}
                        data={dataReport?.waterhole_chart?.access_level_represas_percentage || []}
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg12 = () => {
        return (
            <>
                <div className="containerGraph">
                    <ChartBarDouble
                        label={"Oferta de água na seca por tipo de aguada e pastos"}
                        labelOptions={["Seca", "Não seca"]}
                        optionsColumn1={dataReport?.waterhole_chart?.dry_season_offer_by_watering_type
                            .filter((_, index) => index % 2 == 0)}
                        optionsColumn2={dataReport?.waterhole_chart?.dry_season_offer_by_watering_type
                            .filter((_, index) => index % 2 == 1)}
                        fullWidth
                    />
                </div>

                <div>
                    <div className="pdfTableBorder">
                        <section className="pdfTableColumn" style={{ width: "100%" }}>
                            <div>Área de acesso bebedouro artificial (metros)</div>
                        </section>
                        <section className="pdfTableViewRows">
                            <span>{dataReport?.waterhole_chart?.total_area_access}</span>
                        </section>
                    </div>


                    <div className="containerGraph">
                        <ChartBar
                            label={"Necessidade de reparo bebedouro artificiais"}
                            data={dataReport?.waterhole_chart?.repair_necessity_bebedouro_artificial || []}
                        />
                        <ChartPie
                            label={"% de necessidade de reparo bebedouro artificiais"}
                            data={dataReport?.waterhole_chart?.repair_necessity_bebedouro_artificial_percentage || []}
                        />
                    </div>
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg13 = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`10 Depósito de suplementos`, currentPage - 1)))
                setMapPages(new Map(mapPages.set(`11 Cochos`, currentPage - 1)))
            }
        }, [, pageHeight]);

        return (
            <>
                <div className="pdfTopicContainer" ref={resultadosRef}>
                    <h2 className="pdfMicroArea" style={{ width: 45 }}>10</h2>
                    <h2 className="pdfMicroArea">Depósito de suplementos</h2>
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"N° de pastos com e sem depósitos"}
                        data={dataReport?.supplement_chart?.micro_areas_quantity || []}
                    />
                    <ChartPie
                        label={"% de pastos com e sem depósitos"}
                        data={dataReport?.supplement_chart?.micro_areas_quantity_percentage || []}
                    />
                </div>

                <div className="pdfTableBorder">
                    <section className="pdfTableColumn" style={{ width: "100%" }}>
                        <div>Capacidade média dos depósitos de suplemento (kg)</div>
                    </section>
                    <section className="pdfTableViewRows">
                        <span>{dataReport?.supplement_chart?.total_capacity || []}</span>
                    </section>
                </div>

                <div className="pdfTopicContainer">
                    <h2 className="pdfMicroArea" style={{ width: 45 }}>11</h2>
                    <h2 className="pdfMicroArea">Cochos</h2>
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"N° de pastos com e sem cochos"}
                        data={dataReport?.trough_chart?.micro_areas_trough_quantity || []}
                    />
                    <ChartPie
                        label={"% de pastos com e sem cochos"}
                        data={dataReport?.trough_chart?.micro_areas_trough_quantity_percentage || []}
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg14 = () => {
        return (
            <>
                <div className="containerGraph">
                    <ChartBar
                        label={"Cochos com e sem cobertura"}
                        data={dataReport?.trough_chart?.trough_coverage || []}
                    />
                    <ChartPie
                        label={"% de cochos com e sem cobertura"}
                        data={dataReport?.trough_chart?.trough_coverage_percentage || []}
                    />
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"N° de pastos por tipo de cocho"}
                        data={dataReport?.trough_chart?.micro_areas_by_trough_type || []}
                    />
                    <ChartPie
                        label={"% de tipo de cocho"}
                        data={dataReport?.trough_chart?.micro_areas_by_trough_type_percentage || []}

                    />
                </div>

                <span className="titleGraph" style={{ margin: 0 }}>Capacidade total de suplementação da fazenda</span>
                <div className="containerGraph">
                    <div className="pdfTableBorder" style={{ width: "100%" }}>
                        <section className="pdfTableColumn">
                            <div>Tipos de cocho</div>
                            <div>Comprimento (metros)</div>
                            <div>Largura (metros)</div>
                            <div>Capacidade (kg)</div>
                        </section>

                        {dataReport?.trough_chart?.farm_total_capacity.map((el, index) => {
                            return (
                                <section className="pdfTableViewRows" key={index}>
                                    <div className={`pdfTableRow ${index % 2 == 0 ? "bg_white" : "bg_gray"}`}>
                                        <div>{el.type}</div>
                                        <div>{el.length}</div>
                                        <div>{el.width}</div>
                                        <div>{el.capacity}</div>
                                    </div>
                                </section>
                            )
                        })}
                    </div>
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg15 = () => {
        return (
            <>
                <div className="containerGraph">
                    <ChartBar
                        label={"Tipo de acesso dos cochos"}
                        data={dataReport?.trough_chart?.trough_access_type || []}
                    />
                    <ChartPie
                        label={"% de acesso dos cochos"}
                        data={dataReport?.trough_chart?.trough_access_type_percentage || []}
                    />
                </div>
                <div className="containerGraph">
                    <ChartBarDouble
                        label={"Necessidade de reparo de cochos por tipo"}
                        labelOptions={["Necessita reparo", "Não necessita reparo"]}
                        optionsColumn1={dataReport?.trough_chart?.repair_necessity_by_type
                            .filter((_, index) => index % 2 == 0)}
                        optionsColumn2={dataReport?.trough_chart?.repair_necessity_by_type
                            .filter((_, index) => index % 2 == 1)}
                        fullWidth
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg16 = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`12 Cercas`, currentPage - 1)))
            }
        }, [, pageHeight]);

        return (
            <>
                <div className="containerGraph">
                    <ChartBar
                        label={"Nível de reparo dos cochos"}
                        data={dataReport?.trough_chart?.trough_repair_level || []}
                    />

                    <ChartPie
                        label={"% de tipo de reparo"}
                        data={dataReport?.trough_chart?.trough_repair_level_percentage || []}
                    />
                </div>

                <div>
                    <div className="pdfTopicContainer" ref={resultadosRef}>
                        <h2 className="pdfMicroArea" style={{ width: 45 }}>12</h2>
                        <h2 className="pdfMicroArea">Cercas</h2>
                    </div>

                    <div className="containerGraph">
                        <ChartBar
                            label={"Tipo de cerca por pasto"}
                            data={dataReport?.fence_chart?.fence_type_by_micro_areas || []}
                        />
                        <ChartPie
                            label={"% de tipo de cerca"}
                            data={dataReport?.fence_chart?.fence_type_by_micro_areas_percentage || []}
                        />
                    </div>
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg17 = () => {
        return (
            <>
                <div className="containerGraph">
                    <ChartBar
                        label={"Material da cerca por pastos"}
                        data={dataReport?.fence_chart?.fence_material_by_micro_areas || []}
                    />
                    <ChartPie
                        label={"% de tipo de cerca"}
                        data={dataReport?.fence_chart?.fence_material_by_micro_areas_percentage || []}
                    />
                </div>

                <div className="pdfTableBorder">
                    <section className="pdfTableColumn" style={{ width: "100%" }}>
                        <div>Média de quantidade de fios das cercas</div>
                    </section>
                    <section className="pdfTableViewRows">
                        <span>{Number(dataReport?.fence_chart?.wire_median_quantity).toFixed(1)} fios</span>
                    </section>
                </div>

                <div className="containerGraph">
                    <ChartBar
                        label={"Necessidade de reparo de cercas por pastos"}
                        data={dataReport?.fence_chart?.repair_necessity_by_micro_areas || []}
                    />
                    <ChartPie
                        label={"% de necessidade de reparo das cercas"}
                        data={dataReport?.fence_chart?.repair_necessity_by_micro_areas_percentage || []}
                    />
                </div>
            </>
        );
    }

    const MicroAreaRelatorioPDFpg18 = () => {
        const resultadosRef = useRef(null);

        useEffect(() => {
            if (resultadosRef.current) {
                const componentTop = (resultadosRef.current as HTMLElement)?.offsetTop;
                const currentPage = Math.floor(componentTop / pageHeight);
                setMapPages(new Map(mapPages.set(`13 Relevo`, currentPage - 1)))
                setMapPages(new Map(mapPages.set(`14 Solo`, currentPage - 1)))
            }
        }, [, pageHeight]);

        return (
            <>
                <div>
                    <div className="pdfTopicContainer" ref={resultadosRef}>
                        <h2 className="pdfMicroArea" style={{ width: 45 }}>13</h2>
                        <h2 className="pdfMicroArea">Relevo</h2>
                    </div>
                    <div className="containerGraph">
                        <ChartBar
                            label={"Tipo de relevo por pastos"}
                            data={dataReport?.terrain_chart?.terrain_by_micro_areas || []}
                        />

                        <ChartPie
                            label={"% de tipo de relevo"}
                            data={dataReport?.terrain_chart?.terrain_by_micro_areas_percentage || []}
                        />
                    </div>
                </div>

                <div>
                    <div className="pdfTopicContainer">
                        <h2 className="pdfMicroArea" style={{ width: 45 }}>14</h2>
                        <h2 className="pdfMicroArea">Solo</h2>
                    </div>
                    <div className="containerGraph">
                        <ChartBar
                            label={"Tipo de solo por pastos"}
                            data={dataReport?.terrain_chart?.soil_by_micro_areas || []}
                        />

                        <ChartPie
                            label={"% de tipo de reparo"}
                            data={dataReport?.terrain_chart?.soil_by_micro_areas_percentage || []}
                        />
                    </div>
                </div>
            </>
        );
    }

    const MicroAreaRelatorioMapaPDF = () => {
        const mapRefPosition = useRef(null);
        const [mapref, setMap] = useState<google.maps.Map>();
        const [centerCoords, setCenterCoords] = useState<any>([]);
        const [colorsArea, setColorsArea] = useState(new Map<Number, string>());

        const mapStyles = {
            width: "100%",
            height: "1440px",
        } as CSSProperties;

        function getRandomColor() {
            const colors: Array<string> = Object.values(Constants.GRAZINGMAP_COLORS);
            return colors[Math.floor(Math.random() * colors.length)];
        }

        const {
            getBounds
        } = useGoogleMaps();

        const onLoad = useCallback(function callback(map: any) {
            setMap(map);
        }, []);

        useEffect(() => {
            setMapPages(new Map(mapPages.set(`${1} Mapa`, summaryPages + 1)))
            areas.map((area: any) => {
                setColorsArea(new Map(colorsArea.set(Number(area.value), getRandomColor())))
            })
        }, []);

        useEffect(() => {
            if (centerCoords !== undefined && mapref !== undefined) {
                mapref.fitBounds(getBounds(centerCoords))
            }
        }, [centerCoords, mapref])

        useEffect(() => {
            setCenterCoords(farm.info?.map_coords)
        }, [farm]);

        return (
            <div className="pdfPage" style={{ padding: 0 }} ref={mapRefPosition}>
                <div className="pdfPageMap">
                    <div className="pdfContent">
                        <div>
                            <h1>MAPA</h1>
                            <div className="areaContainer">
                                <FarmIcon width={25} height={25} className="whiteColor" />
                                <h1>{farm.info?.initials}</h1>
                                <LocateIcon width={14} height={14} className="greenColor" />
                            </div>
                            <div className="detailareaContainer">
                                <h1>{farm.info?.name}</h1>
                                <span>{dataReport?.micro_areas_map_coords_details?.length} pasto(s) avaliado(s)
                                    ({dataReport?.micro_areas_map_coords_details?.reduce((acc, el) => acc + el.micro_area_acreage, 0).toFixed(2)} ha)
                                </span>
                            </div>
                        </div>
                    </div>

                    <FooterPDF pageSugest={summaryPages + 2} light />
                </div>

                <GoogleMap
                    onTilesLoaded={() => {
                        Promise.all(
                            Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; })))
                            .finally(() => {
                                window.print()
                            });
                    }}
                    onLoad={onLoad}
                    mapContainerStyle={mapStyles}
                    options={{
                        streetViewControl: false,
                        scaleControl: true,
                        fullscreenControl: false,
                        styles: [
                            {
                                featureType: "poi.business",
                                elementType: "labels",
                                stylers: [
                                    {
                                        visibility: "off",
                                    },
                                ],
                            },
                        ],
                        gestureHandling: "greedy",
                        disableDoubleClickZoom: true,
                        minZoom: 5,
                        mapTypeControl: true,
                        mapTypeId: google.maps.MapTypeId.SATELLITE,
                        mapTypeControlOptions: {
                            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                            position: google.maps.ControlPosition.BOTTOM_CENTER,
                            mapTypeIds: [
                                google.maps.MapTypeId.ROADMAP,
                                google.maps.MapTypeId.SATELLITE,
                                google.maps.MapTypeId.HYBRID,
                            ],
                        },
                        clickableIcons: false,
                        draggableCursor: "default",
                        draggable: false,
                        zoomControl: false
                    }}
                >
                    <Polyline
                        options={Constants.POLYLINE_DASHED_GREEN}
                        path={farm.info?.map_coords}
                        draggable={false}
                        editable={false}
                    />
                    {dataReport?.micro_areas_map_coords_details && dataReport?.micro_areas_map_coords_details.length > 0
                        ? dataReport?.micro_areas_map_coords_details
                            .map((polygonMicroArea) =>
                                <Polygon
                                    options={{
                                        ...Constants.POLYGON_SELECTED,
                                        fillOpacity: 0.5,
                                        fillColor: colorsArea.get(polygonMicroArea.area_id),
                                    }}
                                    path={polygonMicroArea?.micro_area_map_coords || []}
                                    draggable={false}
                                    editable={false}
                                />
                            )
                        : <></>}
                </GoogleMap>
            </div>
        )
    }

    return (
        <div className="pdfContainer">
            <div className="pdfPage" style={{ background: "#365951" }} ref={pageRef}>
                <div className="pdfHeader">
                    <div className="pdfHeaderContainer">
                        <h2>{farm.label}</h2>
                        <h1>Relatório global</h1>
                    </div>
                    <div className="pdfLogoPastejo">
                        <LogoPastejoIcon />
                    </div>
                </div>
            </div>

            {splitArray(Array.from(mapPages.keys()), 30).map((pg: any) => (
                <div className="pdfSumario">
                    <h2>Sumário</h2>
                    <div>
                        {pg.map((element: any) => (
                            <div className="pdfPageContent">
                                <span className="pdfPageIndice">{element}</span>
                                <span className="pdfPageLine">{".".repeat(315)}</span>
                                <span className="pdfPageNumber">{(mapPages.get(element) || 0) + summaryPages + 1}</span>
                            </div>
                        ))}
                    </div>
                </div>
            ))}

            <div className="pdfPageMapContainer">
                {MicroAreaRelatorioMapaPDF()}
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFForragem1()}
                </div>
                <FooterPDF />
            </div>

            {dataReport?.fodder_chart?.secondary_fodder_details &&
                <div className="pdfPage">
                    <div className="pdfContent">
                        {MicroAreaRelatorioPDFForragem2()}
                    </div>
                    <FooterPDF />
                </div>
            }

            {dataReport?.fodder_chart?.tertiary_fodder_details &&
                <div className="pdfPage">
                    <div className="pdfContent">
                        {MicroAreaRelatorioPDFForragem3()}
                    </div>
                    <FooterPDF />
                </div>
            }

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg2()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg3()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg4()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg5()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg51()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg6()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFFolhaEstreita1()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFFolhaEstreita2()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFPragas()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg9()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg10()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg11()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg12()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg13()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg14()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg15()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg16()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg17()}
                </div>
                <FooterPDF />
            </div>

            <div className="pdfPage">
                <div className="pdfContent">
                    {MicroAreaRelatorioPDFpg18()}
                </div>
                <FooterPDF />
            </div>
        </div>
    );
};