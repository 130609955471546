import { Polygon } from '@react-google-maps/api'
import {
  CSSProperties,
  MutableRefObject,
  useCallback,
  useEffect,
  useState,
} from 'react'
import AnimateHeight from 'react-animate-height'
import { IMicroAreas } from '../../../../../@types/app/IMicroArea'
import { ICoord } from '../../../../../@types/GoogleMaps/ICoord'
import { IPositionProps } from '../../../../../@types/GoogleMaps/IPositionProps'
import { ReactComponent as CloseIcon } from '../../../../../assets/svg/icons/close.svg'
import { ReactComponent as EyeClosed } from '../../../../../assets/svg/icons/eye.svg'
import { ReactComponent as EyeOpen } from '../../../../../assets/svg/icons/eyeClosed.svg'
import { ReactComponent as PenIcon } from '../../../../../assets/svg/icons/penEdit.svg'
import { ReactComponent as Trash } from '../../../../../assets/svg/icons/trashOnboarding.svg'
import Constants from '../../../../../constants/Index'
import { useGoogleMaps } from '../../../../../hooks/useGoogleMaps'
import { ButtonWithChildren } from '../../../../Buttons/ButtonWithChildren/Index'
import { DrawPolygon } from '../../../../GoogleMaps/DrawPolygon/Index/Index'
import { Map } from '../../../../GoogleMaps/Map/Index'
import { HandleUUID } from '../../../../Inputs/HandleUUID/Index'
import { TextInput } from '../../../../Inputs/TextInputs/TextInput/Index'
import { TextInputWithIcon } from '../../../../Inputs/TextInputs/TextInputWithIcon/Index'
import { ReactComponent as Available } from '../../../../../assets/svg/icons/available.svg'
import { ReactComponent as Unvailable } from '../../../../../assets/svg/icons/unavailable.svg'
import { Modal } from '../../../../Modal/Default/Index'

type MicroAreaProps = {
  onAnywhereChanges?: () => void
  formRef: MutableRefObject<HTMLFormElement | undefined>
  farm: any
  area: any
  micro?: any
  keyFarm: number
  keyArea: number
  keyMicroArea: number
  onlyRead?: boolean
  onRemoveMicroArea?: (uuid: number) => void
  isDeletable: boolean
  initValues: any[]
  errorMap: Map<string, string>
  acreageAvailable: number
}

const contentStyleModal = {
  width: '95%',
  height: '95vh',
  maxWidth: '1350px',
  maxHeight: '937px',
  borderRadius: '20px',
  overflow: 'hidden',
} as CSSProperties

export function MicroArea({
  onAnywhereChanges,
  formRef,
  farm,
  area,
  micro,
  keyFarm,
  keyArea,
  keyMicroArea,
  onRemoveMicroArea,
  onlyRead = false,
  isDeletable,
  initValues,
  errorMap,
  acreageAvailable,
}: MicroAreaProps) {
  const [visibleFarm, setVisibleFarm] = useState<'auto' | 0>('auto')
  const [readOnly, setIsReadOnly] = useState<boolean>(true)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [acreageMask, setAcreageMask] = useState<string | undefined>(undefined)
  const [centerMap, setCenterMap] = useState<ICoord>()
  const { getCenterPolygon, setExistingMaps, setToolMode } = useGoogleMaps()

  const handleVisibleFarm = async () => {
    setVisibleFarm((prev) => (prev === 0 ? 'auto' : 0))
  }

  function handleInputOnlyRead(indexMicroArea: number) {
    const value = readOnly
      ? farm.initials + '-' + area.initials + '-'
      : farm.initials +
        '-' +
        area.initials +
        '-' +
        indexMicroArea.toString().padStart(3, '0')

    formRef.current?.setFieldValue(
      `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].initials`,
      value,
    )

    setIsReadOnly((prevState) => !prevState)
  }

  function getInitials(initials: string, contador: number) {
    const value = initials + contador.toString().padStart(3, '0')
    return value
  }

  const reloadinitialspreload = () => {
    const value = farm.initials + '-' + area.initials + '-'
    let valuedig = formRef.current?.getFieldValue(
      `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].initials`,
    )
    valuedig = valuedig.replace(value, '')
    if (valuedig.length > 4) valuedig = valuedig.substring(0, 4)
    valuedig = value + valuedig
    formRef.current?.setFieldValue(
      `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].initials`,
      valuedig.toLocaleUpperCase(),
    )
  }

  function handleMaskAcreage(text: string) {
    switch (text.replace('.', '').length) {
      case 3:
        setAcreageMask('99.99999')
        break
      case 4:
        setAcreageMask('999.9999')
        break
      case 5:
        setAcreageMask('9999.9999')
        break
      case 6:
        setAcreageMask('99999.9999')
        break
      case 7:
        setAcreageMask('999999.99999')
        break
      case 8:
        setAcreageMask('9999999.99')
        break
      default:
        setAcreageMask('9999999.99')
        break
    }
  }

  const [existentingArea, setExistentingArea] = useState<
    IPositionProps | undefined
  >()

  useEffect(() => {
    if (micro && initValues.length == 0) {
      formRef.current?.setFieldValue(
        `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].uuid`,
        micro.uuid,
      )
      formRef.current?.setFieldValue(
        `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].area_id`,
        area?.uuid,
      )
      if (micro?.initials != '') {
        formRef.current?.setFieldValue(
          `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].initials`,
          farm.initials + '-' + area.initials + '-' + micro.initials,
        )
      }
      if (micro?.acreage) {
        formRef.current?.setFieldValue(
          `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].acreage`,
          micro.acreage,
        )
      }
      if (
        micro.map_coords &&
        micro.map_coords.uuid != '' &&
        micro.map_coords.coords &&
        micro.map_coords.coords.length > 0
      ) {
        formRef.current?.setFieldValue(
          `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].map_coords`,
          micro.map_coords,
        )
        setExistentingArea(micro.map_coords)
        setCenterMap(getCenterPolygon(micro.map_coords.coords))
      }
    } else {
      const listMicroAreas = initValues.filter(
        (obj: any) => obj.farm.id == farm.id && obj.area.id == area.id,
      )
      if (listMicroAreas[keyMicroArea]) {
        formRef.current?.setFieldValue(
          `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].area_id`,
          listMicroAreas[keyMicroArea].area.id,
        )
        formRef.current?.setFieldValue(
          `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].map_coords`,
          { coords: listMicroAreas[keyMicroArea].map_coords },
        )
        setExistentingArea({
          uuid: '',
          coords: listMicroAreas[keyMicroArea].map_coords,
        })
        setCenterMap(getCenterPolygon(listMicroAreas[keyMicroArea].map_coords))
        formRef.current?.setFieldValue(
          `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].acreage`,
          listMicroAreas[keyMicroArea].acreage + '',
        )
      } else {
        if (micro.initials != '')
          formRef.current?.setFieldValue(
            `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].initials`,
            farm.initials + '-' + area.initials + '-' + micro.initials,
          )
        formRef.current?.setFieldValue(
          `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].area_id`,
          area.id,
        )
        if (micro.map_coords.uuid != '') {
          formRef.current?.setFieldValue(
            `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].map_coords`,
            { coords: micro.map_coords.coords },
          )
          setExistentingArea({
            uuid: micro.map_coords.uuid,
            coords: micro.map_coords.coords,
          })
          setCenterMap(getCenterPolygon(micro.map_coords.coords))
        }
        if (micro.acreage)
          formRef.current?.setFieldValue(
            `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].acreage`,
            micro.acreage + '',
          )
        handleMaskAcreage((micro.acreage + '').replace('.', ','))
      }
    }
    if (micro && micro.acreage) {
      handleMaskAcreage(micro.acreage + '')
    }
  }, [initValues, micro])

  // Isso captura qualquer tipo de alteração nos Inputs repassados
  const handleAnyChanges = useCallback(
    (change?: any) => {
      if (change) onAnywhereChanges && onAnywhereChanges()
    },
    [onAnywhereChanges],
  )

  function handleDrawMicroArea() {
    setToolMode('draw')
    setExistingMaps(() => [])
    const form: IMicroAreas =
      formRef.current?.getData().farms[keyFarm].areas[keyArea].microareas

    const filteredArea = form[keyMicroArea]
    if (!!filteredArea) {
      setExistingMaps(() =>
        form
          .filter(
            (micro) =>
              micro.area_id === filteredArea.area_id &&
              micro.map_coords.coords.length > 0,
          )
          .map((micro) => ({
            uuid: micro.map_coords.uuid,
            coords: micro.map_coords.coords,
            nameMap: `${micro.initials ? micro.initials : ''}`,
          })),
      )
    }
  }

  function handleSuccess(polygon?: IPositionProps) {
    const areas: IMicroAreas =
      formRef.current?.getData().farms[keyFarm].areas[keyArea].microareas
    const areaFounded = areas.find(
      (area) => area.map_coords.uuid === polygon?.uuid,
    )
    if (!!areaFounded && areaFounded.map_coords)
      if (areaFounded.map_coords.coords.length === 0) {
        setExistentingArea(undefined)
      } else {
        setExistentingArea(areaFounded.map_coords)
        setCenterMap(getCenterPolygon(areaFounded.map_coords.coords))
        setExistingMaps((prev) => {
          const response = prev.filter((f) => f.uuid !== polygon?.uuid)
          return [
            ...response,
            { ...areaFounded.map_coords, nameMap: areaFounded.initials },
          ]
        })
      }
    setOpenModal(false)
    handleAnyChanges(true)
    errorMap.delete(
      `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].map_coords.coords`,
    )
  }

  function discardAreaPolygon() {
    setExistentingArea(undefined)
  }

  function blurAcreage(event: any) {
    if (event.target.value.length > 0 && event.target.value.length <= 3) {
      formRef.current?.setFieldValue(
        `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].acreage`,
        event.target.value.length == 2
          ? event.target.value + '00'
          : event.target.value + '0',
      )
    }
    handleAnyChanges(true)
  }

  return (
    <>
      <div style={{ marginLeft: 20 }}>
        <div className="microarea__title--container">
          <h3>Pasto {keyMicroArea + 1}</h3>
          {!onlyRead && isDeletable && (
            <button
              onClick={() => {
                onRemoveMicroArea && onRemoveMicroArea(keyMicroArea)
              }}
              className="microarea__header_trash"
              style={{ marginTop: 6 }}
              type={'button'}
            >
              <Trash style={{ color: 'red' }} />
            </button>
          )}
          <button type="button" onClick={handleVisibleFarm}>
            {visibleFarm ? <EyeOpen /> : <EyeClosed />}
          </button>
        </div>

        <AnimateHeight height={visibleFarm}>
          <section className="microarea__content">
            <div className="microarea__form">
              <TextInput hidden={true} value={!onlyRead + ''} name="isNew" />
              <HandleUUID name="area_id" />
              <HandleUUID name="uuid" />
              <div className="farm__textinput--container ">
                <label>*Sigla</label>
                <TextInput
                  id={`textinput-initials-microArea-${keyMicroArea}`}
                  disabled={onlyRead || readOnly}
                  name="initials"
                  onChange={reloadinitialspreload}
                  defaultValue={getInitials(
                    farm.initials + '-' + area.initials + '-',
                    keyMicroArea + 1,
                  )}
                  handleAnywhereChanges={handleAnyChanges}
                />

                {!onlyRead && (
                  <button
                    id={`button-readonly-initials-microArea-${keyMicroArea}`}
                    className="farm__button--readonly"
                    type="button"
                    onClick={() => {
                      handleInputOnlyRead(keyMicroArea + 1),
                        handleAnyChanges(true)
                    }}
                  >
                    {!readOnly ? 'Usar sigla automática' : 'Alterar sigla'}
                  </button>
                )}
              </div>

              <div className="container__input container__input_addNewMicroArea">
                <label>Quantos hectares de área útil?</label>
                <div className="microarea__container">
                  <div style={{ marginRight: 10 }}>
                    <TextInput
                      handleAnywhereChanges={handleAnyChanges}
                      onChange={(text) => {
                        handleMaskAcreage(text.target.value)
                      }}
                      mask={acreageMask}
                      autoComplete="off"
                      name="acreage"
                      disabled={onlyRead}
                      returnAsNumber
                      id={
                        `acreage-` +
                        keyFarm +
                        `-` +
                        keyArea +
                        `-` +
                        keyMicroArea
                      }
                      onBlur={blurAcreage}
                    />
                  </div>
                  <TextInputWithIcon
                    name={'AreaAcreage'}
                    Icon={acreageAvailable >= 0 ? Available : Unvailable}
                    value={`${acreageAvailable} disponiveis`}
                    size={20}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <div
                className={`microarea__drawarea--container ${
                  !!existentingArea ? 'hasMicroPolygon' : 'noHasMicroPolygon'
                }`}
              >
                {existentingArea && (
                  <Map currentCoords={centerMap}>
                    <Polygon
                      path={existentingArea.coords}
                      options={Constants.POLYGON_NEW_POLYGON}
                    />
                  </Map>
                )}
                {!onlyRead && (
                  <ButtonWithChildren
                    theme={!existentingArea ? 'dark' : 'info'}
                    onClick={handleDrawMicroArea}
                  >
                    <div
                      className="buttondraw__container"
                      onClick={() => setOpenModal(true)}
                    >
                      <PenIcon />
                      {!existentingArea && <h3>Desenhe o Pasto</h3>}
                    </div>
                  </ButtonWithChildren>
                )}
              </div>
              {errorMap &&
                errorMap.get(
                  `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].map_coords.coords`,
                ) && (
                  <small className="textInput--small-error animation__fadeIn">
                    {errorMap.get(
                      `farms[${keyFarm}].areas[${keyArea}].microareas[${keyMicroArea}].map_coords.coords`,
                    )}
                  </small>
                )}
            </div>
          </section>
        </AnimateHeight>
      </div>

      <Modal visible={openModal} contentStyle={contentStyleModal}>
        <header className="drawpolygon__header">
          <ButtonWithChildren theme="light" onClick={() => setOpenModal(false)}>
            <CloseIcon />
          </ButtonWithChildren>
        </header>
        <div
          className="drawmicroarea__container"
          id={`draw-` + keyFarm + `-` + keyArea + `-` + keyMicroArea}
        >
          <DrawPolygon
            labelContainerMap={farm.initials + '-' + area.initials}
            area={area.map_coords}
            onHandleDiscardChanges={discardAreaPolygon}
            onHandleSuccess={handleSuccess}
            onHandleValidate={handleAnyChanges}
          />
        </div>
      </Modal>
    </>
  )
}
