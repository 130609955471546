import { ReactNode, useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { ICoords } from "../../../@types/GoogleMaps/ICoord";

import { ReactComponent as PlusIcon } from "../../../assets/svg/icons/plus.svg";
import { ReactComponent as LessIcon } from "../../../assets/svg/icons/less.svg";
import { ReactComponent as LocationIcon } from "../../../assets/svg/icons/locationcenter.svg";
import "./styles.css";

type CustomControllsProps = {
  defaultLocation?: ICoords;
  overrideCentermap?: () => void;
};

export function CustomControls({ defaultLocation, overrideCentermap: overrideCentermap }: CustomControllsProps) {
  const map = useMap();

  const centerMap = () => {
    if (overrideCentermap) overrideCentermap();
    else if (defaultLocation) {
      map.flyToBounds(defaultLocation as any);
    }
  };

  const zoomIn = () => {
    const currentZoom = map.getZoom();
    map.setZoom(currentZoom + 1);
  };

  const zoomOut = () => {
    const currentZoom = map.getZoom();
    map.setZoom(currentZoom - 1);
  };

  return (
    <section className="leaflet__container--constrolls">
      <button className="custom__button--location" onClick={centerMap}>
        <LocationIcon />
      </button>
      <button className="custom__button--zoomin" onClick={zoomIn}>
        <PlusIcon />
      </button>
      <button className="custom__button--zoomout" onClick={zoomOut}>
        <LessIcon />
      </button>
    </section>
  );
}
