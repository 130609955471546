import "./styles.css";
import { ReactComponent as AnimalsIcon } from "../../../assets/svg/icons/animais.svg";

type BatchesSpecsDetailsProps = {
  batchLabel: string;
  qtdLabel: string;
  capacity?: string;
};

export function BatchesSpecsDetails({
  batchLabel,
  qtdLabel,
  capacity
}: BatchesSpecsDetailsProps) {
  return (
    <section className="batchesspecsDetails__container">
      <div className="batchesspecsDetails__container_sigla">
        <AnimalsIcon />
        <h3 className="loteDetails">{batchLabel}</h3>
      </div>
      <h3 className="qtdDetails">{qtdLabel}</h3>
      {capacity && (<h3 className="capacity">{capacity} UA/HA</h3>)}
    </section>
  );
}
