import React from "react";
import { Modal } from "../../Default/Index";
import { RoundedCloseButton } from "../../../Buttons/RoundedCloseButton/Index";
import { ReactComponent as Check } from "../../../../assets/svg/icons/CheckFull.svg";
import "./style.css";
import { ButtonOnlyTitle } from "../../../Buttons/ButtonOnlyTitle/Index";

interface ErrorModalProps {
  visible: boolean;
  onClose: () => void;
  errorMessage?: string;
  monitoringScreen?: () => void;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  visible,
  onClose,
  errorMessage,
  monitoringScreen,
}) => {
  return (
    <div style={{ zIndex: 910 }}>
      <Modal
        visible={visible}
        contentStyle={{
          padding: "60px",
          width: "600px",
          height: 'auto',
          borderRadius: 25,
          position: "relative",
        }}
      >
        <header className="error__modal-header">
          <RoundedCloseButton onClick={() => onClose()} />
        </header>
        <div className="error__modal-message">
          <h2>Erro ao criar tarefa</h2>
          {errorMessage &&
            <span>{errorMessage}</span>
          }
        </div>
        <footer className="error__modal-button-container">
          <ButtonOnlyTitle
            title="Cancelar"
            theme="info"
            onClick={() => onClose()}
          />
        </footer>
      </Modal>
    </div>
  );
};
