import { ChangeEvent, CSSProperties, MutableRefObject, useCallback, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import "./styles.css";

import { ReactComponent as EyeOpen } from "../../../../assets/svg/icons/eyeClosed.svg";
import { ReactComponent as EyeClosed } from "../../../../assets/svg/icons/eye.svg";

import { fetchStateCities, fetchStatesUF } from "../../../../services/ibge";
import { FileUploadKml } from "../../../Inputs/FileUploadKml/Index";
import { Select } from "../../../Inputs/Select/Index";
import { sortBy } from "../../../../utils/arrays/sortBy";
import { ReactComponent as UploadIcon } from "../../../../assets/svg/icons/upload.svg";
import { useOnboarding } from "../../../../hooks/useOnboarding";
import { IFarm } from "../../../../@types/app/IFarm";
import { TextInput } from "../../../Inputs/TextInputs/TextInput/Index";
import { ButtonOnlyTitle } from "../../../Buttons/ButtonOnlyTitle/Index";
import { Scope } from "@unform/core";
import { IOptionsProps } from "../../../../@types/reactSelect/IOption";
import { SliderComponent } from "../../../Inputs/Slider/Index";
import Constants from "../../../../constants/Index";
import { AlertModal } from "../../../Modal/Alert/Index";
import { SimpleButtonClose } from "../../../Modal/Contents/SimpleButtonClose/Index";
import { TextModal } from "../../../Modal/Contents/TextModal/Index";
import { TitleModal } from "../../../Modal/Contents/TitleModal/Index";
import { ButtonRedirect } from "../../../Modal/Error/ButtonRedirect/Index";
import { HandleUUID } from "../../../Inputs/HandleUUID/Index";

type FarmProps = {
  formRef: MutableRefObject<HTMLFormElement | undefined>;
  onEventRemove?: (uuid: number) => void;
  onAnywhereChanges?: () => void;
  managers: IOptionsProps;
  isDeletable?: boolean;
  initData?: IFarm;
  path?: string;
  uuid: number;
  onlyRead?: boolean;
  onBoarding?: boolean;
};

export function CreateFarm({
  onAnywhereChanges,
  onEventRemove,
  isDeletable,
  managers,
  initData,
  formRef,
  uuid,
  onlyRead = false,
  onBoarding = false,
}: FarmProps) {
  const { setModalError } = useOnboarding();

  // Isso diz se o campo é editável ou não
  const [readOnly, setIsReadOnly] = useState<boolean>(true);
  // Isso diz quando mostrar a fazenda
  const [visibleFarm, setVisibleFarm] = useState<"auto" | 0>("auto");
  // Isso guarda a lista de Ufs disponíveis
  const [listUF, setListUF] = useState<Array<{ label: string; value: string }>>([]);
  // isso guarda a lista de cidades com base no uf selecionado
  const [listCities, setListCities] = useState<Array<{ label: string; value: string }>>([]);

  const [maskCFPCNPJ, setMaskCFPCNPJ] = useState<string>("999.999.999-999999");

  const [blockCities, setBlockCities] = useState<boolean>(true);

  const [alertVisible, setAlertVisible] = useState<boolean>(false);

  const [resetMap, setResetMap] = useState<boolean>(false);

  const { payload } = useOnboarding();
  const [inicioChuva, setinicioChuva] = useState(3);
  const [inicioSeca, setinicioSeca] = useState(8);

  // Isso esconde ou mostra a fazenda digitada
  const handleVisibleFarm = () => setVisibleFarm((prev) => (prev === 0 ? "auto" : 0));

  // Isso habilita ou desabilita a edição de sigla
  const handleInputOnlyRead = () => {
    if (!readOnly) {
      const nameInput = formRef.current?.getFieldRef(`farms[${uuid}].name`);
      if (nameInput.current.value) handleTitleFarm(nameInput.current.value);
    }
    setIsReadOnly((prevState) => !prevState);
  };

  // Isso é o trigger para a função de remover fazenda
  const eventRemove = () => {
    reset();
    onEventRemove && onEventRemove(uuid);
  };

  const contentStyle = {
    width: "440px",
    height: "218px",
    background: "#FFFFFF",
    borderRadius: "20px",
    padding: "10px",
  } as CSSProperties;

  // Isso Verifica se já existe uma sigla com mesmo nome
  function nickNameExists(nick: string) {
    const response = formRef.current?.getData().farms;
    const filtered = response.filter((nickName: any) => {
      return nickName?.initials && nickName.initials.toLocaleUpperCase() === nick.toLocaleUpperCase();
    });
    return filtered.length > 1; // Se o filtro devolve um valor maior que 1 temos ambiquidade
  }

  // Isso lida com a sigla da Fazenda
  function handleTitleFarm(event: ChangeEvent<HTMLInputElement> | string) {
    if (onBoarding || !onlyRead) {
      // Isso diz que nao se deve alterar a sigla se o usuário quiser inerir manualmente
      if (!readOnly && typeof event === "object") return;
      formRef.current?.setErrors({ [`farms[${uuid}].sigla`]: "" });
      const value = typeof event === "string" ? event : event.target.value;
      let currentValue = value.charAt(0);
      // Se o valor digitado tem espaços colocamos uma letra de cada sentença
      if (value.includes(" ")) {
        const teste = value.split(" ");
        currentValue = value.charAt(0) + teste[1].charAt(0);
      } else currentValue += value.charAt(1); // Senão colocamos a primeira e segunda letra da senteça
      // Atribui no input
      formRef.current?.setFieldValue(`farms[${uuid}].initials`, currentValue.toLocaleUpperCase());
      if (nickNameExists(currentValue))
        formRef.current?.setErrors({
          [`farms[${uuid}].sigla`]: "Já Existe uma Fazenda com essa sigla!",
        });
    }
  }

  // Isso lista os nomes de cidade com relação ao valor selecionado
  function handleUFSelected(option: any) {
    setBlockCities(false);
    fetchStateCities(option.value).then((data) => {
      const response = data.map((city: any) => ({ label: city.nome, value: city.id })).sort();
      setListCities(() => response);
    });
  }

  // Isso lida com qual máscara ele deve exibir no componente
  const handleDocument = async (input: ChangeEvent<HTMLInputElement>) => {
    onAnywhereChanges && onAnywhereChanges();
    setMaskCFPCNPJ(input.target.value.length <= 14 ? "999.999.999-999999" : "99.999.999/9999-99");
  };

  function handleMapError(error: any) {
    setAlertVisible(true);
    setModalError((prev) => (prev ? { ...prev, hasError: true, body: error } : prev));
  }

  // Isso captura qualquer tipo de alteração nos Inputs repassados
  const handleAnyChanges = useCallback((change?: any) => {
    if (change) onAnywhereChanges && onAnywhereChanges();
  }, [onAnywhereChanges]);

  useEffect(() => {
    fetchStatesUF().then((data) => {
      const ufs: Array<any> = data.map((uf: any) => ({
        label: uf,
        value: uf,
      }));
      setListUF(() => ufs.sort(sortBy("label")));
    });
    if (payload != undefined && payload.farms != undefined) {
      payload.farms[uuid]
        ? (setinicioChuva(payload.farms[uuid]["properties"]["season"]["rain_initial"]),
          setinicioSeca(payload.farms[uuid]["properties"]["season"]["dry_initial"]))
        : "";
    }
  }, []);

  async function bindForm() {
    if (!initData?.address.city?.value) setBlockCities(true);
    formRef.current?.setFieldValue(`farms[${uuid}].uuid`, initData?.uuid);
    formRef.current?.setFieldValue(`farms[${uuid}].name`, initData?.name);
    formRef.current?.setFieldValue(`farms[${uuid}].initials`, initData?.initials);
    formRef.current?.setFieldValue(`farms[${uuid}].state_registration`, initData?.state_registration);
    formRef.current?.setFieldValue(
      `farms[${uuid}].document`,
      initData?.document == null ? "" : initData?.document
    );
    formRef.current?.setFieldValue(`farms[${uuid}].address.city`, initData?.address.city);
    formRef.current?.setFieldValue(`farms[${uuid}].address.uf`, initData?.address.uf);
    formRef.current?.setFieldValue(`farms[${uuid}].manager_user_id`, initData?.manager_user_id);
    formRef.current?.setFieldValue(`farms[${uuid}].properties.season`, initData?.properties.season);
    formRef.current?.setFieldValue(`farms[${uuid}].file`, initData?.file);
  }

  const reset = () => {
    setListCities([]);
    setBlockCities(true);
    setResetMap(true);
    formRef.current?.setFieldValue(`farms[${uuid}].address.city`, { value: "", label: "Selecione..." });
    formRef.current?.setFieldValue(`farms[${uuid}].address.uf`, { value: "", label: "Selecione..." });
    formRef.current?.setFieldValue(`farms[${uuid}].address.uf`, { value: "", label: "Selecione..." });
    formRef.current?.setFieldValue(`farms[${uuid}].manager_user_id`, { value: "", label: "Selecione..." });
    formRef.current?.setFieldValue(`farms[${uuid}].file`, { name: "", coords: [] });
  };

  useEffect(() => {
    async function initForm() {
      await bindForm();
    }
    initForm();
    if (initData && initData.document)
      setMaskCFPCNPJ(initData.document?.length <= 11 ? "999.999.999-999999" : "99.999.999/9999-99");
  }, [initData]);

  return (
    <>
      <AlertModal visible={alertVisible} contentStyle={contentStyle}>
        <SimpleButtonClose onClick={() => setAlertVisible(false)} />
        <div className="createuser__alertModalBody">
          <TitleModal>{Constants.MESSAGE_INVALID_MODAL_TITLE}</TitleModal>
          <TextModal>{Constants.MESSAGE_INVALID_MAP}</TextModal>
        </div>
        <ButtonRedirect onClick={() => setAlertVisible(false)}>VOLTAR</ButtonRedirect>
      </AlertModal>
      <section className="farm__container" id={`farm-${uuid}`}>
        <div className="farm__title--container">
          <h3>Fazenda {uuid + 1}</h3>
          <button type="button" onClick={handleVisibleFarm}>
            {visibleFarm ? <EyeOpen /> : <EyeClosed />}
          </button>
        </div>
        <AnimateHeight height={visibleFarm}>
          <section className="farm__content">
            <div className="farm__form">
              <section className="farm__textinput--container">
                <HandleUUID name="uuid" />
                <TextInput
                  hidden={true}
                  value={!onlyRead + ""}
                  name="isNew"
                />
                <label>*Nome da Fazenda</label>
                <TextInput
                  handleAnywhereChanges={handleAnyChanges}
                  id={`textinput-namefarm-${uuid}`}
                  placeholder="Nome da Fazenda"
                  onChange={handleTitleFarm}
                  name="name"
                  disabled={onBoarding}
                />
              </section>

              <section className="farm__textinput--container">
                <label>*Sigla</label>
                <TextInput
                  handleAnywhereChanges={handleAnyChanges}
                  id={`textinput-initials-${uuid}`}
                  readOnly={readOnly}
                  name="initials"
                  mask="***"
                  disabled={onBoarding}
                />
                {!onlyRead && (
                  <button
                    id={`button-readonly-initials-${uuid}`}
                    className="farm__button--readonly"
                    onClick={handleInputOnlyRead}
                    type="button"
                  >
                    {!readOnly ? "Usar sigla automática" : "Alterar sigla"}
                  </button>
                )}
              </section>

              <section className="farm__textinput--container">
                <label>CPF / CNPJ</label>
                <TextInput
                  id={`textinput-document-${uuid}`}
                  onChange={handleDocument}
                  mask={maskCFPCNPJ}
                  name="document"
                  returnUnmasked
                  disabled={onBoarding}
                />
              </section>

              <section className="farm__textinput--container">
                <label>Inscrição Estadual</label>
                <TextInput
                  id={`textinput-state_registration-${uuid}`}
                  name="state_registration"
                  disabled={onBoarding}
                />
              </section>

              <Scope path="address">
                <section className="farm__dropdown--address">
                  <div className="farm__textinput--container">
                    <label>*UF</label>
                    <Select
                      handleAnywhereChanges={handleAnyChanges}
                      getSelectedData={(option) => handleUFSelected(option)}
                      placeholder="Selecione..."
                      defaultMenuIsOpen={false}
                      closeMenuOnSelect={true}
                      id={`select-uf-${uuid}`}
                      options={listUF}
                      name="uf"
                      isDisabled={onlyRead || onBoarding}
                    />
                  </div>
                  <div className="farm__textinput--container">
                    <label>*Cidade</label>
                    <Select
                      noOptionsMessage={() => (
                        <small className="noContent--msg">"Selecione um UF primeiro"</small>
                      )}
                      handleAnywhereChanges={handleAnyChanges}
                      id={`select-city-${uuid}`}
                      placeholder="Selecione..."
                      defaultMenuIsOpen={false}
                      isDisabled={(onlyRead || blockCities)}
                      closeMenuOnSelect={true}
                      options={listCities}
                      isSearchable={true}
                      name="city"
                    />
                  </div>
                </section>
              </Scope>

              <div className="farm__textinput--container">
                <label>*Responsável da Fazenda</label>
                <Select
                  noOptionsMessage={() => (
                    <small className="noContent--msg">Não foram encontrados perfis</small>
                  )}
                  id={`select-manager_user_id-${uuid}`}
                  placeholder="Selecione..."
                  defaultMenuIsOpen={false}
                  closeMenuOnSelect={true}
                  name="manager_user_id"
                  isSearchable={true}
                  options={managers}
                  isDisabled={onlyRead || onBoarding}
                  handleAnywhereChanges={handleAnyChanges}
                />
              </div>

              <Scope path="properties">
                <div className="farm__textinput--container">
                  <label>Definição de Estação</label>
                  <div className="slider-seasons">
                    <SliderComponent
                      name="season"
                      listOptions={Constants.MONTHS_SHORT_NAME}
                      rain_initial={inicioChuva}
                      dry_initial={inicioSeca}
                      disable={onBoarding}
                    />
                  </div>
                </div>
              </Scope>

              <div className="farm__divider" />

              {isDeletable && (
                <div className="farm__button--delete">
                  <ButtonOnlyTitle title="- Remover essa fazenda" theme="light" onClick={eventRemove} type={"button"} />
                </div>
              )}
            </div>
            <FileUploadKml
              title="*Clique para adicionar sua fazenda."
              handleAnywhereChanges={(event) => handleAnyChanges(event[0]?.type)}
              onMessageError={(error) => handleMapError(error)}
              name="file"
              reset={resetMap}
              onlyRead={onlyRead}
            >
              <UploadIcon />
              <strong>Upload Mapa KML</strong>
            </FileUploadKml>
          </section>
        </AnimateHeight>
      </section>
    </>
  );
}
