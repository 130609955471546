import "./styles.css";
import { useForm } from "react-hook-form";
import { CSSProperties, useEffect, useState } from "react";
import { Input } from "../../EditOnboarding/components/Input";
import axios from "../../../services/axios";
import { Dropdown } from "../../EditOnboarding/components/Select";
import { IOptionProps } from "../../../@types/reactSelect/IOption";
import { ButtonOnlyTitle } from "../../Buttons/ButtonOnlyTitle/Index";
import { CounterInputEdit } from "../../EditOnboarding/components/Input/CounterInput/Index";
import { SuccessModalCreateProduct } from "../../Modal/CreateProduct/Index";
import { AlertModal } from "../../Modal/Alert/Index";
import { TitleModal } from "../../Modal/Contents/TitleModal/Index";
import { TextModal } from "../../Modal/Contents/TextModal/Index";
import { ButtonWithChildren } from "../../Buttons/ButtonWithChildren/Index";
import { ReactComponent as PositiveIcon } from "./../../../assets/svg/icons/positive.svg";
import { ReactComponent as NegativeIcon } from "./../../../assets/svg/icons/negative.svg";
import { RoundedCloseButton } from "../../Buttons/RoundedCloseButton/Index";
import { ReactComponent as Check } from "../../../assets/svg/icons/CheckFull.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEditOnboarding } from "../../../hooks/useEditOnboarding";
import { useFilter } from "../../../hooks/useFilter";

const contentStyleAlert = {
  width: "600px",
  height: "345px",
  borderRadius: "25px",
} as CSSProperties;

export function CreateProducts() {
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const productSchema = yup.object({
    trade_name: yup.string().required("Nome do produto é necessário."),
    manufacturer: yup.string().required("Fabricante é necessário."),
    classification: yup.object().required("Campo obrigatório não preenchido.").nullable(),
    sub_classification: yup.object({
      label: yup.string(),
      value: yup.string().required("Campo obrigatório não preenchido."),
    }).required("Campo obrigatório não preenchido.").nullable(),
    expectedUse: yup.string().test('value', 'Campo obrigatório não informado. ', () => {
      setShowErrorMessage(true)
      return true
    }),
    frequency: yup.string().test('value', 'Campo obrigatório não informado. ', () => {
      setShowErrorMessage(true)
      return true
    }),
  });

  const [expectedUse, setExpectedUse] = useState(0);
  const [frequency, setFrequency] = useState(0);

  const maxInputValue = 10000000

  const {
    setOptionForm,
    defaultValue
  } = useEditOnboarding();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm({ resolver: yupResolver(productSchema), defaultValues: defaultValue });

  const [mapClassification] = useState(new Map());

  const [optionsClassification, setOptionsClassification] = useState<IOptionProps[]>([]);

  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);

  const [errorExpectedUse, setErrorExpectedUse] = useState("");

  const [successCreator, setSuccessCreator] = useState(false);

  const [errorFrequency, setErrorFrequency] = useState("");

  const [resetConsumo, setResetConsumo] = useState(0);

  const classificationSelected = watch("classification")

  const { setSideBarOptionSelected } = useFilter();

  function onSubmit(data: any) {
    if (expectedUse != 0 && frequency != 0) {
      if (defaultValue) {
        axios
          .patch("/products/supplement",
            {
              id: data?.id,
              manufacturer: data?.manufacturer,
              trade_name: data?.trade_name,
              sub_classification_id: Number(data?.sub_classification?.value),
              expected_consumption: expectedUse,
              supply_frequency: frequency
            })
          .then(() => {
            setSuccessCreator(true)
          })
      } else {
        axios
          .post("/products/supplement",
            {
              manufacturer: data?.manufacturer,
              trade_name: data?.trade_name,
              sub_classification_id: Number(data?.sub_classification?.value),
              expected_consumption: expectedUse,
              supply_frequency: frequency
            })
          .then(() => {
            setSuccessCreator(true)
          })
      }
    }
  }

  function deleteProduct(id: number) {
    axios
      .delete(`/products/${id}`)
      .then(() => {
        setOptionForm(undefined)
        setModalDeleteVisible(!modalDeleteVisible)
      })
  }

  useEffect(() => {
    axios.get("/products/supplement/classification")
      .then(({ data }) => {
        mapClassification.set(data[0].name, "Tipo de sal")
        mapClassification.set(data[1].name, "Peso vivo")
        mapClassification.set(data[2].name, "Peso vivo")

        setOptionsClassification(
          data.map((el: any) =>
          ({
            label: el.name,
            value: el.name,
            info: el.sub_classification.map((el2: any) => ({
              label: el2.name,
              value: el2.id
            }))
          }))
        )
      })
  }, []);

  useEffect(() => {
    if (showErrorMessage && expectedUse == 0) setErrorExpectedUse("Preencha o consumo esperado.")
    else setErrorExpectedUse("")
  }, [expectedUse, showErrorMessage]);

  useEffect(() => {
    if (showErrorMessage && frequency == 0) setErrorFrequency("Preencha a frequência de fornecimento.")
    else setErrorFrequency("")
  }, [frequency, showErrorMessage]);

  useEffect(() => {
    if (defaultValue) {
      setFrequency(defaultValue?.supply_frequency)
      setExpectedUse(defaultValue?.expected_consumption)

      setValue("classification", {
        label: defaultValue?.classification,
        value: defaultValue?.classification,
        ...optionsClassification.find(el => el.label == defaultValue?.classification)
      })
    }
  }, [defaultValue, optionsClassification]);

  useEffect(() => {
    if (showErrorMessage && classificationSelected) setError("sub_classification", { message: "Campo obrigatório não preenchido." })
    if (defaultValue) {
      setValue("sub_classification", {
        label: defaultValue?.sub_classification,
        value: watch("classification")?.info?.find((el: any) => el.label)?.value
      })
    }
  }, [classificationSelected]);


  return (
    <main className={"body__formEditOnboarding"}>
      <div className="firstColumn">
        <form onSubmit={() => handleSubmit(onSubmit)} style={{ width: 500 }}>
          <Input
            control={control}
            label={"Nome comercial do produto"}
            theme={"normal"}
            name={"trade_name"}
          />

          <Input
            control={control}
            label={"Fabricante"}
            theme={"normal"}
            name={"manufacturer"}
          />

          <Dropdown
            control={control}
            label={"Classificação"}
            name={"classification"}
            closeMenuOnSelect={true}
            placeholderText={"Selecione..."}
            options={optionsClassification}
            getSelectedData={(e) => {
              setValue("sub_classification", null)
            }}
          />

          {classificationSelected && (
            <>
              <div hidden={!watch("classification")}>
                <Dropdown
                  control={control}
                  label={mapClassification.get(watch("classification").value)}
                  name={"sub_classification"}
                  closeMenuOnSelect={true}
                  placeholderText={"Selecione..."}
                  options={watch("classification").info}
                  getSelectedData={() => {
                    setResetConsumo(resetConsumo + 1)
                  }}
                />
              </div>

              <div hidden={!watch("sub_classification")}>
                <label className="formLabel">Consumo esperado</label>
                <CounterInputEdit
                  isInline
                  label={"g/kg de PV"}
                  defaultValue={defaultValue?.expected_consumption || 0}
                  control={control}
                  name={"expected_consumption"}
                  onChangevalue={(e) => {
                    setExpectedUse(e)
                  }}
                  max={maxInputValue}
                  errorText={errorExpectedUse}
                />
              </div>

              <div hidden={!expectedUse}>
                <label className="formLabel">Frequência de fornecimento</label>
                <CounterInputEdit
                  isInline
                  label={"x por semana"}
                  defaultValue={defaultValue?.supply_frequency || 0}
                  control={control}
                  name={"supply_frequency"}
                  onChangevalue={(e) => {
                    setFrequency(e)
                  }}
                  max={7}
                  errorText={errorFrequency}
                />
              </div>
            </>
          )}
        </form>
      </div>
      <div className="secondColumn">
        <span className="title_createProduct">Resumo</span>

        <div className="body_resume">
          <div className="formContainerProduct">
            <label className="body_title">
              Nome comercial
            </label>
            <label className="container_formResult">
              {watch("trade_name")}
            </label>
          </div>
          <div className="formContainerProduct">
            <label className="body_title">
              Fabricante
            </label>
            <label className="container_formResult">
              {watch("manufacturer")}
            </label>
          </div>
          <div className="formContainerProduct">
            <label className="body_title">
              Consumo
            </label>
            <label className="container_formResult">
              {expectedUse ? `${expectedUse} g/kg de PV` : ""}
            </label>
          </div>
          <div className="formContainerProduct">
            <label className="body_title">
              Frequência
            </label>
            <label className="container_formResult last">
              {frequency ? `${frequency} x por semana` : ""}
            </label>
          </div>
        </div>

        <div className="buttonsSubmit">
          <ButtonOnlyTitle
            title="Cancelar"
            theme="light"
            type="button"
            onClick={() => setOptionForm(undefined)}
          />

          <ButtonOnlyTitle
            title="Deletar"
            theme="urgent"
            type="button"
            onClick={() => setModalDeleteVisible(true)}
            hidden={!defaultValue || !defaultValue.can_delete}
          />

          <ButtonOnlyTitle
            title="Concluir"
            theme="info"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!(watch("trade_name") && watch("manufacturer") && expectedUse && frequency)}
          />
        </div>
      </div>

      <SuccessModalCreateProduct
        visible={successCreator}
        onClose={() => {
          setOptionForm(undefined)
          setSuccessCreator(false)
        }
        }
        backScreen={() => {
          setSideBarOptionSelected("MAPA")
          setOptionForm(undefined)
          setSuccessCreator(false)
        }}
        newProduct={() => {
          setSuccessCreator(false)
          setOptionForm(undefined)
          setTimeout(() => setOptionForm("create_products"), 1)
        }}
      />

      <AlertModal visible={modalDeleteVisible} contentStyle={contentStyleAlert}>
        <section className="formDeleteProduct">
          <div className="close">
            <RoundedCloseButton onClick={() => setModalDeleteVisible(false)} />
          </div>
          <Check width={40} height={40} />

          <div className="infoModal">
            <TitleModal>Você tem certeza?</TitleModal>
            <TextModal>
              Ao deletar, você perderá esse produto!
            </TextModal>
          </div>

          <div className="buttons-controll">
            <ButtonWithChildren onClick={() => deleteProduct(defaultValue.id)}>
              <div className="buttonWithChildren--content-inline">
                <PositiveIcon />
                <h6>Sim</h6>
              </div>
            </ButtonWithChildren>
            <ButtonWithChildren onClick={() => setModalDeleteVisible(false)}>
              <div className="buttonWithChildren--content-inline">
                <NegativeIcon />
                <h6>Não</h6>
              </div>
            </ButtonWithChildren>
          </div>
        </section>
      </AlertModal>
    </main>
  );
}
