import './styles.css'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { FarmContainer } from '../../../../components/Onboarding/Register/CreateArea/FarmContainer/Farm'
import { Form } from '@unform/web'
import { Scope } from '@unform/core'
import * as yup from 'yup'
import Constants from '../../../../constants/Index'
import { IFieldsNames } from '../../../../components/Onboarding/Register/CreateArea/FormArea/Index'
import { ICoords } from '../../../../@types/GoogleMaps/ICoord'
import {
  IOptionsProps,
  IOptionProps,
} from '../../../../@types/reactSelect/IOption'
import { TextInput } from '../../../Inputs/TextInputs/TextInput/Index'

yup.addMethod(yup.array, 'uniqueArea', function (message: string, key: string) {
  return this.test('uniqueArea', function (list: any) {
    const errors = list.map((element: any, ind: number) => {
      if (element[key] && element[key].length < 1) return
      const counter = list.filter((li: any) => li[key] === element[key]).length
      if (counter > 1)
        return new yup.ValidationError(message, message, `areas[${ind}].${key}`)
    })
    const filtered = errors.filter((err: any) => !!err)
    if (filtered.length < 1) return true
    return this.createError({
      message: () => errors.filter((err: any) => !!err),
    })
  })
})

const areaForm = yup.object().shape({
  areas: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Campo obrigatório não informado.'),
        acreage: yup
          .string()
          .min(1, 'Quantidade de hectares informado inválido!')
          .max(999, 'Quantidade de hectares informado inválido!')
          .required('Campo obrigatório não informado.'),
        initials: yup.string().required('Campo obrigatório não informado.'),
        manager_user_id: yup
          .object({
            label: yup.string(),
            value: yup.string(),
          })
          .nullable()
          .required('Campo obrigatório não informado.'),
        indoor_grazing_time: yup
          .object({
            label: yup.string(),
            value: yup.string().required('Tempo máximo COM gado é necessário.'),
          })
          .nullable()
          .required('Tempo máximo COM gado é necessário.'),
        outdoor_grazing_time: yup
          .object({
            label: yup.string(),
            value: yup.string().required('Tempo máximo SEM gado é necessário.'),
          })
          .nullable()
          .required('Tempo máximo SEM gado é necessário.'),

        map_coords: yup
          .object()
          .shape({
            coords: yup.array(),
            id: yup.string(),
          })

          .test({
            message: Constants.MESSAGE_MAPPING_NOT_FOUND,
            test: (map) => {
              if (map && map.coords) return map.coords.length > 0
              return true
            },
          }),
      }),
    )
    //@ts-ignore
    .uniqueArea('Retiro informado já está cadastrado para a Fazenda!', 'name')
    .uniqueArea('Esta sigla já está sendo utilizada.', 'initials'),
})

interface CreateAreasProps {
  isEdition?: boolean
  disableEditFields?: Array<IFieldsNames>
  dataFarms: IFarmsArea
  dataAreas: ICreateAreas
  managersList?: IOptionsProps
  validateDataForm?: (data: any) => void
  onFormRef?: (form: any) => void
}

export type CreateAreasRefProps = {
  isFormValid: () => Promise<boolean>
  showErrorsForm: () => Promise<boolean>
  getFormData: () => Promise<any>
}

export type IFarmArea = {
  id: string
  initials: string
  name: string
  coords: ICoords
}

export type ICreateArea = {
  id: string
  farmId: string
  name: string
  initials: string
  managerUser: {
    label: string
    value: string
  } | null
  coords: ICoords
  openForm: boolean
  isNew: boolean
  acreage: string
  indoor_grazing_time: IOptionProps | null
  outdoor_grazing_time: IOptionProps | null
}

export interface IFarmsArea extends Array<IFarmArea> {}
export interface ICreateAreas extends Array<ICreateArea> {}

export const CreateAreas = forwardRef((props: CreateAreasProps, ref) => {
  const {
    isEdition = false,
    disableEditFields = [],
    dataFarms,
    dataAreas,
    managersList = [],
    validateDataForm,
    onFormRef,
  } = props

  const [farms, setFarms] = useState<IFarmsArea>([])
  const formRef = useRef<HTMLFormElement>()

  useImperativeHandle(ref, () => ({
    async isFormValid() {
      return await validateForm(false)
    },

    async showErrorsForm() {
      return await validateForm()
    },

    async getFormData() {
      if (formRef && formRef.current) return formRef.current?.getData() || null
      return null
    },
  }))

  useEffect(() => {
    if (dataFarms.length > 0) setFarms(dataFarms)
  }, [dataFarms])

  useEffect(() => {
    if (formRef && formRef.current) onFormRef && onFormRef(formRef)
  }, [formRef])

  async function validateForm(isShowMessages = true) {
    const formData = formRef.current?.getData() || null
    formRef.current?.setErrors({})
    const errors = {} as any
    if (formData) {
      var form = await farms.map(async (_, index) => {
        const farmsFiltered = formData.farms[index]['areas'].filter(
          (area: any) => area?.name || area?.name == '',
        )
        try {
          await areaForm.validate(
            { areas: farmsFiltered },
            { abortEarly: !isShowMessages },
          )
          return true
        } catch (err) {
          if (err instanceof yup.ValidationError) {
            err.inner.forEach((e) => {
              errors[(`farms[${index}].` + e.path) as string] = e.message
            })
          }
          return false
        } finally {
          if (formRef && formRef.current) {
            if (isShowMessages) formRef.current.setErrors(errors)
          }
        }
      })
      for (let i = 0; i < farms.length; i++) {
        if (!(await form[i]).valueOf()) {
          validateDataForm && validateDataForm(false)
          return false
        }
      }
      validateDataForm && validateDataForm(true)
      return true
    }
    return false
  }

  return (
    <section className="createArea__container">
      <h1 id="focoaqui">Retiro</h1>
      <Form ref={formRef as any} onSubmit={() => {}}>
        <TextInput hidden={true} value={'areas'} name="form" />
        {farms.map((farm, index) => (
          <Scope key={index} path={`farms[${index}]`}>
            <FarmContainer
              formRef={formRef}
              farmid={index}
              areaSchema={areaForm}
              farm={farm}
              isEdition={isEdition}
              disableEditFields={disableEditFields}
              dataAreas={dataAreas}
              managersList={managersList}
              validateDataForm={validateForm}
            />
          </Scope>
        ))}
      </Form>
    </section>
  )
})
