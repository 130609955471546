import { useEffect, useState } from "react";
import { useOnboarding } from "../../../../hooks/useOnboarding";
import { ButtonOnlyTitle } from "../../../Buttons/ButtonOnlyTitle/Index";
import "./styles.css";

type FooterControllsProps = {
  backStepEvent?: () => boolean | Promise<boolean>;
  nextStepEvent?: () => boolean | Promise<boolean>;
  skipStepEvent?: () => boolean | Promise<boolean>;
};

export function FooterControlls({
  nextStepEvent,
  skipStepEvent,
  backStepEvent,
}: FooterControllsProps) {
  const {
    advanceStep,
    userProgress,
    screenNumber,
    subScreenNumber,
    skipStep,
    backStep,
    blockNextStep,
  } = useOnboarding();

  const [isLast, setIsLast] = useState<boolean>(false);

  // O Retorno da promisse depende do valor que foi retorndado na callback
  const ResolvePromise = async (callback: () => boolean | Promise<boolean>) => {
    return await new Promise((resolve) => {
      return resolve(callback());
    });
  };

  async function handleBackStep() {
    if (backStepEvent) {
      // Se a promisse retornar "true" significa que estamos autorizados a voltar
      (await ResolvePromise(backStepEvent)) &&
        backStep(userProgress, screenNumber, subScreenNumber);
    } else backStep(userProgress, screenNumber, subScreenNumber);
  }

  async function handleNextStep() {
    if (nextStepEvent) {
      // Se a promisse retornar "true" significa que estamos autorizados a avançar
      (await ResolvePromise(nextStepEvent)) &&
        advanceStep(userProgress, screenNumber, subScreenNumber);
    } else advanceStep(userProgress, screenNumber, subScreenNumber);
  }

  async function handleSkipStep() {
    if (skipStepEvent) {
      // Se a promisse retornar "true" significa que estamos autorizados a pular
      (await ResolvePromise(skipStepEvent)) &&
        skipStep(userProgress, screenNumber, subScreenNumber);
    } else skipStep(userProgress, screenNumber, subScreenNumber);
  }

  useEffect(() => {
    const sectionQntd = userProgress?.length;
    if (sectionQntd >= 0) {
      const subSectionQntd = userProgress[userProgress.length - 1]?.screens?.length;
      setIsLast(sectionQntd === screenNumber && subScreenNumber === (parseInt(subSectionQntd + "") - 1));
    }
  }, [userProgress]);

  return (
    <div className="footer-controlls__container">
      {screenNumber === 1 && subScreenNumber === 0 ? (
        false
      ) : (
        <ButtonOnlyTitle
          onClick={handleBackStep}
          id="buttonprevstep"
          title="Voltar"
          theme="light"
        />
      )}

      <ButtonOnlyTitle
        title={isLast ? "Concluir" : "Avançar"}
        disabled={blockNextStep}
        onClick={handleNextStep}
        id="buttonnextstep"
        theme="info"
      />
    </div>
  );
}
