import { ReactNode, useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import "./styles.css";

import { useOnboarding } from "../../../../hooks/useOnboarding";
import { SubStep } from "../SubStep/Index";

import { ScreensProps } from "../../../../contexts/OnboardingContext";

export type SubStepsScreenProps = {
  name: string;
  element: ReactNode;
  required?: boolean;
};

type OnboardingOptionProps = {
  title: string;
  description?: string;
  stepNumber: number;
  subSteps?: Array<SubStepsScreenProps>;
  required?: boolean;
};

export function StepByStep({ description, stepNumber, subSteps, title }: OnboardingOptionProps) {
  const {
    setAndRederTopScreen,
    unMountUserProgress,
    updateUserProgress,
    setScreenNumber,
    subScreenNumber,
    setShowScreen,
    userProgress,
    screenNumber,
  } = useOnboarding();

  // Isto diz se a tela está com os passos completos
  const [isActive, setIsActive] = useState<boolean>(false);

  // Isto diz se nos passos algum foi pulado
  const [hasJumping, setHasJumping] = useState(false);

  // Isto Monitora a Tela que está ativa e define se a seção está completa
  useEffect(() => {
    const progress = userProgress[stepNumber - 1];
    if (progress && stepNumber < screenNumber) {
      progress.screens?.every((e) => e.type !== "skiped" && e.type !== "nothing") ? setIsActive(true) : setIsActive(false);
      progress.screens?.some((e) => e.type === "skiped") ? setHasJumping(true) : setHasJumping(false);
    }
  }, [screenNumber]);

  useEffect(() => {
    // Isto faz uma varredura das telas a serem gerenciadas
    const screens: Array<ScreensProps> = [];
    subSteps?.forEach((e, i) => {
      screens.push({
        name: e.name,
        element: e.element,
        type: i === 0 && stepNumber == 1 ? "visualized" : "nothing",
        required: e.required,
      } as ScreensProps);
    });

    // Isto manda a subtela para o contexto
    updateUserProgress({
      section: stepNumber,
      screens,
    });

    // Isto define qual a primeira tela a ser renderizada
    if (stepNumber === 1) {
      const section = [
        {
          section: stepNumber,
          screens,
        },
      ];
      setShowScreen(section, 1, 0);
    }

    // Na desmontagem do componente removemos o progresso
    return () => {
      unMountUserProgress();
    }
  }, []);

  return (
    <>
      <section className="sbs__container">
        <div className="sbs__content--main">
          <section className="sbs__container--steps">
            {stepNumber !== 1 && <div className="subStep__verticalLine" />}
            <div
              className={`contentStep__main ${isActive && "contentStep__main--active"} ${
                hasJumping && "contentStep__main--skiped"
              }`}
            >
              <strong>{stepNumber}</strong>
              {/* O texto sobrepoe todo o container para uma melhor captura de evento */}
              <button
                className="sbs__main--title"
                onClick={() => {
                  setScreenNumber(stepNumber);
                  setAndRederTopScreen(userProgress, stepNumber, subScreenNumber);
                }}
              >
                <strong>{title}</strong>
                <span>{description}</span>
              </button>
            </div>
            {/* Aqui são renderizados as subtelas da seção */}
            <AnimateHeight height={screenNumber !== stepNumber ? 0 : "auto"}>
              {subSteps &&
                subSteps.map((subStep, ind) => (
                  <SubStep key={ind} subStep={subStep} sectionNumb={stepNumber} screenNumb={ind} />
                ))}
            </AnimateHeight>
          </section>
        </div>
      </section>
    </>
  );
}
