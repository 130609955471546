import { ICoord } from "../../../@types/GoogleMaps/ICoord";
import { generateuuid } from "../../../utils/uuid/generate";
import { CustomPopup } from "../../Leaflet/CustomPopup/Index";

import { ReactComponent as CattleIcon } from "../../../assets/svg/icons/animais.svg";

type IBatchIconProps = {
  coords: ICoord;
};

export function BatchIcon({ coords }: IBatchIconProps) {
  return (
    <CustomPopup key={generateuuid()} position={coords} offset={[0, 40]}>
      <div className="labelbatches__container">
        <CattleIcon />
      </div>
    </CustomPopup>
  );
}
