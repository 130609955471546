import { useCallback } from "react";
import { Scope } from "@unform/core";
import { TextInput } from "../TextInputs/TextInput/Index";
import { CounterInput } from "../Counters/CounterInput/Index";
import { ICategory } from "../../../@types/app/ICategory";
import { ILotCategory } from "../../../@types/app/ILot";

interface SelectWithInputProps {
  onlyRead: boolean;
  categoryMap: Map<number | undefined, ICategory>;
  defaultValues: Map<number, ILotCategory>;
  selected: Array<number>;
  handleChangeUA: () => void;
  handleAnywhereChanges: (change: any) => void;
}

export function SelectWithInput({
  onlyRead,
  selected,
  categoryMap,
  defaultValues,
  handleChangeUA,
  handleAnywhereChanges,
}: SelectWithInputProps) {
  const changeValue = useCallback(() => handleChangeUA(), [handleChangeUA]);

  return (
    <>
      {selected?.map((selected) => {
        const category = categoryMap.get(selected);
        const existed = defaultValues.get(selected);
        return (
          <>
            <Scope path={`categories[${category?.id}]`}>
              <div className="lot__title--category">{category?.name}</div>
              <div className="lot__subtitle--category">{`${category?.middle_weight} ${category?.unit} - ${category?.age_description}`}</div>
              <TextInput name="id" value={category?.id} hidden={true} />
              <TextInput
                name="middle_weight"
                value={category?.middle_weight.toString()}
                hidden={true}
              />
              <TextInput name="month" value={category?.month} hidden={true} />
              <CounterInput
                name="quantity"
                isInline
                onlyRead={onlyRead}
                defaultValue={existed ? existed.quantity : 0}
                onChangevalue={changeValue}
                handleAnywhereChanges={handleAnywhereChanges}
              />
            </Scope>
          </>
        );
      })}
    </>
  );
}
