import "./styles.css";

type BatchesAvailableDetailsProps = {
  reason: string;
};

export function BatchesAvailableDetails({
  reason,
}: BatchesAvailableDetailsProps) {
  return (
    <section className="batchesAvailableDetails__container">
      <h3>{reason}</h3>
    </section>
  );
}
