import { useEffect, useRef, useState } from 'react';
import ReactSelect, { Props as SelectProps } from 'react-select';
import './styles.css';
import { ReactComponent as ChevronIcon } from '../../../assets/svg/icons/chevronDown.svg';
import { useField } from '@unform/core';
import { IOptionProps, IOptionsProps } from '../../../@types/reactSelect/IOption';

interface ReactSelectProps extends SelectProps<any> {
  name: string;
  getMessageError?: (error: string) => void;
  handleAnywhereChanges?: (event?: any) => void;
  getSelectedData?: (data: IOptionProps, action?: string) => void;
}

const mHeight = '300px';
const speed = '300ms';

export function Select({
  name,
  options,
  getMessageError,
  getSelectedData,
  handleAnywhereChanges,
  ...rest
}: ReactSelectProps) {
  const selectRef = useRef<any>(null);
  const [open, setOpen] = useState(rest.menuIsOpen);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [errorSelect, setErrorSelect] = useState<string>('');
  const [value, setvalue] = useState<any>(null);
  const [selectOptions, setSelectOption] = useState<IOptionsProps>([]);
  const [upData, setUpData] = useState<boolean>(false);

  const selectStyles = () => ({
    option: (provided: any, state: any) => ({
      ...provided,
      position: 'relative',
      background: 'transparent',
      color: state.isSelected ? '#4D4D4D' : '#999999',
      textTransform: 'captilize',
      fontWeight: 'bold',
      opacity: open ? 1 : 0.5,
      transition: `all ${speed} ease-in-out`,
      padding: '0',
      paddingBottom: '20px',
      paddingLeft: '12px',
      '&:hover': {
        background: 'transparent',
        color: '#4D4D4D',
        cursor: 'pointer'
      },
      '&:last-child': {
        paddingBottom: '0'
      }
    }),
    control: (provided: any, { isDisabled }: any) => ({
      ...provided,
      borderRadius: !open ? '19.6px' : '19.6px 19.6px 0px 0px',
      background: rest.isDisabled ? '#F1F1F1' : '#FFF',
      outline: 'none',
      minHeight: '56px',
      boxShadow: 'none',
      border: `1px solid ${errorSelect ? '#ff6161' : '#e5e5e5'} !important`,
      fontSize: 18,
      transition: `all ${speed} ease-in-out`,
      zIndex: 3,
      paddingLeft: '20px',
      paddingRight: '20px',
      cursor: isDisabled ? 'not-allowed' : 'default'
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: '0px 0px 19.6px 19.6px',
      outline: 'none',
      boxShadow: 'none',
      borderLeft: `1px solid ${errorSelect ? '#ff6161' : '#e5e5e5'} !important`,
      borderRight: `1px solid ${errorSelect ? '#ff6161' : '#e5e5e5'} !important`,
      borderBottom: `1px solid ${errorSelect ? '#ff6161' : '#e5e5e5'} !important`,
      position: 'relative',
      marginTop: '-10px',
      paddingTop: '10px',
      paddingLeft: '12px',
      paddingRight: '12px',
      marginBottom: '0px',
      fontSize: 18,
      maxHeight: open ? mHeight : '0px',
      overflow: 'hidden',
      opacity: open ? 1 : 0.5,
      transition: `all ${speed} ease-in-out`,
      visibility: open ? 'visible' : 'hidden',
      animation: open ? `ease selectOpen ${speed} forwards` : `ease selectClose ${speed} forwards`,
      zIndex: 2,
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '270px'
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      transition: 'ease-in-out 400ms',
      transform: open ? 'rotateZ(0deg)' : 'rotateZ(180deg)',
    }),
    input: (defaultStyles: any) => ({
      ...defaultStyles,
      margin: 0,
      padding: 0
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: 0
    }),
    noOptionsMessage: (defaultStyles: any) => ({
      ...defaultStyles,
      with: '100%',
      padding: '12px 0',
      margin: '0px'
    })
  });

  function onSelectChanges(event?: any) {
    if (event && handleAnywhereChanges) handleAnywhereChanges(event);
  }

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (ref.state.selectValue.length === 0) return [];
          return ref.state.selectValue.map((option: any) => option.value);
        }
        if (ref.state.selectValue.length === 0) return null;
        return ref.state.selectValue[0];
      },
      setValue: (ref: any, value: any) => {
        if (value) ref.setValue(value);
      }
    });
  }, [fieldName, registerField, rest.isMulti]);


  useEffect(() => {
    if (rest.defaultMenuIsOpen) {
      setOpen(rest.defaultMenuIsOpen);
    }
  }, [rest.defaultMenuIsOpen]);

  useEffect(() => {
    if (getMessageError) getMessageError(error ? error : '');
    else setErrorSelect(() => (error ? error : ''));
  }, [error]);

  useEffect(() => {
    if (value) onSelectChanges(value);
  }, [value]);

  useEffect(() => {
    if (options && options.length) {
      setSelectOption(() =>
        options.map((option: IOptionProps) => ({
          label: String(option.label),
          value: String(option.value),
          info: option?.info
        }))
      );
      setUpData(!upData)
    }
    else {
      setSelectOption([])
    }
  }, [options]);

  useEffect(() => {
    if (rest.isDisabled) {
      setOpen(false)
    }
  }, [rest.isDisabled])

  return (
    <>
      <div
        className={errorSelect && 'select__drop--error'}
        onClick={() => {
          if (!rest.isDisabled) setOpen((prevState) => !prevState);
        }}>
        <ReactSelect
          {...rest}
          defaultValue={defaultValue}
          ref={selectRef}
          options={selectOptions}
          menuIsOpen={true}
          defaultMenuIsOpen={open}
          styles={selectStyles()}
          components={{
            ...rest.components,
            ClearIndicator: () => null,
            IndicatorSeparator: () => null,
            DropdownIndicator: () => <ChevronIcon />
          }}
          onChange={(data, { action }) => {
            getSelectedData && getSelectedData(data, action);
            if (action) setOpen(rest.closeMenuOnSelect);
            if (action) setvalue(data);
          }}
          onFocus={() => onSelectChanges()}
          className="react-select"
        />
      </div>
      {errorSelect && (
        <small className="textInput--small-error animation__fadeIn">{errorSelect}</small>
      )}
    </>
  );
}
