import { ReactComponent as InfoIcon } from "../../../../assets/svg/icons/info.svg";
import "./styles.css";

type TableVisualizationRowProps = {
  evaluation: ITaskEvaluation;
  setSelectedId: (e: number) => void;
};

export default function TableVisualizationRowTask({
  evaluation,
  setSelectedId
}: TableVisualizationRowProps) {
  const created = new Date(evaluation?.created_at)
  const updated = new Date(evaluation?.updated_at)

  return (
    <div className="tableRowTaskEvaluation">
      <div>{evaluation.name}</div>
      <div>{evaluation.task}</div>
      <div>{evaluation.micro_area_initials}</div>
      <div>{evaluation.status}</div>
      <div>
        {evaluation?.created_at ?
          `${String(created.getDate()).padStart(2, "0")
          }/${String(created.getMonth() + 1).padStart(2, "0")
          }/${String(created.getFullYear() % 100).padStart(2, "0")
          } - ${String(created.getHours()).padStart(2, "0")
          }:${String(created.getMinutes()).padStart(2, "0")
          }` :
          ""}
      </div>
      <div>
        {evaluation?.updated_at ?
          `${String(updated.getDate()).padStart(2, "0")
          }/${String(updated.getMonth() + 1).padStart(2, "0")
          }/${String(updated.getFullYear() % 100).padStart(2, "0")
          } - ${String(updated.getHours()).padStart(2, "0")
          }:${String(updated.getMinutes()).padStart(2, "0")
          }` :
          ""}
      </div>
      <div className="infoButton">
        <div hidden={evaluation.status != "Concluído"}>
          <InfoIcon
            onClick={() => {
              setSelectedId(evaluation.id)
            }}
          />
        </div>
      </div>
    </div>
  );
}