import { InfoBox, Polyline } from "@react-google-maps/api";
import "./styles.css";

import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { useGoogleMaps } from "../../../../hooks/useGoogleMaps";
import { LabelIcon } from "../LabelIcon/Index";
import { IPositionProps } from "../../../../@types/GoogleMaps/IPositionProps";

type FixedAreasProps = {
  area: IPositionProps;
  farmInitials?: string;
  areaInitials?: string;
};

export function FixedAreas({ area, farmInitials, areaInitials }: FixedAreasProps) {
  const { getCenterPolygon } = useGoogleMaps();
  const areasConfigs = {
    strokeColor: "#fff",
    strokeOpacity: 1,
    strokeWeight: 3,
  };

  return area && area.coords.length > 0 ? (
    <>
      <Polyline
        editable={false}
        draggable={false}
        path={[...area.coords, area.coords[0]]}
        options={areasConfigs}
      />
      <InfoBox
        position={getCenterPolygon([...area.coords, area.coords[0]]) as never}
        options={{ closeBoxURL: "", pixelOffset: new google.maps.Size(-70, -30) }}
      >
        <div className="labelarea__container">
          <LabelIcon Icon={AreaIcon} label={areaInitials} />
        </div>
      </InfoBox>
    </>
  ) : (
    <></>
  );
}
