import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as BackIcon } from "../../../../assets/svg/icons/back.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as GrassIcon } from "../../../../assets/svg/icons/grass.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";
import { ButtonYesOrNo } from "../../../Buttons/ButtonYesOrNo";

interface InfoProductIncomingProps {
  task: ITaskProductIncoming | undefined;
}

export default function InfoProductIncoming(task: InfoProductIncomingProps) {
  const [taskProductIncoming, setTaskProductIncoming] = useState<ITaskProductIncoming>();
  useEffect(() => {
    setTaskProductIncoming(task.task)
  }, []);

  return (
    <>
      <MessageBalloon text={`Recebeu o Produto?`} />

      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <MessageBalloon text={`${taskProductIncoming?.sub_classification} - ${taskProductIncoming?.classification} - ${taskProductIncoming?.manufacturer}`} pointed />

        <div style={{ display: "flex", gap: 10 }}>
          <MessageBalloon text={`${taskProductIncoming?.quantity_ordered}`} />
          <MessageBalloon text={`quilos`} />
        </div>
      </div>

      <div className="containerQuestion">
        <MessageBalloon text={`A tarefa foi concluída?`} pointed />
        <ButtonYesOrNo onlyResult optionSelected={!taskProductIncoming?.skip_reason ? "yes" : "no"} />
      </div>

      {taskProductIncoming?.skip_reason ?
        <MessageBalloon text={`${taskProductIncoming?.skip_reason}`} pointed />
        :
        <CounterInputEdit isDisabled defaultValue={taskProductIncoming?.quantity_received || 0} control={undefined} isInline label="km" />
      }
    </>
  )
}