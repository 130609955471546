import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MultipleSelect } from "../../MultipleSelect/Index";
import axios from "../../../services/axios";
import {
  IOptionProps,
  IOptionsProps,
} from "../../../@types/reactSelect/IOption";
import { MessageBalloon } from "../../MessageBalloon/Index";
import { CounterInputEdit } from "../../EditOnboarding/components/Input/CounterInput/Index";
import "./style.css";
import { capitalize } from "../../../utils/strings/capitalize";
import { useTaskCreation } from "../../../hooks/useTaskCreation";
import { sortBy } from "../../../utils/arrays/sortBy";

interface FertilizationProps {}

interface SelectedProductProps extends IOptionProps {
  weight?: number;
}

type SelectedProductsProps = Array<SelectedProductProps>;

export const Fertilization: React.FC<FertilizationProps> = () => {
  const { control } = useForm();
  const {
    payload,
    setPayload,
    setAllowTaskCreation,
    responsibleId,
    microAreaId,
  } = useTaskCreation();

  const [productOptions, setProductOptions] = useState<IOptionsProps>([]);
  const [selectedProducts, setSelectedProducts] =
    useState<SelectedProductsProps>([]);
  const [showProductsCounter, setShowProductsCounter] =
    useState<boolean>(false);

  useEffect(() => {
    getProducts();
  }, []);

  function nameProduct(text: string) {
    var resultText = text.toLowerCase().replace(/\(([^)]+)\)/, (match, content) => {
      return '(' + content.toUpperCase() + ')';
    });

    return resultText;
  }

  const getProducts = async () => {
    await axios.get("tasks/fertilization/products").then((response) => {
      if (!response.data) return;
      setProductOptions(
        response.data
          .map((prod: { name: string; id: number }) => ({
            label: nameProduct(prod.name),
            value: prod.id,
          }))
          .sort(sortBy("label"))
      );
    });
  };

  const handleProductsSelection = (prodArray: IOptionsProps) => {
    let auxArray = prodArray.slice();
    prodArray.forEach((prod, index) => {
      selectedProducts.forEach((selProd) => {
        if (prod.value === selProd.value) auxArray[index] = selProd;
      });
    });
    setSelectedProducts(auxArray);
  };

  const handleProductWeight = (weight: any, index: number) => {
    let currProducts = selectedProducts.slice();

    currProducts[index] = { ...currProducts[index], weight: +weight };

    setSelectedProducts(currProducts);
  };

  useEffect(() => {
    if (selectedProducts.length === 0) setShowProductsCounter(false);
    else setShowProductsCounter(true);

    if (
      selectedProducts.length === 0 ||
      selectedProducts.some((prod) => typeof prod.weight === "undefined")
    )
      return setAllowTaskCreation(false);

    const noZeroWeight = !selectedProducts.some((prod) => prod.weight === 0);

    setAllowTaskCreation(noZeroWeight);
    if (noZeroWeight) {
      const products = selectedProducts.map((product) => ({
        product_id: product.value,
        weight_per_ha: product.weight,
      }));

      setPayload({
        ...payload,
        changes: [
          {
            assigned_user_id: responsibleId,
            micro_area_id: microAreaId,
            products: products,
          },
        ],
      });
    }
  }, [selectedProducts]);

  return (
    <>
      <MultipleSelect
        placeholder="Selecionar produto(s) utilizado(s)..."
        options={productOptions ?? []}
        onChange={(ev: IOptionsProps) => {
          handleProductsSelection(ev);
        }}
      />
      {showProductsCounter &&
        selectedProducts.map((product, index) => {
          return (
            <React.Fragment key={product.value}>
              <div className="fertilization-counter-container">
                <div className="balloon-container">
                  <MessageBalloon
                    text="Qual a quantidade em quilo por hectares do
                    produto?"
                    pointed
                  />
                </div>
                <span className="product-label">
                  {capitalize(nameProduct(product.label))}
                </span>
                <CounterInputEdit
                  defaultValue={0}
                  control={control}
                  isInline
                  label="kg/ha"
                  onChangevalue={(ev) => handleProductWeight(ev, index)}
                  max={99999999}
                />
              </div>
            </React.Fragment>
          );
        })}
    </>
  );
};
