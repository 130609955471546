import {
  CSSProperties,
  ElementType,
  InputHTMLAttributes,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import { Controller } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { Props as InputProps } from "react-select";

import "./styles.css";
type THEME_BUTTON = "normal" | "none" | "read-only";

interface ITextComponent extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  control: any;
  errorField?: string;
  disabled?: boolean;
  theme: THEME_BUTTON;
  labelPrefix?: string;
  mask?: string | (string | RegExp)[];
  prefixPosition?: "left" | "right";
  overrideStyles?: {
    container?: CSSProperties;
    contentInput?: CSSProperties;
    prefixLabel?: CSSProperties;
    input?: CSSProperties;
  };
  errorText?: string;
  getMessageError?: (error: string) => void;
  Icon: ElementType;
}

export function InputIcon({
  getMessageError,
  Icon,
  mask,
  errorText,
  name,
  control,
  errorField,
  disabled = false,
  labelPrefix,
  overrideStyles,
  prefixPosition = "right",
  ...props
}: ITextComponent) {
  const inputUniqueId = useId();
  const [inputContentTheme, setInputContentTheme] =
    useState<THEME_BUTTON>("none");
  const [fieldError, setFieldError] = useState<string | null>(null);
  return (
    <>
      <div
        className={`textInputIcon-label__container${
          disabled ? "-disabled" : ""
        }`}
        style={overrideStyles?.container}
      >
        <div
          className={`rhf__input--content${
            !!fieldError || errorText ? "Error" : ""
          } rhf__input--theme-${inputContentTheme} rhf__prefix-on-${prefixPosition}`}
          style={overrideStyles?.contentInput}
        >
          <label
            htmlFor={`${inputUniqueId}-rhf-text-input`}
            className="rhf__prefix--label"
            style={overrideStyles?.prefixLabel}
          >
            {labelPrefix}
          </label>
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({
              field: { name, onChange, value, onBlur },
              fieldState: {},
              formState: { errors },
            }) => {
              setFieldError(
                !!errors[name] ? (errors[name]?.message as string) : null
              );
              return (
                <ReactInputMask
                  mask={mask || ""}
                  //@ts-ignore
                  maskChar={null}
                  id={`${inputUniqueId}-rhf-text-input`}
                  style={overrideStyles?.input}
                  autoComplete="off"
                  disabled={disabled}
                  {...props}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                />
              );
            }}
          />
        </div>
        <Icon className="textInputIcon-icon" />
      </div>
      {(errorText || !!fieldError) && (
        <small className="textInputwithlabel__error animation__fadeIn">
          {errorText || fieldError}
        </small>
      )}
    </>
  );
}
