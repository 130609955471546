import "./styles.css";
import { TableEditRow } from "../../CattleMap/EvolutionGoal/Table/EditRow/Index";
import { CSSProperties, MutableRefObject, useState } from "react";
import { SimpleButtonClose } from "../../../Modal/Contents/SimpleButtonClose/Index";
import { TitleModal } from "../../../Modal/Contents/TitleModal/Index";
import Constants from "../../../../constants/Index";
import { ButtonRedirect } from "../../../Modal/Error/ButtonRedirect/Index";
import { AlertModal } from "../../../Modal/Alert/Index";
import { IGoal } from "../../../../@types/app/IGoal";
import { IGoalMonthQuantity } from "../../../../@types/Onboarding/IGoal";
import { Scope } from "@unform/core";

type DeathGoalProps = {
  onAnywhereChanges?: () => void;
  formRef: MutableRefObject<HTMLFormElement | undefined>;
  inputsErrors?: (err: string) => void;
  inputError?: (err?: string) => void;
  initData?: Array<IGoalMonthQuantity>;
  list: Array<IGoal>;
  type: string;
  startIndex?: number;
  onlyRead?: boolean;
};

export function CreateDeathGoal({
  onAnywhereChanges,
  formRef,
  inputError,
  initData,
  list,
  type,
  startIndex,
  onlyRead = false,
}: DeathGoalProps) {
  const [alertVisible, setAlertVisible] = useState<boolean>(false);

  const contentStyle = {
    width: "440px",
    height: "218px",
    background: "#FFFFFF",
    borderRadius: "20px",
    padding: "10px",
  } as CSSProperties;

  return (
    <>
      <AlertModal visible={alertVisible} contentStyle={contentStyle}>
        <SimpleButtonClose onClick={() => setAlertVisible(false)} />
        <div className="createuser__alertModalBody">
          <TitleModal>{Constants.MESSAGE_INVALID_MODAL_TITLE}</TitleModal>
        </div>
        <ButtonRedirect onClick={() => setAlertVisible(false)}>VOLTAR</ButtonRedirect>
      </AlertModal>
      {list.map((goal, index) => (
        <section className="evolution__container" id={`evolution-${goal.category.id}`}>
          <div className="deathGoal__title--container">
            <h4>{`${goal?.category?.name} ${goal?.category?.age_description}`}</h4>
          </div>
          <Scope key={index} path={`goal[${(startIndex || 0) + index}]`}>
            <TableEditRow
              key={goal.category.id}
              goalIndex={index}
              category={goal?.category?.id}
              formRef={formRef}
              inputError={inputError}
              handleInputChanges={onAnywhereChanges}
              initData={initData}
              onlyRead={onlyRead}
            />
          </Scope>
        </section>
      ))}
    </>
  );
}
