import { useEffect, useState } from "react";
import "./styles.css";

type TabContentProps = {
  render: number;
};

export function TabContent({ render }: TabContentProps) {
  const [renderTab, setRenderTab] = useState(0);

  useEffect(() => {
    setRenderTab(render);
  }, [render]);

  return (
    <>
      {renderTab === 0 && (
        <>
          <div className={`tab ${renderTab === 0 && "tab-fade-in"}`}>
            <p>
              Plataforma que fornece banco de dados do estoque de animais e sua
              distribuição na fazenda de forma didática e atualizada. Trazendo
              uma visão geral do estoque e suas movimentações com opções de
              filtros para melhor análise, além de, um painel de visão comparada
              e gestão à vista de informações sobre: nascimentos, mortes,
              compras, vendas e taxa de lotação.
            </p>

            <div className="tab-content">
              <div className="tab-text-informations">
                <div className="tab-information">
                  <h6>Foco na coleta de dados</h6>
                  <p>
                    Recursos para alimentação do banco de dados simples,
                    didáticos e rápidos.
                  </p>
                </div>

                <div className="tab-information">
                  <h6>Análise das Informações</h6>
                  <p>
                    Painéis e filtros desenvolvidos com foco nas informações
                    relevantes.
                  </p>
                </div>

                <div className="tab-information">
                  <h6>Informações Georreferenciadas</h6>
                  <p>
                    Localização para lançamento de dados e para auxílio no uso
                    diário das ferramentas.
                  </p>
                </div>

                <div className="tab-information">
                  <h6>Avisos Inteligentes</h6>
                  <p>
                    Alertas que auxiliam na operação do dia a dia da fazenda.
                  </p>
                </div>
              </div>

              {/* <div className="tab-numbers-informations">
                <h3>
                  Seus animais estão sendo
                  <br />
                  acompanhados
                </h3>
                <main>
                  <div className="piket-numbers">
                    <h1>4.232</h1>
                    <h6>animais no seu piquete 1</h6>
                  </div>

                  <div className="pasture-numbers">
                    <div>
                      <h6>1.687</h6>
                      <span>bezerros</span>
                    </div>

                    <div>
                      <h6>871</h6>
                      <span>garrotes</span>
                    </div>

                    <div>
                      <h6>2.412</h6>
                      <span>adultos</span>
                    </div>
                  </div>
                </main>
              </div> */}
            </div>
          </div>
        </>
      )}

      {renderTab === 1 && (
        <>
          <div className={`tab ${renderTab === 1 && "tab-fade-in"}`}>
            <p>
              Plataforma para controle e monitoramento da utilização das
              pastagens, que trabalha em conjunto ao Mapa de Gado compreendendo
              a distribuição dos animais na fazenda. Traz controles direcionados
              para uma análise do uso dos pastos e também informações off-line
              para trabalho a campo
            </p>

            <div className="tab-content">
              <div className="tab-text-informations">
                <div className="tab-information">
                  <h6>Avaliação de Pastagem</h6>
                  <p>
                    Relatório do status forrageiro e de infraestrutura da
                    propriedade.
                  </p>
                </div>

                <div className="tab-information">
                  <h6>Controle das Movimentações</h6>
                  <p>
                    Análises focadas na utilização das pastagens, auxiliando no
                    pastejo uniforme.
                  </p>
                </div>

                <div className="tab-information">
                  <h6>Aplicativo Off-line</h6>
                  <p>
                    Informações na palma da mão para tomada de decisão à campo
                  </p>
                </div>

                <div className="tab-information">
                  <h6>Imagens de Satélite</h6>
                  <p>
                    Imagem NDVI e mapa de calor da utilização das pastagens.
                  </p>
                </div>
              </div>

              {/* <div className="tab-numbers-informations">
                <h3>
                  Seus animais estão sendo
                  <br />
                  acompanhados
                </h3>
                <main>
                  <div className="piket-numbers">
                    <h1>4.232</h1>
                    <h6>animais no sue piquete 1</h6>
                  </div>

                  <div className="pasture-numbers">
                    <div>
                      <h6>1.687</h6>
                      <span>bezerros</span>
                    </div>

                    <div>
                      <h6>871</h6>
                      <span>garrotes</span>
                    </div>

                    <div>
                      <h6>2.412</h6>
                      <span>adultos</span>
                    </div>
                  </div>
                </main>
              </div> */}
            </div>
          </div>
        </>
      )}

      {renderTab === 2 && (
        <>
          <div className={`tab ${renderTab === 2 && "tab-fade-in"}`}>
            <p>
              Ferramenta de gestão da suplementação e mineralização do rebanho,
              auxiliando no trabalho de planejamento nutricional, controle de
              estoques e da operação, e mensurações de consumo para análise de
              desempenho.
            </p>

            <div className="tab-content">
              <div className="tab-text-informations">
                <div className="tab-information">
                  <h6>Controle do Estoque</h6>
                  <p>Controle do estoque total e nos pastos.</p>
                </div>

                <div className="tab-information">
                  <h6>Comunicação entre a equipe</h6>
                  <p>
                    Canal direto de comunicação entre gestor, capataz e
                    salgador.
                  </p>
                </div>

                <div className="tab-information">
                  <h6>Controle da Operação</h6>
                  <p>
                    Auxílio no trabalho de logística de distribuição do produto.
                  </p>
                </div>

                <div className="tab-information">
                  <h6>Mensuração do Consumo</h6>
                  <p>
                    Mensuração do suplemento mineral colocado no cocho e
                    eventuais sobras.
                  </p>
                </div>
              </div>

              {/* <div className="tab-numbers-informations">
                <h3>Sua nutrição, controlada.</h3>
                <main>
                  <div className="piket-numbers">
                    <h1>52.876 Kg</h1>
                    <h6>
                      de suplementação introduzida
                      <br />
                      no seu rebanho
                    </h6>
                  </div>
                  <div className="pasture-numbers">
                    <div>
                      <h6>11.412Kg</h6>
                      <span>sais minerais</span>
                    </div>
                    <div>
                      <h6>8.711</h6>
                      <span>proteína</span>
                    </div>
                    <div>
                      <h6>36.213</h6>
                      <span>vitaminas</span>
                    </div>
                  </div>
                </main>
              </div> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}
