import { useEffect, useState } from "react";
import { CounterInputEdit } from "../../../EditOnboarding/components/Input/CounterInput/Index";
import { MessageBalloon } from "../../../MessageBalloon/Index";
import { ReactComponent as PointIcon } from "../../../../assets/svg/icons/point.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/svg/icons/areaIcon.svg";
import { ReactComponent as AnimalsIcon } from "../../../../assets/svg/icons/animais.svg";
import { ReactComponent as GrassIcon } from "../../../../assets/svg/icons/grass.svg";
import { ButtonPressed } from "../../../Buttons/ButtonPressed";
import { ButtonYesOrNo } from "../../../Buttons/ButtonYesOrNo";
import { format } from "date-fns";

interface InfoContourFarmingProps {
  task: ITaskContourFarming | undefined;
}


export default function InfoContourFarming(task: InfoContourFarmingProps) {
  const [taskContourFarming, setTaskContourFarming] = useState<ITaskContourFarming>();
  useEffect(() => {
    setTaskContourFarming(task.task)
  }, []);


  return (
    <>
      <div >
        <span>No pasto</span>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <GrassIcon width={30} className="iconInitialTaskInfo" />
          <div className="labelInitialTaskInfo">
            <span>{taskContourFarming?.initials}</span>
            <PointIcon width={15} />
          </div>
        </div>
      </div>

      <div className="containerQuestion">
        <MessageBalloon text={`A tarefa foi iniciada?`} pointed />
        <ButtonYesOrNo onlyResult optionSelected={!taskContourFarming?.skip_reason ? "yes" : "no"} />
      </div>

      {taskContourFarming?.skip_reason ?
        <MessageBalloon text={`${taskContourFarming?.skip_reason}`} pointed />
        :
        <>
          <MessageBalloon text={`${format(new Date(taskContourFarming?.start_time || 0), 'dd/MM/yyyy - HH:mm')}`} />

          <MessageBalloon text={`Informar horímetro inicial`} />
          <CounterInputEdit isDisabled defaultValue={taskContourFarming?.initial_hour_meter || 0} control={undefined} isInline label="h" />


          <ButtonPressed onlyResult optionSelected={1} value={1} text={`Horímetro final`} />

          <MessageBalloon text={`${format(new Date(taskContourFarming?.end_time || 0), 'dd/MM/yyyy - HH:mm')}`} />

          <MessageBalloon text={`Informar horímetro final`} />
          <CounterInputEdit isDisabled defaultValue={taskContourFarming?.final_hour_meter || 0} control={undefined} isInline label="h" />
        </>
      }
    </>
  )
}