import { useEffect, useState } from "react";
import { IAnimalCategory } from "../../../../../../@types/app/AnimalCategory/ICategory";
import { IAnimalMonth } from "../../../../../../@types/app/AnimalCategory/IMonths";
import "./styles.css";

type TableVisualizationRow = {
  category: IAnimalCategory;
  month: IAnimalMonth;
};

export function TableVisualizationRow({
  category: defaultcategory,
  month: defaultMonth,
}: TableVisualizationRow) {
  const [category, setCategory] = useState<IAnimalCategory>(defaultcategory);
  const [{ weight }, setMonthProps] = useState<IAnimalMonth>(defaultMonth);

  useEffect(() => {
    setCategory(() => defaultcategory);
    setMonthProps(() => defaultMonth);
  }, [defaultcategory, defaultMonth]);

  return (
    <div className="containerTable__content--item">
      <div>{category.name}</div>
      <div>{category.age_description}</div>
      <div>
        {weight.value} {weight.unit}
      </div>
    </div>
  );
}
