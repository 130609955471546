export const moveCharLeft = (str: any, char: any) => {
  if (char != ".") {
    if (str.length === 5 && str[str.length - 2] != ".") {
      let index = str.indexOf(char);
      if (index !== -1 && index !== 0) {
        str = str.split("");
        str.splice(index - 1, 0, str[index]);
        str.splice(index + 1, 1);
        str = str.join("");
      }
    }
  } else {
    if (str.length === 6 && str[str.length - 1] != ".") {
      let index = str.indexOf(char);
      if (index !== -1 && index !== 0) {
        str = str.split("");
        str.splice(index, 0, str[index]);
        str.splice(index, 1, str[index + 2]);
        str.splice(index + 1, 2, str[index + 1]);
        str = str.join("");
      }
    }
  }

  str = str[str.length - 1] == "." && str.length > 2 ? str / 10 + "" : str;

  if(str.split(".")[1]?.length==3)  {
    str = str * 10 + ""
  }

  return str;
};
