import "./styles.css";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { ImSearch } from "react-icons/im";
import { IOptionProps, IOptionsProps } from "../../../@types/reactSelect/IOption";

import { ReactComponent as CloseIcon } from "../../../assets/svg/icons/close.svg";

export type DropDownSearchFilterProps = {
  placeHolder: string;
  options: IOptionsProps;
  onChange?: (id: string | undefined) => void;
  exportOption?: (option: IOptionProps | undefined) => void;
  defaultOption?: IOptionProps | undefined;
};

export type DropDownSearchFilterRefProps = {
  resetDropDownFilter: () => void;
};

export const DropDownSearch = forwardRef((props: DropDownSearchFilterProps, ref) => {
  const { options, placeHolder, onChange, defaultOption, exportOption } = props;

  const [optionSelected, setOptionSelected] = useState<string | number | undefined>(undefined);

  useImperativeHandle(ref, () => ({
    resetDropDownFilter() {
      setFiltered(() => []);
      setOptionSelected(() => "");
      if (inputRef.current) inputRef.current.value = "";
    },
  }));

  const [filtered, setFiltered] = useState<IOptionsProps>([]);

  const inputRef = useRef<HTMLInputElement>(null);

  function filterList(value: string) {
    if (!!value) {
      const listFiltered = options.filter((e) => e.label.toUpperCase().includes(value.toUpperCase()));
      setFiltered(() => (value.length > 0 ? listFiltered : []));
    } else setFiltered(() => options);
  }

  function onBlur() {
    setTimeout(() => {
      setFiltered([]);
      if (inputRef && inputRef.current && !inputRef.current.value) {
        onChange && onChange(undefined);
        setOptionSelected(undefined);
      }
    }, 400);
  }

  function cancelSelection() {
    setOptionSelected("");
    if (inputRef.current) {
      inputRef.current.value = "";
      onChange && onChange(undefined);
      exportOption ? exportOption(undefined) : "";
      setFiltered(() => options);
    }
  }

  function focusInput() {
    setFiltered(() => options);
  }

  useEffect(() => {
    if (inputRef.current && !inputRef.current.value && defaultOption && defaultOption.label) {
      inputRef.current.value = defaultOption.label as string;
      setOptionSelected(() => defaultOption.value);
    }
  }, [defaultOption]);

  return (
    <div className="dropdownSearch" onBlur={onBlur}>
      <header className={filtered.length < 1 ? "" : "isOpen"}>
        <input
          type="text"
          placeholder={placeHolder}
          onChange={(text) => filterList(text.target.value)}
          ref={inputRef}
          onFocus={focusInput}
        />
        <button onClick={cancelSelection}>
          {optionSelected ? <CloseIcon className="dropdownSearch__iconclose" /> : <ImSearch />}
        </button>
      </header>
      <main>
        <AnimateHeight height={filtered.length < 1 ? 0 : "auto"}>
          <div className="dropdownSeach__listResult">
            <ul className="dropdownSeach__listResult--list">
              {filtered.map(({ label, value }, index) => (
                <li key={index}>
                  <button
                    onClick={() => {
                      inputRef && inputRef.current
                        ? ((inputRef.current.value = label), setFiltered([]))
                        : false;
                      onChange ? onChange(value) : "";
                      exportOption ? exportOption({ label, value }) : "";
                      setOptionSelected(value);
                    }}
                  >
                    <span className={!!optionSelected && optionSelected === value ? "isSelected" : ""}>
                      {label}
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </AnimateHeight>
      </main>
    </div>
  );
});
