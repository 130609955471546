import { generateuuid } from "../../utils/uuid/generate";
import { ICoords } from "./ICoord";

export type IPositionProps = {
  uuid: string;
  coords: ICoords;
};

export interface IPositionsProps extends Array<IPositionProps> {}

export const baseIPositionProps = { uuid: generateuuid(), coords: [] };
