import {
  ChangeEvent,
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import "./styles.css";

import { ReactComponent as PenEdit } from "../../../assets/svg/icons/penEdit.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/icons/close.svg";
import { ReactComponent as PlusIcon } from "../../../assets/svg/icons/plus.svg";

import { ReactComponent as TrashIcon } from "../../../assets/svg/icons/trash.svg";
import { ReactComponent as CheckIcon } from "../../../assets/svg/icons/check.svg";
import { ReactComponent as UndoIcon } from "../../../assets/svg/icons/undo.svg";
import { ReactComponent as RedoIcon } from "../../../assets/svg/icons/redo.svg";
import { ReactComponent as PositiveIcon } from "../../../assets/svg/icons/positive.svg";
import { ReactComponent as NegativeIcon } from "../../../assets/svg/icons/negative.svg";

import { useGoogleMaps } from "../../../hooks/useGoogleMaps";
import { readFileKML } from "../../../utils/kml/readFile";
import { FarmPolygon } from "../../GoogleMaps/Farm/Index";
import { Map } from "../../GoogleMaps/Map/Index";
import { ICoord, ICoords } from "../../../@types/GoogleMaps/ICoord";
import FarmMap from "../../../assets/png/farmMap.png";
import { useField } from "@unform/core";
import { Modal } from "../../Modal/Default/Index";
import { IKmlsUpload } from "../../../@types/app/IKmlUpload";
import { AlertModal } from "../../Modal/Alert/Index";
import { SimpleButtonClose } from "../../Modal/Contents/SimpleButtonClose/Index";
import { TextModal } from "../../Modal/Contents/TextModal/Index";
import { TitleModal } from "../../Modal/Contents/TitleModal/Index";
import { ButtonWithChildren } from "../../Buttons/ButtonWithChildren/Index";

type UploadFileKmlProps = {
  children: ReactNode;
  title: string;
  name: string;
  isMulti?: boolean;
  reset?: boolean;
  onMessageError?: (error: string) => void;
  handleAnywhereChanges?: (event: any) => void;
  onlyRead?: boolean;
};

const farmStyle = {
  strokeColor: "#ededed",
  fillColor: "#61FFF6",
  strokeOpacity: 1,
  fillOpacity: 0.5,
  strokeWeight: 3,
};

const farmEditStyle = {
  strokeColor: "rgba(255, 149, 1, 1)",
  fillColor: "rgba(255, 149, 1, 0.5)",
  strokeOpacity: 1,
  fillOpacity: 0.5,
  strokeWeight: 3,
};

const contentStyleModal = {
  width: "95%",
  height: "95vh",
  maxWidth: "1350px",
  maxHeight: "937px",
  borderRadius: "20px",
  overflow: "hidden",
} as CSSProperties;

const contentStyleAlert = {
  width: "335px",
  height: "237px",
  borderRadius: "20px",
} as CSSProperties;

export function FileUploadKml({
  handleAnywhereChanges,
  isMulti = false,
  onMessageError,
  children,
  title,
  name,
  reset,
  onlyRead = false
}: UploadFileKmlProps) {
  const { fitBoundsPolygon, getBounds } = useGoogleMaps();

  const inputRef = useRef(null);

  const { fieldName, defaultValue, registerField, error } = useField(name);

  // Nome do arquivo
  const [fileName, setFileName] = useState<string>("");

  // Erros no arquivo
  const [fileError, setFileError] = useState<string>("");

  // Erros do unform
  const [unformError, setUnfomError] = useState<string>("");

  // Vetor de Coodernadas
  const [farmEditPolygon, setFarmEditPolygon] = useState<IKmlsUpload>([]);

  // Vetor de Coodernadas
  const [farmPolygon, setFarmpolygon] = useState<IKmlsUpload>([]);

  // Posição central do mapa após a leitura
  const [centerMap, setCenterMap] = useState<ICoord | undefined>(undefined);

  // Controla a
  const [editPolygon, setEditPolygon] = useState<boolean>(false);

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  // Isso lida com a mensagem de erro
  function handleError(error: string) {
    if (onMessageError) onMessageError(error);
    else setFileError(error);
  }

  // Isso faz a leitura do arquivo
  async function handleFileCoords(event: ChangeEvent<HTMLInputElement>) {
    setFileError("");

    if (event.target.files) {

      const fileName = event.target.files[0].name;
      if (!fileName.includes(".kml")) {
        handleError("Por favor! Somente aquivos Kml/kmz");
        return;
      }
      const response = await readFileKML(event);
      // se a leitura devolver NULL mostramos um erro
      if (!response) {
        handleError(`Desculpe! mas não conseguimos ler o arquivo ${fileName}`);
        return;
      }
      const dataCoords = response as IKmlsUpload;
      const [coords] = dataCoords.map((data) => data.coords);
      // Se não tivermos no mínimo 3 coordenadas devolvemos um erro
      if (coords.length < 3) {
        handleError(`Desculpe! mas não conseguimos ler o arquivo ${fileName}`);
        return;
      }
      setCenterMap(fitBoundsPolygon(coords));
      setFarmpolygon(() => response as IKmlsUpload);
      setFileName(fileName);
    }
  }

  // Isso redefine o input em caso de desistência
  function resetFileSelected() {
    setFarmpolygon(() => []);
    setFileName(() => "");
    setEditPolygon(() => false);
  }

  function handleEditPolygon(coords: ICoords, key: number) {
    const polygonUpdated = farmPolygon.map((polygon, index) => {
      if (index === key) return { ...polygon, coords };
      return polygon;
    });
    setFarmEditPolygon(() => polygonUpdated);
  }

  useEffect(() => {
    resetFileSelected()
  }, [reset])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: () => {
        if (farmPolygon && farmPolygon.length > 0) {
          if (isMulti)
            return farmPolygon.map((polygon) => ({
              name: fileName,
              coords: polygon.coords,
            }));
          return { name: fileName, coords: farmPolygon[0].coords };
        }
        return null;
      },
      setValue: (ref, value: any) => {
        if (value) {
          const kml = [
            {
              name: value.name,
              coords: value.coords,
            },
          ] as IKmlsUpload;
          setCenterMap(fitBoundsPolygon(value.coords));
          setFarmpolygon(() => kml);
          setFileName(value.name);
        }
      },
      clearValue: (ref) => {
        setFarmpolygon(() => ({} as IKmlsUpload));
        setFileName("");
      },
    });
  }, [fieldName, registerField, farmPolygon, fileName]);

  useEffect(() => setUnfomError(error || ""), [error]);

  useEffect(() => {
    handleAnywhereChanges && handleAnywhereChanges(farmPolygon);
  }, [farmPolygon]);

  return (
    <>
      <Modal contentStyle={contentStyleModal} visible={editPolygon}>
        {farmEditPolygon.length > 0 && (
          <>
            <Map currentCoords={centerMap}>
              <section className="fileUploadKml__header--modal">
                <div className="uploadFileKMl__button--namekml">
                  <h6>{fileName}</h6>
                  <button onClick={resetFileSelected} type={"button"}>
                    <CloseIcon />
                  </button>
                </div>
                <button onClick={() => setEditPolygon((prev) => !prev)} type={"button"}>
                  <CloseIcon />
                </button>
              </section>

              {farmEditPolygon.map((farm, key) => (
                <FarmPolygon
                  key={key}
                  onFarmEdit={(coords) => handleEditPolygon(coords, key)}
                  farmProps={farm}
                  polygonStyle={farmEditStyle}
                  editable
                />
              ))}

              <section className="fileUploadKml__footer--modal">
                <div className="fileUploadKml__footer--modal-controlls">
                  <h6>Editar arquivo</h6>
                  <button type={"button"}>
                    <UndoIcon />
                  </button>
                  <button type={"button"}>
                    <RedoIcon />
                  </button>
                  <button className="cancelButton" type={"button"}>
                    <TrashIcon />
                  </button>
                  <button
                    className="confirmButton"
                    onClick={() => {
                      setFarmpolygon(() => farmEditPolygon);
                      setEditPolygon((prev) => !prev);
                    }}
                    type={"button"}
                  >
                    <CheckIcon />
                  </button>
                </div>
              </section>
            </Map>
          </>
        )}
      </Modal>

      <AlertModal visible={modalVisible} contentStyle={contentStyleAlert}>
        <section className="modalEdit__alert">
          <SimpleButtonClose onClick={() => { setModalVisible(!modalVisible) }} />
          <TitleModal>Você tem certeza?</TitleModal>
          <TextModal>
            Ao remover, você perde todas as edições realizadas!
          </TextModal>
          <div className="buttons-controll">
            <ButtonWithChildren>
              <div className="buttonWithChildren--content-inline">
                <PositiveIcon />
                <h6>Sim</h6>
              </div>
            </ButtonWithChildren>
            <ButtonWithChildren>
              <div className="buttonWithChildren--content-inline">
                <NegativeIcon />
                <h6>Não</h6>
              </div>
            </ButtonWithChildren>
          </div>
        </section>
      </AlertModal>

      <div className="uploadFileKMl--container">
        {!onlyRead &&
          <h6>{title}</h6>
        }
        {!onMessageError && <small>{fileError}</small>}
        <span>{unformError}</span>
        <div className="uploadFileKMl--kml">
          {farmPolygon.length > 0 ? (
            <Map
              currentCoords={centerMap}
              zoomControl={false}
              bounds={getBounds(farmPolygon[0].coords)}
            >
              {farmPolygon.map((farm, key) => (
                <FarmPolygon
                  key={key}
                  farmProps={farm}
                  polygonStyle={farmStyle}
                />
              ))}
            </Map>
          ) : (
            <img src={FarmMap} alt="mapa fazenda" />
          )}
          {!onlyRead && farmPolygon.length > 0 && (
            <button
              className="uploadFileKMl--buttonEdit"
              onClick={() => {
                if (farmPolygon.length > 0) {
                  setFarmEditPolygon(() => farmPolygon);
                  setEditPolygon((prev) => !prev);
                }
              }}
              type={"button"}
            >
              {farmPolygon.length > 0 ? (
                <PenEdit fill="#FFF" />
              ) : (
                <PlusIcon fill="#FFF" />
              )}
            </button>
          )}
        </div>
        {fileName ? (
          <div className="uploadFileKMl__button--namekml">
            <h6>{fileName}</h6>
            {!onlyRead &&
              <button onClick={resetFileSelected} type={"button"}>
                <CloseIcon />
              </button>
            }
          </div>
        ) : (
          <div className="uploadFileKMl__container">
            {!onlyRead &&
              <label htmlFor={fieldName}>{children}</label>
            }
            <input
              id={fieldName}
              ref={inputRef}
              type="file"
              defaultValue={defaultValue}
              onChange={handleFileCoords}
            />
          </div>
        )}
      </div>
    </>
  );
}
